import React, {
  KeyboardEvent, Ref, forwardRef,
} from 'react';
import { InputBaseProps } from '@mui/material/InputBase';
import { InputBase as InputBaseStyled } from './input.styles';

function InputBase(props: InputBaseProps, ref: Ref<unknown>) {
  /**
 * Prevent the use of arrow keys on inputs
  */
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  };
  /**
   * Prevent the use of the mouse wheel on an input
   */
  const handleOnWheel = () => {
    (document?.activeElement as HTMLElement)?.blur();
  };

  return (
    <InputBaseStyled
      {...props}
      autoComplete="off"
      ref={ref}
      onKeyDown={handleKeyDown}
      onWheel={handleOnWheel}
    />
  );
}

export default forwardRef(InputBase);
