import React, { useEffect } from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  ConsentForm,
  FormContainerStyled,
  InlinePostcodeField,
  InlineTextField,
} from './amra-doctor-details.styles';
import { TextField } from '../../../../components/form/fields';
import { amraDoctorsDetailsSchema } from '../../../../services/application-helpers';
import { MedicalDetailsDto } from '../../../../services/models/medical-details-dto';
import { FormFieldContainer, InputStatus } from '../../../../components/form';
import { AddressDto } from '../../../../services/models/address-dto';

function getAddressDefaultValues(address: AddressDto | null) {
  if (!address) return {};
  return Object.keys(address).reduce((acc, key) => {
    if (address[key as keyof AddressDto] === null) {
      acc[key as keyof AddressDto] = '';
    } else {
      acc[key as keyof AddressDto] = address[key as keyof AddressDto]!;
    }
    return acc;
  }, {} as AddressDto);
}

export interface AmraDoctorFormProps {
  medicalDetails: MedicalDetailsDto;
  address: AddressDto | null;
  onChange: (values: MedicalDetailsDto, isValid: boolean) => unknown;
  readOnly?: boolean;
}

function AmraDoctorForm({
  medicalDetails,
  address,
  onChange,
  readOnly = false,
}: AmraDoctorFormProps) {
  const { t } = useTranslation();
  const formMethods = useForm({
    defaultValues: {
      ...medicalDetails,
      ...getAddressDefaultValues(address),
    },
    resolver: yupResolver(amraDoctorsDetailsSchema as any),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    getValues,
    setValue,
    formState: { isValid, errors },
    getFieldState,
  } = formMethods;

  const handleChange = async (name: string, value: unknown) => {
    const key = name as keyof MedicalDetailsDto;
    setValue(key, value as string | boolean, { shouldDirty: true, shouldValidate: true });
    onChange({ ...getValues(), [key]: value }, isValid);
  };

  useEffect(() => {
    onChange(getValues(), isValid);
  }, [isValid]);

  const validAddress = !['practiceName', 'address1', 'cityName', 'postcode'].some((field) => _.isEmpty(getValues()[field as keyof MedicalDetailsDto]) || Object.keys(errors).includes(field));
  const validPhone = !getFieldState('phoneNumber').error && !_.isEmpty(getValues().phoneNumber);
  const validName = !getFieldState('gpName').error;

  return (
    <FormProvider {...formMethods}>
      <FormContainerStyled autoComplete="off">
        <Typography variant="h3">
          {t('components.amraDoctorForm.confirm')}
        </Typography>
        <Typography variant="body1">
          {t('components.amraDoctorForm.description')}
        </Typography>
        <ConsentForm sx={{ mt: 3 }}>
          <FormFieldContainer rowEnd={<InputStatus complete={validName} />}>
            <Stack direction="row" gap={3} className="MuiFormControl-root">
              <TextField
                sublabel={t('components.amraDoctorForm.gpName')}
                name="gpName"
                onChangeCommitted={handleChange}
                FormControlProps={{ sx: { flex: '1 1 auto' } }}
                autoComplete="off"
                inputProps={{ readOnly }}
              />
            </Stack>
          </FormFieldContainer>
          <FormFieldContainer rowEnd={<InputStatus complete={validAddress} />}>
            <Stack direction="column" gap={3} className="MuiFormControl-root">
              <InlineTextField
                sublabel={t('components.amraDoctorForm.practiceName')}
                name="practiceName"
                onChangeCommitted={handleChange}
                autoComplete="off"
                inputProps={{ readOnly }}
              />
              <InlineTextField
                sublabel={t('components.amraDoctorForm.address1')}
                name="address1"
                onChangeCommitted={handleChange}
                autoComplete="new-password"
                InputProps={{
                  inputProps: { maxLength: 60, autoComplete: 'off', role: 'presentation' },
                  readOnly,
                }}
              />
              <InlineTextField
                sublabel={t('components.amraDoctorForm.address2')}
                name="address2"
                onChangeCommitted={handleChange}
                InputProps={{
                  inputProps: { maxLength: 60, autoComplete: 'off', role: 'presentation' },
                  readOnly,
                }}
              />
              <InlineTextField
                sublabel={t('components.amraDoctorForm.cityName')}
                name="cityName"
                onChangeCommitted={handleChange}
                FormControlProps={{ sx: { flex: '1 1 auto' } }}
                InputProps={{
                  inputProps: { maxLength: 50, autoComplete: 'off', role: 'presentation' },
                  readOnly,
                }}
              />
              <InlineTextField
                sublabel={t('components.amraDoctorForm.county')}
                name="county"
                onChangeCommitted={handleChange}
                FormControlProps={{ sx: { flex: '1 1 auto' } }}
                InputProps={{
                  inputProps: { maxLength: 50, autoComplete: 'off', role: 'presentation' },
                  readOnly,
                }}
              />
              <InlinePostcodeField
                sublabel={t('components.amraDoctorForm.postcode')}
                name="postcode"
                onChangeCommitted={handleChange}
                FormControlProps={{ sx: { flex: '1 1 auto', '& label': { maxWidth: 300 } } }}
                inputProps={{ maxLength: 10, autoComplete: 'off', role: 'presentation' }}
                readOnly={readOnly}
              />
            </Stack>
          </FormFieldContainer>
          <FormFieldContainer rowEnd={<InputStatus complete={validPhone} />}>
            <InlineTextField
              sublabel={t('components.amraDoctorForm.phoneNumber')}
              name="phoneNumber"
              type="tel"
              onChangeCommitted={handleChange}
              InputProps={{
                inputProps: { maxLength: 20, autoComplete: 'off', role: 'presentation' },
                readOnly,
              }}
            />
          </FormFieldContainer>
        </ConsentForm>
      </FormContainerStyled>
    </FormProvider>
  );
}

export default AmraDoctorForm;
