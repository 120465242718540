import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import { ApplicationDto } from '../../../../services/models/application-dto';
import LabelAndValue, { LabelAndValueProps } from '../../../../components/label-and-value';
import Address from '../../../../components/address';
import { asClientDate, nthNumber } from '../../../../utils/converters';
import { getApplicantFullName, hasAnyThirdPartyDetails } from '../../../../services/application-helpers';
import { ThirdPartyType } from '../../../../services/models/third-party-type';

export const attributeProps: Partial<LabelAndValueProps> = {
  sx: {
    display: 'flex',
    gap: 4,
  },
  labelProps: {
    variant: 'body2',
    minWidth: 300,
  },
  valueProps: {
    variant: 'body2',
    fontWeight: 'normal',
  },
};

function ThirdPartyAttribute(props: LabelAndValueProps) {
  return (
    <LabelAndValue {...attributeProps} {...props} />
  );
}

export interface ThirdPartyDetailsProps {
  application: ApplicationDto;
  onEdit: () => unknown;
}

function ThirdPartyDetails({ application, onEdit }: ThirdPartyDetailsProps) {
  const { t } = useTranslation();
  const { thirdPartyPayer, bankDetails } = application;
  if (!hasAnyThirdPartyDetails(thirdPartyPayer, bankDetails)) {
    return (
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onEdit}
        >
          {t('components.thirdPartyDetails.add')}
        </Button>
      </Box>
    );
  }
  const address = thirdPartyPayer?.addressId ? application.addresses?.find((a) => a.id === thirdPartyPayer!.addressId) ?? null : null;
  return (
    <Stack gap={1}>
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.payerType')}
        value={thirdPartyPayer?.thirdPartyType ? t(`components.thirdPartyDetails.thirdPartyType.${thirdPartyPayer?.thirdPartyType}`) : null}
      />
      {thirdPartyPayer?.thirdPartyType === ThirdPartyType.Business && (
        <ThirdPartyAttribute
          label={t('components.thirdPartyDetails.name')}
          value={thirdPartyPayer?.companyName}
        />
      )}
      {thirdPartyPayer?.thirdPartyType === ThirdPartyType.Individual && (
        <ThirdPartyAttribute
          label={t('components.thirdPartyDetails.name')}
          value={getApplicantFullName(thirdPartyPayer)}
        />
      )}
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.email')}
        value={thirdPartyPayer?.emailAddress}
      />
      {thirdPartyPayer?.thirdPartyType === ThirdPartyType.Individual && (
        <ThirdPartyAttribute
          label={t('components.thirdPartyDetails.dateOfBirth')}
          value={asClientDate(thirdPartyPayer?.dateOfBirth)}
        />
      )}
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.address')}
        value={<Address address={address} />}
      />
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.postcode')}
        value={address?.postcode}
      />
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.accountCode')}
        value={bankDetails?.accountCode}
        marginTop={2}
      />
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.sortCode')}
        value={bankDetails?.sortCode}
      />
      <ThirdPartyAttribute
        label={t('components.thirdPartyDetails.collectionDay')}
        value={bankDetails?.collectionDay ? t('components.bankDetails.collectionDayOption', { day: nthNumber(bankDetails?.collectionDay) }) : null}
      />
      <Stack direction="row" gap={4} alignItems="center" marginTop={2}>
        <Stack direction="row" gap={1} alignItems="center" minWidth={300}>
          {bankDetails?.accountValidation && (
            <>
              <CheckCircleOutlineIcon color="success" />
              <Typography
                variant="body1"
                fontWeight="bold"
              >
                {t('components.thirdPartyDetails.validated')}
              </Typography>
            </>
          )}

        </Stack>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onEdit}
        >
          {t('components.thirdPartyDetails.edit')}
        </Button>
      </Stack>
    </Stack>
  );
}

export default ThirdPartyDetails;
