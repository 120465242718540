import React from 'react';
import { Button } from '@mui/material';
import { Document } from '../../services/models/document';
import DownloadIcon from '../../assets/icons/download-icon.svg';
import analytics from '../../services/analytics';
import { useAppSelector } from '../../store/hooks';
import { selectOnboardingToolSettings } from '../../features/settings-slice';

interface DocumentProps {
  document: Document
}

function DocumentLink({
  document,
}: DocumentProps) {
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled;
  const handleClick = () => {
    analytics.trackResourceDownload(document.name);
    const location = onboardingEnabled
      ? document.onboardingLocation ?? document.location
      : document.location;
    window.open(location);
  };

  return (
    <Button variant="text" color="secondary" sx={{ gap: '8px' }} onClick={handleClick}>
      {document.name}
      <img src={DownloadIcon} alt={document.name ?? ''} width={16} />
    </Button>
  );
}

export default DocumentLink;
