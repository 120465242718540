import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DocumentCard = styled(Card)(() => ({

}));

export const DocumentCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
  '& button': {
    fontSize: theme.typography.body2.fontSize,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& button': {
      textAlign: 'left',
    },
  },
}));
