import React, {
  Fragment, PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Stack } from '@mui/material';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import {
  Container,
  ExclusionsContainer,
  TableContainerStyled,
  TableCellInner,
  TableRow,
  TotalRatingDecisionCardContainer,
} from './decision-table.styles';
import {
  decisionHasNoInd,
  formatEmLoading,
  formatIndicativePmLoading,
  hasAnyIndicativeDecision,
  hasAnyIndicativeExclusions,
  hasAnyIndicativeLoadings,
  hasAnyLoadings,
  hasEvidence,
  hasExclusions,
  hasIndicativeLoading,
  isImmediateDecision,
  preSalesDecisionHasExclusions,
  reasonDecisionHasNoInd,
} from '../../../../services/decision-helpers';
import { DecisionType } from '../../../../services/product-helpers';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { TextWithInfo } from '../../../../components/info';
import { TotalPremiumDecisionCard } from '../decision-total';
import { ReasonDecisionIndicative } from '../../../../services/models/reason-decision-indicative';
import Exclusions from '../deferred-periods/exclusions';
import { Decision, ImmediateDecision } from '../../../../components/decision';
import { RangedPermanentLoading } from '../../../../services/models/ranged-permanent-loading';
import { RangedTemporaryLoading } from '../../../../services/models/ranged-temporary-loading';
import { ReasonDecisionAccordion } from '../deferred-periods/deferred-periods.styles';
import { ReasonDecisionRatingsTooltip } from '../reason-decision-card';
import { TotalPrice } from '../price';

function displayLoading(loading: RangedPermanentLoading | RangedTemporaryLoading) {
  if (loading.min !== loading.max) {
    return `${formatEmLoading(loading.min)} to ${formatEmLoading(loading.max)}`;
  }
  return formatEmLoading(loading.min);
}

function UnavailableTotal() {
  const { t } = useTranslation();
  return (
    <TextWithInfo
      variant="body2"
      fontWeight="bold"
      info={t('components.deferredPeriods.unavailableTotalInfo')}
    >
      {t('components.deferredPeriods.unavailable')}
    </TextWithInfo>
  );
}

function displayInicativeLoadings(indicative: ReasonDecisionIndicative | undefined): string[] {
  const { em, pm } = indicative!;
  const response = [];
  if (hasIndicativeLoading(em?.permanent)) {
    response.push(displayLoading(em?.permanent!));
  }
  if (hasIndicativeLoading(em?.temporary)) {
    response.push(`${formatIndicativePmLoading(em?.temporary!)} for ${em?.temporary?.duration} months`);
  }
  if (hasIndicativeLoading(pm?.permanent)) {
    response.push(formatIndicativePmLoading(pm?.permanent!));
  }
  if (hasIndicativeLoading(pm?.temporary)) {
    response.push(`${formatIndicativePmLoading(pm?.temporary!)} for ${pm?.temporary?.duration} months`);
  }
  return response;
}

function getRowSpan(reasonDecision: ReasonDecision, hideNonStandardRating: boolean = false) {
  return [
    hasAnyLoadings(reasonDecision)
    || (reasonDecision.decision === DecisionType.NON_STANDARD && !hideNonStandardRating)
    || reasonDecisionHasNoInd(reasonDecision),
    hasAnyIndicativeLoadings(reasonDecision),
    hasEvidence(reasonDecision),
    hasExclusions(reasonDecision),
    hasAnyIndicativeExclusions(reasonDecision),
    hasAnyIndicativeDecision(reasonDecision),
  ].filter((hasRow) => !!hasRow).length + 1;
}

interface DecisionTableRowProps extends PropsWithChildren {
  header: string
}

function DecisionTableRow({ header, children }: DecisionTableRowProps) {
  return (
    <TableRow>
      <TableCell>
        <TableCellInner>
          {header}
          <span>
            {children}
          </span>
        </TableCellInner>
      </TableCell>
    </TableRow>
  );
}

export interface DecisionTableProps {
  decision: PreSalesDecision
  productId: string
  hideNonStandardRating?: boolean
}

function DecisionTable({
  decision,
  productId,
  hideNonStandardRating = false,
}: DecisionTableProps) {
  const { t } = useTranslation();

  const hasNoInd = decision.reasonDecisions?.some(reasonDecisionHasNoInd);
  const hasAnyExclusions = preSalesDecisionHasExclusions(decision);

  return (
    <Container>
      <TableContainerStyled>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="280">{t('components.decisionTable.decisionHeader')}</TableCell>
              <TableCell component="td" data-testid="overall-decision">
                <Stack direction="row" gap={4}>
                  <Decision decision={decision.decision} />
                  {isImmediateDecision(decision.decision) && <ImmediateDecision />}
                </Stack>
              </TableCell>
            </TableRow>
            {decision && (
              <TableRow>
                <TableCell width="280">{t('components.decisionTable.totalPrice')}</TableCell>
                <TableCell component="td" align="right">
                  {!hasNoInd && (
                    <TotalRatingDecisionCardContainer>
                      {decisionHasNoInd(decision)
                        ? <UnavailableTotal />
                        : (decision.premium && <TotalPremiumDecisionCard premium={decision.premium} totalRating={decision.totalRating} priceProps={{ size: 'large' }} />)}
                    </TotalRatingDecisionCardContainer>
                  )}
                  {hasNoInd && (
                    <TextWithInfo
                      variant="body2"
                      fontWeight="bold"
                      info={t('components.decisionTable.unavailableTotalInfo')}
                    >
                      {t('components.decisionTable.unavailable')}
                    </TextWithInfo>
                  )}
                </TableCell>
              </TableRow>
            )}
            {hasAnyExclusions && (
              <TableRow>
                <TableCell>
                  {t('components.decisionTable.totalExclusions')}
                </TableCell>
                <TableCell component="td">
                  <Exclusions decision={decision} />
                </TableCell>
              </TableRow>
            )}
            {decision.consolidatedEvidences && decision.consolidatedEvidences.length > 0 && (
              <TableRow>
                <TableCell width="280">{t('components.decisionTable.evidenceRequired')}</TableCell>
                <TableCell component="td">
                  {decision.consolidatedEvidences.map((evidence) => evidence.text).join(', ')}
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
        <ReasonDecisionAccordion productId={productId}>
          <Table sx={{ top: -1 }}>
            <TableBody>
              {decision.reasonDecisions?.map((reasonDecision, index) => (
                <Fragment key={reasonDecision.reason}>
                  <TableRow>
                    <TableCell width="280" rowSpan={getRowSpan(reasonDecision, hideNonStandardRating)} component="th">{reasonDecision.reason}</TableCell>
                    <TableCell>
                      <TableCellInner>
                        {t('components.decisionTable.decision')}
                        <TextWithInfo
                          component="span"
                          variant="body2"
                          info={reasonDecision.decision === DecisionType.POSTPONE && reasonDecision.postpones && reasonDecision.postpones.map((postpone) => (
                            <Fragment key={postpone.code}>
                              {postpone.text}
                              <br />
                            </Fragment>
                          ))}
                        >
                          {t(`common.decision.${reasonDecision.decision}`)}
                        </TextWithInfo>
                      </TableCellInner>
                    </TableCell>
                  </TableRow>
                  {(hasAnyLoadings(reasonDecision)
                    || (reasonDecision.decision === DecisionType.NON_STANDARD && !hideNonStandardRating)
                    || reasonDecisionHasNoInd(reasonDecision))
                    && (
                      <DecisionTableRow header={t('components.decisionTable.rating')}>
                        {reasonDecisionHasNoInd(reasonDecision) ? (
                          <TextWithInfo
                            component="span"
                            variant="body2"
                            info={t('components.decisionTable.unavailableInfo')}
                          >
                            {t('components.decisionTable.unavailable')}
                          </TextWithInfo>
                        ) : (
                          <TextWithInfo
                            component="div"
                            info={<ReasonDecisionRatingsTooltip reasonDecision={reasonDecision} id={`${index}_${reasonDecision.reason}`} />}
                          >
                            <TotalPrice premium={reasonDecision} priceProps={{ size: 'small', showPlusSign: true }} />
                          </TextWithInfo>
                        )}
                      </DecisionTableRow>
                    )}
                  {hasEvidence(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.evidence')}>
                      {reasonDecision.evidences!.map((evidence) => evidence.text).join(', ')}
                    </DecisionTableRow>
                  )}
                  {hasExclusions(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.exclusions')}>
                      <ExclusionsContainer>
                        {reasonDecision.exclusions!.map((exclusion) => (
                          <TextWithInfo key={exclusion.code} component="span" variant="body2" info={exclusion.text}>
                            {exclusion.shortText}
                            {exclusion.duration && ` for ${exclusion.duration} months`}
                          </TextWithInfo>
                        ))}
                      </ExclusionsContainer>
                    </DecisionTableRow>
                  )}
                  {hasAnyIndicativeExclusions(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.indicativeExclusion')}>
                      <ExclusionsContainer>
                        {reasonDecision.indicative?.exclusions!.map((exclusion) => (
                          <TextWithInfo key={exclusion.code} component="span" variant="body2" info={exclusion.text}>
                            {exclusion.shortText}
                            {exclusion.duration && ` for ${exclusion.duration} months`}
                          </TextWithInfo>
                        ))}
                      </ExclusionsContainer>
                    </DecisionTableRow>
                  )}
                  {hasAnyIndicativeLoadings(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.indicativeRating')}>
                      {displayInicativeLoadings(reasonDecision?.indicative).join(` ${t('common.and')} `)}
                    </DecisionTableRow>
                  )}
                  {hasAnyIndicativeDecision(reasonDecision) && (
                    <DecisionTableRow header={t('components.decisionTable.indicativeDecision')}>
                      {t(`common.decision.${reasonDecision.indicative?.decision}`)}
                    </DecisionTableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </ReasonDecisionAccordion>
      </TableContainerStyled>
    </Container>
  );
}

export default DecisionTable;
