import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.background3,
  display: 'flex',
  height: 205,
  marginTop: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingLeft: 85,
  paddingRight: 85,
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  '& > div': {
    position: 'relative',
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 230,
    '& h1': {
      textTransform: 'uppercase',
      lineHeight: 1.1,
      [theme.breakpoints.down('lg')]: {
        width: 500,
      },
      [theme.breakpoints.down('md')]: {
        width: 300,
        fontSize: 24,
      },
    },
    '& > img': {
      position: 'absolute',
      left: 0,
      top: 0,
      [theme.breakpoints.down('sm')]: {
        transform: 'scale(0.75)',
        right: 16,
        top: 64,
      },
    },
  },
}));

export default {};
