import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { ProductDefinition } from '../services/models/product-definition';
// eslint-disable-next-line import/no-cycle
import { RootState, AppDispatch } from '../store';
import productApi from '../services/product-api';
import { Tool } from '../services/models/tool';
import toolsApi from '../services/tools-api';
import { ToolSettings } from '../services/models/tool-settings';
import agencyApi from '../services/agency-api';
import { getFirmFrnFromToken } from '../services/auth-helpers';
import { isCollegue } from '../services/auth-api';
import profileApi from '../services/profile-api';
import { getCurrentUserProfileId } from '../services/profile-helpers';
import { UserDto } from '../services/bff/models/user-dto';
import { AdvisorDto } from '../services/bff/models/advisor-dto';

export const NONE = { status: 'none' } as const;
export const LOADING = { status: 'loading' } as const;
export type AvailableSettings = {
  status: 'available',
  productDefinitions: ProductDefinition[],
  tools: ToolSettings[],
  currentUserId: string,
  user: UserDto,
  currentUserAdvisorDetails: AdvisorDto | null;
};

export type SettingsState =
  typeof NONE |
  typeof LOADING |
  AvailableSettings;

export const SETTINGS_SLICE_NAME = 'SETTINGS';

export const settingsSlice = createSlice({
  name: SETTINGS_SLICE_NAME,
  initialState: NONE as SettingsState,
  reducers: {
    reset() {
      return NONE;
    },
    startLoading() {
      return LOADING;
    },
    update(_, action: PayloadAction<AvailableSettings>) {
      return action.payload;
    },
  },
});

export const {
  reset,
  startLoading,
  update,
} = settingsSlice.actions;

export const selectSettingsState = (state: RootState) => state.settings;
export const selectAvailableSettings = (state: RootState) => (state.settings as AvailableSettings);
export const selectProducts = (state: RootState) => selectAvailableSettings(state).productDefinitions;
export const getProductDefinitionByCode = createSelector(
  [selectProducts, (state, productCode) => productCode],
  (products, productCode) => products.find((product) => product.productCode === productCode),
);
export const selectTools = (state: RootState) => (state.settings.status === 'available' ? state.settings.tools : []);
export const selectUser = (state: RootState) => (state.settings as AvailableSettings).user;
export const selectCurrentUserAdvisorDetails = (state: RootState) => (state.settings as AvailableSettings).currentUserAdvisorDetails;

export const selectOnboardingToolSettings = (state: RootState) => (
  selectTools(state).find((t) => t.tool === Tool.Onboarding));

export const selectPresalesToolSettings = (state: RootState) => (
  selectTools(state).find((t) => t.tool === Tool.PreSales));

const ONBOARDING_DISABLED: ToolSettings = { tool: Tool.Onboarding, enabled: false, productCodes: { codes: [] } };

async function userHasOnboardingAccess(): Promise<boolean> {
  if (isCollegue()) {
    return true;
  }
  const firmFrn = await getFirmFrnFromToken();
  return firmFrn ? agencyApi.getFirmOnboardingAccess(firmFrn) : false;
}

export const loadSettings = () => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const products = productApi.getProductDefinitions();
    const onboardingAccess = await userHasOnboardingAccess();
    const tools = (await toolsApi.getTools())
      .map((t) => (!onboardingAccess && t.tool === Tool.Onboarding ? ONBOARDING_DISABLED : t));
    const currentUserId = await getCurrentUserProfileId();
    const user = await profileApi.getUser(currentUserId);
    const currentUserAdvisorDetails = await agencyApi.getCurrentUserAdvisorDetails();

    dispatch(update({
      status: 'available',
      productDefinitions: await products,
      tools,
      currentUserId,
      user,
      currentUserAdvisorDetails,
    }));
  } catch (e) {
    dispatch(reset());
    throw e;
  }
};
