import * as yup from 'yup';
import { AddressDto } from './models/address-dto';
import { postcodeValidationSchema } from './application-helpers';

export enum RegistrationStep {
  FCAREFERENCE = 'fcaReference',
  FIRMDETAILS = 'firmDetails',
  LEGAL = 'legal',
  COMMISSION = 'commission',
  ADMINISTRATOR = 'administrator',
  TERMS = 'terms',
}

export enum SameCorrespondenceAddress {
  SAME = 'same',
  DIFFERENT = 'different',
}

export function getNextRegistrationStep(currentStep: RegistrationStep): RegistrationStep {
  const steps = Object.values(RegistrationStep);
  const currentIndex = steps.indexOf(currentStep);

  if (currentIndex === -1) {
    throw new Error('Invalid registration step');
  }

  const nextIndex = currentIndex + 1;

  if (nextIndex >= steps.length) {
    throw new Error('No next step available');
  }

  return steps[nextIndex];
}

export function getPreviousRegistrationStep(currentStep: RegistrationStep): RegistrationStep {
  const steps = Object.values(RegistrationStep);
  const currentIndex = steps.indexOf(currentStep);

  if (currentIndex === -1) {
    throw new Error('Invalid registration step');
  }

  const previousIndex = currentIndex - 1;

  if (previousIndex < 0) {
    throw new Error('No previous step available');
  }

  return steps[previousIndex];
}

export const fcaReferenceSchema = yup.object({
  frn: yup
    .string()
    .min(6, 'Must be at least 6 characters')
    .max(7, 'Must be at most 7 characters')
    .matches(/^\d+$/, 'Must be a number')
    .required('Required'),
});

export const firmDetailsSchema = yup.object({
  firmName: yup.string().required('Required'),
  registeredAddress_address1: yup.string().required('Required'),
  registeredAddress_address2: yup.string(),
  registeredAddress_cityName: yup.string().required('Required'),
  registeredAddress_county: yup.string(),
  registeredAddress_postcode: postcodeValidationSchema,
  sameCorrespondenceAddress: yup.string().required('Required'),
  correspondenceAddress_address1: yup
    .string()
    .when('sameCorrespondenceAddress', ([sameCorrespondenceAddress]) => (
      sameCorrespondenceAddress === SameCorrespondenceAddress.SAME
        ? yup.string().notRequired()
        : yup.string().required('Required')
    )),
  correspondenceAddress_address2: yup.string(),
  correspondenceAddress_cityName: yup
    .string()
    .when('sameCorrespondenceAddress', ([sameCorrespondenceAddress]) => (
      sameCorrespondenceAddress === SameCorrespondenceAddress.SAME
        ? yup.string().notRequired()
        : yup.string().required('Required')
    )),
  correspondenceAddress_county: yup.string(),
  correspondenceAddress_postcode: yup
    .string()
    .when('sameCorrespondenceAddress', ([sameCorrespondenceAddress]) => (
      sameCorrespondenceAddress === SameCorrespondenceAddress.SAME
        ? yup.string().notRequired()
        : postcodeValidationSchema
    )),
});
export interface RegistrationFormProps {
  onNext?: (values: Partial<FirmRegistrationDetails>) => unknown;
  onPrevious: () => unknown;
  registrationDetails: FirmRegistrationDetails | null;
}

export function getValidationSchema(step: RegistrationStep) {
  switch (step) {
    case RegistrationStep.FCAREFERENCE: return fcaReferenceSchema;
    case RegistrationStep.FIRMDETAILS: return firmDetailsSchema;
    default: throw Error(`Unexpected view ${step}`);
  }
}

export type FirmRegistrationDetails = {
  frn?: string | null;
  firmName?: string | null;
  registeredAddress?: AddressDto | null;
  correspondenceAddress?: AddressDto | null;
};

export function getRegistrationStepFromContent(content: string | null): RegistrationStep | null {
  return Object.values(RegistrationStep).find((value) => value === content) ?? null;
}

export default {};
