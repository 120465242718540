import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import { toastError, toastSuccess } from '../../../../components/toast';
import { useAppDispatch } from '../../../../store/hooks';
import { updateApplicationOwner } from '../../../../features/application-slice';
import HeaderButton from '../application-layout/header-button';
import OwnerDialog from '../../../../components/owner-dialog';
import { AgencyDto } from '../../../../services/bff/models/agency-dto';

export interface AssignApplicationProps {
  applicationId: string;
  firm?: Pick<AgencyDto, 'name' | 'frn'>;
  firmReadOnly?: boolean;
}

function AssignApplication({
  applicationId,
  firm = undefined,
  firmReadOnly = undefined,
}: AssignApplicationProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleConfirm = useCallback(async (adviserId: string) => {
    if (adviserId) {
      try {
        await dispatch(updateApplicationOwner(applicationId, adviserId));
        toastSuccess('Application assigned successfully');
        setOpen(false);
      } catch {
        toastError('Failed to assign application');
      }
    }
  }, [applicationId, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HeaderButton
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
        icon={<PersonIcon />}
      >
        {t('components.applicationHeader.assignApplication')}
      </HeaderButton>
      {open && (
        <OwnerDialog
          open
          onClose={handleClose}
          onConfirm={handleConfirm}
          title={t('components.assignApplication.title')}
          firm={firm}
          firmReadOnly={firmReadOnly}
        />
      )}
    </>
  );
}

export default AssignApplication;
