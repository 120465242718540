import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const EnableDecisionContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  width: '100%',
  '& > div': {
    width: '100%',
  },
  '& > div > p': {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

export default EnableDecisionContainer;
