import React from 'react';
import {
  Box,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Enquiry } from '../../../../services/models/enquiry';
import {
  formatAnswer,
  getClientDetailsEnquiryLine,
} from '../../../../services/enquiry-helpers';
import { Section } from './personal-information';
import { View, lifestyleView } from '../../../view';
import {
  DetailsTable,
  DetailsTableBody,
  DetailsTableCell,
  EditButton,
} from './client-details-bar.styles';
import { SectionStatus } from '../../../../services/models/section-status';

interface AdditionalInformationProps {
  enquiry: Enquiry;
  status?: SectionStatus;
  onEdit: (view: View) => void;
}

function AdditionalInformationPanel({
  enquiry,
  onEdit,
  status = undefined,
}: AdditionalInformationProps) {
  const { t } = useTranslation();

  const enquiryLine = getClientDetailsEnquiryLine(enquiry);

  if (!enquiryLine) return null;

  return (
    <Section title={t('components.additionalInformation.title')} status={status}>
      <DetailsTable>
        <DetailsTableBody>
          {enquiryLine && enquiry.allAnswers && enquiryLine.questions.map((question) => (
            <TableRow key={question.name} data-testid={question.name?.toLowerCase()}>
              <DetailsTableCell component="th">
                {question.definition.text!}
              </DetailsTableCell>
              <DetailsTableCell>
                {formatAnswer(question, enquiry.allAnswers!)}
              </DetailsTableCell>
            </TableRow>
          ))}
        </DetailsTableBody>
      </DetailsTable>
      <Box sx={{ marginTop: 'auto' }}>
        <EditButton variant="outlined" color="primary" onClick={() => onEdit(lifestyleView)}>
          {t('common.edit')}
        </EditButton>
      </Box>
    </Section>
  );
}

export default AdditionalInformationPanel;
