import React, { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DataLabel,
  DataValue,
  SectionTitle,
  StatusChip,
  Section as SectionStyled,
  EditButton,
} from './client-details-bar.styles';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { asAge, asClientDate } from '../../../../utils/converters';
import { View, editApplicantView } from '../../../view';
import { SectionStatus } from '../../../../services/models/section-status';

interface PersonalInformationProps {
  applicant: ApplicantDto;
  status?: SectionStatus;
  onEdit: (view: View) => void;
}

interface DataRowProps extends BoxProps {
  label: string;
  value: ReactNode;
}

export function convertName(value: string | null) {
  return value?.toLowerCase().replace(' ', '-');
}

export function DataRow({ value, label, ...props }: DataRowProps) {
  const id = convertName(label);
  const labelId = `${id}_label`;
  return (
    <Box data-testid={id} {...props}>
      <DataLabel id={labelId}>{label}</DataLabel>
      <DataValue aria-labelledby={labelId}>{value}</DataValue>
    </Box>
  );
}

interface SectionProps extends BoxProps {
  title: string;
  status?: SectionStatus;
}

export function Section({
  title,
  children,
  status = undefined,
}: SectionProps) {
  const { t } = useTranslation();
  return (
    <SectionStyled status={status} data-testid={convertName(title)}>
      <Stack direction="row" alignItems="center" gap={2}>
        <SectionTitle variant="h5">
          {title}
        </SectionTitle>
        {status && <StatusChip status={status} label={t(`common.sectionStatus.${status}`)} />}
      </Stack>
      <Stack minWidth={300} sx={{ flex: 1, mt: 2 }}>
        {children}
      </Stack>
    </SectionStyled>
  );
}

function PersonalInformation({
  applicant,
  status = undefined,
  onEdit,
}: PersonalInformationProps) {
  const { t } = useTranslation();
  return (
    <Section title={t('components.personalInformation.title')} status={status}>
      {applicant.dateOfBirth && (
        <DataRow
          label={t('components.personalInformation.dateOfBirth')}
          value={t('components.personalInformation.dateOfBirthWithAge', {
            dateOfBirth: asClientDate(applicant.dateOfBirth),
            age: asAge(applicant.dateOfBirth),
          })}
        />
      )}
      {applicant.age && <DataRow label={t('components.personalInformation.age')} value={applicant.age.toString()} />}
      <DataRow label={t('components.personalInformation.gender')} value={applicant.gender ? t(`common.gender.${applicant.gender}`) : null} />
      <DataRow label={t('components.personalInformation.smokerStatus')} value={applicant.smokerStatus ? t(`common.smokerStatus.${applicant.smokerStatus}`) : null} />
      <Box sx={{ marginTop: 2 }}>
        <EditButton variant="outlined" color="primary" onClick={() => onEdit(editApplicantView)} sx={{ marginTop: 'auto' }}>
          {t('common.edit')}
        </EditButton>
      </Box>
    </Section>
  );
}

export default PersonalInformation;
