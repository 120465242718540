import React, { FormEvent, useRef, useState } from 'react';
import {
  Breadcrumbs,
  FormLabel,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainer, FormFieldContainer, InputStatus } from '../../../../components/form';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActionsWithNavigation,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { ApplicationDto } from '../../../../services/models/application-dto';
import { ContactDetailsDto } from '../../../../services/models/contact-details-dto';
import { AddressDto } from '../../../../services/models/address-dto';
import {
  patchApplicantContactAddress,
  patchContactDetailsField,
} from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';
import { TextField } from '../../../../components/form/fields';
import { handleError, handlePostcodeError } from '../../../../components/form/form.utils';
import { FormInfoBar } from './contact-details-form.styles';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import applicationApi from '../../../../services/application-api';
import {
  contactDetailsValidationSchema,
  getApplicantAddress,
} from '../../../../services/application-helpers';
import useFocusScroll from '../../../../hooks/use-focus-scroll';
import AddressLookupFields from '../../../../components/form/address';

type FormData = Omit<ContactDetailsDto, 'addressId'> & Omit<AddressDto, 'id'>;

function getFromData(application: ApplicationDto, applicant: ApplicantDto): FormData {
  return {
    ...applicant.contactDetails,
    ...getApplicantAddress(application, applicant),
  };
}

export interface ContactDetailsFormProps {
  application: ApplicationDto;
  applicant: ApplicantDto;
  onSubmit: () => unknown;
  onCancel: () => unknown;
  hideNavigation?: boolean;
}

function ContactDetailsForm({
  application,
  applicant,
  onSubmit,
  onCancel,
  hideNavigation = false,
}: ContactDetailsFormProps) {
  const dispatch = useAppDispatch();
  const queue = useRef(new SequentialTaskQueue()).current;
  const cardRef = useRef<HTMLDivElement>(null);
  useFocusScroll(cardRef, 100);
  const defaultValues = getFromData(application, applicant);
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(contactDetailsValidationSchema) as any,
    mode: 'onChange',
  });
  const {
    formState: { isValid },
    setValue,
    getValues,
    resetField,
    clearErrors,
    setError,
  } = formMethods;

  const handleContactDetailsFieldChange = queue.sequentialize(async (name: string, value: unknown) => {
    const key = name as keyof Pick<ContactDetailsDto, 'phoneNumber' | 'emailAddress'>;
    try {
      const newValue = await dispatch(patchContactDetailsField(application.id, applicant.id, key, value)) as any;
      resetField(key, { defaultValue: newValue });
      setValue(key, newValue, { shouldTouch: true, shouldValidate: true });
    } catch (e) {
      handleError(e, (message) => {
        clearErrors(key);
        setError(key, { message });
      });
    }
  });

  const handleAddressChange = queue.sequentialize(async (changes: Partial<AddressDto> | null) => {
    if (changes) {
      const keys = Object.keys(changes) as (keyof Omit<AddressDto, 'id'>)[];
      if (keys.length === 1 && keys[0] === 'postcode' && typeof changes.postcode === 'string' && changes.postcode) {
        try {
          await applicationApi.lookupAddress(changes.postcode.toUpperCase());
          clearErrors('postcode');
        } catch (e) {
          handlePostcodeError(e, (message) => {
            clearErrors('postcode');
            setError('postcode', { message, type: 'focus' });
          });
          return;
        }
      }
      try {
        await dispatch(patchApplicantContactAddress(application.id, applicant.id, changes));
        keys.forEach((key) => {
          resetField(key, { defaultValue: changes[key] });
          setValue(key, changes[key], { shouldTouch: true, shouldValidate: true });
        });
      } catch (e) {
        handleError(e, (message) => {
          clearErrors(keys[0]);
          setError(keys[0], { message });
        });
      }
    }
  });

  const handleSubmit = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setSubmitting(true);
    try {
      await queue.waitForPendingTasks();
      await formMethods.handleSubmit(onSubmit)(event);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={handleSubmit} autoComplete="off">
        <DrawerCard ref={cardRef}>
          <DrawerCardHeader
            title={(
              <Breadcrumbs separator=">">
                <span>{t('components.contactDetailsForm.clientDetails')}</span>
                <span>{t('components.contactDetailsForm.title')}</span>
              </Breadcrumbs>
            )}
            action={<CancelDrawerButton onClick={onCancel} />}
          />
          <DrawerCardContent sx={{ paddingBottom: 0, paddingTop: !hideNavigation ? '98px' : 6, position: 'relative' }}>
            {!hideNavigation && (
              <FormInfoBar>
                <Typography variant="body1">{t('components.contactDetailsForm.instructions')}</Typography>
              </FormInfoBar>
            )}
            <FormLabel>{t('components.contactDetailsForm.address')}</FormLabel>
            <AddressLookupFields
              address={getValues()}
              onChange={handleAddressChange}
              sx={{ mt: 2 }}
            />
            <FormFieldContainer rowEnd={<InputStatus />}>
              <TextField
                label={t('components.contactDetailsForm.emailAddress')}
                name="emailAddress"
                type="email"
                onChangeCommitted={handleContactDetailsFieldChange}
                InputProps={{
                  inputProps: { maxLength: 40, autoComplete: 'off', role: 'presentation' },
                }}
              />
            </FormFieldContainer>
            <FormFieldContainer rowEnd={<InputStatus />} hideDivider>
              <TextField
                label={t('components.contactDetailsForm.phoneNumber')}
                name="phoneNumber"
                type="tel"
                onChangeCommitted={handleContactDetailsFieldChange}
                InputProps={{
                  inputProps: { maxLength: 20, autoComplete: 'off', role: 'presentation' },
                }}
              />
            </FormFieldContainer>
          </DrawerCardContent>
          <DrawerCardActionsWithNavigation
            onNext={handleSubmit}
            nextProps={{ disabled: !isValid || submitting }}
            hidePrevious={hideNavigation}
            hideNext={hideNavigation}
          >
            <LoadingButton
              variant={hideNavigation ? 'contained' : 'outlined'}
              color={hideNavigation ? 'primary' : 'secondary'}
              type="submit"
              disabled={!isValid}
              loading={submitting}
            >
              {t('components.contactDetailsForm.confirmButtonLabel')}
            </LoadingButton>
          </DrawerCardActionsWithNavigation>
        </DrawerCard>
      </FormContainer>
    </FormProvider>
  );
}

export default ContactDetailsForm;
