import React from 'react';
import {
  SvgIconProps,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import InfoIcon from './info.styles';

interface InfoIconProps extends Omit<TooltipProps, 'children'> {
  IconProps?: SvgIconProps;
}

function InfoTooltip({ IconProps = undefined, ...props }: InfoIconProps) {
  return (
    <Tooltip arrow {...props}>
      <InfoIcon {...IconProps} />
    </Tooltip>
  );
}

export default InfoTooltip;
