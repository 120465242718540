import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import CommissionDetailsAccordion from './commission-details-accordion';
import { QuoteCommission } from '../../services/models/quote-commission';
import { Agency } from '../../services/models/agency';

export interface CommissionDetailsProps {
  productId: string;
  commission: QuoteCommission | null | undefined;
  agency: Agency | null | undefined;
}

function CommissionDetails({
  productId,
  commission,
  agency,
}: CommissionDetailsProps) {
  const { t } = useTranslation();
  const commissionStyleLabelId = `${productId}-commissionStyle`;
  const initialSacrificeLabelId = `${productId}-initialSacrifice`;
  const renewalSacrificeLabelId = `${productId}-renewalSacrifice`;
  const dealLabelId = `${productId}-deal`;
  return (
    <CommissionDetailsAccordion productId={productId} commission={commission}>
      <Stack flexWrap="wrap">
        <Typography variant="body2" id={commissionStyleLabelId}>
          {t('components.commissionDetails.commissionStyle')}
        </Typography>
        {commission?.style && (
          <Typography variant="body2" fontWeight="bold" aria-labelledby={commissionStyleLabelId}>
            {t(`common.commissionStyle.${commission?.style}`)}
          </Typography>
        )}
      </Stack>
      <Stack flexWrap="wrap">
        <Typography variant="body2" id={initialSacrificeLabelId}>
          {t('components.commissionDetails.initialSacrificePercentage')}
        </Typography>
        {isNumber(commission?.initialSacrificePercentage) && (
          <Typography variant="body2" fontWeight="bold" aria-labelledby={initialSacrificeLabelId}>
            {commission?.initialSacrificePercentage}
            %
          </Typography>
        )}
      </Stack>
      <Stack flexWrap="wrap">
        <Typography variant="body2" id={renewalSacrificeLabelId}>
          {t('components.commissionDetails.renewalSacrificePercentage')}
        </Typography>
        {isNumber(commission?.renewalSacrificePercentage) && (
          <Typography variant="body2" fontWeight="bold" aria-labelledby={renewalSacrificeLabelId}>
            {commission?.renewalSacrificePercentage}
            %
          </Typography>
        )}
      </Stack>
      <Stack flexWrap="wrap">
        <Typography variant="body2" id={dealLabelId}>
          {t('components.commissionDetails.deal')}
        </Typography>
        {agency?.commissionTerms?.dealName && (
          <Typography variant="body2" fontWeight="bold" aria-labelledby={dealLabelId}>
            {agency.commissionTerms.dealName}
          </Typography>
        )}
      </Stack>
    </CommissionDetailsAccordion>
  );
}

export default CommissionDetails;
