import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ProductsCard, ProductsCardContent, ProductsCardHeader } from './additional-product-panel.styles';
import { AvailableProductDefinition } from '../../../../services/models/available-product-definition';
import AddProductButtons from './add-product-buttons';

interface AdditionalProductPanelProps {
  products: AvailableProductDefinition[];
  onCancel: () => void;
  onChange?: () => unknown;
}

function AdditionalProductPanel({
  products,
  onCancel,
  onChange = undefined,
}: AdditionalProductPanelProps) {
  const { t } = useTranslation();

  return (
    <ProductsCard elevation={0}>
      <ProductsCardHeader
        title={t('components.additionalProductPanel.title')}
        titleTypographyProps={{ variant: 'h3' }}
        action={(
          <IconButton aria-label={t('common.cancel')!} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        )}
      />
      <ProductsCardContent>
        <AddProductButtons products={products} onChange={onChange} />
      </ProductsCardContent>
    </ProductsCard>
  );
}

export default AdditionalProductPanel;
