import { Box, Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinkButton = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.common.greyDark,
}));

export const ToolCard = styled(Card)(({ theme }) => ({
  '& .MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(1),
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
  },
  '& .MuiListItemSecondaryAction-root': {
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      right: 'auto',
      top: 'auto',
      textAlign: 'left',
      marginTop: theme.spacing(1),
      marginLeft: 65,
    },
  },
}));

export const LeftColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  width: 340,
}));

export const MainColumn = styled(Box)(() => ({
  flex: 1,
}));

export default {};
