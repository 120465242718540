import React from 'react';
import Question from './question';
import { EnquiryLine as EnquiryLineType } from '../../../services/models/enquiry-line';
import { WithOnChangeCommitted } from '../../types';
import { GroupTags, groupQuestionsByTag } from '../../../services/enquiry-helpers';

interface EnquiryLineProps extends WithOnChangeCommitted<unknown> {
  enquiryLine: EnquiryLineType;
  disabled?: boolean;
  search: (name: string, searchValue: string) => string[] | Promise<string[]>;
  hideStatus?: boolean;
}

function EnquiryLine({
  enquiryLine,
  search,
  disabled = false,
  onChangeCommitted = undefined,
  hideStatus = undefined,
}: EnquiryLineProps) {
  if (enquiryLine.questions) {
    return (
      <>
        {groupQuestionsByTag(enquiryLine.questions, GroupTags.ALCOHOL).map((question) => (
          <Question
            key={question.path}
            question={question}
            disabled={disabled}
            onChangeCommitted={onChangeCommitted}
            search={search}
            hideStatus={hideStatus}
          />
        ))}
      </>
    );
  }
  return null;
}

export default EnquiryLine;
