import React, { useEffect, useMemo } from 'react';
import {
  FormLabel,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { QuoteCommission } from '../../../../services/models/quote-commission';
import { NumberField, SelectField } from '../../../../components/form/fields';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  productStatusUpdated,
  selectApplication,
  selectApplicationAdviserDetails,
  updateCommissionTerms,
} from '../../../../features/application-slice';
import { CommissionDetailsAccordion } from '../../../../components/commission-details';
import { ProductErrorType, toProductErrors } from '../../../../services/product-helpers';
import { FormControl } from '../../../../components/form';
import { Select } from '../../../../components/select';

export interface EditableCommissionDetailsProps {
  productId: string;
  commission: QuoteCommission | null | undefined;
  onChangeCommitted: (name: string, value: unknown) => unknown;
}

function EditableCommissionDetails({
  productId,
  commission,
  onChangeCommitted,
}: EditableCommissionDetailsProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplication);
  const adviserDetails = useAppSelector(selectApplicationAdviserDetails);
  const allowedCommissionStyles = adviserDetails?.allowedCommissionStyles ?? [];
  const commissionTermsId = application.agency?.commissionTerms?.commissionTermsId ?? '';

  const dealOptions = useMemo(() => adviserDetails?.commissionTerms?.map((term) => ({
    value: term.commissionTermsId!,
    label: term.dealName ?? '',
  })) ?? [], [adviserDetails]);
  const commissionStyle = watch('commission.style');
  const { setValue, resetField } = useFormContext();

  const handleDealChange = async (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    try {
      const terms = adviserDetails?.commissionTerms?.find((c) => c.commissionTermsId === value);
      await dispatch(updateCommissionTerms({
        commissionTermsId: terms?.commissionTermsId,
        dealName: terms?.dealName,
      }));
    } catch (e: unknown) {
      dispatch(productStatusUpdated({
        productId,
        errors: toProductErrors(e, {
          id: productId,
          type: ProductErrorType.Product,
        }),
      }));
    }
  };

  useEffect(() => {
    if (commission?.style && commission?.style !== commissionStyle) {
      resetField('commission.style', { defaultValue: commission?.style });
      setValue('commission.style', commission?.style);
    }
  }, [commission?.style]);

  return (
    <CommissionDetailsAccordion productId={productId} commission={commission}>
      <SelectField
        name="commission.style"
        options={allowedCommissionStyles}
        labelTranslationBasePath="common.commissionStyle"
        label={t('components.commissionDetails.commissionStyle')}
        sx={{ minWidth: 180 }}
        onChangeCommitted={onChangeCommitted}
        readOnly={allowedCommissionStyles.length === 1 && allowedCommissionStyles[0] === commissionStyle}
      />
      <NumberField
        name="commission.initialSacrificePercentage"
        label={t('components.commissionDetails.initialSacrificePercentage')}
        endAdornment={<InputAdornment position="end" disablePointerEvents>%</InputAdornment>}
        inputProps={{ maxLength: 3 }}
        sx={{ width: 90 }}
        min={0}
        max={100}
        onChangeCommitted={onChangeCommitted}
      />
      <NumberField
        name="commission.renewalSacrificePercentage"
        label={t('components.commissionDetails.renewalSacrificePercentage')}
        endAdornment={<InputAdornment position="end" disablePointerEvents>%</InputAdornment>}
        inputProps={{ maxLength: 3 }}
        sx={{ width: 90 }}
        min={0}
        max={100}
        onChangeCommitted={onChangeCommitted}
      />
      <FormControl>
        <FormLabel htmlFor="commissionTermsId">{t('components.commissionDetails.deal')}</FormLabel>
        <Select
          inputProps={{ id: 'commissionTermsId' }}
          name="commissionTermsId"
          value={commissionTermsId}
          onChange={handleDealChange}
          options={dealOptions}
          sx={{ minWidth: 420 }}
          readOnly={dealOptions.length === 1 && dealOptions[0].value === commissionTermsId}
        />
      </FormControl>
    </CommissionDetailsAccordion>
  );
}

export default EditableCommissionDetails;
