import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { ProgressStatus } from '../../../../services/models/progress-status';

export interface EditButtonProps extends ButtonProps {
  status: ProgressStatus;
}

function EditButton({ status, ...props }: EditButtonProps) {
  const { t } = useTranslation();
  switch (status) {
    case ProgressStatus.NotStarted: return (
      <Button
        variant="contained"
        color="primary"
        {...props}
      >
        {t('components.disclosureSection.start')}
      </Button>
    );
    case ProgressStatus.Incomplete: return (
      <Button
        variant="outlined"
        color="secondary"
        {...props}
      >
        {t('components.disclosureSection.continue')}
      </Button>
    );
    default: return (
      <Button
        variant="text"
        color="secondary"
        endIcon={<EditIcon fontSize="small" />}
        {...props}
      >
        {t('components.disclosureSection.edit')}
      </Button>
    );
  }
}

export default EditButton;
