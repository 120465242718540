import { Box, styled } from '@mui/material';
import { shouldForwardProp } from '../types';

export type DecoratedTitleProps = {
  offset?: number;
};

export const DecoratedTitle = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<DecoratedTitleProps>(['offset'], prop),
})<DecoratedTitleProps>(({ theme, offset }) => ({
  display: 'inline-block',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: offset ?? -20,
    left: 0,
    right: 0,
    height: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const CardTitleWithDetailStyled = styled(Box)(({ theme }) => ({
  '& > .card-title-detial': {
    fontFamily: theme.typography.h6.fontFamily,
  },
}));
