import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ConfirmStatements = styled(Box)(({ theme }) => ({
  '& > p': {
    marginBottom: theme.spacing(1),
  },
  '& > ul': {
    margin: 0,
    '& > li': {
      ...theme.typography.body2,
      marginBottom: theme.spacing(1),
    },
  },
}));

export default {};
