import { styled } from '@mui/material/styles';
import { Box, CardActions } from '@mui/material';

export const ProductCardActions = styled(CardActions)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
}));

export const ProductFooterText = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  padding: theme.spacing(2),
}));

export const ProductOptionsContainer = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  display: 'flex',
  gap: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
}));
