import { styled } from '@mui/material/styles';
import { alpha, Card, List } from '@mui/material';

export const CommissionCard = styled(Card)(() => ({
  textAlign: 'left',
  '& .MuiCardContent-root': {
    '&:last-child': {
      padding: 0,
    },
  },
}));

export const CommissionList = styled(List)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.primary200, 0.2),
  paddingBottom: 0,
  '& .MuiListSubheader-root': {
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiButtonBase-root': {
    '& .MuiListItemText-primary': {
      color: theme.palette.common.blue,
      ...theme.typography.body2,
    },
    '& .MuiListItemText-secondary': {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
    },
    '&:hover': {
      '& .MuiListItemText-primary': {
        textDecoration: 'underline',
      },
    },
  },
  '& .MuiListItemText-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .MuiListItem-root:last-child': {
    '& > .MuiListItemText-root': {
      alignItems: 'center',
      '& > span': {
        textTransform: 'uppercase',
        fontSize: theme.typography.caption.fontSize,
      },
      '& > p': {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
      },
    },
  },
}));

export default {};
