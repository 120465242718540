import {
  CardContent,
  CardHeader,
  Theme,
  styled,
} from '@mui/material';

function hintContainerStyle(theme: Theme): any {
  return {
    backgroundColor: theme.palette.common.primary200,
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative',
    minHeight: 50,
    '&:after': {
      backgroundColor: theme.palette.common.primary200,
      content: '""',
      position: 'absolute',
      bottom: -11,
      left: 'calc(50% - 11px)',
      width: 22,
      height: 22,
      transform: 'rotate(45deg)',
      zIndex: 1,
    },
  };
}

export const HintCardHeader = styled(CardHeader)(({ theme }) => ({
  ...hintContainerStyle(theme),
  '& .MuiCardHeader-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiCardHeader-title': {
      fontFamily: theme.typography.body1.fontFamily,
    },
  },
}));

export const HintCardContent = styled(CardContent)(({ theme }) => ({
  ...hintContainerStyle(theme),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: theme.typography.body1.fontFamily,
}));

export default {};
