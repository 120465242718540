import React from 'react';
import { Button, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectApplicationState, startApplication } from '../../../features/application-slice';
import { selectOnboardingToolSettings } from '../../../features/settings-slice';
import { UnderwriteMeEnquiryType } from '../../../services/models/underwrite-me-enquiry-type';
import { ApplicationPage } from '../../pages';
import ArrowIcon from '../../../assets/icons/arrow-forward-icon.svg';

const Title = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(8),
  color: theme.palette.common.greyDark,
}));

function PortalTitle() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const applicationState = useAppSelector(selectApplicationState);
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled;

  const handleStartApplication = async (enquiryType: UnderwriteMeEnquiryType) => {
    const application = await dispatch(startApplication(enquiryType));
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id || null }));
  };

  return (
    <>
      <Title variant="h2">
        {t('components.appBar.title')}
      </Title>
      {onboardingEnabled && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleStartApplication(UnderwriteMeEnquiryType.Full)}
          disabled={applicationState.status === 'loading'}
          sx={{ ml: 'auto', mr: 8 }}
          data-testid="start-onboarding"
          endIcon={
            <img src={ArrowIcon} alt="" width={16} />
          }
        >
          {t('components.dashboard.onboardingToolTitle')}
        </Button>
      )}
    </>
  );
}

export default PortalTitle;
