import React, { ChangeEvent, useState } from 'react';
import _ from 'lodash';
import { useController } from 'react-hook-form';
import { InputBaseProps } from '@mui/material/InputBase';
import InputBase from './input-base';
import { WithOnChangeCommitted } from '../../types';
import { asStringOrNull } from '../../../utils/converters';
import { formatPostcode } from '../../../services/application-helpers';

export interface PostcodeInputProps extends Omit<InputBaseProps, 'name' | 'type'>, WithOnChangeCommitted<string> {
  name: string;
}

function PostcodeInput({
  name,
  onChangeCommitted = undefined,
  inputProps,
  ...otherProps
}: PostcodeInputProps) {
  const [editedValue, setEditedValue] = useState<string | null>(null);
  const {
    field,
    formState: { defaultValues },
  } = useController({ name });

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEditedValue(value);
    field.onChange(value);
  };

  const handleOnBlur = () => {
    const oldValue = _.get(defaultValues, name);
    const newValue = field.value;
    setEditedValue(null);
    field.onBlur();
    if (onChangeCommitted && asStringOrNull(oldValue) !== asStringOrNull(newValue)) {
      onChangeCommitted(name, formatPostcode(newValue));
    }
  };

  const handleOnFocus = () => {
    const formattedValue = field.value ? formatPostcode(field.value) : null;
    setEditedValue(formattedValue);
  };

  const formattedValue = editedValue ?? (field.value ? formatPostcode(field.value) : '');
  return (
    <InputBase
      id={name}
      {...field}
      type="text"
      value={formattedValue}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      fullWidth
      inputProps={{
        maxLength: 8,
        autoComplete: 'new-password',
        ...inputProps,
      }}
      {...otherProps}
    />
  );
}

export default PostcodeInput;
