import React, { ReactNode } from 'react';
import { TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Conjunction,
  PostText,
  PriceText,
  StyledDuration,
} from './price.styles';
import { PermanentPremium } from '../../../../services/models/permanent-premium';
import { TemporaryPremium } from '../../../../services/models/temporary-premium';
import { numberToMoney } from '../../../../utils/converters';

function isNumber(n: number | null | undefined): n is number {
  return n !== null && n !== undefined;
}

const formatWithoutPlusSign: Partial<Intl.NumberFormatOptions> = { minimumFractionDigits: 2 };
const formatWithPlusSign: Partial<Intl.NumberFormatOptions> = { ...formatWithoutPlusSign, signDisplay: 'always' };

export interface PriceProps extends TypographyProps {
  premium: PermanentPremium | TemporaryPremium;
  size?: 'small' | 'medium' | 'large';
  frequency?: ReactNode;
  showPlusSign?: boolean;
  postText?: string | null;
}

function Price({
  premium,
  frequency = null,
  showPlusSign = undefined,
  size = undefined,
  postText = undefined,
  ...props
}: PriceProps) {
  const { t } = useTranslation();
  const formatOptions = showPlusSign ? formatWithPlusSign : formatWithoutPlusSign;
  return (
    <PriceText size={size} {...props}>
      {isNumber(premium.min) && premium.min !== premium.max && (
        <>
          {numberToMoney(premium.min, formatOptions)}
          <Conjunction size={size}>{` ${t('common.to')} `}</Conjunction>
        </>
      )}
      {isNumber(premium.max) && numberToMoney(premium.max, formatOptions)}
      {frequency && <Conjunction size={size}>{frequency}</Conjunction>}
      {'duration' in premium && premium.duration && <StyledDuration value={premium.duration} size={size} />}
      {postText && <PostText size={size}>{` ${postText}`}</PostText>}
    </PriceText>
  );
}

export default Price;
