import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Divider,
  Stack,
  StackProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AddressDto } from '../../../services/models/address-dto';
import { FormFieldContainer, InputStatus } from '..';
import { PostcodeField, TextField } from '../fields';
import PostcodeSearch from '../../address-search/postcode-search';
import { formatLoqateAddress, hasPostcodeOnly, postcodeValidationSchema } from '../../../services/application-helpers';
import applicationApi from '../../../services/application-api';
import { toastError } from '../../toast';

export interface AddressLookupFieldsProps extends Omit<StackProps, 'onChange'> {
  address: Omit<AddressDto, 'id'> | null;
  onChange: (changes: Partial<AddressDto>) => unknown;
  readOnly?: boolean;
  hideError?: boolean;
  hideHelpText?: boolean;
  hideStatus?: boolean;
}

function AddressLookupFields({
  address,
  onChange,
  readOnly = false,
  hideError = false,
  hideHelpText = false,
  hideStatus = false,
  ...props
}: AddressLookupFieldsProps) {
  const { t } = useTranslation();
  const [showAddressFields, setShowAddressFields] = useState<boolean>(!!address?.postcode && !hasPostcodeOnly(address));

  const handleFieldChange = (name: string, value: string) => onChange({ [name as keyof AddressDto]: value });

  const handleShowAddressSearch = () => {
    setShowAddressFields(false);
  };

  const handleAddressSelect = async (newAddress: AddressDto | null) => {
    try {
      if (newAddress && newAddress.id) {
        const foundAddress = await applicationApi.searchAddressById(newAddress.id);
        await postcodeValidationSchema.validate(foundAddress.postalCode);
        await onChange(formatLoqateAddress(foundAddress));
        setShowAddressFields(true);
      }
    } catch (err: unknown) {
      const message = err instanceof yup.ValidationError ? err.message : t('components.addressLookupFields.addressSelectError');
      toastError(message);
    }
  };

  return (
    <Stack gap={2} {...props}>
      {!readOnly && (
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            variant={!showAddressFields ? 'contained' : 'outlined'}
            onClick={handleShowAddressSearch}
          >
            {t('components.addressLookupFields.lookup')}
          </Button>
          <Button
            variant={showAddressFields ? 'contained' : 'outlined'}
            onClick={() => setShowAddressFields(true)}
          >
            {t('components.addressLookupFields.enterManually')}
          </Button>
        </ButtonGroup>
      )}
      {!showAddressFields && !readOnly && (
        <>
          <PostcodeSearch
            defaultValue={address?.postcode || ''}
            onChange={handleAddressSelect}
          />
          <Divider />
        </>
      )}
      {(showAddressFields || readOnly) && (
        <>
          <FormFieldContainer
            hideDivider
            hideHelpText={hideHelpText}
            rowEnd={!hideStatus && <InputStatus />}
          >
            <TextField
              sublabel={t('components.addressLookupFields.address1')}
              name="address1"
              onChangeCommitted={handleFieldChange}
              InputProps={{
                inputProps: {
                  maxLength: 60,
                  role: 'presentation',
                  autoComplete: 'off',
                },
                readOnly,
              }}
              hideError={hideError}
            />
          </FormFieldContainer>
          <FormFieldContainer
            hideDivider
            hideHelpText={hideHelpText}
            rowEnd={!hideStatus && <InputStatus />}
          >
            <TextField
              sublabel={t('components.addressLookupFields.address2')}
              name="address2"
              onChangeCommitted={handleFieldChange}
              InputProps={{
                inputProps: { maxLength: 60, autoComplete: 'off', role: 'presentation' },
                readOnly,
              }}
              hideError={hideError}
            />
          </FormFieldContainer>
          <FormFieldContainer
            hideHelpText={hideHelpText}
            rowEnd={!hideStatus && <InputStatus />}
          >
            <Stack direction="row" gap={3} className="MuiFormControl-root" alignItems="flex-start">
              <TextField
                sublabel={t('components.addressLookupFields.cityName')}
                name="cityName"
                onChangeCommitted={handleFieldChange}
                FormControlProps={{ sx: { flex: '1 1 auto' } }}
                InputProps={{
                  inputProps: { maxLength: 50, autoComplete: 'off', role: 'presentation' },
                  readOnly,
                }}
                hideError={hideError}
              />
              <PostcodeField
                sublabel={t('components.addressLookupFields.postcode')}
                name="postcode"
                onChangeCommitted={handleFieldChange}
                FormControlProps={{ sx: { flex: '1 1 auto', minWidth: 120 } }}
                inputProps={{
                  maxLength: 10, autoComplete: 'off', role: 'presentation',
                }}
                readOnly={readOnly}
                hideError={hideError}
              />
            </Stack>
          </FormFieldContainer>
        </>
      )}
    </Stack>
  );
}

export default AddressLookupFields;
