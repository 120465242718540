import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignSelf: 'flex-start',
  gap: theme.spacing(theme.mixins.mainHorizontalSpacing),
  width: '100%',
  '& > div': {
    '&.panel-enter': {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    '&.panel-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'opacity 300ms, transform 300ms',
    },
    '&.panel-exit': {
      opacity: 1,
    },
    '&.panel-exit-active': {
      opacity: 0,
      transform: 'scale(0.9)',
      transition: 'opacity 300ms, transform 300ms',
    },
  },
}));

export const DashboardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  textAlign: 'center',
  paddingTop: 100,
  paddingBottom: 50,
  paddingLeft: 85,
  paddingRight: 85,
  '& h3': {
    fontSize: 24,
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default {};
