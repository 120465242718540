import variables from '../variables';
import typography from '../typography';

const MuiTableHead = {
  styleOverrides: {
    root: () => ({
      '& th': {
        borderBottom: `1px solid ${variables.colors.yellow}`,
        ...typography.h5,
        fontSize: typography.body2.fontSize,
      },
    }),
  },
};

export default MuiTableHead;
