import React from 'react';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextWithInfo } from '../../../../components/info';
import { Exclusion } from '../../../../services/models/exclusion';
import Duration from '../../../../components/duration';

interface ReasonDecisionExclusionsProps {
  id: string;
  exclusions: Exclusion[] | null | undefined;
  label?: string | null;
}

function ReasonDecisionExclusions({ id, exclusions, label = undefined }: ReasonDecisionExclusionsProps) {
  const { t } = useTranslation();
  if (!exclusions || exclusions.length === 0) {
    return null;
  }
  const labelId = `${id}_exclusions`;
  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {label || t('components.reasonDecisionCard.exclusions')}
      </Typography>
      <Stack aria-labelledby={labelId} gap={1}>
        {exclusions.map((exclusion) => (
          <Box key={exclusion.code}>
            <TextWithInfo
              variant="body2"
              fontWeight="bold"
              info={exclusion.text}
            >
              {exclusion.shortText}
            </TextWithInfo>
            <Duration value={exclusion.duration} noSpace />
          </Box>
        ))}
      </Stack>
    </section>
  );
}

export default ReasonDecisionExclusions;
