import variables from '../variables';

const MuiTextField = {
  styleOverrides: {
    root: () => ({
      backgroundColor: variables.colors.white,
      '&.Mui-disabled': {
        backgroundColor: variables.colors.white,
      },
      '&.MuiInputBase-root': {
        backgroundColor: variables.colors.white,
      },
    }),
    select: () => ({
      backgroundColor: variables.colors.white,
      minHeight: 26,
      '&.Mui-disabled': {
        backgroundColor: variables.colors.white,
      },
    }),
  },
};

export default MuiTextField;
