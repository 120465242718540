import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { AppDispatch, RootState } from '../store';
import {
  emptyView,
  firmReferenceView,
  ProceedOptions,
  View,
} from '../routes/view';
import { FirmRegistrationDetails } from '../services/registration-helpers';

export const NONE = { status: 'none' } as const;
export const LOADING = { status: 'loading' } as const;
export type AvailableRegistration = {
  status: 'available',
  firmRegistration: FirmRegistrationDetails | null,
  view: View,
};

export type RegistrationState =
  typeof NONE |
  typeof LOADING |
  AvailableRegistration;

export const REGISTRATION_SLICE_NAME = 'REGISTRATION';

/* eslint-disable no-param-reassign */
export const registrationSlice = createSlice({
  name: REGISTRATION_SLICE_NAME,
  initialState: NONE as RegistrationState,
  reducers: {
    reset() {
      return NONE;
    },
    startLoading() {
      return LOADING;
    },
    update(_, action: PayloadAction<AvailableRegistration>) {
      return action.payload;
    },
    firmRegistrationUpdated(state, { payload }: PayloadAction<Partial<FirmRegistrationDetails>>) {
      if (state.status === 'available') {
        state.firmRegistration = state.firmRegistration ? { ...state.firmRegistration, ...payload } : payload;
      }
    },
    viewUpdated(state, { payload }: PayloadAction<View>) {
      if (state.status === 'available') {
        state.view = payload;
      }
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  reset,
  startLoading,
  update,
  firmRegistrationUpdated,
  viewUpdated,
} = registrationSlice.actions;

export const selectRegistationState = (state: RootState) => state.registration;
export const selectAvailableRegistration = (state: RootState) => (state.registration as AvailableRegistration);
export const selectView = (state: RootState) => selectAvailableRegistration(state).view ?? emptyView;
export const selectRegistrationDetails = (state: RootState) => selectAvailableRegistration(state).firmRegistration;

function getNextView(state: RegistrationState, options?: ProceedOptions): View {
  if (state.status !== 'available') {
    return emptyView;
  }
  const target = options?.target || firmReferenceView;
  if (options?.force) {
    return target;
  }
  return target;
}

export const init = () => async (dispatch: AppDispatch) => {
  dispatch(update({
    status: 'available',
    view: firmReferenceView,
    firmRegistration: null,
  }));
};

export const proceed = (options?: ProceedOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const nextView = getNextView(selectRegistationState(getState()), options);
  dispatch(viewUpdated(nextView));
};

export const updateFirmRegistration = (values: Partial<FirmRegistrationDetails>) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.registration.status === 'available') {
    dispatch(firmRegistrationUpdated(values));
  }
};
