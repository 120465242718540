import * as yup from 'yup';
import { AgencyDto } from './bff/models/agency-dto';
import { AdvisorDto } from './bff/models/advisor-dto';
import { Agency } from './models/agency';

export const SJP_FRN = 195351;

export type JournalQueryParams = {
  from: number,
  size: number,
};

export interface AgencyQueryParams {
  from?: number,
  size?: number,
}

export const assignAgencySchema = yup.object({
  firm: yup.string().required('Firm is required'),
  adviser: yup.string().required('Adviser is required'),
});

export function compareByName(a: AgencyDto, b: AgencyDto): number {
  return (a.name ?? '').localeCompare(b.name ?? '');
}

export function isSJP(adviser: AdvisorDto | null | undefined, agency: Agency | null | undefined): boolean {
  return adviser?.frn === SJP_FRN || agency?.firmFRN === SJP_FRN || agency?.networkFRN === SJP_FRN;
}
