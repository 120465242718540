import { Components } from '@mui/material';

const MuiLinearProgress: Components['MuiLinearProgress'] = {
  styleOverrides: {
    root: () => ({
      height: 6,
      borderRadius: 3,
    }),
  },
};

export default MuiLinearProgress;
