import {
  Chip,
  Drawer as MuiDrawer,
  Stack,
  styled,
} from '@mui/material';
import { shouldForwardProp } from '../../../../components/types';

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& > .MuiDrawer-paper': {
    top: theme.mixins.toolbar.minHeight,
    '& > form': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .MuiCard-root': {
      overflow: 'scroll',
      height: '100%',
      '& > .MuiCardHeader-root': {
        position: 'sticky',
        top: 0,
        zIndex: 10,
      },
      '& label': {
        scrollMarginTop: 100,
      },
    },
  },
}));

export const ReferenceChip = styled(Chip)(({ theme }) => ({
  ...theme.typography.body2,
}));

type HeaderStackProps = {
  inline: boolean
};

export const HeaderStack = styled(Stack, {
  shouldForwardProp: (prop) => shouldForwardProp<HeaderStackProps>(['inline'], prop),
})<HeaderStackProps>(({ inline, theme }) => ({
  alignItems: inline ? 'center' : 'flex-start',
  flexDirection: inline ? 'row' : 'column',
  gap: inline ? theme.spacing(2) : 0,
  marginLeft: inline ? theme.spacing(-2) : 0,
  '& > h2': {
    fontSize: inline ? 24 : theme.typography.h2.fontSize,
  },
}));
