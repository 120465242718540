import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';
import { QuestionErrorContainer } from './enquiry.styles';
import { Question } from '../../../services/models/question';
import {
  SystemOfMeasurement,
  kilogrammsToImperial,
  metersToImperial,
  toCentimeters,
} from '../../../utils/converters';

interface UnitQuestionErrorProps {
  question: Question;
  systemName: string;
}

function getBoundingUnit(name: string, bounding: number | null, units: SystemOfMeasurement) {
  if (name === 'HEIGHT_UNITS' && units === 'metric') {
    return `${toCentimeters(bounding)}cm`;
  }
  if (name === 'HEIGHT_UNITS' && units === 'imperial') {
    return `${metersToImperial(bounding).feet}ft and ${Math.round(metersToImperial(bounding).inches!)}in`;
  }
  if (name === 'WEIGHT_UNITS' && units === 'metric') {
    return `${(bounding)}kg`;
  }
  if (name === 'WEIGHT_UNITS' && units === 'imperial') {
    return `${kilogrammsToImperial(bounding).stones}st and ${Math.round(kilogrammsToImperial(bounding).pounds!)}lbs`;
  }
  return bounding;
}

function UnitQuestionError({
  question,
  systemName,
}: UnitQuestionErrorProps) {
  const { field: systemField } = useController({ name: systemName });
  const { t } = useTranslation();

  if (!_.isEmpty(question.validationErrors)) {
    return (
      <QuestionErrorContainer>
        <Typography variant="body2" color="error">
          {`Please enter a ${t(`common.units.${systemField.name}`)} between ${getBoundingUnit(systemField.name, question.definition.lowerBound, systemField.value)} and ${getBoundingUnit(systemField.name, question.definition.upperBound, systemField.value)}`}
        </Typography>
      </QuestionErrorContainer>
    );
  }
  return null;
}

export default UnitQuestionError;
