import React from 'react';
import { useController } from 'react-hook-form';
import Chip from '@mui/material/Chip';
import { isEqual } from 'lodash';
import { getMultipleAnswer } from '../../../services/enquiry-helpers';
import { WithOnChangeCommitted } from '../../types';
import Typeahead, { TypeaheadProps } from '../../typeahead/typeahead';

export interface MultiTypeaheadInputProps extends Omit<TypeaheadProps<true, false, boolean>, 'value' | 'onChange'>, WithOnChangeCommitted<string[]> {
  name: string;
}

function MultiTypeaheadInput({
  name,
  onChangeCommitted = undefined,
  ...props
}: MultiTypeaheadInputProps) {
  const {
    field: { value, ...field },
    formState: { defaultValues },
  } = useController({ name });
  const selectedValues = getMultipleAnswer(value);
  const defaultAnswer = getMultipleAnswer(defaultValues?.[name]);

  const handleChange = (event: any, newValues: string[]) => {
    field.onChange(newValues);
    if (newValues && !isEqual(newValues, defaultAnswer)) {
      if (onChangeCommitted) {
        onChangeCommitted(name, newValues);
      }
    }
  };

  const handleDelete = (valueToDelete: string) => {
    const newValues = selectedValues.filter((selectedValue) => selectedValue !== valueToDelete);
    handleChange(null, newValues);
  };

  return (
    <div>
      <Typeahead
        {...field}
        {...props}
        id={name}
        value={selectedValues}
        multiple
        onChange={handleChange}
      />
      <div style={{ marginTop: '10px' }}>
        {selectedValues.map((selectedValue) => (
          <Chip
            key={selectedValue}
            label={selectedValue}
            style={{ marginRight: '5px', marginBottom: '5px' }}
            onDelete={() => handleDelete(selectedValue)}
          />
        ))}
      </div>
    </div>
  );
}

export default MultiTypeaheadInput;
