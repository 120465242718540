import type { } from '@mui/x-date-pickers/themeAugmentation';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { createTheme } from '@mui/material/styles';
import components from './components';
import palette from './palette';
import typography from './typography';

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    infoBar: CSSProperties;
    mainHorizontalSpacing: number;
    footerBarMinHeight: number;
  }
}

const defaultTheme = createTheme();
defaultTheme.shadows[24] = '0px 0px 10px 1px rgba(0, 0, 0, 0.05)';

const theme = createTheme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      lg: 1367,
    },
  },
  components,
  mixins: {
    toolbar: {
      ...defaultTheme.mixins.toolbar,
      minHeight: 80,
      '@media (min-width:600px)': {
        minHeight: 80,
      },
    },
    infoBar: {
      height: 'auto',
      minHeight: 90,
    },
    mainHorizontalSpacing: 10,
    footerBarMinHeight: 78,
  },
  palette,
  shadows: defaultTheme.shadows,
  typography,
});

export default theme;
