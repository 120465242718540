import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../../../components/layout/layout.styles';
import AppBar from '../../../components/layout/app-bar/app-bar';
import { DashboardPage, Page, SearchPage } from '../../pages';
import {
  HeaderContainer,
  HeaderTab,
  HeaderTabs,
  Title,
} from './dashboard-title.styles';
import { getUserFirstnameFromToken } from '../../../services/auth-helpers';

function DashboardTitle() {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = React.useState<Page>(DashboardPage);
  const [name, setName] = useState<string>('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: Page) => {
    setActiveTab(newValue);
    navigate(newValue.path);
  };

  const handleGetFirstname = async () => {
    setName(await getUserFirstnameFromToken());
  };

  useEffect(() => {
    handleGetFirstname();
  }, []);

  useEffect(() => {
    if (pathname === SearchPage.path) {
      setActiveTab(SearchPage);
    } else {
      setActiveTab(DashboardPage);
    }
  }, [pathname]);

  return (
    <Container maxWidth={false} disableGutters>
      <AppBar
        logoSize="large"
        header={(
          <HeaderContainer>
            <Title gutterBottom>{`Hi ${name}, welcome to your portal.`}</Title>
            <HeaderTabs onChange={handleTabChange} value={activeTab}>
              <HeaderTab label={t('components.dashboard.tabs.dashboardLabel')} value={DashboardPage} />
              <HeaderTab
                label={t('components.dashboard.tabs.search')}
                value={SearchPage}
              />
            </HeaderTabs>
          </HeaderContainer>
        )}
      />
    </Container>
  );
}

export default DashboardTitle;
