import {
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { RadioOptionsField } from '../../../../../components/form/fields';
import { ApplicationDto } from '../../../../../services/models/application-dto';
import { booleanToYesNo, handleError, yesNoToBoolean } from '../../../../../components/form/form.utils';
import { useAppDispatch } from '../../../../../store/hooks';
import { patchApplicationAdvisedSale, patchApplicationAdviserDeclaration } from '../../../../../features/application-slice';
import { CheckboxInput } from '../../../../../components/form/inputs';
import { ConfirmStatements } from './advice-styles';
import useBusyState from '../../../../../hooks/use-busy-state';

const validationSchema = yup.object({
  advice: yup.boolean().required('Required'),
  confirm: yup.boolean().required('Required'),
});

export interface AdviceProps {
  application: ApplicationDto;
  adviceReadOnly?: boolean;
}

function Advice({ application, adviceReadOnly = false }: AdviceProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [busy, withBusyState] = useBusyState();
  const formMethods = useForm({
    defaultValues: {
      advice: booleanToYesNo(application.isAdvisedSale),
      confirm: application.adviserDeclaration ?? false,
    },
    resolver: yupResolver(validationSchema) as any,
  });
  const { clearErrors, setError } = formMethods;

  const handleChange = async (name: string, value: string | null) => {
    try {
      await dispatch(patchApplicationAdvisedSale(application.id, yesNoToBoolean(value)));
    } catch (e) {
      handleError(e, (message) => {
        clearErrors('advice');
        setError('advice', { message });
      });
    }
  };

  const handleDeclarationConfirmed = withBusyState(async (name: string, value: boolean | null) => {
    try {
      await dispatch(patchApplicationAdviserDeclaration(application.id, value));
    } catch (e) {
      handleError(e, (message) => {
        clearErrors('confirm');
        setError('confirm', { message });
      });
    }
  });

  return (
    <FormProvider {...formMethods}>
      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <RadioOptionsField
            label={t('components.advice.giveAdvice')}
            name="advice"
            options={['yes', 'no']}
            sx={{
              minWidth: 200,
            }}
            labelTranslationBasePath="common.yesNo"
            onChangeCommitted={handleChange}
            disabled={adviceReadOnly}
            hideError
          />
        </CardContent>
        <Divider />
        <CardContent data-testid="adviser-confirmation-statement">
          <CheckboxInput
            name="confirm"
            label={t('components.advice.confirmation')}
            checkedValue
            uncheckedValue={false}
            disabled={busy || !!application.adviserDeclaration}
            data-busy={busy}
            onChangeCommitted={handleDeclarationConfirmed}
          />
          <ConfirmStatements>
            <Typography variant="body2">{t('components.advice.confirmationStatements.0')}</Typography>
            <ul>
              <li>{t('components.advice.confirmationStatements.1')}</li>
              <li>{t('components.advice.confirmationStatements.2')}</li>
              <li>{t('components.advice.confirmationStatements.3')}</li>
              <li>{t('components.advice.confirmationStatements.4')}</li>
              <li>{t('components.advice.confirmationStatements.5')}</li>
            </ul>
          </ConfirmStatements>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

export default Advice;
