import React from 'react';
import { selectOnboardingToolSettings, selectPresalesToolSettings } from '../../features/settings-slice';
import { useAppSelector } from '../../store/hooks';
import Dashboard from '../dashboard';
import PreSalesIntro from '../presales-intro';

function Home() {
  const onboardingTool = useAppSelector(selectOnboardingToolSettings);
  const preSalesTool = useAppSelector(selectPresalesToolSettings);
  if (onboardingTool?.enabled) {
    return <Dashboard />;
  }
  if (preSalesTool?.enabled) {
    return <PreSalesIntro />;
  }
  return null;
}

export default Home;
