import React from 'react';
import {
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EnquiryLine } from '../../../services/models/enquiry-line';
import {
  groupQuestionsByTag,
  GroupTags,
  isQuestionComplete,
  isQuestionDisplayable,
} from '../../../services/enquiry-helpers';

export interface EnquiryLineProgressProps {
  enquiryLine: EnquiryLine;
}

function EnquiryLineProgress({ enquiryLine }: EnquiryLineProgressProps) {
  const { t } = useTranslation();
  const questions = groupQuestionsByTag(enquiryLine.questions, GroupTags.ALCOHOL)
    .filter((question) => question.path && isQuestionDisplayable(question));
  const completedQuestions = questions.filter((question) => isQuestionComplete(question));
  const progress = questions.length > 0 ? (completedQuestions.length / questions.length) * 100 : 100;
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <LinearProgress
        variant="determinate"
        color="success"
        value={progress}
        sx={{ width: 300 }}
      />
      <Typography variant="body2" data-testid="enquiry-line-progress">
        <b>{completedQuestions.length}</b>
        {t('components.enquiryLineProgress.of')}
        <b>{questions.length}</b>
        {t('components.enquiryLineProgress.completed')}
      </Typography>
    </Stack>
  );
}

export default EnquiryLineProgress;
