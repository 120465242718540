import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../components/form/fields';
import { FormContainerStyled } from './health-care-form-styles';
import { Option } from '../../../../components/types';
import { ProductEditability, ProductFormProps } from '../../../../services/product-helpers';

const mentalHealthOptions: Option[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

function HealthCareForm({
  onChangeCommitted,
  editable = ProductEditability.Editable,
}: ProductFormProps) {
  const { t } = useTranslation();

  return (
    <FormContainerStyled>
      <SelectField
        name="mentalHealthCover"
        label={t('components.healthCareForm.mentalHealthCover')}
        options={Object.values(mentalHealthOptions)}
        sx={{
          minWidth: 200,
        }}
        onChangeCommitted={onChangeCommitted}
        hideError
        readOnly={editable !== ProductEditability.Editable}
      />
    </FormContainerStyled>
  );
}

export default HealthCareForm;
