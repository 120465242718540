import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LoadingButton from '@mui/lab/LoadingButton';
import { EnableDecisionContainer } from './enable-decision.styles';
import useBusyState from '../../../../hooks/use-busy-state';

interface EnableDecisionProps {
  decisionAvailable?: boolean;
  disabled?: boolean;
  label: string;

  onClick: () => unknown;
}

function EnableDecision({
  decisionAvailable = false,
  disabled = false,
  label,
  onClick,
}: EnableDecisionProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();

  const handleDecisionButtonClick = withBusyState(onClick);

  return (
    <EnableDecisionContainer>
      {!decisionAvailable && (
        <Stack direction="row" justifyContent="space-between">
          <Typography>
            <InfoIcon color="success" />
            {t('components.enableDecision.configure')}
          </Typography>
        </Stack>
      )}
      {decisionAvailable && (
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleDecisionButtonClick}
          disabled={disabled}
          loading={busy}
        >
          {label}
        </LoadingButton>
      )}
    </EnableDecisionContainer>
  );
}

export default EnableDecision;
