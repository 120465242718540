import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { StyledDecision } from './decision.styles';

function ImmediateDecision() {
  const { t } = useTranslation();
  return (
    <StyledDecision data-testid="immediate-decision">
      <CheckCircleIcon color="success" />
      {t('common.immediateDecision')}
    </StyledDecision>
  );
}

export default ImmediateDecision;
