import React from 'react';
import { CardActionsProps, CardHeaderProps, Stack } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  DrawerCardActions,
  DrawerCardHeader as DrawerCardHeaderStyled,
} from './drawer-card.styles';

function DrawerCardHeader(props: CardHeaderProps) {
  return (
    <DrawerCardHeaderStyled
      {...props}
      titleTypographyProps={{
        variant: 'h3',
        component: 'h3',
        gutterBottom: true,
      }}
      subheaderTypographyProps={{
        variant: 'body1',
        component: 'p',
      }}
    />
  );
}

interface DrawerCardActionsWithNavigationProps extends CardActionsProps {
  hideNext?: boolean;
  hidePrevious?: boolean;
  nextProps?: LoadingButtonProps;
  previousProps?: LoadingButtonProps;
  onNext?: () => unknown;
  onPrevious?: () => unknown;
}

export function DrawerCardActionsWithNavigation({
  hideNext = false,
  hidePrevious = false,
  nextProps = undefined,
  previousProps = undefined,
  onNext = undefined,
  onPrevious = undefined,
  children,
  ...props
}: DrawerCardActionsWithNavigationProps) {
  const { t } = useTranslation();
  return (
    <DrawerCardActions {...props}>
      <Stack direction="row" gap={2}>
        {!hidePrevious && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            disabled={!onPrevious}
            onClick={onPrevious}
            startIcon={<ArrowBackIcon />}
            {...previousProps}
          >
            {previousProps?.children ?? t('common.previous')}
          </LoadingButton>
        )}
        {!hideNext && (
          <LoadingButton
            variant="contained"
            color={onNext ? 'primary' : 'secondary'}
            disabled={!onNext}
            onClick={onNext}
            endIcon={<ArrowForwardIcon />}
            {...nextProps}
          >
            {nextProps?.children ?? t('common.next')}
          </LoadingButton>
        )}
      </Stack>
      {children}
    </DrawerCardActions>
  );
}

export default DrawerCardHeader;
