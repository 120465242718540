import { AccordionDetails, AccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CommissionAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

export const CommissionAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  '& > .MuiStack-root > *:first-of-type': {
    minWidth: 180,
  },

  '& .MuiFormControl-root': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexFlow: 'column',
    label: {
      ...theme.typography.body2,
    },
  },
}));
