import {
  Container,
  Divider as MuiDivider,
  Paper,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const AuthContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.common.background4,
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}));

export const AuthPanel = styled(Paper)(({ theme }) => ({
  borderTop: `5px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(3),
  minWidth: 565,
  maxWidth: 565,
  minHeight: 410,
  '& h3': {
    fontSize: 24,
    marginBottom: theme.spacing(3),
  },
  '& label': {
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: 'normal',
    marginBottom: theme.spacing(1),
  },
  '& .MuiFormControl-root': {
    marginBottom: theme.spacing(2),
  },
  '& .MuiFormHelperText-root': {
    fontFamily: theme.typography.h5.fontFamily,
    marginTop: theme.spacing(1),
  },
  '& .MuiButton-text': {
    fontSize: 15,
  },
  '& h5, p': {
    fontSize: 15,
  },
  '& p': {
    fontFamily: theme.typography.h6.fontFamily,
  },
}));

export const VerficationCodeInputContainer = styled(Stack)(() => ({
  justifyContent: 'center',
  '& .MuiInputBase-root': {
    maxWidth: 75,
    minHeight: 75,
    '& input': {
      textAlign: 'center',
      fontSize: 30,
    },
  },
}));

export const Divider = styled(MuiDivider)(() => ({
  marginTop: 0,
  marginBottom: 0,
  margin: 0,
}));

export default {};
