import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import { DecisionType } from '../../../../services/product-helpers';

export interface EvidenceRequirementsProps {
  decision: PreSalesDecision
}

function EvidenceRequirements({
  decision,
}: EvidenceRequirementsProps) {
  const { t } = useTranslation();

  if (decision.decision === DecisionType.DECLINE) {
    return null;
  }

  if ((!decision.consolidatedEvidences || decision.consolidatedEvidences.length === 0)) {
    return (
      <Typography variant="body2">
        {t('components.evidenceRequirements.none')}
      </Typography>
    );
  }

  return decision.consolidatedEvidences.map((evidence) => (
    <Typography variant="body2" key={evidence.code}>
      {evidence.text}
    </Typography>
  ));
}

export default EvidenceRequirements;
