import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import * as yup from 'yup';

export const loginSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const passwordResetSchema = yup.object({
  email: yup.string().email().required('Email is required'),
});

export const changePasswordSchema = yup.object({
  verficationCode: yup.string().required('Verification code is required'),
  password: yup.string().required('Password is required').min(10, 'Password must have at least 10 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const forgotPasswordSchema = yup.object({
  email: yup.string().required('Email is required'),
  verficationCode: yup.string().required('Verification code is required'),
  password: yup.string().required('Password is required').min(10, 'Password must have at least 10 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const newPasswordSchema = yup.object({
  phoneNumber: yup.string().required('Mobile number is required'),
  password: yup.string().required('Password is required').min(10, 'Password must have at least 10 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const verificationCodeSchema = yup.object({
  verficationCode: yup.string().required('Verification code is required'),
  rememberMe: yup.boolean(),
});

export function replaceErrorMessage(errorMessage: string): string {
  switch (errorMessage) {
    case 'User does not exist.': return 'Incorrect username or password';
    case 'Username/client id combination not found.': return 'If this email is a valid account we will send a reset email notification';
    default: return errorMessage;
  }
}

export async function getUserFirstnameFromToken(): Promise<string> {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.idToken?.payload?.given_name as string ?? '';
  } catch {
    return '';
  }
}

export async function getUserNameFromToken(): Promise<string> {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.accessToken?.payload?.name as string ?? '';
  } catch {
    return '';
  }
}

export async function getAdviserIdFromToken(): Promise<string | null> {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.accessToken?.payload?.['the-exeter:agentId'] as string ?? null;
  } catch {
    return null;
  }
}

export async function getFirmFrnFromToken(): Promise<string | null> {
  try {
    const session = await fetchAuthSession();
    return session.tokens?.accessToken?.payload?.['the-exeter:firmFrn'] as string ?? null;
  } catch {
    return null;
  }
}

export async function getUserAttribute(attribute: string): Promise<string | undefined | null> {
  try {
    const attributes = await fetchUserAttributes();
    return attributes[attribute];
  } catch {
    return null;
  }
}

export function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/^0/, '+44');
}

export default {};
