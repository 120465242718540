import React, { ReactNode } from 'react';
import { BoxProps } from '@mui/material';
import { CardTitleWithDetailStyled } from './card-title.styles';

export interface CardTitleWithDetailProps extends Omit<BoxProps, 'title'> {
  title: ReactNode;
  detail?: ReactNode;
}

function CardTitleWithDetail({ title, detail = undefined, ...props }: CardTitleWithDetailProps) {
  return (
    <CardTitleWithDetailStyled {...props}>
      {title}
      {detail && <span className="card-title-detial">{detail}</span>}
    </CardTitleWithDetailStyled>
  );
}

export default CardTitleWithDetail;
