import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '../types';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#DAD6DC';
};

type ContianerProps = {
  isFocused: boolean,
  isDragReject: boolean;
  isDragAccept: boolean;
};

export const Container = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<ContianerProps>(['isFocused', 'isDragReject', 'isDragAccept'], prop),
})<ContianerProps>((props: any) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 15,
  borderWidth: 2,
  borderRadius: 5,
  borderColor: getColor(props),
  borderStyle: 'dashed',
  backgroundColor: '#fffff',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}));

export default Container;
