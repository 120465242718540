import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { ApplicationDto } from '../../../../services/models/application-dto';
import FileUpload from '../../../../components/file-upload';
import useBusyState from '../../../../hooks/use-busy-state';
import documentApi from '../../../../services/document-api';
import { toastError } from '../../../../components/toast';
import DownloadDocumentButton from '../../../../components/download/download-document-button';
import { useAppDispatch } from '../../../../store/hooks';
import { uploadDirectDebitMandate } from '../../../../features/application-slice';
import { FormHelpText } from '../../../../components/form';
import { Delete } from '../../../../components/svg-icon';
import { DocumentCard, LinkButton } from './direct-debit-mandate-panel.styles';

export interface DirectDebitMandatePanelProps {
  application: ApplicationDto;
  showMandate: boolean;
}

function DirectDebitMandatePanel({ application, showMandate }: DirectDebitMandatePanelProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [uploading, withUploadingState] = useBusyState();

  const generateDirectDebitMandateDocument = () => documentApi.downloadDirectDebitMandateFormDocument(application.id);

  const handleFileSelect = (files: File[]) => {
    setFile(files[0]);
  };

  const handleFileUpload = withUploadingState(async () => {
    if (file) {
      try {
        await dispatch(uploadDirectDebitMandate(application.id, file));
        setFile(null);
      } catch {
        toastError(t('components.directDebitMandatePanel.uploadError'));
      }
    }
  });

  const handleMandateDownload = async () => {
    try {
      const promise = documentApi.downloadDirectDebitMandateDocument(application.id);
      const fileName = documentApi.getFileNameFromResponse(await promise, `Direct Debit Mandate - ${application.id}`);
      await documentApi.downloadDocument(promise, fileName);
    } catch {
      toastError(t('components.directDebitMandatePanel.downloadError'));
    }
  };

  return (
    <Stack gap={2}>
      <Typography variant="h6">{t('components.directDebitMandatePanel.title')}</Typography>
      <FormHelpText>
        {t('components.directDebitMandatePanel.guidance')}
      </FormHelpText>
      {showMandate && (
        <>
          <Box>
            <DownloadDocumentButton
              label={t('components.directDebitMandatePanel.downloadTemplate')}
              fileName={`Direct Debit Mandate - ${application.id}.pdf`}
              resourceName="Direct Debit Mandate"
              errorMessage={t('components.directDebitMandatePanel.downloadError')}
              generateDocument={generateDirectDebitMandateDocument}
              variant="contained"
              color="primary"
            />
          </Box>
          <Typography variant="h6" marginTop={4}>{t('components.directDebitMandatePanel.signedInstructionTitle')}</Typography>
          <Typography variant="body2">{t('components.directDebitMandatePanel.signedInstructionGuidance')}</Typography>
          {!application.bankDetails?.directDebitMandateDocumentId && (
            <>
              <Stack gap={4} direction="row" alignItems="center">
                <FileUpload
                  onFileSelect={handleFileSelect}
                  maxFileSizeMb={3}
                  dropzoneOptions={{
                    accept: {
                      'image/*': ['.jpeg', '.png', '.jpg'],
                      'application/pdf': ['.pdf'],
                    },
                  }}
                />
                <LoadingButton
                  loading={uploading}
                  disabled={!file}
                  onClick={handleFileUpload}
                  variant="outlined"
                  color="secondary"
                >
                  {t('common.upload')}
                </LoadingButton>
              </Stack>
              {file && (<Typography variant="body1">{file.name}</Typography>)}
            </>
          )}
          {application.bankDetails?.directDebitMandateDocumentId && (
            <Stack direction="row" gap={2}>
              <DocumentCard sx={{ flexGrow: 1 }}>
                <LinkButton onClick={handleMandateDownload}>
                  {t('components.directDebitMandatePanel.signedInstruction')}
                </LinkButton>
                <LinkButton onClick={handleMandateDownload}>
                  {t('common.view')}
                </LinkButton>
              </DocumentCard>
              <IconButton
                aria-label={t('common.delete')!}
                disabled
                sx={(theme) => ({ color: theme.palette.common.surface2 })}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

export default DirectDebitMandatePanel;
