import React from 'react';
import { useTranslation } from 'react-i18next';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BoxProps } from '@mui/material';
import { DecisionType } from '../../services/product-helpers';
import { StyledDecision } from './decision.styles';

export function getDecisionIcon(decision: string | null | undefined) {
  switch (decision) {
    case DecisionType.STANDARD: return <CheckCircleOutlineIcon color="success" />;
    case DecisionType.NON_STANDARD: return <InfoOutlinedIcon color="success" />;
    case DecisionType.REFER: return <ArrowCircleRightOutlinedIcon color="warning" />;
    case DecisionType.EVIDENCE_REQUIRED: return <HelpOutlineOutlinedIcon color="warning" />;
    case DecisionType.POSTPONE: return <AccessTimeOutlinedIcon color="error" />;
    case DecisionType.DECLINE: return <DoDisturbAltOutlinedIcon color="error" />;
    default: return null;
  }
}

interface DecisionProps extends BoxProps {
  decision: string | null
}

function Decision({
  decision,
  ...props
}: DecisionProps) {
  const { t } = useTranslation();

  if (!decision) return null;

  return (
    <StyledDecision {...props} data-testid="decision">
      {getDecisionIcon(decision)}
      {t(`common.decision.${decision}`)}
    </StyledDecision>
  );
}

export default Decision;
