import React, { Fragment, useState } from 'react';
import {
  Button,
  CardContent,
  Stack,
} from '@mui/material';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../../components/section-header/section-header';
import PlaceholderCard from '../../../../components/placeholder-card';
import { Enquiry } from '../../../../services/models/enquiry';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  addDisclosure,
  removeConditon,
  removeDisclosure,
  selectApplicationOrigin,
} from '../../../../features/application-slice';
import {
  firstUnsatisfiedEnquiryLine,
  getAddDisclosureSection,
  getConditionEnquiryLines,
  getConditionSections,
  getSectionStatus,
  getUMConditionSections,
} from '../../../../services/enquiry-helpers';
import DisclosureForm from './disclosure-form';
import { EnquiryLine } from '../../../../services/models/enquiry-line';
import {
  DisclosureCard,
  DisclosureDivider,
  StyledDisclosureButtonContainer,
  StyledDisclosuresPanel,
} from './disclosure-panel.styles';
import DisclosureAccordion from './disclosure-accordion';
import { isUMEApplication } from '../../../../services/application-helpers';
import EnquiryLinePanel from './enquiry-line-panel';
import useBusyState from '../../../../hooks/use-busy-state';

interface EnquiryEditState {
  snapshot: Enquiry;
  disclosureId: string;
}

export interface PreSalesDisclosuresPanelProps {
  applicantId: string;
  enquiry: Enquiry;
  disabled?: boolean;
  showDisclosures?: boolean;
  onEdit?: (enquiryLine: EnquiryLine) => unknown;
  onChange: () => void;
}

function PreSalesDisclosuresPanel({
  applicantId,
  enquiry,
  disabled = false,
  showDisclosures = false,
  onEdit = undefined,
  onChange,
}: PreSalesDisclosuresPanelProps) {
  const [busy, withBusyState] = useBusyState();
  const addDisclosureSection = getAddDisclosureSection(enquiry);
  const firstUnsatistied = firstUnsatisfiedEnquiryLine(addDisclosureSection);
  const editState = firstUnsatistied ? { snapshot: enquiry, disclosureId: firstUnsatistied.alias! } : null;
  const [edited, setEdited] = useState<EnquiryEditState | null>(editState);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const editedEnquiryLine = edited ? addDisclosureSection?.enquiryLines.find((enquiryLine) => enquiryLine.alias === edited?.disclosureId) : undefined;
  const isUME = isUMEApplication(useAppSelector(selectApplicationOrigin));
  const disclosureSections = isUME ? getUMConditionSections(enquiry) : getConditionSections(edited ? edited.snapshot : enquiry, false);

  const handleAdd = withBusyState(async () => {
    const disclosureId = await dispatch(addDisclosure(applicantId, enquiry));
    setEdited({ snapshot: enquiry, disclosureId });
  });

  const handleCancel = async (disclosureId: string) => {
    await dispatch(removeDisclosure(applicantId, enquiry, disclosureId));
    setEdited(null);
  };

  const handleDelete = async (enquiryLine: EnquiryLine) => {
    await dispatch(removeConditon(applicantId, enquiry, enquiryLine));
    onChange();
  };

  const handleSubmit = () => {
    setEdited(null);
    onChange();
  };

  return (
    <StyledDisclosuresPanel>
      <SectionHeader>
        {t('components.disclosuresPanel.preSalesTitle')}
      </SectionHeader>
      {!showDisclosures && (
        <PlaceholderCard>
          <CardContent>{t('components.disclosuresPanel.noProductMessage')}</CardContent>
        </PlaceholderCard>
      )}
      {showDisclosures && (
        <Stack gap={3} data-testid="disclosures-section">
          {disclosureSections.length > 0 && (
            <DisclosureCard>
              <CardContent>
                {disclosureSections.map((section, index) => {
                  const enquiryLines = getConditionEnquiryLines(section);
                  return (
                    <Fragment key={section.name}>
                      <DisclosureAccordion
                        title={section.name ?? ''}
                        status={getSectionStatus(section)}
                        hideDetails={isUME}
                        expandable={false}
                      >
                        {enquiryLines.map((enquiryLine) => (
                          <EnquiryLinePanel
                            key={enquiryLine.path}
                            enquiryLine={enquiryLine}
                            onEdit={onEdit}
                            onDelete={handleDelete}
                            disabled={!!edited || busy || disabled}
                            hideInformation={disabled}
                          />
                        ))}
                      </DisclosureAccordion>
                      {index < disclosureSections.length - 1 && <DisclosureDivider />}
                    </Fragment>
                  );
                })}
              </CardContent>
            </DisclosureCard>
          )}
          {editedEnquiryLine && (
            <DisclosureForm
              applicantId={applicantId}
              enquiryLine={editedEnquiryLine}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          )}
          {!edited && (
            <StyledDisclosureButtonContainer>
              <Button
                color="primary"
                variant="contained"
                endIcon={<ControlPointOutlinedIcon />}
                onClick={handleAdd}
                disabled={busy || disabled}
              >
                {t('components.disclosuresPanel.addDisclosures')}
              </Button>
            </StyledDisclosureButtonContainer>
          )}
        </Stack>
      )}
    </StyledDisclosuresPanel>
  );
}

export default PreSalesDisclosuresPanel;
