import {
  Box,
  styled,
} from '@mui/material';

export const ToolkitBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
  border: `1px solid ${theme.palette.common.secondary200}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  '& > h5': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    width: 160,
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
  },
  '& > p': {
    fontFamily: theme.typography.h6.fontFamily,
    flex: '1 1 auto',
  },
  '& > button': {
    minWidth: 140,
  },
}));

export default {};
