import React from 'react';
import { useSelector } from 'react-redux';
import { ProductDto } from '../../services/models/product-dto';
import { ApplicantDto } from '../../services/models/applicant-dto';
import {
  CardTitleWithDetail,
  CardTitleWithDetailProps,
  DecoratedTitle,
  DecoratedTitleProps,
} from '../card-title';
import { getProductDefinitionByCode } from '../../features/settings-slice';
import { getApplicantName } from '../../services/application-helpers';

export interface ProductCardTitleProps extends Omit<CardTitleWithDetailProps, 'title' | 'detail'> {
  product: ProductDto;
  applicants?: ApplicantDto[];
  decoratorProps?: DecoratedTitleProps;
}

function ProductCardTitle({
  product,
  applicants = undefined,
  decoratorProps = undefined,
  ...props
}: ProductCardTitleProps) {
  const productDefinition = useSelector((state) => getProductDefinitionByCode(state, product.code));
  const productNameBase = productDefinition?.name ?? product.code;
  const jointLife = product.applicantIds && product.applicantIds?.length > 1;
  const productName = jointLife ? `${productNameBase} (joint life)` : productNameBase;
  const detail = applicants ? ` - ${applicants.map((applicant) => getApplicantName(applicant)).join(' & ')}` : undefined;
  return (
    <CardTitleWithDetail
      title={<DecoratedTitle {...decoratorProps}>{productName}</DecoratedTitle>}
      detail={detail}
      {...props}
    />
  );
}

export default ProductCardTitle;
