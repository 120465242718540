import React, { SyntheticEvent } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { SelectOption, WithOnChangeCommitted } from '../../types';

export interface SelectTypeaheadInputProps<
  Value extends SelectOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>, 'renderInput'>, WithOnChangeCommitted<Value | null> {
  name: string;
}

function SelectTypeaheadInput<Value extends SelectOption>({
  name,
  options,
  onChangeCommitted = undefined,
  ...props
}: SelectTypeaheadInputProps<Value, false, boolean, boolean>) {
  const {
    field,
  } = useController({ name });

  const handleChange = (event: SyntheticEvent<unknown>, value: Value | string | null) => {
    const newValue = typeof value === 'string' ? null : value;
    field.onChange(newValue);
    if (onChangeCommitted) {
      onChangeCommitted(name, newValue);
    }
  };

  const getOptionLabel = (option: Value | string) => {
    if (typeof option === 'string') {
      return options.find((opt) => opt.id === option)?.label ?? option;
    }
    if (option) {
      return option.label;
    }
    return '';
  };

  return (
    <Autocomplete
      {...field}
      {...props}
      id={name}
      value={field.value}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => {
        if (typeof value === 'string') {
          return option.id === value;
        }
        return option.id === value.id;
      }}
      options={options}
      renderInput={(params) => <TextField {...params} name={name} placeholder="Please select" />}
    />
  );
}

export default SelectTypeaheadInput;
