import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  Theme,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { LoadingButton, TabList, TabPanel } from '@mui/lab';
import { SectionStatus } from '../../../../services/models/section-status';
import { shouldForwardProp } from '../../../../components/types';

export const TogglePanelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  border: 'none',
  marginLeft: 'auto',
  minWidth: 134,
  '& .MuiButton-endIcon > *:nth-of-type(1)': {
    fontSize: 25,
  },
}));

export const FloatingTogglePanelButton = styled(TogglePanelButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: theme.spacing(4),
}));

export const FirmOwnerDisplay = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 15,
  right: 190,
  display: 'flex',
  gap: theme.spacing(2),
}));

export const ClientDetailsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(5),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  minHeight: 90,
}));

export const ClientDetailsPanel = styled(Box)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.common.secondary,
    color: theme.palette.common.white,
    borderRadius: 0,
    boxShadow: 'none',
    width: '100vw',
    maxWidth: '100vw',
    left: 0,
    paddingLeft: theme.spacing(theme.mixins.mainHorizontalSpacing + 2),
    paddingRight: theme.spacing(theme.mixins.mainHorizontalSpacing + 2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: theme.spacing(16),
  },
}));

export const ClientDetailsStatusPanel = styled(Stack)(() => ({

}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

export const DataLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  width: '50%',
  display: 'inline-block',
  verticalAlign: 'text-top',
  whiteSpace: 'nowrap',
  color: alpha(theme.palette.common.white, 0.65),
}));

export const DataValue = styled(DataLabel)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.common.white,
}));

export const DetailsDivider = styled(Divider)(({ theme }) => ({
  borderWidth: 1,
  borderColor: theme.palette.common.surface9,
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(1.5),
}));

export const DetailsTable = styled(Table)(() => ({
  background: 'transparent',
  border: 'none',
}));

export const DetailsTableBody = styled(TableBody)(({ theme }) => ({
  '& th': {
    ...theme.typography.subtitle1,
    paddingRight: theme.spacing(2),
    borderBottom: 'none',
    color: alpha(theme.palette.common.white, 0.65),
  },
  '& td': {
    ...theme.typography.subtitle1,
    fontFamily: theme.typography.h5.fontFamily,
    borderBottom: 'none',
  },
}));

export const DetailsTableCell = styled(TableCell)(({ theme }) => ({
  background: 'transparent',
  color: theme.palette.common.white,
  padding: 0,
}));

function getApplicationStatusColor(status: SectionStatus, theme: Theme): string {
  switch (status) {
    case SectionStatus.Complete: return theme.palette.common.green;
    case SectionStatus.Incomplete: return theme.palette.common.orange;
    default: return 'transparent';
  }
}

type StatusChipProps = {
  status: SectionStatus;
};

export const StatusChip = styled(Chip, {
  shouldForwardProp: (prop) => shouldForwardProp<StatusChipProps>(['status'], prop),
})<StatusChipProps>(({ theme, status }) => ({
  backgroundColor: 'transparent',
  '&:before': {
    content: '""',
    width: 12,
    height: 12,
    backgroundColor: getApplicationStatusColor(status, theme),
    borderRadius: '50%',
    position: 'relative',
    top: -1,
  },
  '& > .MuiChip-label': {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(0.75),
    ...theme.typography.caption,
  },
}));

type SectionProps = {
  status: SectionStatus | undefined;
};

export const Section = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<SectionProps>(['status'], prop),
})<SectionProps>(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '& > h5': {
    marginBottom: status ? 0 : theme.spacing(2),
  },
  '& > .MuiChip-root': {
    marginBottom: status ? theme.spacing(2) : 0,
  },
}));

export const ClientTabList = styled(TabList)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(5),
}));

export const ClientTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface9,
  borderRadius: 0,
  color: theme.palette.common.white,
  fontFamily: theme.typography.body1.fontFamily,
  minWidth: 'auto',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  '&.Mui-selected': {
    backgroundColor: '#3C3A3A',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body1.fontFamily,
  },
}));

export const AddClientButton = styled(Tab)(({ theme }) => ({
  alignItems: 'flex-start',
  ...theme.typography.body2,
  background: 'transparent',
  color: theme.palette.common.white,
  textDecoration: 'underline',
  '&.Mui-disabled': {
    color: theme.palette.common.greyLight,
    svg: {
      color: theme.palette.common.greyLight,
    },
  },
}));

export const DeleteClientButton = styled(LoadingButton)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginLeft: 'auto',
  ...theme.typography.body2,
  color: theme.palette.common.white,
  '.MuiButton-startIcon > svg': {
    color: theme.palette.common.yellow,
    width: 16,
  },
  '.MuiCircularProgress-root': {
    color: theme.palette.common.greyLight,
  },
}));

export const ClientTabPanel = styled(TabPanel)(({ theme }) => ({
  backgroundColor: '#3C3A3A',
  position: 'relative',
  paddingLeft: theme.spacing(8.5),
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
}));

export const EditButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.surface9, 0.5),
  },
}));
