import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { forgetDevice, ForgetDeviceInput, fetchDevices } from 'aws-amplify/auth';
import { adviserCognito, collegueCognito } from '../exports';
import { DashboardPage, InternalLoginPage } from '../routes/pages';
import { getUserNameFromToken } from './auth-helpers';

export const adviserAwsconfig = {
  aws_cognito_identity_pool_id: adviserCognito.cognitoIdentityPoolId,
  aws_cognito_region: adviserCognito.cognitoRegion,
  aws_cognito_user_pool_domain: adviserCognito.cognitoUserPoolDomain,
  aws_user_pools_id: adviserCognito.cognitoUserPoolId,
  aws_user_pools_web_client_id: adviserCognito.cognitoAppClientId,
  aws_region: adviserCognito.cognitoRegion,
  oauth: {},
};
export const collegueAwsconfig = {
  aws_cognito_identity_pool_id: collegueCognito.cognitoIdentityPoolId,
  aws_cognito_region: collegueCognito.cognitoRegion,
  aws_cognito_user_pool_domain: collegueCognito.cognitoUserPoolDomain,
  aws_user_pools_id: collegueCognito.cognitoUserPoolId,
  aws_user_pools_web_client_id: collegueCognito.cognitoAppClientId,
  aws_region: adviserCognito.cognitoRegion,
  oauth: {
    domain: collegueCognito.cognitoUserPoolDomain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}`,
    redirectSignOut: `${window.location.origin}/internal`,
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    identityProvider: 'AzureAD',
    client_id: collegueCognito.cognitoAppClientId,
  },
};

function getCollegueKeyprefix() {
  return `CognitoIdentityServiceProvider.${collegueAwsconfig.aws_user_pools_web_client_id}`;
}

export function isCollegue(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  const codeExists = urlParams.get('code');
  return !!localStorage.getItem(`${getCollegueKeyprefix()}.oauthSignIn`) || !!codeExists || !!window.location.href.includes(InternalLoginPage.path);
}

export const awsConfig = isCollegue() ? collegueAwsconfig : adviserAwsconfig;

interface CognitoUserWithSession extends AmazonCognitoIdentity.CognitoUser {
  Session?: string;
  deviceKey?: string;
}

export type LoginSuccess = {
  firstLogin: boolean,
  isValid: boolean,
  mfaRequired?: boolean,
  mfaChallengeParams?: any,
  userAttr?: any | null,
  session?: string | null,
};

export class AuthSession {
  protected username: string;

  protected phoneNumber: string | null = null;

  protected rememberMe: boolean = false;

  protected password: string | null = null;

  protected userPool: AmazonCognitoIdentity.CognitoUserPool;

  protected user: CognitoUserWithSession;

  constructor(username: string) {
    this.username = username;

    this.userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: adviserCognito.cognitoUserPoolId,
      ClientId: adviserCognito.cognitoAppClientId,
    });

    this.user = new AmazonCognitoIdentity.CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
  }

  getUser() {
    return this.user;
  }

  getUserName() {
    return this.username;
  }

  setPhoneNumber(phoneNumber: string | null) {
    this.phoneNumber = phoneNumber;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  setRememberMe(rememberMe: boolean) {
    this.rememberMe = rememberMe;
  }

  getRememberMe() {
    return this.rememberMe;
  }

  getPassword() {
    return this.password;
  }

  async signIn(password: string): Promise<LoginSuccess> {
    this.password = null;
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      { Username: this.username, Password: password },
    );

    return new Promise((resolve, reject) => {
      this.user.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          AWS.config.region = adviserCognito.cognitoRegion;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: adviserCognito.cognitoIdentityPoolId,
            Logins: {
              [`cognito-idp.${adviserCognito.cognitoRegion}.amazonaws.com/${adviserCognito.cognitoUserPoolId}`]: session
                .getIdToken()
                .getJwtToken(),
            },
          });
          resolve({ firstLogin: false, isValid: session.isValid() });
        },
        onFailure: async (error) => {
          reject(error);
        },
        newPasswordRequired: (userAttr) => {
          resolve({ firstLogin: true, isValid: false, userAttr });
        },
        mfaRequired: (codeDeliveryDetails: AmazonCognitoIdentity.ChallengeName, mfaChallengeParams) => {
          this.password = password;
          resolve({
            firstLogin: false,
            isValid: false,
            mfaRequired: true,
            mfaChallengeParams,
          });
        },
      });
    });
  }

  static getCurrentUser(): AmazonCognitoIdentity.CognitoUser | null {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: adviserCognito.cognitoUserPoolId,
      ClientId: adviserCognito.cognitoAppClientId,
    });
    return userPool.getCurrentUser();
  }

  static async getCurrentUserName(): Promise<string | undefined> {
    if (isCollegue()) {
      const username = await getUserNameFromToken();
      return username;
    }
    return AuthSession.getCurrentUser()?.getUsername();
  }

  static async getSession(user: AmazonCognitoIdentity.CognitoUser) {
    const userSession = await new Promise((resolve, reject) => {
      user.getSession((err: Error, session: AmazonCognitoIdentity.CognitoUserSession | null) => {
        if (err) {
          reject(err);
        } else if (session === null) {
          resolve(null);
        } else {
          resolve(session);
        }
      });
    });
    return userSession;
  }

  static async checkUserAuthenticated(userPool: AmazonCognitoIdentity.CognitoUserPool): Promise<boolean> {
    try {
      const isValid = await new Promise((resolve, reject) => {
        const currentUser = userPool.getCurrentUser();
        if (currentUser) {
          currentUser.getSession((err: Error, session: AmazonCognitoIdentity.CognitoUserSession | null) => {
            if (err) {
              reject(err);
            } else if (session === null) {
              reject(new Error('no valid user session'));
            } else {
              resolve(session.isValid());
            }
          });
        }
        reject(new Error('no user found'));
      });
      return isValid as boolean;
    } catch (err) {
      return false;
    }
  }

  async isAuthenticated(): Promise<boolean> {
    return AuthSession.checkUserAuthenticated(this.userPool);
  }

  static async isCurrentUserAuthenticated(): Promise<boolean> {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: awsConfig.aws_user_pools_id,
      ClientId: awsConfig.aws_user_pools_web_client_id,
    });
    return AuthSession.checkUserAuthenticated(userPool);
  }

  static signOut() {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: awsConfig.aws_user_pools_id,
      ClientId: awsConfig.aws_user_pools_web_client_id,
    });
    if (isCollegue()) {
      localStorage.clear();
      window.location.href = `//${collegueCognito.cognitoUserPoolDomain}/logout?logout_url=${window.location.origin}/internal&client_id=${collegueCognito.cognitoAppClientId}&redirect_uri=${window.location.origin}&response_type=code`;
    } else {
      const currentUser = userPool.getCurrentUser();
      currentUser?.signOut();
      window.location.href = DashboardPage.path;
    }
  }

  async sendVerificationCode() {
    return new Promise((resolve, reject) => {
      this.user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  async updatePassword(verficationCode: string, password: string) {
    return new Promise((resolve, reject) => {
      this.user.confirmPassword(verficationCode, password, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  }

  async newPassword(password: string, userAttr: any): Promise<LoginSuccess> {
    return new Promise((resolve, reject) => {
      this.user.completeNewPasswordChallenge(password, userAttr, {
        onSuccess(session) {
          AWS.config.region = adviserCognito.cognitoRegion;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: adviserCognito.cognitoIdentityPoolId,
            Logins: {
              [`cognito-idp.${adviserCognito.cognitoRegion}.amazonaws.com/${adviserCognito.cognitoUserPoolId}`]: session
                .getIdToken()
                .getJwtToken(),
            },
          });
          resolve({ firstLogin: false, isValid: session.isValid() });
        },
        onFailure(err) {
          reject(err);
        },
        mfaRequired: (codeDeliveryDetails: AmazonCognitoIdentity.ChallengeName, mfaChallengeParams) => {
          this.password = password;
          resolve({
            firstLogin: false,
            isValid: false,
            mfaRequired: true,
            mfaChallengeParams,
          });
        },
      });
    });
  }

  async sendMFACode(code: string) {
    return new Promise((resolve, reject) => {
      this.user.sendMFACode(code, {
        onSuccess(session) {
          AWS.config.region = adviserCognito.cognitoRegion;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: adviserCognito.cognitoIdentityPoolId,
            Logins: {
              [`cognito-idp.${adviserCognito.cognitoRegion}.amazonaws.com/${adviserCognito.cognitoUserPoolId}`]: session
                .getIdToken()
                .getJwtToken(),
            },
          });
          resolve({ firstLogin: false, isValid: session.isValid() });
        },
        onFailure(err) {
          reject(err);
        },
      }, 'EMAIL_OTP');
    });
  }

  async setDeviceStatusRemembered() {
    return new Promise((resolve, reject) => {
      this.user.getCachedDeviceKeyAndPassword();
      this.user.setDeviceStatusRemembered({
        onSuccess(result) { resolve(result); },
        onFailure(err) { reject(err); },
      });
    });
  }

  async setDeviceStatusNotRemembered() {
    return new Promise((resolve, reject) => {
      this.user.getCachedDeviceKeyAndPassword();
      this.user.setDeviceStatusNotRemembered({
        onSuccess(result) { resolve(result); },
        onFailure(err) { reject(err); },
      });
    });
  }

  async getUserAttributes(): Promise<AmazonCognitoIdentity.CognitoUserAttribute[]> {
    return new Promise((resolve, reject) => {
      this.user.getUserAttributes((err, results) => {
        if (results) {
          resolve(results);
        }
        if (err) {
          reject(err);
        }
      });
    });
  }

  static getPhoneNumberFromAttributes(userAttr: any): string | null {
    return userAttr?.phone_number ?? null;
  }

  static async deleteDevice(deviceId: string) {
    const input: ForgetDeviceInput = {
      device: {
        id: deviceId,
      },
    };
    await forgetDevice(input);
  }

  static async fetchDevices() {
    return fetchDevices();
  }
}
