import { Components } from '@mui/material/styles';

const MuiAccordion: Components['MuiAccordion'] = {
  styleOverrides: {
    root: () => ({
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    }),
  },
};

export default MuiAccordion;
