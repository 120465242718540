import React from 'react';
import { SvgIconProps } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { useTranslation } from 'react-i18next';
import { ProgressStatus } from '../../../../services/models/progress-status';
import { StyledDisclosureStatus } from './disclosure-panel.styles';

interface DisclosureStatusProps {
  status: ProgressStatus;
}

function getIconFor(status: ProgressStatus) {
  const props: SvgIconProps = { fontSize: 'small', sx: { verticalAlign: 'top', marginRight: 0.5 } };
  switch (status) {
    case ProgressStatus.NotStarted: return <CircleOutlinedIcon {...props} />;
    case ProgressStatus.Incomplete: return <CircleIcon color="warning" {...props} />;
    case ProgressStatus.Complete: return <CircleIcon color="success" {...props} />;
    default: return null;
  }
}

function DisclosureStatus({ status }: DisclosureStatusProps) {
  const { t } = useTranslation();
  const icon = getIconFor(status);
  return (
    <StyledDisclosureStatus>
      {icon}
      {t(`components.disclosureSection.enqiryLineStatus.${status}`)}
    </StyledDisclosureStatus>
  );
}

export default DisclosureStatus;
