import React from 'react';
import LabelAndValue, { LabelAndValueProps } from '../../label-and-value';

export const defaultReviewAttributeProps: Partial<LabelAndValueProps> = {
  sx: {
    display: 'flex',
    gap: 4,
  },
  labelProps: {
    variant: 'body2',
    minWidth: 300,
  },
  valueProps: {
    variant: 'body2',
    fontWeight: 'normal',
  },
};

function ReviewAttribute(props: LabelAndValueProps) {
  return (
    <LabelAndValue {...defaultReviewAttributeProps} {...props} />
  );
}

export default ReviewAttribute;
