import React from 'react';
import {
  BoxProps, Button, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ToolkitBox,
} from './toolkit-panel.styles';
import WalletIcon from '../../../../assets/icons/wallet-icon.svg';
import HandMoneyIcon from '../../../../assets/icons/hand-money-icon.svg';
import { SalesResource } from '../../../../services/models/sales-resource';
import { ProductCode } from '../../../../services/models/product-code';

function getIcon(productCode: ProductCode) {
  switch (productCode) {
    case ProductCode.INCOME_PROTECTION: return WalletIcon;
    case ProductCode.REAL_LIFE: return HandMoneyIcon;
    default: return WalletIcon;
  }
}

interface ToolkitPanelProps extends BoxProps {
  productCode: ProductCode,
  toolkitResource: SalesResource;
}

function ToolkitPanel({
  productCode,
  toolkitResource,
  ...props
}: ToolkitPanelProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    if (toolkitResource.location) {
      window.open(toolkitResource.location);
    }
  };

  return (
    <ToolkitBox {...props}>
      <Typography
        variant="h5"
      >
        <img src={getIcon(productCode)} alt="" />
        {t(`components.toolkitPanel.${productCode}.title`)}
      </Typography>
      <Typography
        variant="body2"
      >
        {t(`components.toolkitPanel.${productCode}.description`)}
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClick}
      >
        {t('components.toolkitPanel.discoverMore')}
      </Button>
    </ToolkitBox>
  );
}

export default ToolkitPanel;
