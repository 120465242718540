import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, InputAdornment, Stack } from '@mui/material';
import { useController } from 'react-hook-form';
import { WithOnChangeCommitted } from '../../types';
import InputBase from './input-base';
import { asStringOrNull } from '../../../utils/converters';

type MonthYear = {
  month: string | null
  year: string | null
};

function isValidYear(value: string, min: number, max: number): boolean {
  return (value === '' || (value && value.length < min.toString().length && !!value.match(/^[1-2][0-9]{0,3}$/)))
    || (!Number.isNaN(parseFloat(value)) && parseFloat(value) >= min && parseFloat(value) <= max);
}

function isValidMonth(value: string | null): boolean {
  return value === null || value === '' || (value && value.length === 1 ? !!value.match(/0|1/) : !!value.match(/0[1-9]|1[0-2]/));
}

function isValidFuzzyDate(date: MonthYear) {
  if (date.month === null || date.month === '') {
    return true;
  }
  if (date.month && date.month.length > 0 && date.year && date.year.length > 0) {
    return true;
  }
  return false;
}

interface InputProps extends WithOnChangeCommitted<number> {
  name: string;
  value: string | null;
  disabled?: boolean;
  onChange: (newValue: string | null) => void;
  onBlur: () => void;
}

function adjustForDisplay(value: string | null): MonthYear {
  const dateArray = value && typeof value === 'string' ? value.split(' ') : '';
  if (dateArray.length === 2) {
    return { month: dateArray[0], year: dateArray[1] };
  }
  if (dateArray.length === 1) {
    return { month: '', year: dateArray[0] };
  }
  return { month: '', year: '' };
}

function formatResponse(value: MonthYear): string {
  return `${value.month !== '0' ? value.month : ''} ${value.year}`.trim();
}

function DateInputs({
  name,
  value,
  disabled = false,
  onChange,
  onBlur,
}: InputProps) {
  const [monthYearValue, setMonthYearValue] = useState<MonthYear>(adjustForDisplay(value));
  const maxYear = 2200;
  const minYear = 1900;
  const monthsId = `${name}_months`;
  const yearsId = `${name}_years`;

  const handleMonthInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? event.target.value : '';
    if (isValidMonth(newValue)) {
      const newMonthYearValue = { ...monthYearValue, month: newValue };
      setMonthYearValue(newMonthYearValue);
      if (isValidFuzzyDate(newMonthYearValue)) {
        onChange(formatResponse(newMonthYearValue));
      }
    }
  };

  const handleYearsInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? event.target.value : '';
    if (isValidYear(newValue, minYear, maxYear)) {
      const newMonthYearValue = { ...monthYearValue, year: newValue };
      setMonthYearValue(newMonthYearValue);
      if (isValidFuzzyDate(newMonthYearValue)) {
        onChange(formatResponse(newMonthYearValue));
      }
    }
  };

  useEffect(() => {
    setMonthYearValue(adjustForDisplay(value));
  }, [value]);

  return (
    <>
      <InputBase
        id={name}
        aria-describedby={monthsId}
        name={name}
        type="string"
        value={monthYearValue.month}
        inputProps={{
          sx: { width: 40 },
          maxLength: 2,
          placeholder: 'MM',
        }}
        disabled={disabled}
        onChange={handleMonthInputChange}
        onBlur={onBlur}
        endAdornment={<InputAdornment position="end" disablePointerEvents>month</InputAdornment>}
      />
      <FormControl>
        <InputBase
          id={`${name}_years`}
          aria-describedby={yearsId}
          name={`${name}_years`}
          type="number"
          value={monthYearValue.year}
          inputProps={{
            min: 1900,
            max: 2200,
            step: 1,
            placeholder: 'YYYY',
            sx: { width: 55 },
          }}
          disabled={disabled}
          onChange={handleYearsInputChange}
          onBlur={onBlur}
          endAdornment={<InputAdornment position="end" disablePointerEvents>year</InputAdornment>}
        />
      </FormControl>
    </>
  );
}

export interface FuzzyDateInputProps extends WithOnChangeCommitted<string> {
  name: string;
  disabled?: boolean,
}

function FuzzyDateInput({
  name,
  disabled = false,
  onChangeCommitted,
}: FuzzyDateInputProps) {
  const {
    field,
    formState: { defaultValues },
  } = useController({ name });
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (field.value) {
      setValue(field.value);
    }
  }, [field.value]);

  const handleOnChange = (newValue: string | null) => {
    setValue(newValue || '');
  };

  const handleBlur = () => {
    const oldValue = defaultValues?.[name];
    field.onChange(value);
    if (onChangeCommitted && asStringOrNull(oldValue) !== asStringOrNull(value)) {
      onChangeCommitted(name, value);
    }
  };

  return (
    <Stack direction="row" gap={2}>
      <DateInputs name={name} value={value} onChange={handleOnChange} onBlur={handleBlur} disabled={disabled} />
    </Stack>
  );
}

export default FuzzyDateInput;
