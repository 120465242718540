import { Card, styled } from '@mui/material';

const PlaceholderCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface8,
  color: theme.palette.common.greyLight,
  boxShadow: 'none',
  '& .MuiCardContent-root': {
    textAlign: 'center',
  },
}));

export default PlaceholderCard;
