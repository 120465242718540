import { Components } from '@mui/material/styles';
import typography from '../typography';
import variables from '../variables';

const MuiBreadcrumbs: Components['MuiBreadcrumbs'] = {
  styleOverrides: {
    root: () => ({
      ...typography.h3,
    }),
    li: {
      ':last-of-type': {
        color: variables.colors.white,
      },
    },
  },
};

export default MuiBreadcrumbs;
