import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import {
  groupReasonDecisionsByReason,
  hasAnyTotalRatingLoadings,
  preSalesDecisionsHasEvidence,
  preSalesDecisionsHasTotalRating,
  preSalesDecisionsHasExclusions,
  preSalesDecisionsHasPremium,
  decisionHasNoInd,
  isImmediateDecision,
} from '../../../../services/decision-helpers';
import {
  Container,
  TableContainerStyled,
  TableRow,
  TableRowDark,
  RowLabelWidth,
  WaitingPeriod,
  ReasonDecisionAccordion,
} from './deferred-periods.styles';
import EvidenceRequirements from './evidence-requirements';
import { ReasonDecisionCard } from '../reason-decision-card';
import { TotalPremiumDecisionCard, TotalRatingDecisionCard } from '../decision-total';
import { TableControls, TableIndicators } from './table-controls';
import { TextWithInfo } from '../../../../components/info';
import Exclusions from './exclusions';
import { useAppSelector } from '../../../../store/hooks';
import { selectOnboardingToolSettings } from '../../../../features/settings-slice';
import { Decision, ImmediateDecision } from '../../../../components/decision';
import { DeferredPeriodInWeeks } from '../../../../services/models/deferred-period-in-weeks';

function UnavailableTotal() {
  const { t } = useTranslation();
  return (
    <TextWithInfo
      variant="body2"
      fontWeight="bold"
      info={t('components.deferredPeriods.unavailableTotalInfo')}
    >
      {t('components.deferredPeriods.unavailable')}
    </TextWithInfo>
  );
}

const DISPLAY_COLUMNS = 3;

export interface DeferredPeriodsProps {
  productId: string
  decisions: PreSalesDecision[]
}

function DeferredPeriods({
  productId,
  decisions,
}: DeferredPeriodsProps) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [cellWidth, setCellWidth] = useState<number>(0);
  const [scrollLength, setScrollLength] = useState<number>(0);
  const startIndex = decisions.findIndex((d) => d.deferredPeriod === DeferredPeriodInWeeks.NUMBER_4) ?? 0;
  const [activeIndex, setActiveIndex] = useState<number>(startIndex);
  const [scrolling, setScrolling] = useState<boolean>(false);
  const reasonDecisions = useMemo(() => groupReasonDecisionsByReason(decisions), [decisions]);
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled;

  const handleResize = () => {
    if (ref.current) {
      setScrolling(false);
      setCellWidth((ref.current.offsetWidth - RowLabelWidth) / DISPLAY_COLUMNS);
      setScrollLength(-((ref.current.offsetWidth - RowLabelWidth) / DISPLAY_COLUMNS) * activeIndex);
    }
  };

  const handleMoveBack = () => {
    if (ref.current) {
      setActiveIndex((index) => index - 1);
    }
  };

  const handleMoveForward = () => {
    if (ref.current) {
      setActiveIndex((index) => index + 1);
    }
  };

  useEffect(() => {
    if (ref.current) {
      setScrollLength(-((ref.current.offsetWidth - RowLabelWidth) / DISPLAY_COLUMNS) * activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    handleResize();
  }, [ref.current]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    setScrolling(true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [activeIndex]);

  const cellSX = { minWidth: cellWidth, width: cellWidth, maxWidth: cellWidth };
  const hasPremiums = onboardingEnabled && preSalesDecisionsHasPremium(decisions);
  const hasTotalRatings = preSalesDecisionsHasTotalRating(decisions);
  const hasEvidence = preSalesDecisionsHasEvidence(decisions);
  const hasExclusions = preSalesDecisionsHasExclusions(decisions);

  return (
    <Container>
      <TableContainerStyled ref={ref} data-testid="deferred-periods">
        <TableControls
          onBack={handleMoveBack}
          onForward={handleMoveForward}
          disableBack={activeIndex === 0}
          disableForward={((decisions.length - 1) - activeIndex) < DISPLAY_COLUMNS}
        >
          <WaitingPeriod>
            {t('components.deferredPeriods.waitingPeriods')}
            <TableIndicators decisions={decisions} position={activeIndex} displayColumns={DISPLAY_COLUMNS} />
          </WaitingPeriod>
        </TableControls>
        <Table sx={{ left: scrollLength, transition: scrolling ? '0.5s all ease' : 'none' }} id="deferred-table">
          <TableHead>
            <TableRow data-testid="waitingPeriods">
              <TableCell width={RowLabelWidth} />
              {decisions.map((decision) => (
                <TableCell key={`deferredPeriods_${decision.deferredPeriod}`} sx={cellSX}>
                  {t(`common.deferredPeriod.${decision.deferredPeriod}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowDark last={!hasEvidence && !hasTotalRatings} data-testid="decision">
              <TableCell>
                {t('components.deferredPeriods.decision')}
              </TableCell>
              {decisions.map((decision, index) => (
                <TableCell
                  key={`decision_${decision.deferredPeriod}`}
                  sx={cellSX}
                  data-testid={`decision-deferred-period-${decision.deferredPeriod}`}
                  data-isvisible={index >= activeIndex && index <= (activeIndex + (DISPLAY_COLUMNS - 1))}
                >
                  <Decision decision={decision.decision} />
                  {isImmediateDecision(decision.decision) && <ImmediateDecision />}
                </TableCell>
              ))}
            </TableRowDark>
            {hasPremiums && (
              <TableRowDark last={!hasEvidence}>
                <TableCell>
                  {t('components.deferredPeriods.totalPrice')}
                </TableCell>
                {decisions.map((decision) => (
                  <TableCell key={`total_${decision.deferredPeriod}`} sx={cellSX}>
                    {decisionHasNoInd(decision)
                      ? <UnavailableTotal />
                      : (decision.premium && <TotalPremiumDecisionCard premium={decision.premium} totalRating={decision.totalRating} priceProps={{ size: 'large' }} />)}
                  </TableCell>
                ))}
              </TableRowDark>
            )}
            {!hasPremiums && hasTotalRatings && (
              <TableRowDark last={!hasEvidence}>
                <TableCell>
                  {t('components.deferredPeriods.totalRating')}
                </TableCell>
                {decisions.map((decision) => (
                  <TableCell key={`total_${decision.deferredPeriod}`} sx={cellSX}>
                    {hasAnyTotalRatingLoadings(decision) && (
                      decisionHasNoInd(decision) ? <UnavailableTotal /> : <TotalRatingDecisionCard totalRating={decision.totalRating!} />
                    )}
                  </TableCell>
                ))}
              </TableRowDark>
            )}
            {hasEvidence && (
              <TableRowDark last={!hasExclusions}>
                <TableCell>
                  {t('components.deferredPeriods.evidenceRequired')}
                </TableCell>
                {decisions.map((decision) => (
                  <TableCell key={`evidence_${decision.deferredPeriod}`} sx={cellSX}>
                    <EvidenceRequirements decision={decision} />
                  </TableCell>
                ))}
              </TableRowDark>
            )}
            {hasExclusions && (
              <TableRowDark last>
                <TableCell>
                  {t('components.deferredPeriods.exclusions')}
                </TableCell>
                {decisions.map((decision) => (
                  <TableCell key={`exclusions_${decision.deferredPeriod}`} sx={cellSX}>
                    <Exclusions decision={decision} />
                  </TableCell>
                ))}
              </TableRowDark>
            )}
          </TableBody>
        </Table>
        <ReasonDecisionAccordion productId={productId}>
          <Table sx={{ left: scrollLength, transition: scrolling ? '0.5s all ease' : 'none' }} id="reason-decisions-table">
            <TableBody>
              {Object.keys(reasonDecisions).map((reason) => (
                <TableRow key={reason}>
                  <TableCell width={RowLabelWidth}>
                    {reason}
                  </TableCell>
                  {reasonDecisions[reason].map((reasonDecision, index) => {
                    const id = `${reason}_${decisions[index].deferredPeriod}`;
                    return (
                      <TableCell key={id} sx={cellSX}>
                        {reasonDecision && <ReasonDecisionCard id={id} reasonDecision={reasonDecision} />}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ReasonDecisionAccordion>
      </TableContainerStyled>
    </Container>
  );
}

export default DeferredPeriods;
