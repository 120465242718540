import React from 'react';
import {
  Box,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

export const SyledFormHelpText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  backgroundColor: theme.palette.common.surface8,
}));

interface FormHelpTextProps extends TypographyProps {
}

function FormHelpText({ children, variant = 'body2', ...props }: FormHelpTextProps) {
  return (
    <SyledFormHelpText>
      <InfoOutlined />
      <Typography variant={variant} {...props}>{children}</Typography>
    </SyledFormHelpText>
  );
}

export default FormHelpText;
