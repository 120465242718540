import { Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DocumentCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.common.surface8,
}));

export const LinkButton = styled(Button)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  textDecoration: 'underline',
  color: theme.palette.common.greyDark,
}));

export default {};
