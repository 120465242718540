import { useCallback } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useAppDispatch } from '../store/hooks';
import { sendEnquiryAnswer } from '../features/application-slice';
import SequentialTaskQueue from '../services/sequential-task-queue';
import { Enquiry } from '../services/models/enquiry';
import { updateForm } from '../components/form/form.utils';

function useSendAnswerAndUpdateForm(
  applicantId: string,
  {
    getValues,
    formState: { defaultValues },
    resetField,
    setValue,
  }: UseFormReturn<any, any, undefined>,
  getFormData: (enquiry: Enquiry) => FieldValues,
  queue: SequentialTaskQueue,
) {
  const dispatch = useAppDispatch();
  return useCallback(queue.sequentialize(async (name: string, value: unknown) => {
    const updatedEnquiry = await dispatch(sendEnquiryAnswer(applicantId, name, value));
    const newValues = getFormData(updatedEnquiry);
    updateForm(getValues(), defaultValues, resetField, setValue, newValues);
  }), [queue, applicantId, getFormData, resetField, setValue, getValues, defaultValues]);
}

export default useSendAnswerAndUpdateForm;
