import React from 'react';
import FieldBase, { FieldBaseProps } from './field-base';
import WeightInput, { WeightInputProps } from '../inputs/weight-input';

interface WeightFieldProps extends WeightInputProps, FieldBaseProps {
}

function WeightField({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: WeightFieldProps) {
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <WeightInput name={name} {...props} />
    </FieldBase>
  );
}

export default WeightField;
