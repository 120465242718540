import React from 'react';
import {

  Divider,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AmraAlert,
  ConsentForm,
  FormContainerStyled,
} from './amra-declaration.styles';
import { CheckboxField, RadioOptionsField } from '../../../../components/form/fields';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { amraConsentValidationSchema, getApplicantName } from '../../../../services/application-helpers';
import { MedicalDetailsDto } from '../../../../services/models/medical-details-dto';
import { booleanToYesNo, yesNoToBoolean } from '../../../../components/form/form.utils';

export interface AmraConsentFormProps {
  applicant: ApplicantDto
  onChange: (values: MedicalDetailsDto) => unknown
}

function AmraConsentForm({ applicant, onChange }: AmraConsentFormProps) {
  const { t } = useTranslation();
  const formMethods = useForm({
    defaultValues: {
      ...applicant.medicalDetails,
      amraConsent: applicant.medicalDetails?.amraConsent ?? false,
      previewRequired: booleanToYesNo(applicant.medicalDetails?.previewRequired),
    },
    resolver: yupResolver(amraConsentValidationSchema as any),
  });

  const {
    getValues,
    setValue,
    watch,
  } = formMethods;

  watch(['amraConsent', 'previewRequired']);

  const handleChange = (name: string, value: unknown) => {
    const key = name as keyof MedicalDetailsDto;
    setValue(key, value as string | boolean);
    onChange({
      ...getValues(),
      previewRequired: yesNoToBoolean(getValues().previewRequired),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <FormContainerStyled>
        <ConsentForm>
          <Typography variant="h3">
            {t('components.amraDeclaration.consentForm.title')}
            <span>{`(${getApplicantName(applicant)})`}</span>
          </Typography>
          <Divider />
          <CheckboxField
            valueLabel={t('components.amraDeclaration.consentForm.agree')}
            name="amraConsent"
            checkedValue
            uncheckedValue={false}
            onChangeCommitted={handleChange}
            hideError
            disabled={applicant.medicalDetails?.amraConsent ?? false}
          />
          {getValues().amraConsent && (
            <AmraAlert severity="info" sx={{ mt: 3 }}>{t('components.amraDeclaration.consentForm.agreeMessage')}</AmraAlert>
          )}
          <Divider />
          <RadioOptionsField
            label={t('components.amraDeclaration.consentForm.medicalRecordsCopy')}
            name="previewRequired"
            options={['yes', 'no']}
            sx={{
              minWidth: 200,
            }}
            labelTranslationBasePath="common.yesNo"
            onChangeCommitted={handleChange}
            hideError
          />
          {getValues().previewRequired === 'yes' && (
            <AmraAlert severity="info" sx={{ mt: 3 }}>{t('components.amraDeclaration.consentForm.medicalRecordsCopyMessage')}</AmraAlert>
          )}
        </ConsentForm>
      </FormContainerStyled>
    </FormProvider>
  );
}

export default AmraConsentForm;
