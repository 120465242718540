import http from './http';
import { ApplicantDto } from './models/applicant-dto';
import { PreSalesDecisionResult } from './models/pre-sales-decision-result';

/**
 * Retrieve an applicants pre-sales decision.
 */
async function getApplicantPreSalesDecision(applicationId: string, applicantId: string): Promise<PreSalesDecisionResult> {
  return http.get(`/api/v1/bff/application/${applicationId}/applicant/${applicantId}/pre-sales-decision`);
}

async function getAllFor(applicationId: string, applicants: ApplicantDto[]): Promise<Record<string, PreSalesDecisionResult>> {
  const decisionsArray = await Promise.all(applicants.map((applicant) => getApplicantPreSalesDecision(applicationId, applicant.id)));
  return decisionsArray.reduce((result, decision) => ({ ...result, [decision.applicant.id]: decision }), {});
}

const decisionApi = {
  getApplicantPreSalesDecision,
  getAllFor,
};

export default decisionApi;
