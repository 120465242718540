import React from 'react';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectAmraStatus,
  selectApplicationStatus,
  selectEnquiryFor,
  selectPostUnderwritingDeclarationEnabledFor,
} from '../../../../features/application-slice';
import { EnquiryLineForm, EnquiryLineBreadcrumbs } from '../../../../components/form/enquiry';
import { getNavigableConditionByPath } from '../../../../services/enquiry-helpers';
import {
  View,
  amraDeclarationView,
  conditionView,
  postUnderwritingDeclarationView,
} from '../../../view';
import { EnquiryLine } from '../../../../services/models/enquiry-line';
import { Enquiry } from '../../../../services/models/enquiry';
import { isPreSalesAppliction } from '../../../../services/application-helpers';
import { ProgressStatus } from '../../../../services/models/progress-status';
import EnquiryFormProvider from '../../../../components/form/enquiry/enquiry-form-provider';

function getNextView(
  nextCondition: EnquiryLine | null,
  enquiry: Enquiry,
  isPreSales: boolean,
  uwDeclarationEnabled: boolean,
  amraComplete: boolean,
): View | null {
  if (nextCondition?.path) {
    return conditionView(nextCondition.path);
  }
  if (!isPreSales && uwDeclarationEnabled && amraComplete) {
    return postUnderwritingDeclarationView;
  }
  if (!isPreSales && enquiry.isSatisfied && enquiry.isCloseable) {
    return amraDeclarationView;
  }
  return null;
}

interface ConditionFormProps {
  applicant: ApplicantDto;
  conditionPath: string;
  onSubmit: () => unknown;
  onCancel: () => unknown;
  onNext: (view: View) => unknown;
  onPrevious: (view: View) => unknown;
}

function ConditionForm({
  applicant,
  conditionPath,
  onSubmit,
  onCancel,
  onNext,
  onPrevious,
}: ConditionFormProps) {
  const applicationStatus = useAppSelector(selectApplicationStatus);
  const isPreSales = isPreSalesAppliction(applicationStatus);
  const enquiry = useAppSelector(selectEnquiryFor(applicant.id));
  const enquiryLines = getNavigableConditionByPath(enquiry, conditionPath);
  const uwDeclarationEnabled = useAppSelector(selectPostUnderwritingDeclarationEnabledFor(applicant.id));
  const amraComplete = useAppSelector(selectAmraStatus(applicant.id)) === ProgressStatus.Complete;
  if (!enquiryLines) {
    return null;
  }
  const { previous, current, next } = enquiryLines;
  const nextView = getNextView(next, enquiry, isPreSales, uwDeclarationEnabled, amraComplete);

  return (
    <EnquiryFormProvider enquiry={enquiry}>
      <EnquiryLineForm
        applicantId={applicant.id}
        enquiry={enquiry}
        enquiryLine={current}
        onSubmit={onSubmit}
        onCancel={onCancel}
        title={<EnquiryLineBreadcrumbs enquiryLine={current} />}
        onNext={nextView ? () => onNext(nextView) : undefined}
        onPrevious={previous?.path ? () => onPrevious(conditionView(previous.path!)) : undefined}
        showProgress
      />
    </EnquiryFormProvider>
  );
}

export default ConditionForm;
