import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActionsWithNavigation,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import { AmraTitle, ConsentCardContent } from '../amra-declaration/amra-declaration.styles';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { View, amraDeclarationView } from '../../../view';
import { MedicalDetailsDto } from '../../../../services/models/medical-details-dto';
import {
  formatLoqateAddress,
  getApplicantMedicalDetailsAddress,
  getApplicantName,
  isDoctorDetailsReadOnly,
} from '../../../../services/application-helpers';
import AddressSearch from '../../../../components/address-search';
import { AddressResultDto } from '../../../../services/bff/models/address-result-dto';
import AmraDoctorForm from './amra-doctor-form';
import { ConsentForm, LinkButton } from './amra-doctor-details.styles';
import applicationApi from '../../../../services/application-api';
import { AddressDto } from '../../../../services/models/address-dto';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { patchApplicantMedicalDetails, patchApplicantMedicalDetailsAddress, selectApplication } from '../../../../features/application-slice';
import useFocusScroll from '../../../../hooks/use-focus-scroll';
import { toastError } from '../../../../components/toast';

function byApplicantId<T>(applicants: ApplicantDto[], getValue: (applicant: ApplicantDto) => T): Record<string, T> {
  return applicants.reduce((acc, curr) => ({ ...acc, [curr.id]: getValue(curr) }), {});
}

interface AmraDoctorDetailsProps {
  applicationId: string;
  applicants: ApplicantDto[];
  onSubmit: () => unknown;
  onCancel: () => unknown;
  onPrevious: (view: View) => unknown;
}

function AmraDoctorDetails({
  applicationId,
  applicants,
  onSubmit,
  onCancel,
  onPrevious,
}: AmraDoctorDetailsProps) {
  const { t } = useTranslation();
  const cardRef = useRef<HTMLDivElement>(null);
  useFocusScroll(cardRef, 100);
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplication);
  const [busy, setBusy] = useState<boolean>(false);
  const [valid, setValid] = useState<Record<string, boolean>>(byApplicantId(applicants, () => false));
  const [doctorAddress, setDoctorAddress] = useState<Record<string, AddressDto | null>>(
    byApplicantId(applicants, (a) => getApplicantMedicalDetailsAddress(application, a)),
  );
  const [showDoctorForm, setShowDoctorForm] = useState<Record<string, boolean>>(
    byApplicantId(applicants, (a) => !!a.medicalDetails?.addressId),
  );
  const [applicantsMedicalDetails, setApplicantsMedicalDetails] = useState<Record<string, MedicalDetailsDto>>(
    byApplicantId(applicants, (a) => a.medicalDetails!),
  );
  const allValid = Object.values(valid).every((v) => v);
  const readOnly = isDoctorDetailsReadOnly(application);

  const handleSubmit = async () => {
    if (readOnly) {
      await onSubmit();
      return;
    }
    try {
      setBusy(true);
      await Promise.all(applicants.map(async (applicant) => {
        const address = doctorAddress[applicant.id];
        let { addressId } = applicantsMedicalDetails[applicant.id];
        if (address) {
          const savedAddress = await dispatch(patchApplicantMedicalDetailsAddress(
            applicationId,
            applicant.id,
            address,
          ));
          addressId = savedAddress.id;
          setDoctorAddress((state) => ({ ...state, [applicant.id]: address }));
        }
        const updatedApplicant = await dispatch(patchApplicantMedicalDetails(
          applicationId,
          applicant.id,
          { ...applicantsMedicalDetails[applicant.id], addressId },
        ));
        setApplicantsMedicalDetails((state) => ({ ...state, [applicant.id]: updatedApplicant.medicalDetails! }));
      }));
      await onSubmit();
    } catch (e) {
      toastError(t('components.amraDoctorDetails.submitError'));
    } finally {
      setBusy(false);
    }
  };

  const handleAddressSelect = async (applicantId: string, newAddress: AddressResultDto | null) => {
    try {
      if (newAddress) {
        const foundAddress = await applicationApi.searchAddressById(newAddress.id);
        setDoctorAddress((state) => ({ ...state, [applicantId]: formatLoqateAddress(foundAddress) }));
        setApplicantsMedicalDetails((medicalDetails) => ({
          ...medicalDetails,
          [applicantId]: { ...medicalDetails[applicantId], addressId: null, practiceName: foundAddress.company },
        }));
        setShowDoctorForm((currentForm) => ({ ...currentForm, [applicantId]: true }));
      }
    } catch (e) {
      toastError(t('components.amraDoctorDetails.addressSelectError'));
    }
  };

  const handlePrevious = () => {
    onPrevious(amraDeclarationView);
  };

  const handleFormDisplay = (applicantId: string, show: boolean) => {
    setShowDoctorForm((currentForm) => ({ ...currentForm, [applicantId]: show }));
  };

  const handleChange = async (applicantId: string, values: MedicalDetailsDto & AddressDto, isValid: boolean) => {
    setValid((value) => ({ ...value, [applicantId]: isValid }));
    if (isValid) {
      const {
        address1,
        address2,
        address3,
        cityName,
        county,
        postcode,
        ...details
      } = values;
      setApplicantsMedicalDetails((state) => ({
        ...state,
        [applicantId]: details,
      }));
      setDoctorAddress((state) => ({
        ...state,
        [applicantId]: {
          address1,
          address2,
          address3,
          cityName,
          county,
          postcode,
        },
      }));
    }
  };

  return (
    <DrawerCard sx={{ overflow: 'scroll' }} ref={cardRef}>
      <DrawerCardHeader
        title={(
          <Breadcrumbs separator=">">
            <span>{t('components.amraDoctorDetails.titlePart1')}</span>
            <span>{t('components.amraDoctorDetails.titlePart2')}</span>
          </Breadcrumbs>
        )}
        action={<CancelDrawerButton onClick={onCancel} />}
      />
      <ConsentCardContent>
        <AmraTitle variant="h2" gutterBottom>
          {t('components.amraDoctorDetails.doctorDetailsTitle')}
        </AmraTitle>
        <Typography variant="h5" component="p" gutterBottom>
          {t('components.amraDoctorDetails.introTitle')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('components.amraDoctorDetails.introDescription')}
        </Typography>
        {applicants.map((applicant) => (
          <Card sx={{ mt: 4, mb: 4 }} key={applicant.id}>
            <CardHeader
              title={(
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <AccountCircleOutlined titleAccess="" sx={{ height: 28 }} color="primary" />
                  <span>
                    {getApplicantName(applicant)}
                  </span>
                </Stack>
              )}
              action={!readOnly && showDoctorForm[applicant.id] && (
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => handleFormDisplay(applicant.id, false)}
                  startIcon={<RefreshIcon />}
                >
                  {t('common.reset')}
                </Button>
              )}
              titleTypographyProps={{ variant: 'h3' }}
            />
            <CardContent>
              {!readOnly && !showDoctorForm[applicant.id] ? (
                <>
                  <Typography variant="h4" gutterBottom>
                    {t('components.amraDoctorDetails.searchPanel.intro1')}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t('components.amraDoctorDetails.searchPanel.intro2')}
                  </Typography>
                  <ConsentForm>
                    <AddressSearch
                      label={t('components.amraDoctorDetails.searchPanel.inputLabel')}
                      onChange={(foundAddress) => handleAddressSelect(applicant.id, foundAddress)}
                      placeholder={t('components.amraDoctorDetails.searchPanel.placeholder')!}
                    />
                  </ConsentForm>
                  <Divider sx={{ mx: -4, mt: 3, mb: 2 }} />
                  <LinkButton variant="text" color="inherit" onClick={() => handleFormDisplay(applicant.id, true)}>
                    {t('components.amraDoctorDetails.searchPanel.enterManually')}
                  </LinkButton>
                </>
              ) : (
                <AmraDoctorForm
                  medicalDetails={applicantsMedicalDetails[applicant.id]}
                  address={doctorAddress[applicant.id]}
                  onChange={(values, isValid) => handleChange(applicant.id, values, isValid)}
                  readOnly={readOnly}
                />
              )}
            </CardContent>
          </Card>
        ))}
      </ConsentCardContent>
      <DrawerCardActionsWithNavigation
        onPrevious={handlePrevious}
        onNext={handleSubmit}
        previousProps={{ disabled: busy }}
        nextProps={{ disabled: !allValid || busy }}
      >
        <LoadingButton
          variant="outlined"
          color="secondary"
          type="submit"
          disabled={!allValid}
          onClick={handleSubmit}
          loading={busy}
        >
          {t('common.submit')}
        </LoadingButton>
      </DrawerCardActionsWithNavigation>
    </DrawerCard>
  );
}

export default AmraDoctorDetails;
