import React from 'react';
import { BoxProps } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import {
  InputStatisfied as StyledInputStatisfied,
  InputStatus as StyledInputStatus,
} from './form.styles';

function QuestionSatisfied() {
  const { t } = useTranslation();
  return (
    <StyledInputStatisfied icon={<CheckCircleOutlineIcon color="success" />} label={t('components.question.complete')} />
  );
}

interface InputStatusProps extends BoxProps {
  complete?: boolean | null;
}

function InputStatus({ complete = false, ...props }: InputStatusProps) {
  return (
    <StyledInputStatus {...props}>
      {complete && <QuestionSatisfied />}
    </StyledInputStatus>
  );
}

export default InputStatus;
