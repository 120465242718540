import React from 'react';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Authenticator } from '@aws-amplify/ui-react';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { store } from './store';
import './print.css';
import { awsConfig } from './services/auth-api';
import CacheBuster from './components/cache-buster';
import analyticsService from './services/analytics';
import AppRoutes from './routes/routes';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: awsConfig.aws_user_pools_web_client_id,
      userPoolId: awsConfig.aws_user_pools_id,
      loginWith: {
        oauth: {
          domain: awsConfig.aws_cognito_user_pool_domain,
          scopes: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
          responseType: 'code',
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <CacheBuster>
            {(busy: boolean, version: string | null) => {
              if (!busy && version) {
                analyticsService.initGA();
                return (
                  <Authenticator.Provider>
                    <AppRoutes />
                  </Authenticator.Provider>
                );
              }
              return null;
            }}
          </CacheBuster>
        </LocalizationProvider>
      </Provider>
      <ToastContainer newestOnTop />
    </ThemeProvider>
  );
}

export default App;
