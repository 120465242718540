import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Footer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  minHeight: 65,
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

export const FooterBar = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  height: theme.mixins.footerBarMinHeight,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px -5px 13px -3px rgba(0,0,0,0.15)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  gap: theme.spacing(4),
}));

export default {};
