import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  StackProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PreDisclosedPanelStyled } from './pre-disclosed-panel.styles';

interface PreDisclosedPanelProps extends StackProps {
  conditions: string[]
}

function PreDisclosedPanel({ conditions, ...props }: PreDisclosedPanelProps) {
  const { t } = useTranslation();
  return (
    <PreDisclosedPanelStyled gap={1} {...props}>
      <Typography variant="h4">{t('components.preDisclosurePanel.title')}</Typography>
      <Card>
        <CardContent>
          <Typography variant="body1">
            {t('components.preDisclosurePanel.intro')}
          </Typography>
          <ul>
            {conditions.map((condition) => <li key={condition}>{condition}</li>)}
          </ul>
        </CardContent>
        <CardActions>
          <Typography variant="body1">
            {t('components.preDisclosurePanel.footer')}
          </Typography>
        </CardActions>
      </Card>
    </PreDisclosedPanelStyled>
  );
}

export default PreDisclosedPanel;
