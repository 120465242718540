import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { isNil } from 'lodash';
import { FormContainerStyled } from './real-life-form-styles';
import ButtonOptionsField from '../../../../components/form/fields/button-options-field';
import { ProductEditability, ProductFormProps, RealLifeCoverTypes } from '../../../../services/product-helpers';
import TermOrToAge from '../term-or-toage/term-or-toage';
import CoverAmountField from '../cover-amount-field';

function RealLifeForm({
  onChangeCommitted,
  decisionCoverAmount = undefined,
  restrictions = undefined,
  editable = ProductEditability.Editable,
}: ProductFormProps) {
  const { t } = useTranslation();

  return (
    <FormContainerStyled>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <CoverAmountField
          label={t('components.realLifeForm.coverAmount')}
          onChangeCommitted={onChangeCommitted}
          decisionCoverAmount={decisionCoverAmount}
          readOnly={editable === ProductEditability.ReadOnly || !isNil(restrictions?.coverAmount)}
        />
        <ButtonOptionsField
          name="coverType"
          label={t('components.realLifeForm.coverType')}
          options={Object.values(RealLifeCoverTypes)}
          labelTranslationBasePath="common.coverType"
          onChangeCommitted={onChangeCommitted}
          hideError
          disabled={editable !== ProductEditability.Editable || !isNil(restrictions?.coverType)}
        />
        <TermOrToAge
          onChangeCommitted={onChangeCommitted}
          readOnly={editable !== ProductEditability.Editable || !isNil(restrictions?.term) || !isNil(restrictions?.toAge)}
        />
      </Stack>
    </FormContainerStyled>
  );
}

export default RealLifeForm;
