import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
} from '@mui/material';
import { ButtonGroup } from './input.styles';
import { Option, WithOnChangeCommitted, getLabelAndValue } from '../../types';

export interface ButtonOptionsProps extends WithOnChangeCommitted<string> {
  options: (string | Option)[];
  labelTranslationBasePath?: string;
  name: string;
  disabled?: boolean;
}

function ButtonOptions({
  options,
  name,
  labelTranslationBasePath = undefined,
  onChangeCommitted,
  ...props
}: ButtonOptionsProps) {
  const {
    field: { value, onChange, ref },
    formState: { defaultValues },
  } = useController({ name });
  const { t } = useTranslation();

  const handleChange = useCallback((newValue: string) => {
    onChange(newValue);
    const oldValue = defaultValues?.[name];
    if (onChangeCommitted && oldValue !== newValue) {
      onChangeCommitted(name, newValue);
    }
  }, [onChange, onChangeCommitted]);

  return (
    <ButtonGroup disableElevation {...props} ref={ref}>
      {options.map((option: string | Option) => {
        const { label, value: optionValue } = getLabelAndValue(option, t, labelTranslationBasePath);
        return (
          <Button
            key={optionValue}
            variant={_.isEqual(optionValue, value) ? 'contained' : 'outlined'}
            color="secondary"
            onClick={() => handleChange(optionValue)}
          >
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

export default ButtonOptions;
