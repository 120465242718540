import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Autocomplete,
  AutocompleteProps,
  Snackbar,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import { AddressResultDto } from '../../services/bff/models/address-result-dto';
import applicationApi from '../../services/application-api';

interface AddressSearchProps extends Omit<AutocompleteProps<AddressResultDto, false, false, false>, 'onChange' | 'options' | 'renderInput'> {
  label: string;
  minChars?: number;
  onChange: (address: AddressResultDto | null) => void;
}

function AddressSearch({
  label,
  onChange,
  placeholder,
  minChars = 3,
  ...props
}: AddressSearchProps) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<AddressResultDto[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchOptionsDebounced = useMemo(() => debounce(async (searchValue: string) => {
    if (searchValue.length < minChars) {
      setOptions([]);
      return;
    }
    setBusy(true);
    try {
      const fetchedOptions = await applicationApi.searchAddress(searchValue);
      setOptions(fetchedOptions?.items ?? []);
    } catch (e) {
      setError(e);
    } finally {
      setBusy(false);
    }
  }, 500), []);

  const closeError = () => setError(null);

  const handleOnInputChange = (event: any, searchValue: string) => {
    setInputValue(searchValue);
    fetchOptionsDebounced(searchValue);
  };

  const handleChange = (event: any, newValue: AddressResultDto | null) => {
    onChange(newValue);
  };

  return (
    <>
      <Autocomplete
        {...props}
        options={options}
        loading={busy}
        noOptionsText={inputValue.length <= minChars ? t('components.typeahead.typeForOptions') : t('components.typeahead.noOptions')}
        getOptionLabel={(option: any) => {
          if (typeof option === 'object') {
            return `${option.text}, ${option.description}`;
          }
          return option;
        }}
        renderInput={(params) => (
          <TextField {...params} InputLabelProps={{ shrink: true }} label={label} placeholder={placeholder} fullWidth />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={handleOnInputChange}
        onChange={handleChange}
      />
      <Snackbar
        open={!!error}
        onClose={closeError}
      >
        <Alert onClose={closeError} severity="error" sx={{ width: '100%' }}>
          {t('components.typeahead.optionsError')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddressSearch;
