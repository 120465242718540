import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const OccupationLimitsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  '& > h4': {
    marginBottom: theme.spacing(2),
  },
  '& label': {
    color: theme.palette.common.surface1,
  },
  '& label + p': {
    fontFamily: theme.typography.h5.fontFamily,
  },
}));

export default OccupationLimitsContainer;
