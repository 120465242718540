interface Task<T> {
  invoke: () => Promise<T>;
  resolve: (value: T) => void;
  reject: (reason: unknown) => void;
}

class SequentialTaskQueue {
  private tasks: Task<any>[] = [];

  private busy: boolean = false;

  private lastPromise: Promise<unknown> | null = null;

  sequentialize<T extends (...args: any) => any>(callback: T): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    return (...args: Parameters<T>) => this.push(() => callback(...args));
  }

  push<T>(work: () => Promise<T>): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      this.tasks.push({
        invoke: work,
        resolve,
        reject,
      });
      this.dequeue();
    });
    this.lastPromise = promise;
    promise.finally(() => {
      if (this.lastPromise === promise) {
        this.lastPromise = null;
      }
    });
    return promise;
  }

  private dequeue() {
    if (this.busy) {
      return;
    }
    const task = this.tasks.shift();
    if (!task) {
      return;
    }
    try {
      this.busy = true;
      task.invoke()
        .then((value) => {
          this.busy = false;
          task.resolve(value);
          this.dequeue();
        })
        .catch((err) => {
          this.busy = false;
          task.reject(err);
          this.dequeue();
        });
    } catch (err) {
      this.busy = false;
      task.reject(err);
      this.dequeue();
    }
  }

  public async waitForPendingTasks(): Promise<void> {
    if (this.lastPromise) {
      await this.lastPromise;
    }
  }

  public get isEmpty(): boolean {
    return this.tasks.length === 0 && !this.busy;
  }
}

export default SequentialTaskQueue;
