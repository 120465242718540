import React from 'react';
import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Decision, ImmediateDecision } from '../../../../components/decision';
import { isImmediateDecision } from '../../../../services/decision-helpers';
import { MoneyInput, SwitchInput, TwoDecimalsMoneyFormatter } from '../../../../components/form/inputs';
import { TotalPrice } from '../price';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import { QuoteBasis } from '../../../../services/models/quote-basis';
import { DecisionContainer } from './decision-table.styles';
import DiscountChip from '../../../../components/discount-chip';

export interface EditableDecisionProps {
  decision: ProductQuoteDecision | null;
  onChangeCommitted: (name: string, value: unknown) => unknown;
  readOnly?: boolean;
}

function EditableDecision({
  decision,
  onChangeCommitted,
  readOnly = false,
}: EditableDecisionProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const quoteBasis: QuoteBasis = watch('quoteBasis');

  return (
    <DecisionContainer direction="row" gap={4} decision={decision?.decision} flexWrap="wrap">
      <Stack direction="row" gap={4} flexGrow={1} flexWrap="wrap">
        {decision?.decision && (
          <>
            <Decision decision={decision.decision} />
            {isImmediateDecision(decision.decision) && <ImmediateDecision />}
          </>
        )}
      </Stack>
      <SwitchInput
        label={t('components.productQuoteDecisonTable.premiumDriven')}
        formControlLabelProps={{ labelPlacement: 'start' }}
        name="quoteBasis"
        uncheckedValue={QuoteBasis.CoverAmount}
        checkedValue={QuoteBasis.Premium}
        onChangeCommitted={onChangeCommitted}
        color="success"
        disabled={readOnly}
      />
      <Stack
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="row"
        flexWrap="wrap"
        gap={2}
        sx={{
          minWidth: 120,
        }}
      >
        {quoteBasis === QuoteBasis.Premium && (
          <MoneyInput
            name="premium"
            formatter={TwoDecimalsMoneyFormatter}
            onChangeCommitted={onChangeCommitted}
            inputProps={{
              min: 0,
              step: 0.01,
              'data-testid': 'product-premium',
            }}
            sx={{
              maxWidth: 120,
            }}
          />
        )}
        {decision?.productDiscount && <DiscountChip discount={decision?.productDiscount} />}
        {(quoteBasis !== QuoteBasis.Premium) && decision?.premiums && (
          <TotalPrice
            premium={decision.premiums}
            priceProps={{ size: 'medium' }}
            data-testid="product-premium"
          />
        )}
      </Stack>
    </DecisionContainer>
  );
}

export default EditableDecision;
