import { saveAs } from 'file-saver';
import { AxiosResponse } from 'axios';
import { instance } from './http';
import { AddEvidenceDocumentDto } from './bff/models/add-evidence-document-dto';
import { EvidenceDocumentDto } from './bff/models/evidence-document-dto';
import { getExtensionForType } from './file-helper';

/**
 * Download the generation of a Presale document for the application.
 */
async function downloadPresaleDocument(applicationId: string, onboarding: boolean): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/preSale`, { params: { onboarding }, responseType: 'blob' });
}

/**
 * Download the generation of a Q&A illustration document for the application.
 */
async function downloadQuestionsAndAsnwersDocument(applicationId: string, applicantId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/applicants/${applicantId}/documents/questionAnswerIllustration`, { responseType: 'blob' });
}

/**
 * Download the generation of a quote illustration document for the application.
 */
async function downloadQuoteIllustrationDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/quoteIllustration`, { responseType: 'blob' });
}

/**
 * Download the generation of an acceptance document for the application.
 */
async function downloadAcceptanceDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/acceptanceTerms`, { responseType: 'blob' });
}

/**
 * Download the generation of a direct debit for the application.
 */
async function downloadDirectDebitDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/directDebit`, { responseType: 'blob' });
}

/**
 * Download the completed direct debit mandate for the application.
 */
async function downloadDirectDebitMandateDocument(applicationId: string): Promise<AxiosResponse<Blob>> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/directdebitmandate`, { responseType: 'blob' });
}

/**
 * Download the generation of a direct debit mandate form for the application.
 */
async function downloadDirectDebitMandateFormDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/directdebitmandateform`, { responseType: 'blob' });
}

/**
 * Download the generation of a policy schedule for the application.
 */
async function downloadPolicyScheduleDocument(applicationId: string): Promise<void> {
  return instance.get(`/api/v1/bff/Application/${applicationId}/documents/policySchedule`, { responseType: 'blob' });
}

function getFileNameFromContentDisposition(disposition: string | null | undefined): string | null {
  if (!disposition) {
    return null;
  }
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName = null;
  if (utf8FilenameRegex.test(disposition)) {
    const matches = utf8FilenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = decodeURIComponent(matches[1]);
    }
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    // slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        // eslint-disable-next-line prefer-destructuring
        fileName = matches[2];
      }
    }
  }
  return fileName;
}

function getFileNameFromResponse(response: AxiosResponse<Blob>, defaultName: string): string {
  const contentDisposition = response.headers['content-disposition'];
  const fileName = getFileNameFromContentDisposition(contentDisposition)
    ?? `${defaultName}${getExtensionForType(response.data.type)}`;
  return fileName;
}

async function downloadDocument(downloadRequest: Promise<any>, fileName: string) {
  try {
    const response = await downloadRequest;
    saveAs(response.data, fileName);
  } catch {
    throw new Error('Document download failed.');
  }
}

async function uploadDirectDebitMandateDocument(data: AddEvidenceDocumentDto): Promise<EvidenceDocumentDto> {
  return instance.post(`/api/v1/bff/Application/${data.applicationId}/documents/directdebitmandate`, data);
}

const documentApi = {
  getFileNameFromResponse,
  downloadDocument,
  downloadPresaleDocument,
  downloadQuestionsAndAsnwersDocument,
  downloadQuoteIllustrationDocument,
  downloadAcceptanceDocument,
  downloadDirectDebitDocument,
  downloadDirectDebitMandateDocument,
  downloadDirectDebitMandateFormDocument,
  downloadPolicyScheduleDocument,
  uploadDirectDebitMandateDocument,
};

export default documentApi;
