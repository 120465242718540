import React from 'react';
import {
  Box,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DetailsDivider,
  DetailsTable,
  DetailsTableBody,
  DetailsTableCell,
  EditButton,
} from './client-details-bar.styles';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { asAge, asClientDate } from '../../../../utils/converters';
import { View, editApplicantView } from '../../../view';
import { SectionStatus } from '../../../../services/models/section-status';
import { Enquiry } from '../../../../services/models/enquiry';
import { formatAnswer, getClientDetailsEnquiryLine } from '../../../../services/enquiry-helpers';
import { Section } from './personal-information';

export interface PersonalInformationFullProps {
  applicant: ApplicantDto;
  enquiry?: Enquiry;
  status?: SectionStatus;
  onEdit?: (view: View) => void;
}

export function convertName(value: string | null) {
  return value?.toLowerCase().replace(' ', '-');
}

function PersonalInformationFull({
  applicant,
  enquiry = undefined,
  status = undefined,
  onEdit = undefined,
}: PersonalInformationFullProps) {
  const { t } = useTranslation();

  const enquiryLine = enquiry ? getClientDetailsEnquiryLine(enquiry) : null;

  return (
    <Section title={t('components.personalInformation.title')} status={status}>
      <DetailsDivider />
      <DetailsTable sx={{ mb: 2 }}>
        <DetailsTableBody>
          <TableRow>
            <DetailsTableCell component="th">
              {t('components.personalInformation.dateOfBirth')}
            </DetailsTableCell>
            <DetailsTableCell>
              {t('components.personalInformation.dateOfBirthWithAge', {
                dateOfBirth: asClientDate(applicant.dateOfBirth),
                age: asAge(applicant.dateOfBirth),
              })}
            </DetailsTableCell>
          </TableRow>
          {applicant.age && (
            <TableRow>
              <DetailsTableCell component="th">
                {t('components.personalInformation.age')}
              </DetailsTableCell>
              <DetailsTableCell>
                {applicant.age.toString()}
              </DetailsTableCell>
            </TableRow>
          )}
          <TableRow>
            <DetailsTableCell component="th">
              {t('components.personalInformation.gender')}
            </DetailsTableCell>
            <DetailsTableCell>
              {applicant.gender ? t(`common.gender.${applicant.gender}`) : null}
            </DetailsTableCell>
          </TableRow>
          <TableRow>
            <DetailsTableCell component="th">
              {t('components.personalInformation.smokerStatus')}
            </DetailsTableCell>
            <DetailsTableCell>
              {applicant.smokerStatus ? t(`common.smokerStatus.${applicant.smokerStatus}`) : null}
            </DetailsTableCell>
          </TableRow>
          {enquiry && enquiryLine && enquiry.allAnswers && enquiryLine.questions.map((question) => (
            <TableRow key={question.name} data-testid={question.name?.toLowerCase()}>
              <DetailsTableCell component="th">
                {question.definition.text!}
              </DetailsTableCell>
              <DetailsTableCell>
                {formatAnswer(question, enquiry.allAnswers!)}
              </DetailsTableCell>
            </TableRow>
          ))}
        </DetailsTableBody>
      </DetailsTable>

      {onEdit && (
        <Box sx={{ marginTop: 'auto' }}>
          <EditButton variant="outlined" color="primary" onClick={() => onEdit(editApplicantView)}>
            {t('common.edit')}
          </EditButton>
        </Box>
      )}
    </Section>
  );
}

export default PersonalInformationFull;
