import React from 'react';
import { useController } from 'react-hook-form';
import { WithOnChangeCommitted } from '../../types';
import Typeahead, { TypeaheadProps } from '../../typeahead/typeahead';

export interface TypeaheadInputProps extends Omit<TypeaheadProps<false, false, boolean>, 'value' | 'onChange'>, WithOnChangeCommitted<string | null> {
  name: string;
}

function TypeaheadInput({
  name,
  onChangeCommitted = undefined,
  ...props
}: TypeaheadInputProps) {
  const {
    field: { value, ...field },
    formState: { defaultValues },
  } = useController({ name });
  const defaultAnswer = defaultValues?.[name];

  const handleOnChange = (event: any, newValue: string | null) => {
    field.onChange(newValue);
    if (newValue && newValue !== defaultAnswer) {
      if (onChangeCommitted) {
        onChangeCommitted(name, newValue);
      }
    }
  };

  return (
    <Typeahead<false, false, boolean>
      {...field}
      {...props}
      value={value || null}
      id={name}
      onChange={handleOnChange}
    />
  );
}

export default TypeaheadInput;
