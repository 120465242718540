import React from 'react';
import ButtonOptions, { ButtonOptionsProps } from '../inputs/button-options';
import FieldBase, { FieldBaseProps } from './field-base';

interface ButtonOptionsFieldProps extends ButtonOptionsProps, FieldBaseProps {
}

function ButtonOptionsField({
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: ButtonOptionsFieldProps) {
  const { name } = props;
  const labelId = label ? `${name}-label` : undefined;
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <ButtonOptions {...props} aria-labelledby={labelId} />
    </FieldBase>
  );
}

export default ButtonOptionsField;
