import { styled } from '@mui/material/styles';
import { FormContainer } from '../../../../components/form';

export const FormContainerStyled = styled(FormContainer)(({ theme }) => ({
  '& label': {
    marginBottom: theme.spacing(1),
  },
  '& .MuiFormControl-root': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
}));

export default FormContainerStyled;
