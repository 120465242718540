import React from 'react';
import RadioOptions, { RadioOptionsProps } from '../inputs/radio-options';
import FieldBase, { FieldBaseProps } from './field-base';

interface RadioOptionsFieldProps extends RadioOptionsProps, FieldBaseProps {
}

function RadioOptionsField({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: RadioOptionsFieldProps) {
  const labelId = label ? `${name}-label` : undefined;
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <RadioOptions name={name} {...props} aria-labelledby={labelId} />
    </FieldBase>
  );
}

export default RadioOptionsField;
