import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

interface InfiniteScrollProps extends PropsWithChildren {
  active: boolean
  loading: boolean
  hasMore: boolean
  onLoadMore: () => unknown
}

function InfiniteScroll({
  loading,
  hasMore,
  onLoadMore,
  active,
  children,
}: InfiniteScrollProps) {
  const tableEl = useRef<HTMLDivElement>(null);
  const [distanceBottom, setDistanceBottom] = useState<number>(0);

  const scrollListener = useCallback(() => {
    if (tableEl.current) {
      const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
      if (!distanceBottom) {
        setDistanceBottom(Math.round(bottom * 0.2));
      }
      if (tableEl.current.scrollTop > bottom - distanceBottom && hasMore && !loading) {
        onLoadMore();
      }
    }
  }, [hasMore, onLoadMore, loading, distanceBottom]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    if (tableRef) {
      tableRef.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (tableRef) {
        tableRef.removeEventListener('scroll', scrollListener);
      }
    };
  }, [scrollListener]);

  useEffect(() => {
    let bottom = -1;
    if (active && tableEl.current) {
      bottom = tableEl?.current ? tableEl.current.scrollHeight - tableEl.current.clientHeight : 0;
    }
    if (active && !loading && hasMore && bottom === 0) {
      onLoadMore();
    }
  }, [active, loading, hasMore]);

  const child = React.Children.only(children);
  return React.cloneElement(child as any, { ref: tableEl });
}

export default InfiniteScroll;
