import React, { PropsWithChildren } from 'react';
import { CardProps } from '@mui/material/Card';
import HelpIcon from '@mui/icons-material/Help';
import { FaqCard as FaqCardStyled, FaqCardHeader, FaqCardContent } from './faq-card.styles';

function FaqCard({
  title,
  children = null,
  ...otherProps
}: PropsWithChildren<CardProps>) {
  return (
    <FaqCardStyled {...otherProps} elevation={0}>
      {title && (
        <FaqCardHeader
          avatar={
            <HelpIcon />
          }
          title={title}
          titleTypographyProps={{
            variant: 'h4',
          }}
        />
      )}
      <FaqCardContent>
        {children}
      </FaqCardContent>
    </FaqCardStyled>
  );
}

export default FaqCard;
