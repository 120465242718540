import React, {
  MouseEvent,
  useCallback,
  useState,
} from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  Paper,
  PopperProps,
  Stack,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { QuoteDatePopper } from './quote-date.styles';
import { selectApplicationDates, selectApplicationState, updateQuoteEffectiveDate } from '../../../../features/application-slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import HeaderButton from '../application-layout/header-button';
import { DateField } from '../../../../components/form/fields';
import useBusyState from '../../../../hooks/use-busy-state';
import { asServerDate, parseServerDate } from '../../../../utils/converters';
import { toastError, toastSuccess } from '../../../../components/toast';

interface FormData {
  quoteEffectiveDate: string | null;
}

function validationSchema(): yup.ObjectSchema<FormData> {
  return yup.object({
    quoteEffectiveDate: yup.string().required('Required'),
  });
}

interface QuoteDateFormPopperProps extends PopperProps {
  quoteEffectiveDate?: string | null | undefined
  loading?: boolean
  onCancel: () => void
  onConfirm: (reference: string) => void
}

function QuoteDateFormPopper({
  quoteEffectiveDate = '',
  loading = false,
  onCancel,
  onConfirm,
  ...props
}: QuoteDateFormPopperProps) {
  const formMethods = useForm<FormData>({
    defaultValues: { quoteEffectiveDate: asServerDate(quoteEffectiveDate) ?? '' },
    resolver: yupResolver(validationSchema()),
    mode: 'onBlur',
  });
  const {
    formState: {
      isValid,
    },
    getValues,
  } = formMethods;

  return (
    <QuoteDatePopper {...props} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <ClickAwayListener onClickAway={onCancel}>
              <Stack direction="row" alignItems="center">
                <FormProvider {...formMethods}>
                  <DateField
                    name="quoteEffectiveDate"
                    sx={{ width: '100%' }}
                  />
                </FormProvider>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  aria-label="update"
                  color="secondary"
                  size="small"
                  onClick={() => onConfirm(getValues().quoteEffectiveDate ?? '')}
                  disabled={loading || !isValid}
                >
                  {loading ? <CircularProgress size={25} color="secondary" /> : <DoneIcon />}
                </IconButton>
              </Stack>
            </ClickAwayListener>
          </Paper>

        </Fade>
      )}
    </QuoteDatePopper>
  );
}

function QuoteDate() {
  const state = useAppSelector(selectApplicationState);
  const dates = useAppSelector(selectApplicationDates);
  const [busy, withBusyState] = useBusyState();
  const dispatch = useAppDispatch();
  const applicationId = state.status === 'available' ? state.application.id : null;
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleConfirm = useCallback(withBusyState(async (updatedQuoteEffectiveDate: string) => {
    const date = parseServerDate(updatedQuoteEffectiveDate);
    if (applicationId && date) {
      try {
        await dispatch(updateQuoteEffectiveDate(applicationId, date.toISOString()));
        setAnchorEl(null);
        setOpen(false);
        toastSuccess(t('components.quoteDate.updateSuccess'));
      } catch {
        toastError(t('components.quoteDate.updateError'));
      }
    }
  }), [setAnchorEl, setOpen]);

  return (
    <Box>
      <HeaderButton
        variant="contained"
        color="secondary"
        onClick={handleClick}
        icon={<CalendarMonthIcon />}
      >
        {t('components.quoteDate.label')}
      </HeaderButton>
      <QuoteDateFormPopper
        quoteEffectiveDate={dates?.quoteEffective}
        placement="bottom"
        anchorEl={anchorEl}
        open={open}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        loading={busy}
      />
    </Box>
  );
}

export default QuoteDate;
