import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateField, TextField } from '../../../../components/form/fields';
import { FormErrorText } from '../../../../components/form';
import { WithOnChangeCommitted } from '../../../../components/types';

function BirthdateOrAge({ onChangeCommitted }: WithOnChangeCommitted<unknown>) {
  const { t } = useTranslation();
  const {
    fieldState: { error, invalid },
  } = useController({ name: 'dateOfBirthOrAge' });
  const [dateOfBirth, age] = useWatch({ name: ['dateOfBirth', 'age'] });
  const ageDisabled = !!dateOfBirth;
  const dateOfBirthDisabled = !!age && !ageDisabled;

  return (
    <Box>
      <Stack direction="row" gap={1} alignItems="end" justifyContent="space-evenly">
        <DateField
          name="dateOfBirth"
          sublabel={t('components.clientDetailsForm.dateOfBirth')}
          disableFuture
          disabled={dateOfBirthDisabled}
          onChangeCommitted={onChangeCommitted}
          FormControlProps={{ sx: { maxWidth: 200 } }}
        />
        <Typography variant="body1" sx={{ marginTop: '3em', alignSelf: 'center' }}>{t('common.or')}</Typography>
        <TextField
          name="age"
          sublabel={t('components.clientDetailsForm.age')}
          InputProps={{
            inputProps: { maxLength: 3 },
            sx: { maxWidth: 50 },
          }}
          disabled={ageDisabled}
          onChangeCommitted={onChangeCommitted}
        />
      </Stack>
      {invalid && <FormErrorText>{error?.message}</FormErrorText>}
    </Box>
  );
}

export default BirthdateOrAge;
