import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Tab,
  Tabs,
} from '@mui/material';

export const HeaderContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: 23,
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(1),
}));

export const HeaderTabs = styled(Tabs)(() => ({
  minHeight: 38,
  '& button': {
    marginRight: 0,
  },
}));

export const HeaderTab = styled(Tab)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 0,
  color: theme.palette.secondary.main,
  fontSize: 13.5,
  minHeight: 38,
  maxHeight: 38,
  minWidth: 'auto',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

export default {};
