import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const SalesResourcesButton = styled(Button)(() => ({
  '& .MuiButton-endIcon': {
    marginLeft: 0,
    '& > *:nth-of-type(1)': {
      fontSize: 30,
    },
  },
}));

export default SalesResourcesButton;
