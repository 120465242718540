import React from 'react';
import { useController } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material/Select';
import { Select } from '../../select';
import { SelectProps } from '../../select/select';
import { WithOnChangeCommitted } from '../../types';

export interface SelectInputProps extends SelectProps, WithOnChangeCommitted<string> {
  name: string;
  labelTranslationBasePath?: string;
}

function SelectInput({
  name,
  onChangeCommitted = undefined,
  ...props
}: SelectInputProps) {
  const {
    field,
  } = useController({ name });

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    if (typeof value === 'string') {
      field.onChange(value);
      if (onChangeCommitted) {
        onChangeCommitted(name, value);
      }
    }
  };

  return (
    <Select
      inputProps={{ id: name }}
      name={name}
      value={field.value || ''}
      onChange={handleChange}
      {...props}
    />
  );
}

export default SelectInput;
