import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DecisionTotalCard, DecisionTotalCardContent } from './decision-total.styles';
import { TotalPrice } from '../price';
import { Premium } from '../../../../services/models/premium';
import { TotalPremium } from '../../../../services/models/total-premium';
import { PriceProps } from '../price/price';
import { TextWithInfo } from '../../../../components/info';
import TotalRatingContent from './total-rating-content';
import { TotalRating } from '../../../../services/models/total-rating';

interface TotalPremiumDecisionCardProps {
  premium: Premium | TotalPremium;
  totalRating?: TotalRating;
  frequency?: ReactNode;
  priceProps?: Omit<PriceProps, 'premium' | 'frequency'>;
}

function TotalPremiumDecisionCard({ totalRating = undefined, ...props }: TotalPremiumDecisionCardProps) {
  const { t } = useTranslation();
  return (
    <DecisionTotalCard>
      <DecisionTotalCardContent>
        <TextWithInfo
          component="div"
          info={totalRating
            ? (
              <>
                {t('components.totalPremiumDecisionCard.rating')}
                <TotalRatingContent totalRating={totalRating} />
              </>
            )
            : undefined}
        >
          <TotalPrice {...props} />
        </TextWithInfo>
      </DecisionTotalCardContent>
    </DecisionTotalCard>
  );
}

export default TotalPremiumDecisionCard;
