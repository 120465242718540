import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BankDetailsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  '.MuiFormControl-root': {
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    minWidth: 100,
    maxWidth: 180,
    '&:nth-of-type(1)': {
      maxWidth: 140,
    },
    '&:nth-of-type(2)': {
      width: 100,
    },
    '&:nth-of-type(3)': {
      width: 300,
      maxWidth: 300,
    },
  },
}));

export default {};
