import React, { useState } from 'react';
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActionsWithNavigation,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import {
  AmraTitle,
  ConsentCardContent,
  StyledList,
  TickList,
} from './amra-declaration.styles';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { MedicalDetailsDto } from '../../../../services/models/medical-details-dto';
import AmraConsentForm from './amra-consent-form';
import { View, conditionView } from '../../../view';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { patchAmraDeclarations, selectActiveApplicant, selectEnquiryFor } from '../../../../features/application-slice';
import { getConditions } from '../../../../services/enquiry-helpers';
import { toastError } from '../../../../components/toast';

function amraConsentValid(applicantsMedicalDetails: Record<string, MedicalDetailsDto>): boolean {
  return Object.keys(applicantsMedicalDetails).reduce((acc, curr) => {
    if (applicantsMedicalDetails[curr].amraConsent === true && applicantsMedicalDetails[curr].previewRequired !== null) {
      return true;
    }
    return false;
  }, false);
}

interface AmraDeclarationProps {
  applicationId: string;
  applicants: ApplicantDto[];
  onSubmit: () => unknown;
  onCancel: () => unknown;
  onPrevious: (view: View) => unknown;
}

function AmraDeclaration({
  applicationId,
  applicants,
  onSubmit,
  onCancel,
  onPrevious,
}: AmraDeclarationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [busy, setBusy] = useState<boolean>(false);
  const [applicantsMedicalDetails, setApplicantsMedicalDetails] = useState<Record<string, MedicalDetailsDto>>(
    applicants.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.medicalDetails }), {}),
  );
  const consentValid = amraConsentValid(applicantsMedicalDetails);
  const activeApplicant = useAppSelector(selectActiveApplicant) ?? applicants[applicants.length - 1];
  const enquiry = useAppSelector(selectEnquiryFor(activeApplicant.id));

  const handleChange = (applicantId: string, medicalDetails: MedicalDetailsDto) => {
    setApplicantsMedicalDetails((state) => ({
      ...state,
      [applicantId]: medicalDetails,
    }));
  };

  const handleSubmit = async () => {
    try {
      setBusy(true);
      await dispatch(patchAmraDeclarations(applicationId, applicantsMedicalDetails));
      await onSubmit();
    } catch (error) {
      toastError(t('components.amraDeclaration.submitError'));
    } finally {
      setBusy(false);
    }
  };

  const handlePrevious = () => {
    const conditions = getConditions(enquiry);
    const lastCondition = conditions[conditions.length - 1];
    onPrevious(conditionView(lastCondition.path!));
  };

  return (
    <DrawerCard sx={{ overflow: 'scroll' }}>
      <DrawerCardHeader
        title={(
          <Breadcrumbs separator=">">
            <span>{t('components.amraDeclaration.titlePart1')}</span>
            <span>{t('components.amraDeclaration.titlePart2')}</span>
          </Breadcrumbs>
        )}
        action={<CancelDrawerButton onClick={onCancel} />}
      />
      <ConsentCardContent>
        <AmraTitle variant="h2" gutterBottom>
          {t('components.amraDeclaration.medicalReportTitle')}
        </AmraTitle>
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Typography variant="body1" gutterBottom>
              {t('components.amraDeclaration.medicalReportIntro1')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('components.amraDeclaration.medicalReportIntro2')}
            </Typography>
          </Grid>
        </Grid>
        <Card sx={{ mt: 4, mb: 8 }}>
          <CardHeader title={t('components.amraDeclaration.consentPanel.title')} titleTypographyProps={{ variant: 'h3' }} />
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={12} md={9} lg={8}>
                <Typography variant="body1" gutterBottom>
                  {t('components.amraDeclaration.consentPanel.intro1')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t('components.amraDeclaration.consentPanel.intro2')}
                </Typography>
                <StyledList sx={{ mt: 4, mb: 4 }}>
                  <li>{t('components.amraDeclaration.consentPanel.withoutConsentItems.0')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.withoutConsentItems.1')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.withoutConsentItems.2')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.withoutConsentItems.3')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.withoutConsentItems.4')}</li>
                </StyledList>
                <Typography variant="body1" gutterBottom>
                  {t('components.amraDeclaration.consentPanel.intro3')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {t('components.amraDeclaration.consentPanel.intro4')}
                </Typography>
                <StyledList sx={{ mt: 4, mb: 4 }}>
                  <li>{t('components.amraDeclaration.consentPanel.nonAskedItems.0')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.nonAskedItems.1')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.nonAskedItems.2')}</li>
                </StyledList>
                <TickList sx={{ mt: 4, mb: 4 }}>
                  <li>{t('components.amraDeclaration.consentPanel.agreeItems.0')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.agreeItems.1')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.agreeItems.2')}</li>
                  <li>{t('components.amraDeclaration.consentPanel.agreeItems.3')}</li>
                </TickList>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {applicants.map((applicant) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={applicant.id}>
                  <AmraConsentForm applicant={applicant} onChange={(medicalDetails) => handleChange(applicant.id, medicalDetails)} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Divider />
      </ConsentCardContent>
      <DrawerCardActionsWithNavigation
        onPrevious={handlePrevious}
        onNext={handleSubmit}
        previousProps={{ disabled: busy }}
        nextProps={{ disabled: !consentValid || busy }}
      >
        <LoadingButton
          variant="outlined"
          color="secondary"
          type="submit"
          disabled={!consentValid}
          onClick={handleSubmit}
          loading={busy}
        >
          {t('common.submit')}
        </LoadingButton>
      </DrawerCardActionsWithNavigation>
    </DrawerCard>
  );
}

export default AmraDeclaration;
