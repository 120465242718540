import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ProductCardHeader from '../../../application/components/product-panel/product-card-header';

export const DividerStyled = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.common.secondary200,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
}));

export const CoverCardHeader = styled(ProductCardHeader)(({ theme }) => ({
  position: 'relative',
  minHeight: 66,
  '& .MuiCardHeader-content': {
    flex: '0 0 auto',
  },
  '& .MuiCardHeader-title': {
    marginRight: theme.spacing(2),
  },
  '& .MuiCardHeader-subheader': {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '1.5rem',
      color: theme.palette.common.greyDark,
      '& > span': {
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },
  '& .MuiCardHeader-action': {

  },
}));

export default {};
