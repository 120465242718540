import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';
import {
  TableContainerStyled,
  TableCellInner,
  TableRow,
} from './decision-table.styles';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import {
  hasExclusions,
  hasNonZeroPremium,
  isManualUnderwritingDecision,
  reasonDecisionIsNotStandard,
} from '../../../../services/decision-helpers';
import { TotalPrice } from '../price';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { TextWithInfo } from '../../../../components/info';
import { DecisionType } from '../../../../services/product-helpers';
import { ReasonDecisionRatingsTooltip } from '../reason-decision-card';

function getRowSpan(hasPremium: number | null | undefined, reasonDecision: ReasonDecision) {
  let span = 1;
  if (hasPremium) {
    span += 1;
  }
  if (hasExclusions(reasonDecision) && reasonDecision.exclusions) {
    span += 1;
  }
  return span;
}

interface ReasonDecisionRowProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionRow({ id, reasonDecision }: ReasonDecisionRowProps) {
  const { t } = useTranslation();
  const hasPremium = hasNonZeroPremium(reasonDecision);

  return (
    <>
      <TableRow>
        <TableCell rowSpan={getRowSpan(hasPremium, reasonDecision)} component="th" scope="row">{reasonDecision.reason}</TableCell>
        <TableCell>
          <TableCellInner>
            {t('components.decisionTable.decision')}
            <TextWithInfo
              component="span"
              variant="body2"
              info={reasonDecision.decision === DecisionType.POSTPONE && reasonDecision.postpones && reasonDecision.postpones.map((postpone) => (
                <Fragment key={postpone.code}>
                  {postpone.text}
                  <br />
                </Fragment>
              ))}
            >
              {t(`common.decision.${reasonDecision.decision}`)}
            </TextWithInfo>
          </TableCellInner>
        </TableCell>
      </TableRow>
      {hasExclusions(reasonDecision) && (
        <TableRow>
          <TableCell>
            <TableCellInner>
              {t('components.decisionTable.exclusion')}
              <Box>
                {reasonDecision.exclusions!.map((exclusion, index) => (
                  <Box key={exclusion.code} sx={{ ml: 4, mt: index > 0 ? 1 : 0 }}>{exclusion.text}</Box>
                ))}
              </Box>
            </TableCellInner>
          </TableCell>
        </TableRow>
      )}
      {hasPremium && (
        <TableRow>
          <TableCell>
            <TableCellInner>
              {t(isManualUnderwritingDecision(reasonDecision.decision) ? 'components.decisionTable.indicativeRating' : 'components.decisionTable.rating')}
              <TextWithInfo
                component="div"
                info={<ReasonDecisionRatingsTooltip reasonDecision={reasonDecision} id={id} />}
              >
                <TotalPrice premium={reasonDecision} priceProps={{ size: 'small', showPlusSign: true }} />
              </TextWithInfo>
            </TableCellInner>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

interface ProductQuoteDecisonTableProps {
  applicantId: string;
  decision: ProductQuoteDecision | null;
}

function ProductQuoteDecisonTable({
  applicantId,
  decision,
}: ProductQuoteDecisonTableProps) {
  const applicantDecision = decision?.applicantDecisions.find((ad) => ad.applicantId === applicantId);

  if (!applicantDecision) {
    return null;
  }

  return (
    <TableContainerStyled>
      <Table>
        <TableBody key={applicantDecision.applicantId}>
          {applicantDecision.reasonDecisions?.filter(reasonDecisionIsNotStandard).map((reasonDecision, index) => {
            const id = `${index}_${reasonDecision.reason}`;
            return <ReasonDecisionRow key={id} id={id} reasonDecision={reasonDecision} />;
          })}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
}

export default ProductQuoteDecisonTable;
