import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

export const RegistrationCard = styled(Card)(({ theme }) => ({
  '& .MuiCardHeader-root': {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  '& .MuiCardHeader-title': {
    fontSize: 24,
    marginBottom: theme.spacing(1),
  },
  '& .MuiCardHeader-subheader': {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
  '& .MuiCardContent-root': {
    padding: 0,
    '& > div': {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(2),
      '&:not(:first-of-type)': {
        borderTop: `1px solid ${theme.palette.common.secondary200}`,
      },
      '& > p': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  '& h4': {
    marginBottom: theme.spacing(2),
  },
  '& h5': {
    ...theme.typography.h6,
    fontSize: 20,
    marginTop: theme.spacing(2.5),
  },
  '& ul': {
    margin: 0,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    '& > li': {
      ...theme.typography.body2,
      marginBottom: theme.spacing(0.5),
      '&::marker': {
        color: theme.palette.primary.main,
      },
    },
  },
  '& .MuiCardActions-root': {
    '& .MuiButton-root': {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default {};
