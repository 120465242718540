import React, { useCallback } from 'react';
import { Box, BoxProps, Stack } from '@mui/material';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Container } from './file-upload.styles';
import { mbToByte } from '../../utils/converters';
import { toastError } from '../toast';

interface FileUploadProps extends BoxProps {
  onFileSelect: (files: File[]) => void;
  maxFileSizeMb: number;
  dropzoneOptions?: DropzoneOptions;
}

function FileUpload({
  onFileSelect,
  maxFileSizeMb,
  dropzoneOptions = undefined,
  ...props
}: FileUploadProps) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    onFileSelect(acceptedFiles);
    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          const errorMsg = error && error.code === 'file-too-large' ? `File ${file.name} is too large. Maximum size is ${maxFileSizeMb}MB.` : null;
          toastError(errorMsg ?? error.message);
        });
      });
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: true,
    maxSize: mbToByte(maxFileSizeMb),
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg'],
      'application/msword': ['.doc', '.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-outlook': ['.msg'],
      'message/rfc822': ['.eml'],
    },
    ...dropzoneOptions,
  });

  return (
    <Container
      {...getRootProps({
        isFocused,
        isDragAccept,
        isDragReject,
        role: 'none',
      })}
      {...props}
    >
      <input {...getInputProps()} />
      <Stack direction="row" gap={0.75} alignItems="center">
        <Box sx={{ color: 'info.main', textDecoration: 'underline' }}>{t(('components.fileUpload.chooseFile'))}</Box>
        <div>{`${t('common.or')} ${t('components.fileUpload.dragHere')} (${t('components.fileUpload.maxFileSize')} ${maxFileSizeMb}mb)`}</div>
      </Stack>
    </Container>
  );
}

export default FileUpload;
