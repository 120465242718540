import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FixedBenefitContainer = styled(Box)(({ theme }) => ({
  '& h3': {
    fontSize: 24,
    marginBottom: theme.spacing(2),
  },
  '& h4': {
    fontFamily: theme.typography.h6.fontFamily,
    marginBottom: theme.spacing(3),
  },
  '& table': {
    marginTop: theme.spacing(4),
  },
  '& table > thead > tr > th': {
    backgroundColor: theme.palette.common.background4,
    borderLeft: `1px solid ${theme.palette.common.secondary200}`,
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    textAlign: 'center',
    fontSize: theme.typography.body1.fontSize,
  },
  '& table > thead > tr:first-of-type > th': {
    backgroundColor: theme.palette.common.greyDark,
    border: 'none',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  '& table > tbody > tr > td': {
    backgroundColor: theme.palette.common.background4,
    borderLeft: `1px solid ${theme.palette.common.secondary200}`,
    verticalAlign: 'top',
    fontSize: theme.typography.body1.fontSize,
  },
  '& table > thead > tr > th:last-of-type, table > tbody > tr > td:last-of-type': {
    borderRight: `1px solid ${theme.palette.common.secondary200}`,
  },
  '& ul': {
    marginTop: 0,
    marginBottom: 0,
    '& > li': {
      marginBottom: theme.spacing(0.5),
    },
  },
  '& .MuiListItem-root': {
    marginBottom: 0,
    paddingTop: 2,
    paddingBottom: 2,
  },
  '& .MuiListItemIcon-root': {
    minWidth: theme.spacing(4),
    color: theme.palette.common.green,
    '& > svg': {
      fontSize: 24,
    },
  },
}));

export default {};
