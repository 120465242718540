import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from '../../../../services/models/application-status';
import { asCustomClientDate } from '../../../../utils/converters';

export interface ApplicationExpiryProps extends TypographyProps {
  expiryDate?: string | null;
  status?: ApplicationStatus | null;
}

function ApplicationExpiry({ expiryDate = undefined, status = undefined, ...props }: ApplicationExpiryProps) {
  const { t } = useTranslation();
  return (expiryDate && (
    <Typography variant="caption" component="span" color="common.greyLight" data-testid="validuntil-date" {...props}>
      {`${t(`components.applicationExpiry.validUntil.${status}`)} ${asCustomClientDate(expiryDate, 'DD MMMM YYYY')}`}
    </Typography>
  ));
}

export default ApplicationExpiry;
