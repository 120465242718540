import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import PolicySetupLayout from './components/policy-setup-layout';
import useProceed from '../../hooks/use-proceed';
import { useAppSelector } from '../../store/hooks';
import { selectAvailableApplication } from '../../features/application-slice';
import { selectAvailableSettings } from '../../features/settings-slice';
import SectionHeader from '../../components/section-header';
import DocumentsPanel from '../../components/documents-panel';
import { getApplicationDocuments, isUMEApplication } from '../../services/application-helpers';
import PaymentDetails from './components/payment-details';
import CoverPanel from './components/cover-panel';
import InfoBarContent from '../application/components/info-bar-content';
import PolicySetupFooter from './components/footer/policy-setup-footer';
import { ProceedOptions } from '../view';
import { ApplicationPage } from '../pages';
import { getJointApplicantProducts, getSingleApplicantProducts } from '../../services/product-helpers';
import { getProductDecision, isUnfavorableDecision } from '../../services/decision-helpers';
import Advice from './components/cover-panel/advice/advice';
import CoverStartDate from './components/cover-start-date';

function PolicySetup() {
  const { t } = useTranslation();
  const state = useAppSelector(selectAvailableApplication);
  const settings = useAppSelector(selectAvailableSettings);
  const proceed = useProceed();
  const navigate = useNavigate();

  const {
    application,
    quoteDecision,
  } = state;

  const proceedToApplicationView = useCallback((options?: ProceedOptions) => {
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id }));
    proceed(options);
  }, [navigate, proceed, application.id]);

  if (!quoteDecision) return null;

  const isUME = isUMEApplication(application.origin);
  const products = application.products.filter((p) => !isUnfavorableDecision(getProductDecision(quoteDecision, p.id)?.decision));
  const applicantsWithProducts = application.applicants.filter((a) => products.some((p) => p.applicantIds?.includes(a.id)));
  const { products: quoteDecisionProducts } = quoteDecision;

  return (
    <PolicySetupLayout
      info={<InfoBarContent proceedToNextView={proceedToApplicationView} />}
      footer={<PolicySetupFooter applicationId={application.id} />}
    >
      <Stack>
        <SectionHeader>{t('components.policySetup.title')}</SectionHeader>
        <PaymentDetails
          application={application}
          applicants={applicantsWithProducts}
          readOnly={isUME}
          sx={{ mb: 4 }}
        />
        <SectionHeader>{t('components.policySetup.advice')}</SectionHeader>
        <Advice application={application} adviceReadOnly={isUME} />
        <SectionHeader>{t('components.policySetup.startCover')}</SectionHeader>
        <CoverStartDate
          applicationId={application.id}
          products={products}
          sx={{ mb: 4 }}
        />
        {applicantsWithProducts.flatMap((applicant) => (
          getSingleApplicantProducts(products, applicant.id).map((product) => (
            <CoverPanel
              sx={{ mb: 4 }}
              key={`${applicant.id}-${product.id}`}
              applicationId={application.id}
              applicants={[applicant]}
              product={product}
              quoteProduct={quoteDecisionProducts.find((p) => p.id === product.id)}
            />
          ))
        ))}
        {getJointApplicantProducts(products, applicantsWithProducts).map((product) => (
          <CoverPanel
            sx={{ mb: 4 }}
            key={product.id}
            applicationId={application.id}
            applicants={applicantsWithProducts}
            product={product}
            quoteProduct={quoteDecisionProducts.find((p) => p.id === product.id)}
          />
        ))}
        <DocumentsPanel documents={getApplicationDocuments(products, settings.productDefinitions)} />
      </Stack>
    </PolicySetupLayout>
  );
}

export default PolicySetup;
