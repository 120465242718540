import { styled } from '@mui/material';

export const RatingsTooltip = styled('div')(({ theme }) => ({
  '.em-value.MuiTypography-root': {
    ...theme.typography.body2,
    fontWeight: 'bold',
  },
}));

export default {};
