import {
  StepperProps,
} from '@mui/material';
import React from 'react';
import {
  StatusStep,
  StatusStepIcon,
  StatusStepLabel,
  StatusStepper,
} from './status-indicator.styles';
import { ProgressStatus } from '../../../../services/models/progress-status';

interface StepIconProps {
  status: ProgressStatus;
  active: boolean;
}

function StepIcon({ status, active }: StepIconProps) {
  return (
    <StatusStepIcon status={status} active={active} />
  );
}

export type ApplicationStep = {
  status: ProgressStatus,
  label: string,
};

interface StatusIndicatorProps extends StepperProps {
  steps: ApplicationStep[]
  hideLabel?: boolean;
}

function StatusIndicator({
  activeStep,
  steps,
  hideLabel = false,
  ...props
}: StatusIndicatorProps) {
  return (
    <StatusStepper activeStep={activeStep} connector={null} data-testid="status-indicator" {...props}>
      {steps.map((step, index) => (
        <StatusStep key={step.label} status={step.status} active={index === activeStep}>
          <StatusStepLabel StepIconComponent={() => StepIcon({ status: step.status, active: index === activeStep })}>
            {index === activeStep && !hideLabel ? step.label : ''}
          </StatusStepLabel>
        </StatusStep>
      ))}
    </StatusStepper>
  );
}

export default StatusIndicator;
