import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import { FormContainer } from '../../../../components/form';
import { PostcodeField, TextField } from '../../../../components/form/fields';

export const FormContainerStyled = styled(FormContainer)(({ theme }) => ({
  'label:has(.MuiCheckbox-root)': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.surface8,
    paddingRight: 9,
    marginLeft: 0,
    '&:has(.Mui-checked)': {
      backgroundColor: theme.palette.common.background3,
    },
  },
  section: {
    marginBottom: theme.spacing(6),
    li: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const ConsentForm = styled(Box)(({ theme }) => ({
  '& h3 > span': {
    fontFamily: theme.typography.h6.fontFamily,
    marginLeft: theme.spacing(1),
  },
  '& label': {
    fontFamily: theme.typography.h5.fontFamily,
  },
  '& label:has(.MuiCheckbox-root)': {
    backgroundColor: theme.palette.common.primary200,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiFormLabel-root': {
      maxWidth: 200,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiFormLabel-root': {
      maxWidth: 'none',
    },
  },
}));

export const InlineTextField = styled(TextField)(() => ({
  flexDirection: 'row',
  '& label': {
    maxWidth: 300,
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 300,
  },
}));

export const InlinePostcodeField = styled(PostcodeField)(() => ({
  flexDirection: 'row',
  '& label': {
    maxWidth: 300,
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 300,
  },
}));

export const LinkButton = styled(Button)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  textDecoration: 'underline',
  color: theme.palette.common.blue,
}));

export default FormContainerStyled;
