import { fetchAuthSession } from 'aws-amplify/auth';
import Cookies from 'js-cookie';
import { UserDto } from './bff/models/user-dto';
import { PermissionsRoleDto } from './bff/models/permissions-role-dto';

const TOKEN_PROFILE_ATTR = 'the-exeter:profileId';

export enum Role {
  CLAIM_ASSESSOR = 'CLAIM_ASSESSOR',
  CLAIM_ADMIN_ASSESSOR = 'CLAIM_ADMIN_ASSESSOR',
  CLAIM_MANAGER = 'CLAIM_MANAGER',
  UNDERWRITING = 'UNDERWRITING',
  UNDERWRITING_MANAGEMENT = 'UNDERWRITING_MANAGEMENT',
  NEW_BUSINESS_ADMIN = 'NEW_BUSINESS_ADMIN',
  NEW_BUSINESS_ADMIN_MANAGEMENT = 'NEW_BUSINESS_ADMIN_MANAGEMENT',
  ADVISER_ADMIN = 'ADVISER_ADMIN',
  USER_ADMIN = 'USER_ADMIN',
  SALES = 'SALES',
  PARAPLANNER = 'PARAPLANNER',
  ADVISER = 'ADVISER',
}

const APPLICATION_NAME = 'ONBOARDING';
const ASSIGN_APPLICATION_ROLES = [Role.NEW_BUSINESS_ADMIN, Role.UNDERWRITING, Role.PARAPLANNER];
const UPDATE_QUOTE_DATE_ROLES = [Role.NEW_BUSINESS_ADMIN];

export async function getCurrentUserProfileId(): Promise<string> {
  const session = await fetchAuthSession();
  return session.tokens?.accessToken?.payload?.[TOKEN_PROFILE_ATTR] as string ?? '';
}

export function getUserApplicationRoles(applicationName: string, user: UserDto): PermissionsRoleDto[] {
  return user?.permissions?.find((permission) => permission.applicationName === applicationName)?.roles ?? [];
}

export function userHasAnyRoleOf(user: UserDto, roles: Role[]): boolean {
  return getUserApplicationRoles(APPLICATION_NAME, user).some((role) => role.name && roles.includes(role.name as Role));
}

export function canUserAssignApplication(user: UserDto): { canAssign: boolean, changeAdviserOnly: boolean } {
  return {
    canAssign: userHasAnyRoleOf(user, ASSIGN_APPLICATION_ROLES),
    changeAdviserOnly: !userHasAnyRoleOf(user, [Role.NEW_BUSINESS_ADMIN, Role.UNDERWRITING]),
  };
}

export function canUserUpdateQuoteDate(user: UserDto) {
  return userHasAnyRoleOf(user, UPDATE_QUOTE_DATE_ROLES);
}

export const PSOB_SESSION_NAME = 'psob-user-preferences';

export type UserPreferencePageTableColumns = Record<string, string[]>;

export type UserPreferences = {
  allowCookies?: boolean,
};

export function getUserPreferences(): UserPreferences {
  if (typeof window === 'undefined') {
    return {};
  }
  const preferences = Cookies.get(PSOB_SESSION_NAME);
  return preferences ? JSON.parse(preferences) : {};
}

export function setUserPreferences(preferences: UserPreferences) {
  if (typeof window === 'undefined') {
    return;
  }
  Cookies.set(PSOB_SESSION_NAME, JSON.stringify(preferences));
}

export function getUserPreferenceAllowCookies(): boolean | null {
  const preferences = getUserPreferences();
  return preferences?.allowCookies ?? null;
}

export function setUserPreferenceAllowCookies(allowCookies: boolean) {
  const preferences = getUserPreferences();
  setUserPreferences({ ...preferences, allowCookies });
}

export function setForgetPassword() {
  sessionStorage.setItem('forgotPassword', 'true');
}

export function unsetForgetPassword() {
  sessionStorage.removeItem('forgotPassword');
}

export function isForgetPassword(): boolean {
  return !!sessionStorage.getItem('forgotPassword');
}

export function setDeviceCookies(deviceData: Record<string, string>, expires = 60) {
  Object.keys(deviceData).forEach((key) => {
    Cookies.set(key, deviceData[key], { expires });
  });
}

export function getDeviceCookies(deviceKeys: string[]): Record<string, string | undefined> {
  return deviceKeys.reduce((acc, key) => {
    acc[key] = Cookies.get(key);
    return acc;
  }, {} as Record<string, string | undefined>);
}

export function getCookie(key: string): string | undefined {
  return Cookies.get(key);
}

export default {};
