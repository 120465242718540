import React from 'react';
import EnquiryLine from './enquiry-line';
import { Section as SectionType } from '../../../services/models/section';
import { WithOnChangeCommitted } from '../../types';

interface SectionProps extends WithOnChangeCommitted<unknown> {
  section: SectionType;
  disabled?: boolean;
  search: (name: string, searchValue: string) => string[] | Promise<string[]>;
}

function Section({
  section,
  search,
  disabled = false,
  onChangeCommitted = undefined,
}: SectionProps) {
  if (section.enquiryLines) {
    return (
      <>
        {section.enquiryLines.map((enquiryLine) => {
          if (enquiryLine && enquiryLine.isRoot !== true && !enquiryLine?.tags?.includes('ISROOT')) {
            return (
              <EnquiryLine
                key={enquiryLine.name}
                enquiryLine={enquiryLine}
                disabled={disabled}
                onChangeCommitted={onChangeCommitted}
                search={search}
              />
            );
          }
          return null;
        })}
      </>
    );
  }
  return null;
}

export default Section;
