import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import LabelAndValue from '../label-and-value/label-and-value';
import { numberToMoney } from '../../utils/converters';
import { ProductDetailsProps } from './product-details-types';

function IncomeProtectionDetails({
  product,
  decisionCoverAmount = undefined,
  labelAndValueProps: attributeProps,
  ...props
}: ProductDetailsProps) {
  const { t } = useTranslation();
  const coverAmount = decisionCoverAmount ?? product.coverAmount;
  return (
    <Stack direction="row" gap={4} flexWrap="wrap" {...props}>
      <LabelAndValue
        label={t('components.incomeProtectionDetails.coverAmount')}
        value={coverAmount ? numberToMoney(coverAmount) : null}
        {...attributeProps}
      />
      <LabelAndValue
        label={t('components.incomeProtectionDetails.premiumStyle')}
        value={product.premiumStyle ? t(`components.incomeProtectionForm.premiumStyleOptions.${product.premiumStyle}`, '-') : null}
        {...attributeProps}
      />
      <LabelAndValue
        label={t('components.incomeProtectionDetails.claimPeriod')}
        value={product.claimPeriod ? t(`components.incomeProtectionForm.claimPeriodOptions.${product.claimPeriod}`, '-') : null}
        {...attributeProps}
      />
      {product.term && (
        <LabelAndValue
          label={t('components.incomeProtectionDetails.term')}
          value={`${product.term}${t('common.duration.years')}`}
          {...attributeProps}
        />
      )}
      {(product.toAge || !product.term) && (
        <LabelAndValue
          label={t('components.incomeProtectionDetails.toAge')}
          value={product.toAge ? product.toAge : '-'}
          {...attributeProps}
        />
      )}
      <LabelAndValue
        label={t('components.incomeProtectionDetails.coverType')}
        value={product.coverType ? t(`components.incomeProtectionForm.coverTypeOptions.${product.coverType}`, '-') : null}
        {...attributeProps}
      />
      <LabelAndValue
        label={t('components.incomeProtectionDetails.deferredPeriod')}
        value={isNumber(product.deferredPeriod) ? t(`common.deferredPeriod.${product.deferredPeriod}`, '-') : null}
        {...attributeProps}
      />
    </Stack>
  );
}

export default IncomeProtectionDetails;
