import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const ColumnStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  gap: theme.spacing(9),
  '& > div:first-of-type': {
    flex: 1,
    overflow: 'hidden',
  },
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(5),
  },
}));

export const SidebarStack = styled(Stack)(({ theme }) => ({
  maxWidth: 576,
  minWidth: 576,
  [theme.breakpoints.down('lg')]: {
    maxWidth: 232,
    minWidth: 232,
  },
}));

export default ColumnStack;
