/* tslint:disable */
/* eslint-disable */
/**
 * ApplicationService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OperationType = {
    Add: 'add',
    Remove: 'remove',
    Replace: 'replace',
    Move: 'move',
    Copy: 'copy',
    Test: 'test',
    Invalid: 'invalid'
} as const;

export type OperationType = typeof OperationType[keyof typeof OperationType];



