import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import {
  Box,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NumberField, NumberFieldProps } from '../../../../components/form/fields';
import { FormErrorText } from '../../../../components/form';
import { WithOnChangeCommitted } from '../../../../components/types';

export interface TermOrToAgeProps extends WithOnChangeCommitted<unknown> {
  termFieldProps?: Partial<NumberFieldProps>;
  toAgeFieldProps?: Partial<NumberFieldProps>;
  readOnly?: boolean;
}

function TermOrToAge({
  onChangeCommitted,
  termFieldProps = undefined,
  toAgeFieldProps = undefined,
  readOnly = false,
}: TermOrToAgeProps) {
  const { t } = useTranslation();
  const {
    fieldState: { error, invalid },
  } = useController({ name: 'dateOfBirthOrAge' });
  const [term, toAge] = useWatch({ name: ['term', 'toAge'] });
  const toAgeDisabled = !!term;
  const termDisabled = !!toAge && !toAgeDisabled;
  return (
    <Box>
      <Stack direction="row" gap={1} alignItems="end" flexWrap="wrap">
        <NumberField
          name="term"
          label={t('components.termOrToAge.term')}
          min={0}
          inputProps={{ maxLength: 2 }}
          sx={{ maxWidth: 120 }}
          endAdornment={<InputAdornment position="end" disablePointerEvents>{t('common.toAge.years')}</InputAdornment>}
          disabled={termDisabled}
          onChangeCommitted={onChangeCommitted}
          hideError
          readOnly={readOnly}
          {...termFieldProps}
        />
        <Typography variant="body1" sx={{ marginTop: '2.2em', alignSelf: 'center' }}>{t('common.or')}</Typography>
        <NumberField
          name="toAge"
          label={t('components.termOrToAge.toAge')}
          min={0}
          inputProps={{ maxLength: 3 }}
          sx={{ maxWidth: 130 }}
          endAdornment={<InputAdornment position="end" disablePointerEvents>{t('common.toAge.years')}</InputAdornment>}
          disabled={toAgeDisabled}
          onChangeCommitted={onChangeCommitted}
          hideError
          readOnly={readOnly}
          {...toAgeFieldProps}
        />
      </Stack>
      {invalid && <FormErrorText>{error?.message}</FormErrorText>}
    </Box>
  );
}

export default TermOrToAge;
