import React, { useCallback, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SelectOption, WithOnChangeCommitted } from '../../types';
import { SelectTypeaheadField, TextField } from '.';
import useBusyState from '../../../hooks/use-busy-state';
import { useEnquiryFormContext } from '../enquiry/enquiry-form-provider';
import { correctCaseFirstName, correctCaseSurname } from '../../../utils/converters';

interface NameFieldsProps extends WithOnChangeCommitted<string> {
  readOnly?: boolean;
}

function NameFields({ onChangeCommitted, readOnly = false }: NameFieldsProps) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [busy, withBusyState] = useBusyState();
  const { getOptionList } = useEnquiryFormContext();

  const getTitleOptions = useCallback(withBusyState(async () => {
    const response = await getOptionList('TitleOptions');
    setOptions(response.options.map((option) => ({ id: option.text, label: option.text })));
  }), [setOptions]);

  const handleChange = (name: string, value: SelectOption | null) => {
    if (onChangeCommitted) {
      onChangeCommitted(name, value?.id ?? '');
    }
  };

  useEffect(() => {
    if (options.length <= 0) {
      getTitleOptions();
    }
  }, [options]);

  return (
    <Box>
      <Stack direction="row" gap={2} alignItems="start" flexWrap="wrap">
        <SelectTypeaheadField
          name="title"
          sublabel={t('components.nameFields.title')}
          options={options}
          renderOption={(optionProps, option, state, ownerState) => (
            <li {...optionProps} key={option.id}>
              {ownerState.getOptionLabel(option)}
            </li>
          )}
          onChangeCommitted={handleChange}
          loading={busy}
          disabled={busy}
          FormControlProps={{ sx: { width: 250 } }}
          readOnly={readOnly}
        />
        <TextField
          name="firstName"
          sublabel={t('components.nameFields.firstName')}
          validateChange={(str: string) => !/\d/.test(str)}
          onChangeCommitted={onChangeCommitted}
          transform={correctCaseFirstName}
          InputProps={{
            inputProps: { maxLength: 20, autoComplete: 'new-password' },
            readOnly,
          }}
        />
        <TextField
          name="lastName"
          sublabel={t('components.nameFields.lastName')}
          validateChange={(str: string) => !/\d/.test(str)}
          onChangeCommitted={onChangeCommitted}
          transform={correctCaseSurname}
          InputProps={{
            inputProps: { maxLength: 35, autoComplete: 'new-password' },
            autoComplete: 'new-password',
            readOnly,
          }}
        />
      </Stack>
    </Box>
  );
}

export default NameFields;
