import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import iPad from '../../../../assets/images/dashboard/ipad.png';
import { Container } from './hero-panel.styles';

function HeroPanel() {
  const { t } = useTranslation();
  return (
    <Container>
      <Typography variant="h1">
        {t('components.heroPanel.title')}
      </Typography>
      <img src={iPad} alt="" width="235" height="325" />
    </Container>
  );
}

export default HeroPanel;
