import { useEffect, useState } from 'react';
import { EnquiryLine } from '../services/models/enquiry-line';

const useAutoScroll = (containerRef: React.RefObject<HTMLDivElement>, enquiryLine: EnquiryLine) => {
  const [current, setCurrent] = useState<string>();
  useEffect(() => {
    if (enquiryLine.name && enquiryLine.name === current && enquiryLine.questions.some((question) => !!question.hasAnswer)) {
      setCurrent(enquiryLine.name);
      let focusQuestion = enquiryLine.questions.find((question) => !question.hasAnswer);
      const lastAnsweredIndex = enquiryLine.questions
        .map((question, index) => (question.hasAnswer ? index : -1))
        .filter((index) => index !== -1)
        .pop();

      if (lastAnsweredIndex) {
        focusQuestion = enquiryLine.questions.slice(lastAnsweredIndex + 1).find((question) => !question.hasAnswer);
      }
      containerRef.current?.querySelector(`[for="${focusQuestion?.path?.replace(/\|/g, '-')}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [enquiryLine.questions.length]);

  useEffect(() => {
    if (enquiryLine.name) {
      setCurrent(enquiryLine.name);
    }
  }, [enquiryLine.name]);
};

export default useAutoScroll;
