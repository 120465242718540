import {
  Card,
  CardContent,
  Typography,
  styled,
} from '@mui/material';
import typography from '../../../../theme/typography';

export const DecisionCard = styled(Card)(() => ({
  border: 'none',
}));

export const DecisionCardContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  '&:last-child': {
    paddingBottom: 0,
  },
}));

export const EmRatingValue = styled(Typography)(() => ({
  ...typography.body1,
  fontSize: 20,
  lineHeight: 1.2,
  fontWeight: 'bold',
}));

export default {};
