import React from 'react';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EnquiryLine } from '../../../../services/models/enquiry-line';
import {
  getEnquiryLineStatus,
  isEnquiryLineDeletable,
  isEnquiryLineEditable,
} from '../../../../services/enquiry-helpers';
import { EnquiryLinePanel as StyledEnquiryLinePanel, StyledInfoTooltip } from './disclosure-panel.styles';
import useBusyState from '../../../../hooks/use-busy-state';
import { Delete } from '../../../../components/svg-icon';
import EditButton from './edit-button';

interface EnquiryLineInformationProps {
  enquiryLine: EnquiryLine
}

function EnquiryLineInformation({ enquiryLine }: EnquiryLineInformationProps) {
  const { t } = useTranslation();
  let title;

  if (!isEnquiryLineDeletable(enquiryLine) && !isEnquiryLineEditable(enquiryLine)) {
    title = 'components.enquiryLineInformation.noDeleteOrEditInfo';
  } else if (!isEnquiryLineDeletable(enquiryLine)) {
    title = 'components.enquiryLineInformation.noDeleteInfo';
  } else if (!isEnquiryLineEditable(enquiryLine)) {
    title = 'components.enquiryLineInformation.noEditInfo';
  }
  if (title) {
    return <StyledInfoTooltip title={t(title)} />;
  }
  return null;
}

interface EnquiryLinePanelProps {
  enquiryLine: EnquiryLine;
  disabled?: boolean;
  hideInformation?: boolean;
  onDelete?: (enquiryLine: EnquiryLine) => unknown;
  onEdit?: (enquiryLine: EnquiryLine) => unknown;
}
function EnquiryLinePanel({
  enquiryLine,
  disabled = undefined,
  hideInformation = false,
  onDelete = undefined,
  onEdit = undefined,
}: EnquiryLinePanelProps) {
  const [busy, withBusyState] = useBusyState();
  const { t } = useTranslation();
  const status = getEnquiryLineStatus(enquiryLine);
  const handleDelete = withBusyState(onDelete ? () => onDelete(enquiryLine) : undefined);
  const handleEdit = withBusyState(onEdit ? () => onEdit(enquiryLine) : undefined);

  return (
    <StyledEnquiryLinePanel status={status} data-testid="enquiry-line">
      <Box gap={1}>
        <Typography variant="body1" component="h4">{enquiryLine.alias}</Typography>
        {!hideInformation && <EnquiryLineInformation enquiryLine={enquiryLine} />}
        {onDelete && isEnquiryLineDeletable(enquiryLine) && (
          <IconButton
            onClick={handleDelete}
            disabled={disabled || busy}
            sx={(theme) => ({ color: theme.palette.common.surface2 })}
            title={t('common.delete')!}
          >
            <Delete />
          </IconButton>
        )}
      </Box>
      {isEnquiryLineEditable(enquiryLine) && (
        <EditButton
          status={status}
          onClick={handleEdit}
          disabled={disabled || busy}
        />
      )}
    </StyledEnquiryLinePanel>
  );
}

export default EnquiryLinePanel;
