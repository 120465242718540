import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

export const RegistrationContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.common.background4,
  minHeight: '100vh',
  '& h1': {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontFamily: theme.typography.h6.fontFamily,
  },
  [theme.breakpoints.down('sm')]: {
    '& > div > .MuiStack-root': {
      flexDirection: 'column',
    },
  },
}));

export default {};
