import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthSession } from '../../services/auth-api';
import { inactivityTimeout } from '../../exports';

const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const sessionInactivityTimeout: number = parseFloat(inactivityTimeout as any);
const sessionInactivityTimerLimit: number = sessionInactivityTimeout * 60;

function InactivityCheck() {
  const [inactivityTimer, setInactivityTimer] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const resetInactivityTimer = useCallback(() => {
    setInactivityTimer(0);
  }, []);

  const check = async () => {
    const auth = await AuthSession.isCurrentUserAuthenticated();
    if (auth && inactivityTimer >= sessionInactivityTimerLimit) {
      AuthSession.signOut();
    }
  };

  useEffect(() => {
    events.forEach((event) => window.addEventListener(event, resetInactivityTimer));
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimer));
    };
  }, [resetInactivityTimer]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setInactivityTimer((prev) => prev + 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    check();
  }, [inactivityTimer]);

  return null;
}

export default InactivityCheck;
