import React, { ChangeEvent, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroupProps } from '@mui/material';
import { RadioGroup } from './input.styles';
import { WithOnChangeCommitted, Option, getLabelAndValue } from '../../types';

const wrapWordLimit = 3;

export interface RadioOptionsProps extends Omit<RadioGroupProps, 'onChange' | 'value' | 'name' | 'ref'>, WithOnChangeCommitted<string> {
  options: (string | Option)[];
  labelTranslationBasePath?: string;
  name: string;
  disabled?: boolean;
  row?: boolean;
}

function RadioOptions({
  options,
  name,
  labelTranslationBasePath = undefined,
  onChangeCommitted,
  disabled = undefined,
  row = options.length <= 2,
  ...props
}: RadioOptionsProps) {
  const {
    field: { value, onChange, ...field },
    formState: { defaultValues },

  } = useController({ name });
  const { t } = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    onChange(newValue);
    const oldValue = defaultValues?.[name];
    if (onChangeCommitted && oldValue !== newValue) {
      onChangeCommitted(name, newValue);
    }
  }, [onChange, onChangeCommitted]);

  const wrapOptions = options.some((option) => getLabelAndValue(option, t, labelTranslationBasePath).label.split(' ').length > wrapWordLimit);

  return (
    <RadioGroup row={row && !wrapOptions} value={value || null} onChange={handleChange} {...field} {...props}>
      {options.map((option: string | Option) => {
        const labelAndValue = getLabelAndValue(option, t, labelTranslationBasePath);
        return <FormControlLabel key={labelAndValue.value} {...labelAndValue} disabled={disabled} control={<Radio />} />;
      })}
    </RadioGroup>
  );
}

export default RadioOptions;
