import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormControl, InputAdornment, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { WithOnChangeCommitted } from '../../types';
import ButtonOptions from './button-options';
import InputBase from './input-base';
import {
  ImperialLength,
  SystemOfMeasurement,
  asStringOrNull,
  imperialToMeters,
  metersToImperial,
  systemsOfMeasurement,
  toCentimeters,
  toMeters,
} from '../../../utils/converters';

function isValidValue(value: number | null, min: number, max: number): boolean {
  return value == null || (!Number.isNaN(value) && value >= min && value <= max);
}

function formatValue(value: number | null) {
  return value != null ? Math.round(value) : '';
}

function formatAsCentimeters(meters: number | null) {
  return formatValue(toCentimeters(meters));
}

interface InputProps extends WithOnChangeCommitted<number> {
  name: string;
  value: number | null;
  onChange: (newValue: number | null) => void;
  onBlur: () => void;
  min: number;
  max: number;
}

function MetricInput({
  name,
  value,
  onChange,
  onBlur,
  min,
  max,
}: InputProps) {
  const minCm = toCentimeters(min) || 0;
  const maxCm = toCentimeters(max) || 300;

  const handleCmInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : null;
    if (isValidValue(newValue, 0, maxCm)) {
      onChange(toMeters(newValue));
    }
  };

  const cmUnitsId = `${name}_cm-units`;
  return (
    <InputBase
      id={name}
      aria-describedby={cmUnitsId}
      name={name}
      type="number"
      value={formatAsCentimeters(value)}
      inputProps={{
        min: minCm,
        max: maxCm,
        step: 1,
      }}
      onChange={handleCmInputChange}
      onBlur={onBlur}
      endAdornment={<InputAdornment position="end" id={cmUnitsId} disablePointerEvents>cm</InputAdornment>}
    />
  );
}

function adjustForDisplay(imperialValue: ImperialLength): ImperialLength {
  if (formatValue(imperialValue.inches) === 12) {
    return {
      feet: (imperialValue.feet || 0) + 1,
      inches: imperialValue.inches! - 12,
    };
  }
  return imperialValue;
}

function ImperialInputs({
  name,
  value,
  onChange,
  onBlur,
  min,
  max,
}: InputProps) {
  const imperialValue = adjustForDisplay(metersToImperial(value));
  const feetRef = useRef();
  const inchesRef = useRef();
  const [feetEmpty, setFeetEmpty] = useState(value == null);
  const [inchesEmpty, setInchesEmpty] = useState(value == null);
  const feet = feetEmpty ? null : imperialValue.feet;
  const inches = inchesEmpty ? null : imperialValue.inches;

  const feetUnitsId = `${name}_feet-units`;
  const inchesUnitsId = `${name}_inches-units`;
  const minFeet = metersToImperial(min).feet || 0;
  const maxFeet = metersToImperial(max).feet || 9;

  const handleFeetInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : null;
    if (isValidValue(newValue, 0, maxFeet)) {
      const newValueImperial = { feet: newValue, inches };
      onChange(imperialToMeters(newValueImperial));
      setFeetEmpty(newValue == null);
    }
  };

  const handleInchesInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : null;
    if (isValidValue(newValue, 0, 11)) {
      const newValueImperial = { feet, inches: newValue };
      onChange(imperialToMeters(newValueImperial));
      setInchesEmpty(newValue == null);
    }
  };

  // Prevent sending of value if the other input is in focus
  // This stops the removal of any current input value
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (document?.activeElement !== feetRef.current && document?.activeElement !== inchesRef.current) {
        onBlur();
      } else {
        event.target.blur();
      }
    }, 10);
  };

  return (
    <>
      <InputBase
        id={name}
        aria-describedby={feetUnitsId}
        name={name}
        type="number"
        value={formatValue(feet)}
        inputRef={feetRef}
        inputProps={{
          min: minFeet,
          max: maxFeet,
          step: 1,
        }}
        onChange={handleFeetInputChange}
        onBlur={handleBlur}
        endAdornment={<InputAdornment position="end" id={feetUnitsId} disablePointerEvents>ft</InputAdornment>}
      />
      <FormControl>
        <InputBase
          id={`${name}_inches`}
          aria-describedby={inchesUnitsId}
          name={`${name}_inches`}
          type="number"
          value={formatValue(inches)}
          inputRef={inchesRef}
          inputProps={{
            min: 0,
            max: 11,
            step: 1,
          }}
          onChange={handleInchesInputChange}
          onBlur={handleBlur}
          endAdornment={<InputAdornment position="end" id={inchesUnitsId} disablePointerEvents>in</InputAdornment>}
        />
      </FormControl>
    </>
  );
}

export interface HeightInputProps extends WithOnChangeCommitted<number | SystemOfMeasurement> {
  name: string;
  systemName: string;
  min?: number;
  max?: number;
}

function HeightInput({
  name,
  systemName,
  onChangeCommitted,
  min = 0,
  max = 3,
}: HeightInputProps) {
  const { field: systemField } = useController({ name: systemName });
  const { resetField } = useFormContext();

  useEffect(() => {
    if (systemField.value == null) {
      resetField(systemName, { defaultValue: 'metric' });
      if (onChangeCommitted) {
        onChangeCommitted(systemName, 'metric');
      }
    }
  }, [systemField.value, resetField]);
  const system: SystemOfMeasurement = systemField.value || 'metric';

  const {
    field: { value, onChange },
    formState: { defaultValues },
  } = useController({ name });

  const handleBlur = () => {
    const oldValue = defaultValues?.[name];
    if (onChangeCommitted && asStringOrNull(oldValue) !== asStringOrNull(value)) {
      onChangeCommitted(name, value);
    }
  };

  return (
    <Stack direction="row" gap={2}>
      <ButtonOptions
        name={systemName}
        options={systemsOfMeasurement}
        labelTranslationBasePath="common.systemOfMeasurement"
        onChangeCommitted={onChangeCommitted as any}
      />
      {system.toLocaleLowerCase() === 'metric' && (
        <MetricInput name={name} value={value} onChange={onChange} onBlur={handleBlur} min={min} max={max} />
      )}
      {system.toLocaleLowerCase() === 'imperial' && (
        <ImperialInputs name={name} value={value} onChange={onChange} onBlur={handleBlur} min={min} max={max} />
      )}
    </Stack>
  );
}

export default HeightInput;
