import variables from '../variables';

const MuiTableCell = {
  styleOverrides: {
    root: () => ({
      backgroundColor: variables.colors.white,
    }),
  },
};

export default MuiTableCell;
