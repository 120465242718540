import React, { forwardRef, useMemo, useState } from 'react';
import Autocomplete, { AutocompleteInputChangeReason, AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Alert, Snackbar, debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getMultipleAnswer } from '../../services/enquiry-helpers';

export interface TypeaheadProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<string, Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> {
  minChars?: number;
  search: (searchValue: string) => string[] | Promise<string[]>;
}

function Typeahead<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  {
    minChars = 3,
    search,
    onInputChange,
    ...props
  }: TypeaheadProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const optionsPlusSelected = useMemo(() => [...options, ...getMultipleAnswer(props.value)], [options, props.value]);

  const fetchOptionsDebounced = useMemo(() => debounce(async (searchValue: string) => {
    if (searchValue.length < minChars) {
      setOptions([]);
      return;
    }
    setLoading(true);
    try {
      const fetchedOptions = await search(searchValue);
      setOptions(fetchedOptions);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, 500), []);

  const closeError = () => setError(null);

  const handleOnInputChange = (event: any, searchValue: string, reason: AutocompleteInputChangeReason) => {
    setInputValue(searchValue);
    fetchOptionsDebounced(searchValue);
    if (onInputChange) {
      onInputChange(event, searchValue, reason);
    }
  };

  return (
    <>
      <Autocomplete<string, Multiple, DisableClearable, FreeSolo>
        options={optionsPlusSelected}
        loading={loading}
        onInputChange={handleOnInputChange}
        noOptionsText={inputValue.length <= minChars ? t('components.typeahead.typeForOptions') : t('components.typeahead.noOptions')}
        renderTags={() => null}
        autoComplete
        autoSelect
        autoHighlight
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
          />
        )}
        slotProps={{
          popupIndicator: {
            sx: { display: 'none' },
          },
        }}
        {...props}
      />
      <Snackbar
        open={!!error}
        onClose={closeError}
      >
        <Alert onClose={closeError} severity="error" sx={{ width: '100%' }}>
          {t('components.typeahead.optionsError')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default forwardRef(Typeahead) as typeof Typeahead;
