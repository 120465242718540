import React, { FormEvent, useState } from 'react';
import {
  Breadcrumbs,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActionsWithNavigation,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import { FormContainerStyled } from './post-underwriting-declaration.styles';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { confirmPostDeclaration, selectAmraStatus, selectEnquiryFor } from '../../../../features/application-slice';
import useBusyState from '../../../../hooks/use-busy-state';
import { handleError } from '../../../../components/form/form.utils';
import { toastError } from '../../../../components/toast';
import { View, amraDeclarationView, conditionView } from '../../../view';
import { ProgressStatus } from '../../../../services/models/progress-status';
import { getConditions } from '../../../../services/enquiry-helpers';

interface PostUnderwritingDeclarationProps {
  onSubmit: () => unknown;
  onCancel: () => unknown;
  onPrevious: (view: View) => unknown;
  applicantId: string;
}

function PostUnderwritingDeclaration({
  onSubmit,
  onCancel,
  onPrevious,
  applicantId,
}: PostUnderwritingDeclarationProps) {
  const { t } = useTranslation();
  const enquiry = useAppSelector(selectEnquiryFor(applicantId));
  const [confirmed, setConfirmed] = useState<boolean>(!enquiry.isOpen);
  const dispatch = useAppDispatch();
  const [busy, withBusyState] = useBusyState();
  const amraStatus = useAppSelector(selectAmraStatus(applicantId));

  const confirmAndSubmit = withBusyState(async () => {
    try {
      if (enquiry.isOpen) {
        await dispatch(confirmPostDeclaration(applicantId));
      }
      onSubmit();
    } catch (e: unknown) {
      handleError(e, toastError);
    }
  });

  const handleSubmit = withBusyState(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    confirmAndSubmit();
  });

  const handlePrevious = () => {
    if (amraStatus === ProgressStatus.Complete) {
      const conditions = getConditions(enquiry);
      const lastCondition = conditions[conditions.length - 1];
      onPrevious(conditionView(lastCondition.path!));
    } else {
      onPrevious(amraDeclarationView);
    }
  };

  return (
    <FormContainerStyled onSubmit={handleSubmit}>
      <DrawerCard>
        <DrawerCardHeader
          title={(
            <Breadcrumbs separator=">">
              <span>{t('components.postUnderwritingDeclaration.titlePart1')}</span>
              <span>{t('components.postUnderwritingDeclaration.titlePart2')}</span>
            </Breadcrumbs>
          )}
          action={<CancelDrawerButton onClick={onCancel} />}
        />
        <DrawerCardContent sx={{ paddingBottom: 0 }}>
          <Typography variant="body1">
            <strong>Please read this declaration carefully. If you do not understand any point, please ask for further information.</strong>
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                I confirm that I have taken reasonable care to answer all the questions honestly and to the best of my knowledge.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                I understand that if the information provided is not complete and accurate,
                it may result in the policy being cancelled, amended or in a claim not being paid.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                I understand that I should notify The Exeter immediately if there are any changes to my health or personal circumstances
                (this includes a change in occupation or take up of a hazardous hobby) after submitting the application and before the policy starts.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                I agree that the completed application and policy terms and conditions related
                to the selected product(s) will form the basis of my policy with The Exeter.
              </Typography>
            </li>
          </ul>

          <FormControlLabel
            label={t('components.postUnderwritingDeclaration.confirmCheckboxLabel')}
            control={(
              <Checkbox
                id="confirm"
                onChange={() => setConfirmed(true)}
                checked={confirmed}
                disabled={busy || confirmed}
              />
            )}
          />
          <Divider />
        </DrawerCardContent>
        <DrawerCardActionsWithNavigation
          onPrevious={handlePrevious}
          onNext={confirmAndSubmit}
          nextProps={{ disabled: busy || !confirmed, children: t('common.apply') }}
        >
          <LoadingButton
            variant="outlined"
            color="secondary"
            type="submit"
            disabled={busy || !confirmed}
          >
            {t('common.submit')}
          </LoadingButton>
        </DrawerCardActionsWithNavigation>
      </DrawerCard>
    </FormContainerStyled>
  );
}

export default PostUnderwritingDeclaration;
