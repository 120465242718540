import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DisclosureStatus from './disclosure-status';
import { StyledDisclosureAccordion } from './disclosure-panel.styles';
import { ProgressStatus } from '../../../../services/models/progress-status';

export interface DisclosureAccordionProps extends PropsWithChildren {
  title: string;
  status: ProgressStatus;
  expandable?: boolean;
  expanded?: boolean;
  hideDetails?: boolean;
}

function DisclosureAccordion({
  title,
  status,
  expanded = true,
  expandable = true,
  hideDetails = false,
  children,
}: DisclosureAccordionProps) {
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    setOpen(expanded || !expandable);
  }, [expanded, expandable]);

  return (
    <StyledDisclosureAccordion
      data-testid="disclosure-section"
      elevation={0}
      disableGutters
      expanded={open}
      expandable={expandable}
      onChange={(event, isExpanded: boolean) => setOpen(isExpanded || !expandable)}
    >
      <AccordionSummary
        expandIcon={expandable ? <ExpandMoreIcon /> : null}
        aria-controls="disclosure-section-content"
        id="disclosure-section-header"
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">{title}</Typography>
          <DisclosureStatus status={status} />
        </Stack>
      </AccordionSummary>
      {!hideDetails && (
        <AccordionDetails sx={{ mb: 3 }}>
          {children}
        </AccordionDetails>
      )}
    </StyledDisclosureAccordion>
  );
}

export default DisclosureAccordion;
