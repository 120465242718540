import React from 'react';
import { ClientDetailsStatusPanel as StyledClientDetailsStatusPanel } from './client-details-bar.styles';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import AdditionalInfoPanel from './additional-information';
import { Enquiry } from '../../../../services/models/enquiry';
import PersonalInformation from './personal-information';
import { View } from '../../../view';

interface ClientDetailsProps {
  applicant: ApplicantDto;
  enquiry?: Enquiry | null;
  onEdit: (view: View) => void;
}

function ClientDetailsPanel({
  applicant,
  enquiry = null,
  onEdit,
}: ClientDetailsProps) {
  return (
    <StyledClientDetailsStatusPanel direction="row" gap={16} sx={{ mt: 2 }}>
      <PersonalInformation applicant={applicant} onEdit={onEdit} />
      {enquiry && <AdditionalInfoPanel enquiry={enquiry} onEdit={onEdit} />}
    </StyledClientDetailsStatusPanel>
  );
}

export default ClientDetailsPanel;
