import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { FixedBenefitContainer } from './fixed-benefit.styles';

function FixedBenefit() {
  const { t } = useTranslation();
  const listItems: string[] = t('components.fixedBenefit.list', { returnObjects: true });
  return (
    <FixedBenefitContainer>
      <Typography variant="h3">{t('components.fixedBenefit.title')}</Typography>
      <Typography variant="h3" component="h4">{t('components.fixedBenefit.subTitle')}</Typography>
      <List dense sx={{ pt: 2, pb: 0 }}>
        {listItems.map((item) => (
          <ListItem key={item} disableGutters>
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText
              primary={item}
              primaryTypographyProps={{ fontSize: 16 }}
            />
          </ListItem>
        ))}
      </List>
      <TableContainer sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>{t('components.fixedBenefit.table.header')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('components.fixedBenefit.table.subHeader.employed')}</TableCell>
              <TableCell>{t('components.fixedBenefit.table.subHeader.selfEmployed')}</TableCell>
              <TableCell>{t('components.fixedBenefit.table.subHeader.companyDirector')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell valign="top">
                <ul>
                  <li>{t('components.fixedBenefit.table.cell.employed.0')}</li>
                  <li>{t('components.fixedBenefit.table.cell.employed.1')}</li>
                </ul>
              </TableCell>
              <TableCell valign="top">
                <ul>
                  <li>{t('components.fixedBenefit.table.cell.selfEmployed.0')}</li>
                  <li>{t('components.fixedBenefit.table.cell.selfEmployed.1')}</li>
                </ul>
              </TableCell>
              <TableCell valign="top">
                <ul>
                  <li>{t('components.fixedBenefit.table.cell.companyDirector.0')}</li>
                  <li>{t('components.fixedBenefit.table.cell.companyDirector.1')}</li>
                  <li>{t('components.fixedBenefit.table.cell.companyDirector.2')}</li>
                  <li>{t('components.fixedBenefit.table.cell.companyDirector.3')}</li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="body1" gutterBottom>{t('components.fixedBenefit.footer.0')}</Typography>
      <Typography variant="body1">{t('components.fixedBenefit.footer.1')}</Typography>
    </FixedBenefitContainer>
  );
}

export default FixedBenefit;
