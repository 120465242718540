import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { DecisionType } from '../../../../services/product-helpers';
import { TextWithInfo } from '../../../../components/info';

function getPostponeText(reasonDecision: ReasonDecision) {
  if (reasonDecision.decision !== DecisionType.POSTPONE || !reasonDecision.postpones || reasonDecision.postpones.length === 0) {
    return null;
  }
  return (
    <>
      {reasonDecision.postpones.map((postpone) => (
        <Typography key={postpone.code} variant="body1">{postpone.text}</Typography>
      ))}
    </>
  );
}

interface ReasonDecisionDecisionProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionDecision({ id, reasonDecision }: ReasonDecisionDecisionProps) {
  const { t } = useTranslation();
  const labelId = `${id}_decision`;
  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {t('components.reasonDecisionCard.decision')}
      </Typography>
      <TextWithInfo
        variant="body2"
        aria-labelledby={labelId}
        fontWeight="bold"
        info={getPostponeText(reasonDecision)}
      >
        {t(`common.decision.${reasonDecision.decision}`)}
      </TextWithInfo>
    </section>
  );
}

export default ReasonDecisionDecision;
