import {
  CardHeader,
  CardHeaderProps,
  CircularProgress,
  IconButton,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBusyState from '../../../../hooks/use-busy-state';
import { Delete } from '../../../../components/svg-icon';

interface ProductCardHeaderProps extends CardHeaderProps {
  onDelete?: () => unknown;
}

function ProductCardHeader({ onDelete = undefined, ...props }: ProductCardHeaderProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();
  const handleDelete = withBusyState(onDelete);
  return (
    <CardHeader
      titleTypographyProps={{ variant: 'h3' }}
      sx={{ minHeight: 66 }}
      action={onDelete && (
        <IconButton
          aria-label={t('common.delete')!}
          onClick={handleDelete}
          disabled={busy}
          sx={(theme) => ({ color: theme.palette.common.surface2 })}
        >
          {busy ? <CircularProgress color="secondary" size={24} /> : <Delete fontSize="small" />}
        </IconButton>
      )}
      {...props}
    />
  );
}

export default ProductCardHeader;
