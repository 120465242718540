import React, { FormEvent, useCallback, useRef } from 'react';
import {
  Button,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { EnquiryLine } from '../../../../services/models/enquiry-line';
import { Question } from '../../../../components/form/enquiry';
import { getFormValuesFor, getValidationSchemaForQuestions } from '../../../../components/form/form.utils';
import { FormContainer } from '../../../../components/form';
import { StyledCardActions, StyledDisclosureFormCard } from './disclosure-panel.styles';
import useBusyState from '../../../../hooks/use-busy-state';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import { Enquiry } from '../../../../services/models/enquiry';
import { getEnquiryLineByPath } from '../../../../services/enquiry-helpers';
import useOptionLookup from '../../../../hooks/use-option-lookup';
import useSendAnswerAndUpdateForm from '../../../../hooks/use-send-answer-and-update-form';

interface DisclosureFormProps {
  applicantId: string;
  enquiryLine: EnquiryLine;
  onSubmit: () => unknown;
  onCancel: (disclosureId: string) => unknown;
}

function DisclosureForm({
  applicantId,
  enquiryLine,
  onSubmit,
  onCancel,
}: DisclosureFormProps) {
  const [busy, withBusyState] = useBusyState();
  const { t } = useTranslation();
  const queue = useRef(new SequentialTaskQueue()).current;
  const search = useOptionLookup(applicantId);
  const questions = enquiryLine?.questions || [];
  const formMethods = useForm({
    defaultValues: getFormValuesFor(questions) as any,
    resolver: yupResolver(getValidationSchemaForQuestions(questions)),
  });
  const {
    formState: { isValid },
  } = formMethods;
  const path = enquiryLine.path!;
  const getFormData = useCallback((updatedEnquiry: Enquiry) => {
    const updatedQuestions = getEnquiryLineByPath(updatedEnquiry, path)?.questions || [];
    return getFormValuesFor(updatedQuestions);
  }, [path]);
  const onChangeCommitted = useSendAnswerAndUpdateForm(applicantId, formMethods, getFormData, queue);

  const handleCancel = withBusyState(() => onCancel(enquiryLine.alias!));

  const handleSubmit = withBusyState(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await queue.waitForPendingTasks();
    formMethods.handleSubmit(onSubmit)(event);
  });

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={handleSubmit}>
        <StyledDisclosureFormCard>
          <CardHeader
            title={enquiryLine.section}
            titleTypographyProps={{ variant: 'h4' }}
            action={(
              <IconButton aria-label={t('common.cancel')!} onClick={handleCancel} disabled={busy}>
                <CloseIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <Stack gap={2}>
              {enquiryLine.questions.map((question) => (
                <Question
                  key={question.path}
                  question={question}
                  disabled={busy}
                  search={search}
                  onChangeCommitted={onChangeCommitted}
                  hideDivider
                  hideHelpText
                  hideStatus
                />
              ))}
            </Stack>
          </CardContent>
          <StyledCardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!isValid || !enquiryLine.isSatisfied || busy}
              data-testid="add-disclosure"
            >
              {t('components.disclosureForm.confirmButtonLabel')}
            </Button>
          </StyledCardActions>
        </StyledDisclosureFormCard>
      </FormContainer>
    </FormProvider>
  );
}

export default DisclosureForm;
