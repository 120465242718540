import { useEffect, useRef } from 'react';
import { loadSettings, selectSettingsState } from '../features/settings-slice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

function SettingsLoader() {
  const initialised = useRef<boolean>(false);
  const state = useAppSelector(selectSettingsState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!initialised.current && state.status === 'none') {
      initialised.current = true;
      dispatch(loadSettings());
    }
  }, [state.status]);
  return null;
}

export default SettingsLoader;
