import { styled } from '@mui/material/styles';
import { Card, Pagination } from '@mui/material';

export const NotificationsCard = styled(Card)(() => ({
  textAlign: 'left',
  '& .MuiCardContent-root': {
    '&:last-child': {
      padding: 0,
    },
  },
}));

export const NotificationsPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-previousNext': {
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: 32,
    },
  },
  '& .MuiPaginationItem-circular': {
    fontSize: theme.typography.caption.fontSize,
    minWidth: 25,
    maxWidth: 25,
    height: 25,
  },
}));

export default {};
