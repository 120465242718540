import React from 'react';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  formatEmLoading,
  formatIndicativePmLoading,
  hasAnyIndicativeLoadings,
  hasIndicativeLoading,
} from '../../../../services/decision-helpers';
import Duration from '../../../../components/duration';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { EmRatingValue } from './reason-decision.styles';
import { RangedPermanentLoading } from '../../../../services/models/ranged-permanent-loading';
import { RangedTemporaryLoading } from '../../../../services/models/ranged-temporary-loading';

interface IndicativeEmLoadingProps {
  loading: RangedPermanentLoading | RangedTemporaryLoading;
}

function IndicativeEmLoading({ loading }: IndicativeEmLoadingProps) {
  const { t } = useTranslation();
  return (
    <EmRatingValue className="em-value">
      {formatEmLoading(loading.min)}
      {loading.min !== loading.max && (
        <>
          <Typography variant="body2" component="span">
            {' '}
            {t('common.to')}
            {' '}
          </Typography>
          {formatEmLoading(loading.max)}
        </>
      )}
    </EmRatingValue>
  );
}

export interface ReasonDecisionRatingsProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionIndicativeRatings({ id, reasonDecision }: ReasonDecisionRatingsProps) {
  const { t } = useTranslation();
  const noLoadings = !hasAnyIndicativeLoadings(reasonDecision);
  if (noLoadings) {
    return null;
  }
  const { em, pm } = reasonDecision.indicative!;
  const labelId = `${id}_indicative_rating`;
  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {t('components.reasonDecisionCard.indicativeRating')}
      </Typography>
      <Stack gap={1} aria-labelledby={labelId}>
        {hasIndicativeLoading(em?.permanent) && (
          <IndicativeEmLoading loading={em?.permanent!} />
        )}
        {hasIndicativeLoading(em?.temporary) && (
          <Box>
            <IndicativeEmLoading loading={em?.temporary!} />
            <Duration value={em?.temporary?.duration} noSpace />
          </Box>
        )}
        {hasIndicativeLoading(pm?.permanent) && (
          <Typography variant="body2" fontWeight="bold">
            {formatIndicativePmLoading(pm?.permanent!)}
          </Typography>
        )}
        {hasIndicativeLoading(pm?.temporary) && (
          <Box>
            <Typography variant="body2" fontWeight="bold">
              {formatIndicativePmLoading(pm?.temporary!)}
            </Typography>
            <Duration value={pm?.temporary?.duration} noSpace />
          </Box>
        )}
      </Stack>
    </section>
  );
}

export default ReasonDecisionIndicativeRatings;
