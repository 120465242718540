import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import { StyledContainer, DashboardContainer } from './dashboard.styles';
import HeroPanel from './components/hero-panel';
import ArrowIcon from '../../assets/icons/arrow-forward-icon-alt.svg';
import { UnderwriteMeEnquiryType } from '../../services/models/underwrite-me-enquiry-type';
import { Tool } from '../../services/models/tool';
import { useAppSelector } from '../../store/hooks';
import { selectApplicationState } from '../../features/application-slice';
import Tools from './components/tools';
import CommissionPanel from './components/commission-panel';
import NotificationsPanel from './components/notifications-panel';
import { getAdviserIdFromToken } from '../../services/auth-helpers';
import useStartApplication from '../../hooks/use-start-application';

function Dashboard() {
  const { t } = useTranslation();
  const applicationState = useAppSelector(selectApplicationState);
  const [adviserId, setAdviserId] = useState<string | null>();
  const [selectOwnerDialog, handleStartApplication] = useStartApplication();

  const initDashboard = useCallback(async () => {
    try {
      setAdviserId(await getAdviserIdFromToken());
    } catch (e) {
      toast(t('components.dashboard.errorMessage'), {
        position: 'bottom-left',
        type: 'error',
      });
    }
  }, []);

  useEffect(() => {
    initDashboard();
  }, []);

  return (
    <>
      <StyledContainer data-testid="container">
        <Stack sx={{ width: '100%' }}>
          <HeroPanel>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<img src={ArrowIcon} alt="" width={16} />}
              onClick={() => handleStartApplication(UnderwriteMeEnquiryType.PreSales)}
              disabled={applicationState.status === 'loading'}
              data-testid={`start-${Tool.PreSales?.toLocaleLowerCase().replace(' ', '-')}`}
            >
              {t('components.heroPanel.startPreSales')}
            </Button>
          </HeroPanel>
          <DashboardContainer>
            <Container>
              <Stack gap={3}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h3"
                  >
                    {t('components.dashboard.title')}
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => handleStartApplication(UnderwriteMeEnquiryType.Full)}
                    disabled={applicationState.status === 'loading'}
                    data-testid={`start-${Tool.Onboarding?.toLocaleLowerCase().replace(' ', '-')}`}
                  >
                    {t('components.dashboard.newApplication')}
                  </Button>
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3}>
                    <CommissionPanel />
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Stack gap={2}>
                      {adviserId && <NotificationsPanel adviserId={adviserId} />}
                      <Tools />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            </Container>
          </DashboardContainer>
        </Stack>
      </StyledContainer>
      {selectOwnerDialog}
    </>
  );
}

export default Dashboard;
