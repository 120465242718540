import React from 'react';
import FieldBase, { FieldBaseProps } from './field-base';
import SelectInput, { SelectInputProps } from '../inputs/select-input';

interface SelectFieldProps extends SelectInputProps, FieldBaseProps {
}

function SelectField({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  infoText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: SelectFieldProps) {
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      infoText={infoText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <SelectInput name={name} {...props} />
    </FieldBase>
  );
}

export default SelectField;
