import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { CommonColors, alpha, styled } from '@mui/material/styles';
import { shouldForwardProp } from '../../../../components/types';
import { DecisionType } from '../../../../services/product-helpers';
import { RowLabelWidth } from './deferred-periods.styles';

export const TableControlsContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2.5),
  paddingLeft: RowLabelWidth + 32,
  paddingRight: theme.spacing(8),
}));

export const TableControlIndicatorContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
}));

type TableControlIndicatorProps = {
  decision: string | null
  active: boolean
};

function getColorByDecision(decision: string | null): keyof CommonColors {
  switch (decision) {
    case DecisionType.STANDARD: return 'green';
    case DecisionType.NON_STANDARD: return 'green';
    case DecisionType.REFER: return 'orange';
    case DecisionType.EVIDENCE_REQUIRED: return 'orange';
    case DecisionType.POSTPONE: return 'red';
    case DecisionType.DECLINE: return 'red';
    default: return 'secondary200';
  }
}

export const TableControlIndicator = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<TableControlIndicatorProps>(['decision', 'active'], prop),
})<TableControlIndicatorProps>(({ theme, decision, active }) => ({
  width: 11,
  height: 11,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.common.surface2}`,
  borderColor: theme.palette.common[getColorByDecision(decision)],
  backgroundColor: active ? theme.palette.common[getColorByDecision(decision)] : 'transparent',
}));

export const ArrowButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
  '&:disabled': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
}));
