import React, { FocusEvent } from 'react';
import { useController } from 'react-hook-form';
import { OutlinedInput } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { Moment } from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import TextField from './text-field';
import { WithOnChangeCommitted } from '../../types';
import {
  asStringOrNull,
  clientDateFormat,
  formatServerDate,
  parseClientDate,
  parseServerDate,
} from '../../../utils/converters';
import { FieldBaseProps } from './field-base';

interface DateFieldProps extends Omit<InputBaseProps, 'name'>, WithOnChangeCommitted<string | null>, FieldBaseProps {
  name: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  maxDate?: Moment;
}

function DateField({
  name,
  disablePast = false,
  disableFuture = false,
  disabled = false,
  maxDate = undefined,
  onChangeCommitted = undefined,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  readOnly = undefined,
}: DateFieldProps) {
  const {
    field,
    formState: { defaultValues },
  } = useController({ name });

  const commitValueIfChanged = (value: Moment | null) => {
    const oldValue = (defaultValues?.[name] as string)?.substring(0, 10);
    const newValue = formatServerDate(value);
    if (onChangeCommitted && asStringOrNull(oldValue) !== newValue) {
      onChangeCommitted(name, newValue);
    }
  };

  const handleChange = (value: Moment | null) => {
    const newValue = formatServerDate(value);
    if (newValue) {
      field.onChange(newValue);
    } else if (value === null) {
      field.onChange(null);
    }
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    const date = parseClientDate(value);
    if (!date || date.parsingFlags().unusedInput.length > 0) {
      field.onChange(null);
      commitValueIfChanged(null);
    } else {
      commitValueIfChanged(date);
    }
    field.onBlur();
  };

  const handleOnAccept = (value: Moment | null) => {
    commitValueIfChanged(value);
  };

  return (
    <DesktopDatePicker
      format={clientDateFormat}
      onChange={(value: Moment | null) => handleChange(value)}
      onAccept={handleOnAccept}
      value={parseServerDate(field.value)}
      disablePast={disablePast}
      disableFuture={disableFuture}
      disabled={disabled}
      maxDate={maxDate}
      slots={{
        textField: TextField as any,
      }}
      slotProps={{
        textField: {
          sx: { width: 200 },
          name,
          id: name,
          InputComponent: OutlinedInput,
          inputProps: {
            onBlur: handleOnBlur,
          },
          variant: 'outlined',
          label,
          LabelProps,
          helperText,
          hideError,
          FormHelperTextProps,
          FormControlProps,
          sublabel,
          SublabelProps,
        } as any,
      }}
      readOnly={readOnly}
    />
  );
}

export default DateField;
