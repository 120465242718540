import { styled } from '@mui/material/styles';
import InfoCard from '../../../../components/info-card';

export const Panel = styled(InfoCard)(({ theme }) => ({
  '& .MuiCardContent-root': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: theme.spacing(10),
    rowGap: theme.spacing(2),
    justifyContent: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    '&:last-child': {
      paddingBottom: theme.spacing(6),
    },
    '> button': {
      minWidth: 176,
    },
  },
}));

export default Panel;
