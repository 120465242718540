import React, { FormEvent } from 'react';
import {
  Alert,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../components/form/fields';
import AuthLayout from './auth-layout';
import { FormContainer } from '../../../components/form';
import { passwordResetSchema } from '../../../services/auth-helpers';

interface PasswordResetProps {
  loading?: boolean;
  error?: string | null;
  success?: boolean | null;
  onPasswordReset: (email: string) => void;
  onCancel: () => void;
}

function PasswordReset({
  onPasswordReset,
  onCancel,
  loading = false,
  error = null,
  success = null,
}: PasswordResetProps) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(passwordResetSchema),
  });

  const { formState: { isValid }, getValues } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email } = getValues();
    onPasswordReset(email);
  };

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom>{t('components.auth.passwordReset.title')}</Typography>
          <Stack>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}
            {success === true ? (
              <>
                <Typography variant="h5">{t('components.auth.passwordReset.confirmationTitle')}</Typography>
                <Typography variant="body2">{t('components.auth.passwordReset.confirmationMessage')}</Typography>
              </>
            ) : (
              <>
                <TextField
                  name="email"
                  type="email"
                  sublabel={t('components.auth.passwordReset.email')}
                />
                <LoadingButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  disabled={!isValid}
                >
                  {t('components.auth.passwordReset.continue')}
                </LoadingButton>
              </>
            )}
          </Stack>
          <Button
            variant="text"
            color="info"
            size="small"
            sx={{ p: 0, mt: 2, mb: 2 }}
            onClick={onCancel}
          >
            {t('components.auth.passwordReset.back')}
          </Button>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default PasswordReset;
