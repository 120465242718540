import React, { ChangeEvent, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  FormGroupProps,
} from '@mui/material';
import {
  WithOnChangeCommitted,
  Option,
  getLabelAndValue,
} from '../../types';
import { CheckboxGroup } from './input.styles';
import {
  getMultipleAnswer,
  isOptionExclusive,
  isOptionExclusiveSet,
  toggleAnswer,
} from '../../../services/enquiry-helpers';

export interface CheckboxOptionsProps extends Omit<FormGroupProps, 'ref'>, WithOnChangeCommitted<string[]> {
  options: (string | Option)[];
  exclusiveOptions?: string[] | null;
  labelTranslationBasePath?: string;
  name: string;
  disabled?: boolean;
  row?: boolean;
}

function CheckboxOptions({
  options,
  exclusiveOptions = null,
  name,
  labelTranslationBasePath = undefined,
  onChangeCommitted,
  disabled = undefined,
  row = options.length <= 2,
  ...props
}: CheckboxOptionsProps) {
  const { t } = useTranslation();
  const {
    field: { value, onChange, onBlur },
  } = useController({ name });
  const selectedValues = getMultipleAnswer(value);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value;
    const newValues = toggleAnswer(
      changedValue,
      selectedValues,
      isOptionExclusive(changedValue, exclusiveOptions) || isOptionExclusiveSet(selectedValues, exclusiveOptions),
    );
    onChange(newValues);
    if (onChangeCommitted) {
      onChangeCommitted(name, newValues);
    }
  }, [onChange, onChangeCommitted, selectedValues]);

  return (
    <CheckboxGroup row={row} {...props}>
      {options.map((option: string | Option) => {
        const { label, value: optionValue } = getLabelAndValue(option, t, labelTranslationBasePath);
        const checked = selectedValues.includes(optionValue);
        return (
          <FormControlLabel
            key={optionValue}
            label={label}
            disabled={disabled}
            control={
              <Checkbox onChange={handleChange} value={optionValue} name={name} checked={checked} onBlur={onBlur} />
            }
          />
        );
      })}
    </CheckboxGroup>
  );
}

export default CheckboxOptions;
