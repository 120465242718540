import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const NewProductButton = styled(Button)(({ theme }) => ({
  border: `1px dashed ${theme.palette.common.secondary200}`,
  color: theme.palette.common.greyLight,
  fontFamily: theme.typography.body1.fontFamily,
  height: 80,
}));

export default NewProductButton;
