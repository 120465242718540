import React from 'react';
import FieldBase, { FieldBaseProps } from './field-base';
import MultiTypeaheadInput, { MultiTypeaheadInputProps } from '../inputs/multi-typeahead-input';

interface MultiTypeaheadFieldProps extends MultiTypeaheadInputProps, FieldBaseProps {
}

function MultiTypeaheadField({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: MultiTypeaheadFieldProps) {
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={{ ...LabelProps, htmlFor: name }}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <MultiTypeaheadInput name={name} {...props} />
    </FieldBase>
  );
}

export default MultiTypeaheadField;
