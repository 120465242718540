import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTheme } from '@mui/material';
import { AppBar } from './app-bar';
import { Container, OutletContainer } from './layout.styles';
import withTracker from '../analytics';
import ScrollToTop from '../scroll-to-top';

interface LayoutProps extends PropsWithChildren {
  header: ReactNode;
  info?: ReactNode;
  footer?: ReactNode;
}

function Layout({
  header,
  children,
  info = undefined,
  footer = undefined,
}: LayoutProps) {
  const theme = useTheme();
  const ref = useRef<HTMLElement>(null);
  const [appBarHeight, setAppBarHeight] = useState<number>();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setAppBarHeight(ref.current.offsetHeight);
      }
    });
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => resizeObserver.disconnect();
  }, [ref.current]);

  return (
    <Container maxWidth={false} disableGutters>
      <ScrollToTop />
      <AppBar header={header} info={info} ref={ref} />
      <OutletContainer sx={{ paddingTop: `calc(${theme.spacing(2)} + ${appBarHeight}px)` }}>
        {children}
      </OutletContainer>
      {footer}
    </Container>
  );
}

export default withTracker(Layout);
