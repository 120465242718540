import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { SalesResource } from '../../../../services/models/sales-resource';
import { SalesResourcesButton } from './sales-resources.styles';

interface SalesResourcesProps {
  salesResources: SalesResource[]
}

function SalesResources({
  salesResources,
}: SalesResourcesProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewResource = (salesResource: SalesResource) => {
    window.open(salesResource.location!);
    handleClose();
  };

  return (
    <>
      <SalesResourcesButton
        id="sales-resource-menu-button"
        aria-controls={open ? 'sales-resource-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        variant="text"
        color="secondary"
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t('components.salesResources.label')}
      </SalesResourcesButton>
      <Menu
        id="sales-resource-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sales-resource-menu-button',
        }}
      >
        {salesResources.map((salesResource) => (
          <MenuItem key={salesResource.name} onClick={() => handleViewResource(salesResource)}>
            {salesResource.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default SalesResources;
