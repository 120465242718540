import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardActions,
  CardContent,
  CardHeader,
  PaginationItem,
} from '@mui/material';
import { toast } from 'react-toastify';
import NotificationsTable from './notifications-table';
import { JournalQueryParams } from '../../../../services/agency-helpers';
import useBusyState from '../../../../hooks/use-busy-state';
import { AdvisorJournalSearchResultDto } from '../../../../services/bff/models/advisor-journal-search-result-dto';
import agencyApi from '../../../../services/agency-api';
import { NotificationsCard, NotificationsPagination } from './notifications-panel.styles';
import { ReactComponent as NextCircleIcon } from '../../../../assets/icons/next-circle-icon.svg';
import { ReactComponent as PreviousCircleIcon } from '../../../../assets/icons/previous-circle-icon.svg';

const PAGE_ITEMS = 6;

interface NotificationsPanelProps {
  adviserId: string
}

function NotificationsPanel({ adviserId }: NotificationsPanelProps) {
  const [loading, withLoadingState] = useBusyState();
  const [journalResult, setJournalResult] = useState<AdvisorJournalSearchResultDto>();
  const [queryParams, setQueryParams] = useState<JournalQueryParams>({
    from: 0,
    size: PAGE_ITEMS,
  });
  const { t } = useTranslation();

  const getJournalEntries = useCallback(withLoadingState(async (params: JournalQueryParams) => {
    try {
      const response = await agencyApi.getJournalEntries(adviserId, params);
      setJournalResult(response);
    } catch {
      toast(t('components.notificationsPanel.errorMessage'), {
        position: 'bottom-left',
        type: 'error',
      });
    }
  }), [setJournalResult]);

  const handlePageChange = useCallback((event: ChangeEvent<unknown>, value: number) => {
    const updatedQueryParams = {
      ...queryParams,
      from: (value - 1) * PAGE_ITEMS,
    };
    setQueryParams(updatedQueryParams);
    getJournalEntries(updatedQueryParams);
  }, []);

  useEffect(() => {
    getJournalEntries(queryParams);
  }, [adviserId]);

  return (
    <NotificationsCard>
      <CardHeader
        sx={{ paddingX: 2 }}
        title={t('components.notificationsPanel.title')}
      />
      <CardContent sx={{ p: 0 }}>
        <NotificationsTable loading={loading} journeyEntries={journalResult?.items ?? []} />
      </CardContent>
      <CardActions sx={{ p: 0, minHeight: 54, justifyContent: 'center' }}>
        <NotificationsPagination
          count={Math.ceil((journalResult?.totalCount ?? 0) / PAGE_ITEMS)}
          page={((queryParams.from / PAGE_ITEMS) + 1)}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PreviousCircleIcon,
                next: NextCircleIcon,
              }}
              {...item}
            />
          )}
        />
      </CardActions>
    </NotificationsCard>
  );
}

export default NotificationsPanel;
