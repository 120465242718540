import React, { useState } from 'react';
import {
  Button,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { EnquiryLine } from '../../../services/models/enquiry-line';
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  QuestionAndAswers,
} from './enquiry-line-review.styles';
import QuestionType from '../../../services/models/question-type';
import { Question } from '../../../services/models/question';
import { Enquiry } from '../../../services/models/enquiry';
import { formatAnswer } from '../../../services/enquiry-helpers';

function formatAnswers(question: Question, enquiry: Enquiry) {
  const { definition } = question;
  if (definition.type === QuestionType.INTEGER || definition.type === QuestionType.NUMBER) {
    return formatAnswer(question, enquiry.allAnswers!);
  }
  return question.answers?.join(', ');
}

interface EnquiryLineReviewProps {
  enquiry: Enquiry;
  enquiryLine: EnquiryLine;
  onAmend?: (conditionPath: string) => unknown;
}

function EnquiryLineReview({
  enquiry,
  enquiryLine,
  onAmend = undefined,
}: EnquiryLineReviewProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleAmend = onAmend ? () => onAmend(enquiryLine.path!) : undefined;
  return (
    <AccordionStyled
      elevation={0}
      disableGutters
      expanded={open}
      onChange={(event, isExpanded: boolean) => setOpen(isExpanded)}
    >
      <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5">{enquiryLine.alias}</Typography>
        {open && handleAmend && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAmend}
            sx={{ paddingY: 0.5 }}
          >
            {t('common.amend')}
          </Button>
        )}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        {enquiryLine.questions.map((question) => (
          <QuestionAndAswers key={question.path}>
            <Typography variant="body2">
              {question.definition.text}
              {(question.definition.type === QuestionType.OPTION_LIST || question.definition.type === QuestionType.OPTION_GROUP) && (
                <>
                  <br />
                  (
                  {question.definition.options?.join(', ')}
                  )
                </>
              )}
            </Typography>
            <Typography variant="body2">{formatAnswers(question, enquiry)}</Typography>
          </QuestionAndAswers>
        ))}
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
}

export default EnquiryLineReview;
