import React from 'react';
import { Alert, AlertProps, Typography } from '@mui/material';

interface FormErrorsProps extends AlertProps {
  errors: string[]
}

function FormErrors({ errors, ...props }: FormErrorsProps) {
  if (errors && errors.length > 0) {
    return (
      <Alert severity="error" {...props}>
        {errors.map((error) => (
          <Typography variant="body2" color="error.main" key={error}>{error}</Typography>
        ))}
      </Alert>
    );
  }
  return null;
}

export default FormErrors;
