/* tslint:disable */
/* eslint-disable */
/**
 * ApplicationService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const UnderwriteMeEnquiryType = {
    PreSales: 'preSales',
    Full: 'full'
} as const;

export type UnderwriteMeEnquiryType = typeof UnderwriteMeEnquiryType[keyof typeof UnderwriteMeEnquiryType];



