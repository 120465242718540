import React, { PropsWithChildren, ReactNode } from 'react';
import { Divider, Typography } from '@mui/material';

interface DeclarationSectionProps extends PropsWithChildren {
  title: ReactNode;
}

function DeclarationSection({ title, children }: DeclarationSectionProps) {
  return (
    <section>
      <Typography variant="h3">{title}</Typography>
      <Divider />
      {children}
    </section>
  );
}

export default DeclarationSection;
