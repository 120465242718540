import React from 'react';
import {
  Stack,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { numberToMoney } from '../../utils/converters';
import { StyledDecisionChip } from './discount-chip.styles';

interface DiscountChipProps {
  discount: number
}

function DiscountChip({ discount }: DiscountChipProps) {
  const { t } = useTranslation();
  return (
    <Tooltip
      arrow
      title={(
        <Stack direction="row" gap={2}>
          <span>
            {t('components.discountChip.tooltip')}
          </span>
          <strong>
            {numberToMoney(discount)}
          </strong>
        </Stack>
      )}
    >
      <StyledDecisionChip
        label={t('components.discountChip.discounted')}
        icon={<ErrorOutlineOutlinedIcon />}
        sx={{
          flexDirection: 'row-reverse',
        }}
      />
    </Tooltip>
  );
}

export default DiscountChip;
