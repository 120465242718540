import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormControl, InputAdornment, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { WithOnChangeCommitted } from '../../types';
import ButtonOptions from './button-options';
import InputBase from './input-base';
import {
  ImperialWeight,
  SystemOfMeasurement,
  asStringOrNull,
  imperialToKilogramms,
  kilogrammsToImperial,
  systemsOfMeasurement,
} from '../../../utils/converters';

function isValidValue(value: number | null, min: number, max: number): boolean {
  return value == null || (!Number.isNaN(value) && value >= min && value <= max);
}

function formatValue(value: number | null) {
  return value != null ? Math.round(value) : '';
}

interface InputProps extends WithOnChangeCommitted<number> {
  name: string;
  value: number | null;
  onChange: (newValue: number | null) => void;
  onBlur: () => void;
  min: number;
  max: number;
}

function MetricInput({
  name,
  value,
  onChange,
  onBlur,
  min,
  max,
}: InputProps) {
  const handleKgInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : null;
    if (isValidValue(newValue, 0, max)) {
      onChange(newValue);
    }
  };

  const kgUnitsId = `${name}_kg-units`;
  return (
    <InputBase
      id={name}
      aria-describedby={kgUnitsId}
      name={name}
      type="number"
      value={formatValue(value)}
      inputProps={{
        min,
        max,
        step: 1,
      }}
      onChange={handleKgInputChange}
      onBlur={onBlur}
      endAdornment={<InputAdornment position="end" id={kgUnitsId} disablePointerEvents>kg</InputAdornment>}
    />
  );
}

function adjustForDisplay(imperialValue: ImperialWeight): ImperialWeight {
  if (formatValue(imperialValue.pounds) === 14) {
    return {
      stones: (imperialValue.stones || 0) + 1,
      pounds: imperialValue.pounds! - 14,
    };
  }
  return imperialValue;
}

function ImperialInputs({
  name,
  value,
  onChange,
  onBlur,
  min,
  max,
}: InputProps) {
  const imperialValue = adjustForDisplay(kilogrammsToImperial(value));
  const stonesRef = useRef();
  const poundsRef = useRef();
  const [stonesEmpty, setStonesEmpty] = useState(value == null);
  const [poundsEmpty, setPoundsEmpty] = useState(value == null);
  const stones = stonesEmpty ? null : imperialValue.stones;
  const pounds = poundsEmpty ? null : imperialValue.pounds;

  const stonesUnitsId = `${name}_stones-units`;
  const poundsUnitsId = `${name}_pounds-units`;
  const minStones = kilogrammsToImperial(min).stones || 0;
  const maxStones = kilogrammsToImperial(max).stones || 39;

  const handleStonesInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : null;
    if (isValidValue(newValue, 0, maxStones)) {
      const newValueImperial = { stones: newValue, pounds };
      onChange(imperialToKilogramms(newValueImperial));
      setStonesEmpty(newValue == null);
    }
  };

  const handlePoundsInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : null;
    if (isValidValue(newValue, 0, 13)) {
      const newValueImperial = { stones, pounds: newValue };
      onChange(imperialToKilogramms(newValueImperial));
      setPoundsEmpty(newValue == null);
    }
  };

  // Prevent sending of value if the other input is in focus
  // This stops the removal of any current input value
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (document?.activeElement !== stonesRef.current && document?.activeElement !== poundsRef.current) {
        onBlur();
      } else {
        event.target.blur();
      }
    }, 10);
  };

  return (
    <>
      <InputBase
        id={name}
        aria-describedby={stonesUnitsId}
        name={name}
        type="number"
        value={formatValue(stones)}
        inputRef={stonesRef}
        inputProps={{
          min: minStones,
          max: maxStones,
          step: 1,
        }}
        onChange={handleStonesInputChange}
        onBlur={handleBlur}
        endAdornment={<InputAdornment position="end" id={stonesUnitsId} disablePointerEvents>st</InputAdornment>}
      />
      <FormControl>
        <InputBase
          id={`${name}_pounds`}
          aria-describedby={poundsUnitsId}
          name={`${name}_pounds`}
          type="number"
          value={formatValue(pounds)}
          inputRef={(poundsRef)}
          inputProps={{
            min: 0,
            max: 13,
            step: 1,
          }}
          onChange={handlePoundsInputChange}
          onBlur={handleBlur}
          endAdornment={<InputAdornment position="end" id={poundsUnitsId} disablePointerEvents>lbs</InputAdornment>}
        />
      </FormControl>
    </>
  );
}

export interface WeightInputProps extends WithOnChangeCommitted<number | SystemOfMeasurement> {
  name: string;
  systemName: string;
  min?: number;
  max?: number;
}

function WeightInput({
  name,
  systemName,
  onChangeCommitted,
  min = 0,
  max = 250,
}: WeightInputProps) {
  const { field: systemField } = useController({ name: systemName });
  const { resetField } = useFormContext();
  useEffect(() => {
    if (systemField.value == null) {
      resetField(systemName, { defaultValue: 'metric' });
      if (onChangeCommitted) {
        onChangeCommitted(systemName, 'metric');
      }
    }
  }, [systemField.value, resetField]);
  const system: SystemOfMeasurement = systemField.value || 'metric';

  const {
    field: { value, onChange },
    formState: { defaultValues },
  } = useController({ name });

  const handleBlur = () => {
    const oldValue = defaultValues?.[name];
    if (onChangeCommitted && asStringOrNull(oldValue) !== asStringOrNull(value)) {
      onChangeCommitted(name, value);
    }
  };

  return (
    <Stack direction="row" gap={2}>
      <ButtonOptions
        name={systemName}
        options={systemsOfMeasurement}
        labelTranslationBasePath="common.systemOfMeasurement"
        onChangeCommitted={onChangeCommitted as any}
      />
      {system.toLocaleLowerCase() === 'metric' && (
        <MetricInput name={name} value={value} onChange={onChange} onBlur={handleBlur} min={min} max={max} />
      )}
      {system.toLocaleLowerCase() === 'imperial' && (
        <ImperialInputs name={name} value={value} onChange={onChange} onBlur={handleBlur} min={min} max={max} />
      )}
    </Stack>
  );
}

export default WeightInput;
