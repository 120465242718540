import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Layout, MainContent } from '../../components/layout';
import ApplicationHeader from '../application/components/application-layout/application-header';
import SectionHeader from '../../components/section-header';
import { useAppSelector } from '../../store/hooks';
import { selectAvailableApplication } from '../../features/application-slice';
import ProductReview from '../../components/review/product-review/product-review';
import DisclosuresReview from '../../components/review/disclosures-review/disclosures-review';
import InfoBarContent from '../application/components/info-bar-content';
import { getJointApplicantProducts, getSingleApplicantProducts } from '../../services/product-helpers';
import ApplicantReview from '../../components/review/applicant-review/applicant-review';
import BankDetailsReview from '../../components/review/bank-details-review/bank-details-review';
import ApplicationDocuments from '../complete/components/application-documents';

function Cancelled() {
  const { t } = useTranslation();
  const { application, quoteDecision } = useAppSelector(selectAvailableApplication);

  return (
    <Layout
      header={<ApplicationHeader />}
      info={<InfoBarContent readonly />}
    >
      <MainContent maxWidth={false} sx={{ flexDirection: 'column', gap: 2 }}>
        <SectionHeader>{t('components.cancelled.title')}</SectionHeader>
        <Stack gap={4}>
          <Stack gap={1} alignItems="flex-start">
            <Typography variant="h3">{t('components.complete.documents')}</Typography>
            <ApplicationDocuments
              application={application}
              quoteDecision={quoteDecision}
            />
          </Stack>
          <BankDetailsReview application={application} readonly />
          <Stack direction="row" gap={(theme) => theme.mixins.mainHorizontalSpacing}>
            <Stack flexGrow={1} flexBasis={1} gap={4}>
              {application.applicants.flatMap((applicant) => (
                getSingleApplicantProducts(application.products, applicant.id).map((product) => (
                  <ProductReview
                    key={`${applicant.id}-${product.id}`}
                    applicationId={application.id}
                    applicants={[applicant]}
                    product={product}
                    readonly
                  />
                ))))}
              {getJointApplicantProducts(application.products, application.applicants).map((product) => (
                <ProductReview
                  key={product.id}
                  applicationId={application.id}
                  applicants={application.applicants}
                  product={product}
                  readonly
                />
              ))}
              {application.applicants.map((applicant) => (
                <ApplicantReview
                  key={applicant.id}
                  application={application}
                  applicant={applicant}
                  readonly
                />
              ))}
            </Stack>
            <Stack flexGrow={1} flexBasis={1} gap={4}>
              {application.applicants.map((applicant) => (
                <DisclosuresReview
                  key={applicant.id}
                  applicationId={application.id}
                  applicant={applicant}
                  readonly
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </MainContent>
    </Layout>
  );
}

export default Cancelled;
