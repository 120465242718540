import { useCallback } from 'react';
import { Enquiry } from '../services/models/enquiry';
import applicationApi from '../services/application-api';

function useOptionList(enquiry: Enquiry) {
  return useCallback((async (optionListName: string) => (
    applicationApi.getOptionList(enquiry.branch ?? '', enquiry.tag ?? '', optionListName)
  )), [enquiry]);
}

export default useOptionList;
