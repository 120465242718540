import React, {
  FormEvent,
  ReactNode,
  KeyboardEvent,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { EnquiryLine as EnquiryLineModel } from '../../../services/models/enquiry-line';
import { getFormValuesFor, getValidationSchemaForQuestions } from '../form.utils';
import useSendAnswerAndUpdateForm from '../../../hooks/use-send-answer-and-update-form';
import useOptionLookup from '../../../hooks/use-option-lookup';
import { FormContainer } from '../form.styles';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActionsWithNavigation,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../drawer-card';
import EnquiryLine from './enquiry-line';
import { Enquiry } from '../../../services/models/enquiry';
import {
  getAdditionalAnswers,
  getEnquiryLineByPath,
} from '../../../services/enquiry-helpers';
import useBusyState from '../../../hooks/use-busy-state';
import SequentialTaskQueue from '../../../services/sequential-task-queue';
import useFocusScroll from '../../../hooks/use-focus-scroll';
import useAutoScroll from '../../../hooks/use-auto-scroll';
import EnquiryLineProgress from './enquiry-line-progress';

export interface EnquiryLineFormProps {
  applicantId: string;
  enquiry: Enquiry;
  enquiryLine: EnquiryLineModel;
  onSubmit: () => unknown;
  onCancel: () => unknown;
  title?: ReactNode;
  submitLabel?: ReactNode;
  hideNavigation?: boolean;
  onPrevious?: () => unknown;
  onNext?: () => unknown;
  showProgress?: boolean;
}

function EnquiryLineForm({
  applicantId,
  enquiry,
  enquiryLine,
  onSubmit,
  onCancel,
  title = undefined,
  submitLabel = undefined,
  hideNavigation = undefined,
  onPrevious = undefined,
  onNext = undefined,
  showProgress = false,
}: EnquiryLineFormProps) {
  const queue = useRef(new SequentialTaskQueue()).current;
  const cardRef = useRef<HTMLDivElement>(null);
  useFocusScroll(cardRef, 100);
  useAutoScroll(cardRef, enquiryLine);
  const [busy, withBusyState] = useBusyState(queue);
  const [loading, withLoadingState] = useBusyState();
  const { t } = useTranslation();
  const path = enquiryLine.path!;
  const questions = enquiryLine?.questions || [];
  const additionalAnswers = getAdditionalAnswers(enquiry);
  const questionRef = useRef({ length: questions.length });
  const formMethods = useForm({
    defaultValues: getFormValuesFor(questions, additionalAnswers) as any,
    resolver: yupResolver(getValidationSchemaForQuestions(questions)),
  });
  const {
    formState: { isValid },
  } = formMethods;
  const getFormData = useCallback((updatedEnquiry: Enquiry) => {
    const updatedQuestions = getEnquiryLineByPath(updatedEnquiry, path)?.questions || [];
    return getFormValuesFor(updatedQuestions, getAdditionalAnswers(updatedEnquiry));
  }, [path]);
  const search = useOptionLookup(applicantId);
  const sendAnswer = useSendAnswerAndUpdateForm(applicantId, formMethods, getFormData, queue);
  const submitIsPrimary = hideNavigation || !onNext;

  useEffect(() => {
    questionRef.current.length = questions.length;
  }, [questions]);

  const handleSubmit = withLoadingState(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    await queue.waitForPendingTasks();
    await formMethods.handleSubmit(onSubmit)(event);
  });

  const canContinueOnNext = onNext ? () => {
    if (questions.length >= questionRef.current.length) {
      return onNext();
    }
    return undefined;
  } : undefined;

  const handleNavigation = (navFunction?: () => unknown) => {
    if (!navFunction) {
      return undefined;
    }
    return withBusyState(async () => {
      await queue.waitForPendingTasks();
      navFunction();
    });
  };
  const handlePrevious = handleNavigation(onPrevious);
  const handleNext = handleNavigation(canContinueOnNext);
  const handleCancel = handleNavigation(onCancel);

  const preventEnterSubmit = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <FormProvider {...formMethods}>
      <FormContainer onSubmit={handleSubmit} onKeyDown={preventEnterSubmit}>
        <DrawerCard key={enquiryLine.name} ref={cardRef}>
          <DrawerCardHeader
            title={title}
            subheader={enquiryLine.preamble}
            action={<CancelDrawerButton onClick={handleCancel} />}
          />
          <DrawerCardContent sx={{ paddingBottom: 0 }}>
            <EnquiryLine
              enquiryLine={enquiryLine}
              search={search}
              onChangeCommitted={sendAnswer}
            />
          </DrawerCardContent>
          <DrawerCardActionsWithNavigation
            hidePrevious={hideNavigation}
            hideNext={hideNavigation}
            onPrevious={handlePrevious}
            onNext={handleNext}
            previousProps={{ disabled: !handlePrevious || busy || loading }}
            nextProps={{ disabled: !handleNext || busy || loading }}
          >
            <Stack direction="row" gap={4} alignItems="center" justifyContent="space-between" flexGrow={1}>
              {showProgress && (
                <EnquiryLineProgress enquiryLine={enquiryLine} />
              )}
              <LoadingButton
                variant={submitIsPrimary ? 'contained' : 'outlined'}
                color={submitIsPrimary ? 'primary' : 'secondary'}
                type="submit"
                disabled={!isValid || busy || !enquiryLine?.isSatisfied}
                loading={loading}
              >
                {submitLabel || t('components.enquiryLineForm.confirmButtonLabel')}
              </LoadingButton>
            </Stack>
          </DrawerCardActionsWithNavigation>
        </DrawerCard>
      </FormContainer>
    </FormProvider>
  );
}

export default EnquiryLineForm;
