import React, { ChangeEvent, FormEvent } from 'react';
import {
  Breadcrumbs,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActions,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { FormContainerStyled } from './pre-underwriting-declaration.styles';
import { patchApplicant } from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';
import useBusyState from '../../../../hooks/use-busy-state';
import DeclarationSection from './declaration-section';

interface PreUnderwritingDeclarationProps {
  applicationId: string;
  applicant: ApplicantDto;
  onSubmit: () => unknown;
  onCancel: () => unknown;
}

function PreUnderwritingDeclaration({
  applicationId,
  applicant,
  onSubmit,
  onCancel,
}: PreUnderwritingDeclarationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [busy, withBusyState] = useBusyState();

  const handleDeclarationChanged = withBusyState(async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    await dispatch(patchApplicant(applicationId, applicant.id!, 'hasCompletedPreUWDeclaration', checked));
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };

  return (
    <FormContainerStyled onSubmit={handleSubmit}>
      <DrawerCard>
        <DrawerCardHeader
          title={(
            <Breadcrumbs separator=">">
              <span>{t('components.preUnderwritingDeclaration.titlePart1')}</span>
              <span>{t('components.preUnderwritingDeclaration.titlePart2')}</span>
            </Breadcrumbs>
          )}
          action={<CancelDrawerButton onClick={onCancel} />}
        />
        <DrawerCardContent sx={{ paddingBottom: 0 }}>
          <DeclarationSection title="The underwriting questionnaire">
            <Typography variant="body1">
              It&apos;s important that all questions are answered fully, truthfully and accurately,
              if not this may result in the policy being cancelled, amended or in a claim not being paid.
            </Typography>
          </DeclarationSection>
          <DeclarationSection title="For the attention of the applicant">
            <ol>
              <li>
                <Typography variant="body1">
                  Please make sure the information you enter on this form is complete & accurate
                  as it is taken into account when deciding whether to accept your application and
                  for calculating your premium.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  You should notify us immediately if there are any changes to your health or personal circumstances
                  (this includes a change in occupation or take up of a hazardous hobby) after submitting the application and before the policy starts.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  We may need to request further information to assess your application.
                  We may also check a sample of applications by obtaining further information from your doctor after your policy has started.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  I confirm that I give explicit consent for The Exeter to process my personal data in accordance with the
                  {' '}
                  <a href="https://www.the-exeter.com/privacy-policy/" rel="noreferrer" target="_blank">Privacy Policy</a>
                  .
                </Typography>
              </li>
              <li>
                <Typography variant="body1" gutterBottom>
                  If you have had any predictive genetic tests to tell you whether you have inherited
                  a disease or condition that your family suffers from, The Exeter will NOT ask your
                  doctor and you do not need to tell us about them unless:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      You are applying for life assurance of more than £500,000 and you have had a test
                      for Huntington&apos;s disease.
                    </Typography>
                  </li>
                </ul>
                <Typography variant="body1" gutterBottom>
                  You must always tell us if you are having treatment for or experiencing symptoms of a genetic condition.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  However, if you have had a genetic test and the results are favourable
                  you can choose to tell us the results as we may be able to offer you better terms.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  More details on the Genetic Testing Code of Practice can be found on the Association of British Insurer&apos;s (ABI) website.
                </Typography>
              </li>
            </ol>
          </DeclarationSection>
          <DeclarationSection title="For the attention of the applicant's financial adviser">
            <Typography variant="body1">
              Please note that we will not ask for your client&apos;s signature during the application process
              unless we need to ask for a report from their GP or for further information relating to any
              medical information that they have included in the application.
            </Typography>
          </DeclarationSection>
          <FormControlLabel
            label={t('components.preUnderwritingDeclaration.confirmCheckboxLabel')}
            control={<Checkbox id="confirm" onChange={handleDeclarationChanged} />}
          />
          <Divider />
        </DrawerCardContent>
        <DrawerCardActions sx={{ justifyItems: 'flex-start', justifyContent: 'flex-start' }}>
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            disabled={!applicant.hasCompletedPreUWDeclaration}
            loading={busy}
          >
            {t('common.next')}
          </LoadingButton>
        </DrawerCardActions>
      </DrawerCard>
    </FormContainerStyled>
  );
}

export default PreUnderwritingDeclaration;
