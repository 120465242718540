import http from './http';
import { ProductDefinition } from './models/product-definition';

/**
 * Retrieve all product definitions
 */
async function getProductDefinitions(): Promise<ProductDefinition[]> {
  return http.get('/api/v1/product/definitions');
}

const productApi = {
  getProductDefinitions,
};

export default productApi;
