import React from 'react';
import {
  DecisionTotalCard,
  DecisionTotalCardContent,
} from './decision-total.styles';
import { TotalRating } from '../../../../services/models/total-rating';
import TotalRatingContent from './total-rating-content';

export interface TotalRatingDecisionCardProps {
  totalRating: TotalRating;
  inline?: boolean;
}

function TotalRatingDecisionCard({ totalRating, inline = false }: TotalRatingDecisionCardProps) {
  return (
    <DecisionTotalCard>
      <DecisionTotalCardContent>
        <TotalRatingContent totalRating={totalRating} inline={inline} />
      </DecisionTotalCardContent>
    </DecisionTotalCard>
  );
}

export default TotalRatingDecisionCard;
