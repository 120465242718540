import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField, TypeaheadField } from '../form/fields';
import { AgencyDto } from '../../services/bff/models/agency-dto';
import agencyApi from '../../services/agency-api';
import { Option } from '../types';
import useBusyState from '../../hooks/use-busy-state';
import { assignAgencySchema, compareByName } from '../../services/agency-helpers';

type FirmOption = Pick<AgencyDto, 'name' | 'frn'>;

export interface OwnerDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => unknown;
  onConfirm: (adviserId: string) => unknown;
  title: string;
  confirmLabel?: string | null;
  firm?: FirmOption;
  firmReadOnly?: boolean;
}

function OwnerDialog({
  onClose,
  onConfirm,
  title,
  confirmLabel = undefined,
  firm = undefined,
  firmReadOnly = undefined,
  ...props
}: OwnerDialogProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();
  const [loadingAdvisers, withAdviserBusyState] = useBusyState();
  const [firms, setFirms] = useState<FirmOption[]>(firm ? [firm] : []);
  const [advisers, setAdvisers] = useState<AgencyDto[]>([]);

  const formMethods = useForm({
    defaultValues: { firm: firm?.name ?? null, adviser: null as string | null },
    resolver: yupResolver(assignAgencySchema) as any,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const {
    formState: { isValid },
    reset,
    getValues,
    watch,
  } = formMethods;

  const activeFirm = watch('firm');

  const handleConfirm = useCallback(withBusyState(async () => {
    const { adviser: adviserId } = getValues();
    if (adviserId) {
      await onConfirm(adviserId);
      reset();
    }
  }), [getValues, onConfirm]);

  const searchFirms = useCallback((async (stem: string): Promise<string[]> => {
    const response = await agencyApi.searchFirms(stem, { size: 100, from: 0 });
    setFirms(response);
    return response.map((item) => item?.name ?? '');
  }), [setFirms]);

  const searchAdvisers = useCallback(withAdviserBusyState(async (firmId: number) => {
    const response = await agencyApi.searchAdvisors(firmId, { size: 100, from: 0 });
    response.sort(compareByName);
    setAdvisers(response);
  }), [setAdvisers]);

  useEffect(() => {
    setAdvisers([]);
    const foundFirm = firms.find((f) => f?.name === activeFirm);
    if (foundFirm?.frn) {
      searchAdvisers(foundFirm.frn);
    }
  }, [activeFirm]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="owner-dialog-prompt"
      aria-describedby="owner-dialog-description"
      {...props}
    >
      <DialogTitle id="owner-dialog-prompt">
        <Typography variant="h3" component="span">{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 450, maxWidth: 450 }}>
        <FormProvider {...formMethods}>
          <Stack gap={2}>
            {firm && firmReadOnly
              ? (
                <Stack direction="row" gap={2} marginTop={2}>
                  <Typography variant="h4" component="p">{t('components.ownerDialog.firmReadOnly')}</Typography>
                  <Typography variant="body1">{activeFirm}</Typography>
                </Stack>
              )
              : (
                <TypeaheadField
                  name="firm"
                  label={t('components.ownerDialog.firm')}
                  search={searchFirms}
                  filterOptions={() => firms.map((f) => f?.name ?? '')}
                  renderOption={(optionProps, option) => (
                    <li {...optionProps} key={option}>
                      {option}
                    </li>
                  )}
                />
              )}
            <SelectField
              name="adviser"
              options={advisers.map((adviser) => ({ value: adviser.id, label: adviser.name }) as Option)}
              disabled={loadingAdvisers || !activeFirm || !advisers}
              label={t('components.ownerDialog.adviser')}
              fullWidth
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{
        p: 2,
        pl: 3,
        pr: 3,
        justifyContent: 'space-between',
      }}
      >
        <Button onClick={onClose} color="secondary">
          {t('common.cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          autoFocus
          disabled={!isValid}
          loading={busy}
        >
          {confirmLabel ?? t('common.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default OwnerDialog;
