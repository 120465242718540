import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataRow, Section } from './personal-information';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { SectionStatus } from '../../../../services/models/section-status';
import { View, contactDetailsView } from '../../../view';
import { DetailsDivider, EditButton } from './client-details-bar.styles';
import { useAppSelector } from '../../../../store/hooks';
import { selectAddressFor } from '../../../../features/application-slice';
import Address from '../../../../components/address';

export interface ContactInformationProps {
  applicant: ApplicantDto;
  status?: SectionStatus;
  onEdit?: (view: View) => void;
}

function ContactInformation({
  applicant,
  status = undefined,
  onEdit = undefined,
}: ContactInformationProps) {
  const { t } = useTranslation();
  const address = useAppSelector(selectAddressFor(applicant.id));
  return (
    <Section title={t('components.contactInformation.title')} status={status}>
      <DetailsDivider />
      <DataRow label={t('components.contactInformation.address')} value={<Address address={address} />} />
      <DataRow label={t('components.contactInformation.postcode')} value={address?.postcode} />
      <DataRow label={t('components.contactInformation.emailAddress')} value={applicant.contactDetails?.emailAddress ?? null} />
      <DataRow label={t('components.contactInformation.phoneNumber')} value={applicant.contactDetails?.phoneNumber ?? null} sx={{ mb: 2 }} />
      {onEdit && (
        <Box sx={{ marginTop: 'auto' }}>
          <EditButton variant="outlined" color="primary" onClick={() => onEdit(contactDetailsView())}>
            {t('common.edit')}
          </EditButton>
        </Box>
      )}
    </Section>
  );
}

export default ContactInformation;
