import React from 'react';
import FieldBase, { FieldBaseProps } from './field-base';
import { SelectTypeaheadInput, SelectTypeaheadInputProps } from '../inputs';
import { SelectOption } from '../../types';

export interface SelectTypeaheadFieldProps<
  Value extends SelectOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends SelectTypeaheadInputProps<Value, Multiple, DisableClearable, FreeSolo>, FieldBaseProps { }

function SelectTypeaheadField<Value extends SelectOption>({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: SelectTypeaheadFieldProps<Value, false, boolean, boolean>) {
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <SelectTypeaheadInput name={name} {...props} />
    </FieldBase>
  );
}

export default SelectTypeaheadField;
