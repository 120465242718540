import React, { useCallback, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../features/settings-slice';
import { selectApplication, updateApplicationOwner } from '../../../../features/application-slice';
import { Role, userHasAnyRoleOf } from '../../../../services/profile-helpers';
import { ApplicationPage } from '../../../pages';
import OwnerDialog from '../../../../components/owner-dialog';
import { toastSuccess, toastError } from '../../../../components/toast';

function AssignApplicationPopup() {
  const { t } = useTranslation();
  const [dismissed, setDismissed] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const application = useAppSelector(selectApplication);
  const isParaplanner = userHasAnyRoleOf(user, [Role.PARAPLANNER]);
  const onApplicationPage = useMatch(ApplicationPage.path);
  const open = !dismissed && onApplicationPage && !application.agency?.adviserId && isParaplanner;
  const firm = { frn: application.agency?.firmFRN, name: application.agency?.firmName };

  const handleConfirm = useCallback(async (adviserId: string) => {
    if (adviserId) {
      try {
        await dispatch(updateApplicationOwner(application.id, adviserId));
        toastSuccess('Application assigned successfully');
      } catch {
        toastError('Failed to assign application');
      }
    }
  }, [application.id]);

  useEffect(() => {
    setDismissed(false);
  }, [application.id]);

  return open && (
    <OwnerDialog
      open
      title={t('components.assignApplicationPopup.title')}
      firm={firm}
      firmReadOnly={isParaplanner}
      onConfirm={handleConfirm}
      onClose={() => setDismissed(true)}
    />
  );
}

export default AssignApplicationPopup;
