import React, { ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from '@mui/material';

export function convertToId(value: string | null) {
  return value?.toLowerCase().replaceAll(' ', '-');
}

export interface LabelAndValueProps extends BoxProps {
  label: string;
  value: ReactNode;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
}

function LabelAndValue({
  label,
  value,
  labelProps = undefined,
  valueProps = undefined,
  ...props
}: LabelAndValueProps) {
  const id = props.id || convertToId(label);
  const labelId = `${id}-label`;
  return (
    <Box data-testid={id} {...props}>
      <Typography id={labelId} {...labelProps}>{label}</Typography>
      <Typography aria-labelledby={labelId} fontWeight="bold" {...valueProps}>{value}</Typography>
    </Box>
  );
}

export default LabelAndValue;
