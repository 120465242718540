import React from 'react';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DecisionType } from '../../../../services/product-helpers';
import {
  formatEmLoading,
  formatPmLoading,
  hasAnyLoadings,
  reasonDecisionHasNoInd,
} from '../../../../services/decision-helpers';
import Duration from '../../../../components/duration';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { EmRatingValue } from './reason-decision.styles';
import { TextWithInfo } from '../../../../components/info';

export interface ReasonDecisionRatingsProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionRatings({ id, reasonDecision }: ReasonDecisionRatingsProps) {
  const { t } = useTranslation();
  const noLoadings = !hasAnyLoadings(reasonDecision);
  if (noLoadings && reasonDecision.decision !== DecisionType.NON_STANDARD) {
    return null;
  }
  const { em, pm } = reasonDecision;
  const labelId = `${id}_rating`;
  const hasInd = reasonDecisionHasNoInd(reasonDecision);

  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {t('components.reasonDecisionCard.rating')}
      </Typography>
      {hasInd ? (
        <TextWithInfo
          variant="body2"
          fontWeight="bold"
          info={t('components.deferredPeriods.unavailableInfo')}
          aria-labelledby={labelId}
        >
          {t('components.deferredPeriods.unavailable')}
        </TextWithInfo>
      ) : (
        <Stack gap={1} aria-labelledby={labelId}>
          {(em?.permanent?.total || noLoadings) && (
            <EmRatingValue className="em-value">
              {formatEmLoading(em?.permanent?.total)}
            </EmRatingValue>
          )}
          {em?.temporary?.total && (
            <Box>
              <EmRatingValue className="em-value">
                {formatEmLoading(em?.temporary?.total)}
              </EmRatingValue>
              <Duration value={em.temporary.duration} noSpace />
            </Box>
          )}
          {pm?.permanent?.total && (
            <Typography variant="body2" fontWeight="bold">
              {formatPmLoading(pm?.permanent?.total)}
            </Typography>
          )}
          {pm?.temporary?.total && (
            <Box>
              <Typography variant="body2" fontWeight="bold">
                {formatPmLoading(pm?.temporary?.total)}
              </Typography>
              <Duration value={pm.temporary.duration} noSpace />
            </Box>
          )}
        </Stack>
      )}
    </section>
  );
}

export default ReasonDecisionRatings;
