import {
  AuthenticationResultType,
  AuthFlowType,
  CognitoIdentityProviderClient,
  ConfirmDeviceCommand,
  DeviceSecretVerifierConfigType,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Buffer } from 'buffer';
import moment from 'moment';
import { Sha256 } from '@aws-crypto/sha256-js';
import { adviserAwsconfig, AuthSession, LoginSuccess } from './auth-api';
import AuthenticationHelper from './aws-auth-helpers/AuthenticationHelper';
import BigInteger from './aws-auth-helpers/utils/BigInteger';
import getNowString from './aws-auth-helpers/utils/date';
import { authCookieExpiry } from '../exports';
import { todayDate } from '../utils/converters';

class CognitoAuthSession extends AuthSession {
  protected sessionString: string | undefined;

  protected client?: CognitoIdentityProviderClient;

  protected verifierDevices: string | undefined;

  protected deviceGroupKey: string | undefined;

  protected randomPassword: string | undefined;

  protected keyPrefix: string;

  protected deviceKey: string | null | undefined;

  constructor(username: string) {
    super(username);
    this.keyPrefix = `CognitoIdentityServiceProvider.${this.userPool.getClientId()}`;
  }

  async signInMFA(password: string): Promise<LoginSuccess> {
    this.password = null;
    this.deviceKey = localStorage.getItem(`${this.getKeyprefix()}.deviceKey`);

    const deviceExpiry = localStorage.getItem(`${this.getKeyprefix()}.deviceExpiry`);
    if (deviceExpiry && moment(atob(deviceExpiry)).isValid() && todayDate().isAfter(moment(atob(deviceExpiry)))) {
      this.deviceKey = undefined;
    }

    this.client = new CognitoIdentityProviderClient({
      region: adviserAwsconfig.aws_region,
    });

    const authenticationHelper = new AuthenticationHelper(
      this.userPool.getUserPoolName(),
    );

    const srpA = await new Promise<string>((resolve, reject) => {
      authenticationHelper.getLargeAValue((errOnAValue: Error | null, aValue: BigInteger) => {
        if (errOnAValue) {
          reject(errOnAValue);
        }
        resolve(aValue.toString(16));
      });
    });

    const authParameters = {
      PASSWORD: password,
      USERNAME: this.username,
      SRP_A: srpA,
    };

    const initiateAuthCommandInput: InitiateAuthCommandInput = {
      AuthFlow: AuthFlowType.USER_SRP_AUTH,
      AuthParameters: this.deviceKey ? { ...authParameters, DEVICE_KEY: this.deviceKey } : authParameters,
      ClientId: adviserAwsconfig.aws_user_pools_web_client_id,
    };

    const initiateAuthCommand = new InitiateAuthCommand(initiateAuthCommandInput);
    const authResponse = await this.client.send(initiateAuthCommand);

    if (!authResponse.ChallengeParameters) {
      throw new Error();
    }

    const serverBValue = new BigInteger(authResponse.ChallengeParameters.SRP_B, 16);
    const salt = new BigInteger(authResponse.ChallengeParameters.SALT, 16);

    const userHkdf = await new Promise<string>((resolve, reject) => {
      authenticationHelper.getPasswordAuthenticationKey(
        this.username,
        password,
        serverBValue,
        salt,
        (errOnHkdf: Error | null, hkdf: string) => {
          if (errOnHkdf) {
            reject(errOnHkdf);
          }
          resolve(hkdf);
        },
      );
    });

    const dateNow = getNowString();

    const concatBuffer: Buffer = Buffer.concat([
      Buffer.from(this.userPool.getUserPoolName(), 'utf8') as unknown as Uint8Array,
      Buffer.from(this.username, 'utf8') as unknown as Uint8Array,
      Buffer.from(authResponse.ChallengeParameters.SECRET_BLOCK, 'base64') as unknown as Uint8Array,
      Buffer.from(dateNow, 'utf8') as unknown as Uint8Array,
    ]);

    const awsCryptoHash = new Sha256(userHkdf);
    awsCryptoHash.update(concatBuffer);

    const resultFromAWSCrypto = awsCryptoHash.digestSync();
    const signatureString = Buffer.from(resultFromAWSCrypto).toString('base64');

    const challengeResponses = {
      USERNAME: this.username,
      PASSWORD_CLAIM_SECRET_BLOCK: authResponse.ChallengeParameters.SECRET_BLOCK,
      TIMESTAMP: dateNow,
      PASSWORD_CLAIM_SIGNATURE: signatureString,
    };

    const respondToChallengeCommand = new RespondToAuthChallengeCommand({
      ClientId: adviserAwsconfig.aws_user_pools_web_client_id,
      ChallengeName: authResponse.ChallengeName,
      ChallengeResponses: this.deviceKey ? { ...challengeResponses, DEVICE_KEY: this.deviceKey } : challengeResponses,
    });

    let challengeResponse;
    try {
      challengeResponse = await this.client.send(respondToChallengeCommand);
    } catch (error: unknown) {
      if (error instanceof Error && error.message === 'Device does not exist.') {
        respondToChallengeCommand.input.ChallengeResponses = challengeResponses;
        challengeResponse = await this.client.send(respondToChallengeCommand);
      } else {
        throw error;
      }
    }

    if (!challengeResponse) {
      throw new Error('Invalid login');
    }

    if (challengeResponse.Session) {
      this.user.Session = challengeResponse.Session;
    }

    if (challengeResponse.ChallengeName === 'EMAIL_OTP') {
      this.password = password;
      this.sessionString = challengeResponse.Session;
      return {
        firstLogin: false,
        isValid: false,
        mfaRequired: true,
        mfaChallengeParams: challengeResponse.ChallengeParameters,
        session: challengeResponse.Session,
      };
    }

    if (challengeResponse.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      let userAttributes = null;
      if (challengeResponse.ChallengeParameters) {
        userAttributes = JSON.parse(
          challengeResponse.ChallengeParameters.userAttributes,
        );
      }

      return {
        firstLogin: true,
        isValid: false,
        userAttr: userAttributes,
      };
    }

    if (challengeResponse.ChallengeName === 'DEVICE_SRP_AUTH') {
      const keyPrefix = this.getKeyprefix();

      const deviceKey = localStorage.getItem(`${keyPrefix}.deviceKey`);
      const deviceGroupKey = localStorage.getItem(`${keyPrefix}.deviceGroupKey`);
      const randomPassword = localStorage.getItem(`${keyPrefix}.randomPasswordKey`);

      if (!deviceKey || !deviceGroupKey || !randomPassword) {
        throw new Error('No device key');
      }

      this.deviceKey = deviceKey;

      const deviceAuthenticationHelper = new AuthenticationHelper(deviceGroupKey);

      const deviceSrpA = await new Promise<string>((resolve, reject) => {
        deviceAuthenticationHelper.getLargeAValue((errAValue: Error | null, aValue: BigInteger) => {
          if (errAValue) {
            reject(errAValue);
          }
          resolve(aValue.toString(16));
        });
      });

      const respondToAuthChallengeCommandInput: RespondToAuthChallengeCommandInput = {
        ChallengeName: 'DEVICE_SRP_AUTH',
        ClientId: adviserAwsconfig.aws_user_pools_web_client_id,
        ChallengeResponses: {
          USERNAME: this.username,
          DEVICE_KEY: this.deviceKey,
          SRP_A: deviceSrpA,
        },
        Session: challengeResponse.Session,
      };
      const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand(respondToAuthChallengeCommandInput);

      const deviceChallengeResponse = await this.client.send(respondToAuthChallengeCommand);

      if (!deviceChallengeResponse.ChallengeParameters) {
        throw new Error();
      }

      const deviceServerBValue = new BigInteger(deviceChallengeResponse.ChallengeParameters.SRP_B, 16);
      const deviceSalt = new BigInteger(deviceChallengeResponse.ChallengeParameters.SALT, 16);

      const deviceHkdf = await new Promise<string>((resolve, reject) => {
        deviceAuthenticationHelper.getPasswordAuthenticationKey(
          deviceKey,
          randomPassword,
          deviceServerBValue,
          deviceSalt,
          (errHkdf: Error | null, hkdf: string) => {
            if (errHkdf) {
              reject(errHkdf);
            }
            resolve(hkdf);
          },
        );
      });

      const deviceDateNow = getNowString();

      const deviceConcatBuffer = Buffer.concat([
        Buffer.from(deviceGroupKey, 'utf8') as unknown as Uint8Array,
        Buffer.from(deviceKey, 'utf8') as unknown as Uint8Array,
        Buffer.from(deviceChallengeResponse.ChallengeParameters.SECRET_BLOCK, 'base64') as unknown as Uint8Array,
        Buffer.from(deviceDateNow, 'utf8') as unknown as Uint8Array,
      ]);

      const deviceAwsCryptoHash = new Sha256(deviceHkdf);
      deviceAwsCryptoHash.update(deviceConcatBuffer);

      const deviceResultFromAWSCrypto = deviceAwsCryptoHash.digestSync();
      const deviceSignatureString = Buffer.from(deviceResultFromAWSCrypto).toString('base64');

      const respondToDeviceAuthChallengeCommandInput: RespondToAuthChallengeCommandInput = {
        ChallengeName: 'DEVICE_PASSWORD_VERIFIER',
        ClientId: adviserAwsconfig.aws_user_pools_web_client_id,
        ChallengeResponses: {
          USERNAME: deviceChallengeResponse.ChallengeParameters?.USERNAME ?? this.username,
          PASSWORD_CLAIM_SECRET_BLOCK: deviceChallengeResponse.ChallengeParameters.SECRET_BLOCK,
          TIMESTAMP: deviceDateNow,
          PASSWORD_CLAIM_SIGNATURE: deviceSignatureString,
          DEVICE_KEY: this.deviceKey,
        },
        Session: challengeResponse.Session,
      };
      const respondToDeviceAuthChallengeCommand = new RespondToAuthChallengeCommand(respondToDeviceAuthChallengeCommandInput);
      const devicePasswordResponse = await this.client.send(respondToDeviceAuthChallengeCommand);

      if (!devicePasswordResponse || !devicePasswordResponse.AuthenticationResult) {
        throw new Error('No authentication response');
      }

      this.signInUserSession(devicePasswordResponse?.AuthenticationResult);

      return { firstLogin: false, isValid: true };
    }

    if (challengeResponse.AuthenticationResult) {
      this.signInUserSession(challengeResponse?.AuthenticationResult);
      return { firstLogin: false, isValid: true };
    }
    throw new Error('Invalid login');
  }

  async sendMFACode(code: string) {
    const input = new RespondToAuthChallengeCommand({
      ClientId: adviserAwsconfig.aws_user_pools_web_client_id,
      Session: this.sessionString,
      ChallengeName: 'EMAIL_OTP',
      ChallengeResponses: {
        EMAIL_OTP_CODE: code,
        USERNAME: this.username,
      },
    });

    const mfaResponse = await this.client?.send(input);

    if (!mfaResponse || !mfaResponse.AuthenticationResult) {
      throw new Error('No authentication response');
    }

    const deviceKey = mfaResponse?.AuthenticationResult?.NewDeviceMetadata?.DeviceKey;
    const deviceGroupKey = mfaResponse?.AuthenticationResult?.NewDeviceMetadata?.DeviceGroupKey;

    if (!deviceKey || !deviceGroupKey) {
      throw new Error('No device key');
    }

    this.signInUserSession(mfaResponse?.AuthenticationResult);

    const keyPrefix = this.getKeyprefix();

    const authenticationHelper = new AuthenticationHelper(this.userPool.getUserPoolName());

    const deviceSecretVerifierConfig = await new Promise<DeviceSecretVerifierConfigType>((resolve, reject) => {
      authenticationHelper.generateHashDevice(
        deviceGroupKey,
        deviceKey,
        async (errGenHash: Error | null) => {
          if (errGenHash) {
            reject(errGenHash);
          }

          const verifierConfig = {
            Salt: Buffer.from(
              authenticationHelper.getSaltDevices(),
              'hex',
            ).toString('base64'),
            PasswordVerifier: Buffer.from(
              authenticationHelper.getVerifierDevices(),
              'hex',
            ).toString('base64'),
          };

          resolve(verifierConfig);
        },
      );
    });

    this.verifierDevices = deviceSecretVerifierConfig.PasswordVerifier;
    this.deviceGroupKey = deviceGroupKey;
    this.randomPassword = authenticationHelper.getRandomPassword();
    const cookieExpiry: string = authCookieExpiry.toString() as any;

    localStorage.setItem(`${keyPrefix}.deviceKey`, deviceKey);
    localStorage.setItem(`${keyPrefix}.deviceGroupKey`, deviceGroupKey);
    localStorage.setItem(`${keyPrefix}.randomPasswordKey`, this.randomPassword);
    localStorage.setItem(`${keyPrefix}.deviceExpiry`, btoa(todayDate().add(cookieExpiry, 'days').toISOString()));

    const confirmDeviceCommand = new ConfirmDeviceCommand({
      AccessToken: mfaResponse?.AuthenticationResult?.AccessToken,
      DeviceKey: deviceKey,
      DeviceName: navigator.userAgent,
      DeviceSecretVerifierConfig: deviceSecretVerifierConfig,
    });

    await this.client?.send(confirmDeviceCommand);
    return { firstLogin: false, isValid: true };
  }

  async newPassword(password: string, userAttr: any): Promise<LoginSuccess> {
    this.client = new CognitoIdentityProviderClient({
      region: adviserAwsconfig.aws_region,
    });

    const authenticationHelper = new AuthenticationHelper(
      this.userPool.getUserPoolName(),
    );
    const userAttributesPrefix = authenticationHelper.getNewPasswordRequiredChallengeUserAttributePrefix();

    const finalUserAttributes: Record<string, string> = {};
    if (userAttr) {
      Object.keys(userAttr).forEach((key) => {
        finalUserAttributes[`${userAttributesPrefix}${key}`] = userAttr[key];
      });
    }

    finalUserAttributes.NEW_PASSWORD = password;
    finalUserAttributes.USERNAME = this.username;

    const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand({
      ClientId: adviserAwsconfig.aws_user_pools_web_client_id,
      Session: this.user.Session,
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ChallengeResponses: finalUserAttributes,
    });

    const challengeResponse = await this.client.send(respondToAuthChallengeCommand);

    if (challengeResponse.ChallengeName === 'EMAIL_OTP') {
      this.password = password;
      this.sessionString = challengeResponse.Session;

      if (challengeResponse.Session) {
        this.user.Session = challengeResponse.Session;
      }

      return {
        firstLogin: false,
        isValid: false,
        mfaRequired: true,
        mfaChallengeParams: challengeResponse.ChallengeParameters,
        session: challengeResponse.Session,
      };
    }

    if (challengeResponse.Session) {
      this.user.Session = challengeResponse.Session;
      return { firstLogin: false, isValid: true };
    }

    return { firstLogin: false, isValid: false };
  }

  signInUserSession(authenticationResult: AuthenticationResultType) {
    const idToken = new CognitoIdToken({
      IdToken: authenticationResult.IdToken ?? '',
    });
    const accessToken = new CognitoAccessToken({
      AccessToken: authenticationResult.AccessToken ?? '',
    });
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: authenticationResult.RefreshToken ?? '',
    });

    const userSession = new CognitoUserSession({
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    });

    this.user.setSignInUserSession(userSession);
  }

  getKeyprefix() {
    return `CognitoIdentityServiceProvider.${adviserAwsconfig.aws_user_pools_web_client_id}.${this.username}`;
  }
}

export default CognitoAuthSession;
