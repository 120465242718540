import { useNavigate as useNavigateRR, NavigateOptions } from 'react-router-dom';
import { UaEventOptions } from 'react-ga4/types/ga4';
import analytics from '../../services/analytics';

interface NavigateFunctionWithEvent {
  (to: string, options?: NavigateOptions, trackingOptions?: UaEventOptions): void;
}

const useNavigateWithEvent = (): NavigateFunctionWithEvent => {
  const navigate = useNavigateRR();
  function navigateWithEvent(to: string, options: NavigateOptions, trackingOptions: UaEventOptions) {
    analytics.trackEvent(trackingOptions);
    navigate(to, options);
  }

  return navigateWithEvent as NavigateFunctionWithEvent;
};

export default useNavigateWithEvent;
