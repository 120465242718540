import React from 'react';
import {
  Box,
  Card,
  CardHeader,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { getApplicantName } from '../../../services/application-helpers';
import { ApplicantDto } from '../../../services/models/applicant-dto';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { applicantSelected, selectEnquiryFor } from '../../../features/application-slice';
import { getConditionEnquiryLines, getConditionSections } from '../../../services/enquiry-helpers';
import EnquiryLineReview from './enquiry-line-review';
import { CardTitleWithDetail } from '../../card-title';
import useProceed from '../../../hooks/use-proceed';
import { conditionView } from '../../../routes/view';
import { ApplicationPage } from '../../../routes/pages';

export interface DisclosuresReviewProps {
  applicationId: string;
  applicant: ApplicantDto;
  readonly?: boolean;
}

function DisclosuresReview({
  applicationId,
  applicant,
  readonly = false,
}: DisclosuresReviewProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const proceedToNextView = useProceed();
  const enquiry = useAppSelector(selectEnquiryFor(applicant.id));
  if (!enquiry) {
    return null;
  }
  const enquiryLines = getConditionSections(enquiry, true).flatMap((s) => getConditionEnquiryLines(s));
  const handleAmend = (conditionPath: string) => {
    dispatch(applicantSelected(applicant.id));
    navigate(generatePath(ApplicationPage.path, { applicationId }));
    proceedToNextView({ target: conditionView(conditionPath) });
  };
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h3' }}
        title={(
          <CardTitleWithDetail
            title={t('components.disclosuresReview.title')}
            detail={` - ${getApplicantName(applicant)}`}
          />
        )}
      />
      <Box>
        {enquiryLines.map((enquiryLine) => (
          <EnquiryLineReview
            key={enquiryLine.path}
            enquiry={enquiry}
            enquiryLine={enquiryLine}
            onAmend={readonly ? undefined : handleAmend}
          />
        ))}
      </Box>
    </Card>
  );
}

export default DisclosuresReview;
