import React from 'react';
import Typography from '@mui/material/Typography';
import { QuestionErrorContainer } from './enquiry.styles';

interface QuestionErrorProps {
  validationErrors?: string[] | null;
}

function QuestionError({
  validationErrors = null,
}: QuestionErrorProps) {
  if (validationErrors) {
    return (
      <QuestionErrorContainer>
        {validationErrors.map((validationError) => <Typography variant="body2" color="error" key={validationError}>{validationError}</Typography>)}
      </QuestionErrorContainer>
    );
  }
  return null;
}

export default QuestionError;
