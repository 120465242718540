import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Enquiry } from '../../../services/models/enquiry';
import useOptionList from '../../../hooks/use-option-list';
import { OptionListDto } from '../../../services/bff/models/option-list-dto';

interface EnquiryContext {
  enquiry: Enquiry;
  getOptionList: (optionListName: string) => Promise<OptionListDto>
}

interface EnquiryFormContextType extends EnquiryContext {}

const EnquiryFormContext = createContext<EnquiryFormContextType | null>(null);

export const useEnquiryFormContext = () => {
  const context = useContext(EnquiryFormContext);
  if (!context) {
    throw new Error('useEnquiryFormContext must be used within a EnquiryFormProvider');
  }
  return context;
};

interface EnquiryFormProviderProps {
  children: ReactNode;
  enquiry: Enquiry;
}

function EnquiryFormProvider({ children, enquiry }: EnquiryFormProviderProps) {
  const optionList = useOptionList(enquiry);
  const getOptionList = useCallback(async (optionListName: string) => optionList(optionListName), [enquiry]);

  const contextValue: EnquiryFormContextType = useMemo(() => ({
    enquiry,
    getOptionList,
  }), [enquiry]);

  return (
    <EnquiryFormContext.Provider value={contextValue}>
      {children}
    </EnquiryFormContext.Provider>
  );
}

export default EnquiryFormProvider;
