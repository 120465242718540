import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import iPad from '../../../../assets/images/dashboard/ipad.png';
import { Container } from './hero-panel.styles';

function HeroPanel({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  return (
    <Container>
      <Box>
        <img src={iPad} alt="" width="180" height="240" />
        <Stack gap={2} alignItems="flex-start">
          <Typography variant="h2" component="h1">
            {t('components.heroPanel.title')}
          </Typography>
          {children}
        </Stack>
      </Box>
    </Container>
  );
}

export default HeroPanel;
