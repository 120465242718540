import React, {
  PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import {
  ArrowButton,
  TableControlsContainer,
  TableControlIndicator,
  TableControlIndicatorContainer,
} from './table-controls.styles';

interface TableControlsProps extends PropsWithChildren {
  onBack: () => void
  onForward: () => void
  disableBack?: boolean
  disableForward?: boolean
}

export function TableControls({
  onBack,
  onForward,
  disableBack = false,
  disableForward = false,
  children,
}: TableControlsProps) {
  return (
    <TableControlsContainer direction="row" justifyContent="space-between" alignItems="center" id="table-controls">
      <ArrowButton disabled={disableBack} onClick={onBack} data-testid="periodBackBtn">
        <ArrowBackIcon />
      </ArrowButton>
      {children}
      <ArrowButton disabled={disableForward} onClick={onForward} data-testid="periodForwardBtn">
        <ArrowForwardIcon />
      </ArrowButton>
    </TableControlsContainer>
  );
}

interface TableIndicatorsProps {
  decisions: PreSalesDecision[]
  position: number
  displayColumns: number
}

export function TableIndicators({
  decisions,
  position,
  displayColumns,
}: TableIndicatorsProps) {
  const { t } = useTranslation();
  return (
    <TableControlIndicatorContainer>
      {decisions.map((decision, index) => (
        <TableControlIndicator
          key={decision.deferredPeriod}
          decision={decision.decision}
          active={position === index || (index >= position && index < (position + displayColumns))}
          aria-label={`${decision.deferredPeriod} week ${t(`common.decision.${decision.decision}`)}`}
        />
      ))}
    </TableControlIndicatorContainer>
  );
}

export default TableControls;
