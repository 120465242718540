import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { WithOnChangeCommitted } from '../../types';

export interface CheckboxInputProps<T> extends Omit<CheckboxProps, 'name' | 'onChange' | 'value' | 'checked'>, WithOnChangeCommitted<T> {
  checkedValue: T;
  uncheckedValue: T;
  name: string;
  label?: string | null;
  disabled?: boolean;
}

function CheckboxInput<T>({
  checkedValue,
  uncheckedValue,
  name,
  onChangeCommitted,
  label = undefined,
  disabled = undefined,
  ...props
}: CheckboxInputProps<T>) {
  const {
    field: { value, onChange, onBlur },
  } = useController({ name });
  const { resetField } = useFormContext();

  useEffect(() => {
    if (value !== checkedValue && value !== uncheckedValue) {
      resetField(name, { defaultValue: uncheckedValue });
      if (onChangeCommitted) {
        onChangeCommitted(name, uncheckedValue);
      }
    }
  }, [value, resetField]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? checkedValue : uncheckedValue;
    onChange(newValue);
    if (onChangeCommitted) {
      onChangeCommitted(name, newValue);
    }
  }, [onChange, onChangeCommitted, name, checkedValue, uncheckedValue]);
  return (
    <FormControlLabel
      name={name}
      label={label}
      disabled={disabled}
      control={(
        <Checkbox
          onChange={handleChange}
          value={checkedValue}
          name={name}
          checked={value === checkedValue}
          onBlur={onBlur}
          {...props}
        />
      )}
    />
  );
}

export default CheckboxInput;
