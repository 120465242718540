declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    black: string
    white: string
    green: string
    yellow: string
    yellow200: string
    red: string
    orange: string
    blue: string
    greyLight: string
    greyDark: string
    primary: string
    primary800: string
    primary600: string
    primary400: string
    primary200: string
    secondary: string
    secondary800: string
    secondary600: string
    secondary400: string
    secondary200: string
    surface: string
    surface1: string
    surface2: string
    surface3: string
    surface4: string
    surface5: string
    surface6: string
    surface7: string
    surface8: string
    surface9: string
    surface10: string
    background: string
    background1: string
    background2: string
    background3: string
    background4: string
  }
}

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  green: '#18A564',
  yellow: '#FFB800',
  yellow200: '#FBEA98',
  red: '#CA444B',
  orange: '#e98708',
  blue: '#133BC0',
  greyLight: '#8691A2',
  greyDark: '#2F2F2F',
  primary: '#FFD500',
  primary800: '#FEDC33',
  primary600: '#FDE466',
  primary400: '#FBEA98',
  primary200: '#FAF2CB',
  secondary: '#614C55',
  secondary800: '#806F77',
  secondary600: '#9E9299',
  secondary400: '#BCB3BA',
  secondary200: '#DAD6DC',
  surface: '#394554',
  surface1: '#3D4B5B',
  surface2: '#6B7A8C',
  surface3: '#8F9FB3',
  surface4: '#AABFCF',
  surface5: '#CAD4DC',
  surface6: '#D3DDE6',
  surface7: '#E0E7ED',
  surface8: '#F3F4F5',
  surface9: '#4C4C4C',
  surface10: '#F9F3EF',
  background: '#FFFFFF',
  background1: '#F8E4E9',
  background2: '#E1E7EE',
  background3: '#DEEFEA',
  background4: '#F5F5F5',
};

const variables = {
  colors,
};

export default variables;
