import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import {
  CheckboxField,
  MoneyField,
  NumberField,
  SelectField,
} from '../../../../components/form/fields';
import { FormContainerStyled } from './income-protection-form-presales-styles';
import { ProductFormProps } from '../../../../services/product-helpers';
import { PremiumStyle } from '../../../../services/models/premium-style';
import { InputInfoText } from '../../../../components/info';
import { CoverType } from '../../../../services/models/cover-type';
import { ClaimPeriod } from '../../../../services/models/claim-period';

function IncomeProtectionFormPreSales({
  onChangeCommitted,
}: ProductFormProps) {
  const { t } = useTranslation();
  return (
    <FormContainerStyled>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <MoneyField
          name="coverAmount"
          label={t('components.incomeProtectionForm.coverAmount')}
          onChangeCommitted={onChangeCommitted}
          min={0}
          sx={{ maxWidth: 180 }}
          hideError
        />
        <SelectField
          name="premiumStyle"
          label={t('components.incomeProtectionForm.premiumOption')}
          options={Object.values(PremiumStyle)}
          sx={{
            minWidth: 216,
          }}
          onChangeCommitted={onChangeCommitted}
          labelTranslationBasePath="components.incomeProtectionForm.premiumStyleOptions"
          hideError
          infoText={<InputInfoText {...t('components.incomeProtectionForm.infoText.premiumOption', { returnObjects: true })} />}
        />
        <CheckboxField
          label={t('components.incomeProtectionForm.coverType')}
          valueLabel={t('common.yes')}
          name="coverType"
          checkedValue={CoverType.Increasing}
          uncheckedValue={CoverType.Level}
          onChangeCommitted={onChangeCommitted}
          hideError
          infoText={<InputInfoText {...t('components.incomeProtectionForm.infoText.coverType', { returnObjects: true })} />}
        />
        <SelectField
          name="claimPeriod"
          label={t('components.incomeProtectionForm.claimPeriod')}
          options={Object.values(ClaimPeriod)}
          sx={{
            minWidth: 180,
          }}
          onChangeCommitted={onChangeCommitted}
          labelTranslationBasePath="components.incomeProtectionForm.claimPeriodOptions"
          hideError
        />
        <NumberField
          name="toAge"
          label={t('components.incomeProtectionForm.toAge')}
          min={0}
          inputProps={{ maxLength: 3 }}
          sx={{ maxWidth: 130 }}
          endAdornment={<InputAdornment position="end" disablePointerEvents>{t('common.toAge.yearsOld')}</InputAdornment>}
          onChangeCommitted={onChangeCommitted}
          hideError
        />
      </Stack>
    </FormContainerStyled>
  );
}

export default IncomeProtectionFormPreSales;
