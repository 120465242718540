import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import { OccupationLimitsContainer } from './occupation-limits.styles';
import { numberToMoney } from '../../../../utils/converters';

interface OccupationLimitsProps {
  decision: PreSalesDecision | null
}

function OccupationLimits({
  decision,
}: OccupationLimitsProps) {
  const { t } = useTranslation();

  if (!decision) return null;

  return (
    <OccupationLimitsContainer>
      <Typography variant="h4" gutterBottom>
        {t('components.occupationLimits.title')}
      </Typography>
      <Stack direction="row" gap={4}>
        <Box>
          <Typography variant="body2" component="label" id="maxExpiryAge">
            {t('components.occupationLimits.maxExpiryAge')}
          </Typography>
          <Typography variant="body1" id="maxExpiryAge" aria-labelledby="maxExpiryAge">
            {decision.occupationLimits?.maxExpiryAge}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" component="label" id="maxMonthlyBenefit">
            {t('components.occupationLimits.maxMonthlyBenefit')}
          </Typography>
          <Typography variant="body1" id="maxMonthlyBenefit" aria-labelledby="maxMonthlyBenefit">
            {decision.occupationLimits?.maxMonthlyBenefit && numberToMoney(decision.occupationLimits.maxMonthlyBenefit!)}
          </Typography>
        </Box>
      </Stack>

    </OccupationLimitsContainer>
  );
}

export default OccupationLimits;
