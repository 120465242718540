import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ReasonDecisionIndicativeDecisionProps {
  id: string;
  decision?: string | null;
}

function ReasonDecisionIndicativeDecision({ id, decision = undefined }: ReasonDecisionIndicativeDecisionProps) {
  const { t } = useTranslation();
  if (!decision) {
    return null;
  }
  const labelId = `${id}_indicative_decision`;
  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {t('components.reasonDecisionCard.indicativeDecision')}
      </Typography>
      <Typography variant="body2" aria-labelledby={labelId} fontWeight="bold">
        {t(`common.decision.${decision}`)}
      </Typography>
    </section>
  );
}

export default ReasonDecisionIndicativeDecision;
