import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckboxInput } from '../../../../components/form/inputs';
import { ConfirmStatements } from './payment-details.styles';

export interface BankDetailsConfirmationProps {
  readOnly?: boolean;
}

function BankDetailsConfirmation({ readOnly = false }: BankDetailsConfirmationProps) {
  const { t } = useTranslation();
  return (
    <Box>
      <CheckboxInput
        name="confirm"
        label={t('components.bankDetailsConfirmation.label')}
        checkedValue
        uncheckedValue={false}
        disabled={readOnly}
      />
      <ConfirmStatements>
        <ul>
          <li>{t('components.bankDetailsConfirmation.statements.0')}</li>
          <li>{t('components.bankDetailsConfirmation.statements.1')}</li>
          <li>{t('components.bankDetailsConfirmation.statements.2')}</li>
          <li>{t('components.bankDetailsConfirmation.statements.3')}</li>
        </ul>
      </ConfirmStatements>
    </Box>
  );
}

export default BankDetailsConfirmation;
