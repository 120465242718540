/* tslint:disable */
/* eslint-disable */
/**
 * ApplicationService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */

export const ApplicationStatus = {
    PreSale: 'preSale',
    Quote: 'quote',
    Apply: 'apply',
    Decision: 'decision',
    Underwriting: 'underwriting',
    Active: 'active',
    QuoteExpired: 'quoteExpired',
    PreSaleExpired: 'preSaleExpired',
    Expired: 'expired',
    New: 'new',
    Cancelled: 'cancelled',
} as const;

export type ApplicationStatus = typeof ApplicationStatus[keyof typeof ApplicationStatus];



