/* eslint-disable react/require-default-props */
import React, {
  PropsWithChildren,
  ReactNode,
  useRef,
} from 'react';
import { useController } from 'react-hook-form';
import {
  FormHelperText,
  FormControlProps as MuiFormControlProps,
  FormHelperTextProps as MuiFormHelperTextProps,
  FormLabelProps,
  FormLabel,
  InputLabel,
} from '@mui/material';
import { FormControl, FormInputContainer } from '../form.styles';
import FormErrorText from '../form-error-text';
import { InfoTooltip } from '../../info';

export interface FieldBaseProps extends PropsWithChildren {
  name: string;
  FormControlProps?: MuiFormControlProps;
  FormHelperTextProps?: MuiFormHelperTextProps;
  helperText?: ReactNode;
  infoText?: ReactNode;
  hideError?: boolean;
  label?: ReactNode;
  LabelProps?: FormLabelProps;
  sublabel?: ReactNode;
  SublabelProps?: FormLabelProps;
}

const FieldBase = React.forwardRef<HTMLDivElement, FieldBaseProps>(({
  children,
  FormControlProps,
  FormHelperTextProps,
  helperText,
  infoText,
  hideError = false,
  LabelProps,
  label,
  name,
  sublabel,
  SublabelProps,
}, ref) => {
  const {
    fieldState: { error, invalid },
  } = useController({ name });

  const inputRef = useRef();
  const helperTextId = helperText ? `${name}-helper-text` : undefined;
  const labelId = label ? `${name}-label` : undefined;
  const sublabelId = label ? `${name}-sublabel` : undefined;
  const errorTextId = error ? `${name}-error` : undefined;

  return (
    <FormControl
      {...FormControlProps}
      error={FormControlProps?.error ?? invalid}
      ref={ref}
    >
      {label != null && label !== '' && (
        <FormLabel htmlFor={name} id={labelId} {...LabelProps}>
          {label}
          {infoText && <InfoTooltip title={infoText} IconProps={{ fontSize: 'small' }} />}
        </FormLabel>
      )}
      {sublabel != null && sublabel !== '' && (
        <InputLabel htmlFor={!label ? name : undefined} id={sublabelId} {...SublabelProps}>
          {sublabel}
        </InputLabel>
      )}
      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
      <FormInputContainer ref={inputRef}>
        {children}
      </FormInputContainer>
      {!hideError && error?.message && (
        <FormErrorText id={errorTextId}>{error.message}</FormErrorText>
      )}
    </FormControl>
  );
});

export default FieldBase;
