import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardContent,
  CardHeader,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Skeleton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useBusyState from '../../../../hooks/use-busy-state';
import agencyApi from '../../../../services/agency-api';
import { InitialCommissionDto } from '../../../../services/bff/models/initial-commission-dto';
import { numberToMoney } from '../../../../utils/converters';
import { CommissionCard, CommissionList } from './commission-panel.styles';
import { SearchPage } from '../../../pages';

function getCommissionTotal(commissions: InitialCommissionDto[]): number {
  return commissions.reduce((acc, curr) => {
    const total = acc + curr.commissionTotal;
    return total;
  }, 0);
}

function CommissionPanel() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [busy, withBusyState] = useBusyState();
  const [commissions, setCommissions] = useState<InitialCommissionDto[]>();

  const init = useCallback(withBusyState(async () => {
    try {
      setError(false);
      setCommissions(await agencyApi.getCommissions());
    } catch (e) {
      setError(true);
      setCommissions([]);
    }
  }), []);

  const handleCommissionStatusClick = (status: string) => {
    navigate(SearchPage.path, { state: { status: status.toLowerCase() } });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <CommissionCard>
      <CardHeader
        sx={{ paddingX: 2 }}
        title={t('components.commissionPanel.title')}
      />
      <CardContent sx={{ p: 0 }}>
        {busy && <Skeleton variant="rectangular" width="100%" height={288} />}
        {error && <Typography sx={{ padding: 2 }}>{t('components.commissionPanel.error')}</Typography>}
        {!error && commissions && (
          <CommissionList
            subheader={(
              <ListSubheader component="div">
                <span>{t('components.commissionPanel.subTitle')}</span>
                <span>{t('components.commissionPanel.upTo')}</span>
              </ListSubheader>
            )}
          >
            {commissions.map((commission) => (
              <ListItemButton key={commission.status} onClick={() => handleCommissionStatusClick(commission.status)}>
                <ListItemText
                  primary={`${commission.status} (${commission.count})`}
                  secondary={numberToMoney(commission.commissionTotal)}
                />
              </ListItemButton>
            ))}
            <ListItem>
              <ListItemText
                primary={t('components.commissionPanel.total')}
                secondary={numberToMoney(getCommissionTotal(commissions))}
              />
            </ListItem>
          </CommissionList>
        )}
      </CardContent>
    </CommissionCard>
  );
}

export default CommissionPanel;
