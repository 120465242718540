/* tslint:disable */
/* eslint-disable */
/**
 * ApplicationService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PremiumStyle = {
    LevelGuaranteed: 'levelGuaranteed',
    AgeCostedGuaranteed: 'ageCostedGuaranteed',
    AgeCostedReviewable: 'ageCostedReviewable'
} as const;

export type PremiumStyle = typeof PremiumStyle[keyof typeof PremiumStyle];



