import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import { SelectField, TextField } from '../../../../components/form/fields';
import { Option, WithOnChangeCommitted } from '../../../../components/types';
import { validateDigitsOnly } from '../../../../components/form/form.utils';
import { nthNumber } from '../../../../utils/converters';
import { BankDetailsContainer } from './bank-details.styles';

const collectionDays: number[] = range(1, 29);

export interface BankDetailsProps extends WithOnChangeCommitted<string | number | null> {
  readOnly?: boolean;
}

function BankDetails({
  onChangeCommitted,
  readOnly = false,
}: BankDetailsProps) {
  const { t } = useTranslation();

  const collectionDayOptions: Option[] = useMemo(() => (
    collectionDays.map((day) => ({
      value: day.toString(),
      label: t('components.bankDetails.collectionDayOption', { day: nthNumber(day) }) ?? '',
    }))
  ), []);

  const handleCollectionDayChange = (name: string, value: string) => {
    if (onChangeCommitted) {
      onChangeCommitted(name, value ? parseInt(value, 10) : null);
    }
  };

  return (
    <BankDetailsContainer>
      <TextField
        name="accountCode"
        type="text"
        label={t('components.bankDetails.accountCode')}
        InputProps={{
          inputProps: { maxLength: 8 },
          sx: { maxWidth: 160 },
          readOnly,
        }}
        onChangeCommitted={onChangeCommitted}
        validateChange={validateDigitsOnly}
        hideError
      />
      <TextField
        name="sortCode"
        type="text"
        label={t('components.bankDetails.sortCode')}
        InputProps={{
          inputProps: { maxLength: 6 },
          sx: { maxWidth: 100, minWidth: 100 },
          readOnly,
        }}
        onChangeCommitted={onChangeCommitted}
        validateChange={validateDigitsOnly}
        hideError
      />
      <SelectField
        name="collectionDay"
        label={t('components.bankDetails.collectionDay')}
        options={collectionDayOptions}
        sx={{
          minWidth: 210,
        }}
        onChangeCommitted={handleCollectionDayChange}
        readOnly={readOnly}
        hideError
      />
    </BankDetailsContainer>
  );
}

export default BankDetails;
