import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ApplicationDto } from '../../../../services/models/application-dto';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import useBusyState from '../../../../hooks/use-busy-state';
import { patchBankDetails, updateThirdPartyPayer } from '../../../../features/application-slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { BankDetailsDto } from '../../../../services/models/bank-details-dto';
import { handleError } from '../../../../components/form/form.utils';
import {
  bankDetailsValidationSchema,
  confirmationValidationSchema,
  getApplicantName,
  thirdPartyBankAccountOwner,
} from '../../../../services/application-helpers';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { selectOnboardingToolSettings } from '../../../../features/settings-slice';
import { FormContainerStyled } from './payment-details.styles';
import { FormErrors } from '../../../../components/form';
import { RadioOptionsField } from '../../../../components/form/fields';
import DirectDebitPanel from './direct-debit-panel';
import DirectDebitMandatePanel from './direct-debit-mandate-panel';
import BankDetailsConfirmation from './bank-details-confirmation';
import BankDetails from './bank-details';
import ThirdPartyDetails from './third-party-details';
import ThirdPartyDrawer from './third-party-drawer';
import BankDetailsValidation from './bank-details-validation';

interface PaymentDetailsFormData {
  accountOwner?: string | null;
  sortCode?: string | null;
  accountCode?: string | null;
  collectionDay?: number | null;
  confirm: boolean;
}

const emptyBankDetails: BankDetailsDto = {
  bankAccountOwner: [],
  accountCode: null,
  sortCode: null,
  collectionDay: null,
  accountValidation: null,
};

function getAccountOwner(application: ApplicationDto): string | null {
  if (application.thirdPartyPayer) {
    return thirdPartyBankAccountOwner;
  }
  const { bankDetails } = application;
  if (!bankDetails) {
    return null;
  }
  if (bankDetails.bankAccountOwner && bankDetails.bankAccountOwner.length > 0) {
    return bankDetails.bankAccountOwner[0];
  }
  return null;
}

function toFormValues(application: ApplicationDto): PaymentDetailsFormData {
  const { bankDetails } = application;
  return {
    ...application.bankDetails,
    accountOwner: getAccountOwner(application),
    confirm: !!bankDetails?.accountValidation,
  };
}

export interface PaymentDetailsProps extends CardProps {
  application: ApplicationDto;
  applicants: ApplicantDto[];
  readOnly?: boolean;
}

function PaymentDetails({
  application,
  applicants,
  readOnly = false,
  ...props
}: PaymentDetailsProps) {
  const { t } = useTranslation();
  const queue = useRef(new SequentialTaskQueue()).current;
  const [busy, withBusyState] = useBusyState(queue);
  const [bankDetailsErrors, setBankDetailsErrors] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const bankDetailsForm = useForm({
    defaultValues: toFormValues(application),
    resolver: yupResolver(bankDetailsValidationSchema.concat(confirmationValidationSchema)) as any,
    mode: 'onBlur',
  });
  const validated = !!application.bankDetails?.accountValidation;
  const { errors, isValid } = bankDetailsForm.formState;
  const formErrors = Object.values(errors).map((e) => e.message).filter((e) => !!e) as string[];
  const isThirdParty = application.thirdPartyPayer;
  const accountNumber = bankDetailsForm.watch('accountCode');
  const sortCode = bankDetailsForm.watch('sortCode');
  const [editingThirdParty, setEditingThirdParty] = useState(false);

  const thirdPartyPayersEnabled = useAppSelector(selectOnboardingToolSettings)?.thirdPartyPayersEnabled;
  const accountOwnerOptions = useMemo(() => {
    const applicantOptions = applicants.map((applicant) => ({ label: getApplicantName(applicant), value: applicant.id }));
    return thirdPartyPayersEnabled
      ? [...applicantOptions, { label: t('components.paymentDetails.thirdPartyOwner'), value: thirdPartyBankAccountOwner }]
      : applicantOptions;
  }, [thirdPartyPayersEnabled, applicants]);

  const updateFormField = (key: keyof PaymentDetailsFormData, value: PaymentDetailsFormData[keyof PaymentDetailsFormData]) => {
    bankDetailsForm.resetField(key, { defaultValue: value });
    bankDetailsForm.setValue(key, value);
  };

  const updateBankDetails = withBusyState(queue.sequentialize(async (name: string, changes: Partial<BankDetailsDto>) => {
    const key = name as keyof PaymentDetailsFormData;
    try {
      await dispatch(patchBankDetails(application.id, changes));
      setBankDetailsErrors([]);
    } catch (e) {
      handleError(e, (message) => {
        bankDetailsForm.clearErrors(key);
        bankDetailsForm.setError(key, { message });
        setBankDetailsErrors([message]);
      });
    }
  }));

  const handleFieldChange = (name: string, value: unknown) => {
    updateBankDetails(name, { [name as keyof BankDetailsDto]: value });
    updateFormField(name as keyof PaymentDetailsFormData, value as PaymentDetailsFormData[keyof PaymentDetailsFormData]);
  };

  const handleAccountOwnerChange = async (name: string, value: string) => {
    if (value === thirdPartyBankAccountOwner) {
      await updateBankDetails('accountOwner', {
        ...emptyBankDetails,
      });
      await dispatch(updateThirdPartyPayer(application.id, {}));
      updateFormField('accountOwner', thirdPartyBankAccountOwner);
      updateFormField('accountCode', null);
      updateFormField('sortCode', null);
      updateFormField('collectionDay', null);
      updateFormField('confirm', null);
    } else {
      await dispatch(updateThirdPartyPayer(application.id, null));
      await updateBankDetails('accountOwner', {
        ...emptyBankDetails,
        bankAccountOwner: [value],
      });
      updateFormField('accountOwner', value);
    }
  };

  useEffect(() => {
    const { bankDetails, thirdPartyPayer } = application;
    if (applicants.length === 1
      && !thirdPartyPayer
      && (!bankDetails?.bankAccountOwner || bankDetails.bankAccountOwner.length === 0)) {
      handleAccountOwnerChange('accountOwner', applicants[0].id);
    }
  }, []);

  return (
    <>
      <Card {...props}>
        <CardHeader
          title={t('components.paymentDetails.title')}
          titleTypographyProps={{
            variant: 'h3',
          }}
          sx={{ pt: 3, pb: 3 }}
        />
        <CardContent>
          <FormProvider {...bankDetailsForm}>
            <FormContainerStyled>
              <Grid container>
                <Grid item sm={12} md={12} lg={7} paddingRight={2} paddingBottom={2}>
                  <Stack direction="column" gap={2}>
                    <Typography variant="h6" component="h3">{t('components.paymentDetails.bankDetails')}</Typography>
                    <FormErrors errors={bankDetailsErrors.concat(formErrors)} />
                    <RadioOptionsField
                      label={t('components.paymentDetails.accountName')}
                      name="accountOwner"
                      options={accountOwnerOptions}
                      row
                      sx={{
                        minWidth: 200,
                      }}
                      onChangeCommitted={handleAccountOwnerChange}
                      hideError
                      disabled={readOnly || (validated && !isThirdParty)}
                    />
                    {!isThirdParty && (
                      <>
                        <BankDetails
                          onChangeCommitted={handleFieldChange}
                          readOnly={readOnly || validated}
                        />
                        <BankDetailsConfirmation readOnly={readOnly || validated} />
                        <BankDetailsValidation
                          applicationId={application.id}
                          accountNumber={accountNumber}
                          sortCode={sortCode}
                          disabled={readOnly || !isValid || busy}
                          validated={validated}
                          queue={queue}
                          setErrors={setBankDetailsErrors}
                        />
                      </>
                    )}
                    {isThirdParty && (
                      <ThirdPartyDetails
                        application={application}
                        onEdit={() => setEditingThirdParty(true)}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  {isThirdParty
                    ? <DirectDebitMandatePanel application={application} showMandate={validated} />
                    : <DirectDebitPanel />}
                </Grid>
              </Grid>
            </FormContainerStyled>
          </FormProvider>
        </CardContent>
      </Card>
      {editingThirdParty && (
        <ThirdPartyDrawer
          application={application}
          open
          onClose={() => setEditingThirdParty(false)}
        />
      )}
    </>
  );
}

export default PaymentDetails;
