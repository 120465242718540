import React, { FormEvent, useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Drawer,
  DrawerProps,
  FormLabel,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardActions,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../../../components/drawer-card';
import {
  DateField,
  RadioOptionsField,
  TextField,
} from '../../../../components/form/fields';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  patchThirdPartyPayerAddress,
  patchBankDetails,
  updateThirdPartyPayer,
  selectAvailableApplication,
} from '../../../../features/application-slice';
import { ApplicationDto } from '../../../../services/models/application-dto';
import useBusyState from '../../../../hooks/use-busy-state';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import { handleError } from '../../../../components/form/form.utils';
import { AddressDto } from '../../../../services/models/address-dto';
import AddressLookupFields from '../../../../components/form/address';
import { BankDetailsDto } from '../../../../services/models/bank-details-dto';
import BankDetails from './bank-details';
import { confirmationValidationSchema, thirdPartyValidationSchema } from '../../../../services/application-helpers';
import BankDetailsConfirmation from './bank-details-confirmation';
import { ThirdPartyType } from '../../../../services/models/third-party-type';
import { FormContainer, FormErrors } from '../../../../components/form';
import BankDetailsValidation from './bank-details-validation';
import NameFields from '../../../../components/form/fields/name-fields';
import { ThirdPartyPayerDto } from '../../../../services/models/third-party-payer-dto';
import EnquiryFormProvider from '../../../../components/form/enquiry/enquiry-form-provider';

interface ThirdPartyFormData extends Omit<AddressDto, 'id'> {
  sortCode?: string | null;
  accountCode?: string | null;
  addressId?: string | null;
  collectionDay?: number | null;
  confirm: boolean;
  companyName?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  thirdPartyType?: string | null;
  emailAddress?: string | null;
  dateOfBirth?: string | null;
}

function getAddress(application: ApplicationDto): AddressDto | null {
  return application.thirdPartyPayer?.addressId
    ? application.addresses?.find((a) => a.id === application.thirdPartyPayer!.addressId) ?? null
    : null;
}

function toFormValues(application: ApplicationDto): ThirdPartyFormData {
  const { bankDetails, thirdPartyPayer } = application;
  const address = getAddress(application);
  return {
    ...thirdPartyPayer,
    sortCode: bankDetails?.sortCode,
    accountCode: bankDetails?.accountCode,
    collectionDay: bankDetails?.collectionDay,
    confirm: !!bankDetails?.accountValidation,
    address1: address?.address1,
    address2: address?.address2,
    cityName: address?.cityName,
    postcode: address?.postcode,
  };
}

function toThirdPartyPayer(formData: ThirdPartyFormData): ThirdPartyPayerDto {
  return {
    thirdPartyType: formData.thirdPartyType as ThirdPartyType | null,
    companyName: formData.companyName,
    title: formData.title,
    firstName: formData.firstName,
    lastName: formData.lastName,
    dateOfBirth: formData.dateOfBirth,
    emailAddress: formData.emailAddress,
    addressId: formData.addressId,
  };
}

export interface ThirdPartyDrawerProps extends DrawerProps {
  application: ApplicationDto;
  onClose: () => unknown;
}

function ThirdPartyDrawer({
  application,
  onClose,
  ...props
}: ThirdPartyDrawerProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const enquiry = Object.values(useAppSelector(selectAvailableApplication).enquiries)[0];
  const queue = useRef(new SequentialTaskQueue()).current;
  const [busy, withBusyState] = useBusyState(queue);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const validated = !!application.bankDetails?.accountValidation;

  const thirdPartyForm = useForm({
    defaultValues: toFormValues(application),
    resolver: yupResolver(thirdPartyValidationSchema.concat(confirmationValidationSchema)) as any,
    mode: 'onBlur',
  });
  const { isValid, isSubmitting, errors } = thirdPartyForm.formState;
  const formErrors = Object.values(errors).map((e) => e.message).filter((e) => !!e) as string[];
  const accountNumber = thirdPartyForm.watch('accountCode');
  const sortCode = thirdPartyForm.watch('sortCode');

  const updateFormField = (key: keyof ThirdPartyFormData, value: ThirdPartyFormData[keyof ThirdPartyFormData]) => {
    thirdPartyForm.resetField(key, { defaultValue: value });
    thirdPartyForm.setValue(key, value);
  };

  const updateBankDetails = withBusyState(queue.sequentialize(async (name: string, changes: Partial<BankDetailsDto>) => {
    const key = name as keyof ThirdPartyFormData;
    try {
      await dispatch(patchBankDetails(application.id, changes));
    } catch (e) {
      handleError(e, (message) => {
        thirdPartyForm.clearErrors(key);
        thirdPartyForm.setError(key, { message });
        setServerErrors([message]);
      });
    }
  }));

  const handleBankDetailsFieldChange = async (name: string, value: unknown) => {
    await updateBankDetails(name, { [name as keyof BankDetailsDto]: value });
    updateFormField(name as keyof ThirdPartyFormData, value as ThirdPartyFormData[keyof ThirdPartyFormData]);
  };

  const updateThirdPartyDetails = withBusyState(queue.sequentialize(async (name: string, changes: Partial<ThirdPartyPayerDto>) => {
    const key = name as keyof ThirdPartyFormData;
    try {
      await dispatch(updateThirdPartyPayer(application.id, toThirdPartyPayer({ ...thirdPartyForm.getValues(), ...changes })));
    } catch (e) {
      handleError(e, (message) => {
        thirdPartyForm.clearErrors(key);
        thirdPartyForm.setError(key, { message });
        setServerErrors([message]);
      });
    }
  }));

  const handleFieldChange = async (name: string, value: unknown) => {
    await updateThirdPartyDetails(name, { [name as keyof ThirdPartyPayerDto]: value });
    updateFormField(name as keyof ThirdPartyFormData, value as ThirdPartyFormData[keyof ThirdPartyFormData]);
  };

  const handlePayerTypeChange = async (name: string, value: string) => {
    if (value === ThirdPartyType.Business) {
      await updateThirdPartyDetails('thirdPartyType', {
        thirdPartyType: value,
        dateOfBirth: null,
        title: null,
        firstName: null,
        lastName: null,
      });
      updateFormField('thirdPartyType', value);
      updateFormField('dateOfBirth', null);
      updateFormField('title', null);
      updateFormField('firstName', null);
      updateFormField('lastName', null);
    } else if (value === ThirdPartyType.Individual) {
      await updateThirdPartyDetails('thirdPartyType', {
        thirdPartyType: value,
        companyName: null,
      });
      updateFormField('companyName', null);
    }
  };

  const handleAddressChange = withBusyState(queue.sequentialize(async (changes: Partial<AddressDto>) => {
    const keys = Object.keys(changes) as (keyof Omit<AddressDto, 'id'>)[];
    try {
      const address = await dispatch(patchThirdPartyPayerAddress(changes));
      updateFormField('addressId', address.id);
      keys.forEach((key) => updateFormField(key, changes[key]));
    } catch (e) {
      handleError(e, (message) => {
        thirdPartyForm.clearErrors(keys[0]);
        thirdPartyForm.setError(keys[0], { message });
      });
    }
  }));

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await queue.waitForPendingTasks();
    await thirdPartyForm.handleSubmit(() => onClose())(event);
  };

  return (
    <Drawer
      anchor="bottom"
      {...props}
    >
      <EnquiryFormProvider enquiry={enquiry}>
        <FormProvider {...thirdPartyForm}>
          <FormContainer onSubmit={handleSubmit}>
            <DrawerCard>
              <DrawerCardHeader
                title={(
                  <Breadcrumbs separator=">">
                    <span>{t('components.thirdParyDrawer.title1')}</span>
                    <span>{t('components.thirdParyDrawer.title2')}</span>
                  </Breadcrumbs>
                )}
                action={<CancelDrawerButton onClick={onClose} />}
              />
              <DrawerCardContent sx={{ paddingBottom: 0 }}>
                <Stack gap={2} marginBottom={2}>
                  <FormErrors errors={serverErrors.concat(formErrors)} />
                  <RadioOptionsField
                    label={t('components.thirdParyDrawer.payerType')}
                    name="thirdPartyType"
                    options={Object.values(ThirdPartyType)}
                    labelTranslationBasePath="components.thirdParyDrawer.thirdPartyType"
                    row
                    sx={{
                      minWidth: 200,
                    }}
                    onChangeCommitted={handlePayerTypeChange}
                    disabled={validated}
                    hideError
                  />
                  {application.thirdPartyPayer?.thirdPartyType === ThirdPartyType.Business && (
                    <TextField
                      name="companyName"
                      type="text"
                      label={t('components.thirdParyDrawer.name')}
                      InputProps={{
                        inputProps: { maxLength: 60 },
                        sx: { maxWidth: 528 },
                        readOnly: validated,
                      }}
                      onChangeCommitted={handleFieldChange}
                      hideError
                    />
                  )}
                  {application.thirdPartyPayer?.thirdPartyType === ThirdPartyType.Individual && (
                    <Box sx={{ marginBottom: 2 }}>
                      <FormLabel sx={{ display: 'block' }}>{t('components.thirdParyDrawer.name')}</FormLabel>
                      <NameFields onChangeCommitted={handleFieldChange} readOnly={validated} />
                    </Box>
                  )}
                  <TextField
                    name="emailAddress"
                    type="text"
                    label={t('components.thirdParyDrawer.email')}
                    InputProps={{
                      inputProps: { maxLength: 60 },
                      sx: { maxWidth: 528 },
                      readOnly: validated,
                    }}
                    onChangeCommitted={handleFieldChange}
                    hideError
                  />
                  {application.thirdPartyPayer?.thirdPartyType === ThirdPartyType.Individual && (
                    <DateField
                      name="dateOfBirth"
                      label={t('components.thirdParyDrawer.dateOfBirth')}
                      readOnly={validated}
                      disableFuture
                      onChangeCommitted={handleFieldChange}
                      hideError
                    />
                  )}
                  <Stack>
                    <FormLabel sx={{ mb: 0.5 }}>{t('components.thirdParyDrawer.address')}</FormLabel>
                    <AddressLookupFields
                      address={getAddress(application)}
                      onChange={handleAddressChange}
                      readOnly={validated}
                      hideError
                      hideHelpText
                      hideStatus
                    />
                  </Stack>
                  <BankDetails
                    onChangeCommitted={handleBankDetailsFieldChange}
                    readOnly={validated}
                  />
                  <BankDetailsConfirmation readOnly={validated} />
                  <BankDetailsValidation
                    applicationId={application.id}
                    accountNumber={accountNumber}
                    sortCode={sortCode}
                    disabled={!isValid || busy}
                    validated={validated}
                    queue={queue}
                    setErrors={setServerErrors}
                  />
                </Stack>
              </DrawerCardContent>
              <DrawerCardActions>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={!isValid || !validated}
                  loading={isSubmitting}
                >
                  {t('components.thirdParyDrawer.save')}
                </LoadingButton>
              </DrawerCardActions>
            </DrawerCard>
          </FormContainer>
        </FormProvider>
      </EnquiryFormProvider>
    </Drawer>
  );
}

export default ThirdPartyDrawer;
