import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { Layout, MainContent } from '../../components/layout';
import ApplicationHeader from '../application/components/application-layout/application-header';
import SectionHeader from '../../components/section-header';
import { useAppSelector } from '../../store/hooks';
import { selectAvailableApplication } from '../../features/application-slice';
import { getProductDecision, isImmediateDecision } from '../../services/decision-helpers';
import ProductReview from '../../components/review/product-review/product-review';
import DisclosuresReview from '../../components/review/disclosures-review/disclosures-review';
import useProceed from '../../hooks/use-proceed';
import InfoBarContent from '../application/components/info-bar-content';
import { ProceedOptions } from '../view';
import { ApplicationPage } from '../pages';
import { getJointApplicantProducts, getSingleApplicantProducts } from '../../services/product-helpers';
import ApplicantReview from '../../components/review/applicant-review/applicant-review';
import BankDetailsReview from '../../components/review/bank-details-review/bank-details-review';
import ReviewFooter from './components/footer/review-footer';
import { isUMEApplication } from '../../services/application-helpers';

function Review() {
  const { t } = useTranslation();
  const { application, quoteDecision } = useAppSelector(selectAvailableApplication);
  const products = application.products.filter((p) => isImmediateDecision(getProductDecision(quoteDecision, p.id)?.decision));
  const applicantsWithProducts = application.applicants.filter((a) => products.some((p) => p.applicantIds?.includes(a.id)));
  const proceed = useProceed();
  const navigate = useNavigate();
  const isUME = isUMEApplication(application.origin);

  const proceedToApplicationView = useCallback((options?: ProceedOptions) => {
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id }));
    proceed(options);
  }, [navigate, proceed, application.id]);

  return (
    <Layout
      header={<ApplicationHeader />}
      info={<InfoBarContent proceedToNextView={proceedToApplicationView} />}
      footer={<ReviewFooter applicationId={application.id} />}
    >
      <MainContent maxWidth={false} sx={{ flexDirection: 'column', gap: 2 }}>
        <SectionHeader>{t('components.review.title')}</SectionHeader>
        <Stack gap={4}>
          <BankDetailsReview application={application} />
          <Stack direction="row" flexWrap="wrap" gap={(theme) => theme.mixins.mainHorizontalSpacing}>
            <Stack flexGrow={1} flexBasis={1} gap={4}>
              {application.applicants.flatMap((applicant) => (
                getSingleApplicantProducts(products, applicant.id).map((product) => (
                  <ProductReview
                    key={`${applicant.id}-${product.id}`}
                    applicationId={application.id}
                    applicants={[applicant]}
                    product={product}
                    readonly={isUME}
                  />
                ))))}
              {getJointApplicantProducts(products, applicantsWithProducts).map((product) => (
                <ProductReview
                  key={product.id}
                  applicationId={application.id}
                  applicants={applicantsWithProducts}
                  product={product}
                  readonly={isUME}
                />
              ))}
              {applicantsWithProducts.map((applicant) => (
                <ApplicantReview
                  key={applicant.id}
                  application={application}
                  applicant={applicant}
                />
              ))}
            </Stack>
            <Stack flexGrow={1} flexBasis={1} gap={4}>
              {applicantsWithProducts.map((applicant) => (
                <DisclosuresReview
                  key={applicant.id}
                  applicationId={application.id}
                  applicant={applicant}
                  readonly={isUME}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </MainContent>
    </Layout>
  );
}

export default Review;
