import { Button, ButtonProps } from '@mui/material';
import React, { ReactNode } from 'react';
import useBreakpoint from '../../../../hooks/use-breakpoints';

export interface HeaderButtonProps extends ButtonProps {
  icon?: ReactNode
}

function HeaderButton({ icon = undefined, ...props }: HeaderButtonProps) {
  const isWideScreen = useBreakpoint('lg');
  if (!isWideScreen && icon) {
    return (
      <Button
        {...props}
        sx={{ ...props.sx, paddingX: 0 }}
        title={props.children?.toString()}
      >
        {icon}
      </Button>
    );
  }
  return (
    <Button
      startIcon={icon}
      {...props}
    />
  );
}

export default HeaderButton;
