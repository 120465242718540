/* tslint:disable */
/* eslint-disable */
/**
 * WorkbenchService
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */

export const EvidenceStatus = {
    New: 'new',
    Open: 'open',
    Requested: 'requested',
    Received: 'received',
    Waived: 'waived',
    Assessed: 'assessed',
    Rejected: 'rejected',
    Expired: 'expired',
} as const;

export type EvidenceStatus = typeof EvidenceStatus[keyof typeof EvidenceStatus];



