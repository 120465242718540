import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProgressStatus } from '../../../../services/models/progress-status';
import { EnquiryLinePanel as StyledDeclarationPanel } from './disclosure-panel.styles';
import useBusyState from '../../../../hooks/use-busy-state';
import EditButton from './edit-button';

export interface DeclarationPanelProps {
  title: string;
  status: ProgressStatus;
  disabled?: boolean;
  onEdit?: () => unknown;
}

function DeclarationPanel({
  title,
  status,
  disabled = undefined,
  onEdit = undefined,
}: DeclarationPanelProps) {
  const [busy, withBusyState] = useBusyState();
  const { t } = useTranslation();

  const handleEdit = withBusyState(onEdit);

  return (
    <StyledDeclarationPanel status={status} data-testid="enquiry-line">
      <Box gap={1}>
        <Typography variant="body1" component="h4">{title}</Typography>
      </Box>
      <EditButton
        onClick={handleEdit}
        status={status}
        disabled={disabled || busy}
      >
        {status === ProgressStatus.NotStarted ? t('components.disclosureSection.start') : t('components.disclosureSection.edit')}
      </EditButton>
    </StyledDeclarationPanel>
  );
}

export default DeclarationPanel;
