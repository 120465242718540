import React from 'react';
import { Typography, TypographyProps, styled } from '@mui/material';

const StyledSecionHeader = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: theme.spacing(1),
  textAlign: 'left',
  fontSize: '1.5rem',
  minHeight: 42,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2.5),
  },
}));

function SectionHeader(props: TypographyProps) {
  return <StyledSecionHeader variant="h3" {...props} />;
}

export default SectionHeader;
