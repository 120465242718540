import {
  TableContainer,
  styled,
} from '@mui/material';

export const StatusTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 4,
  border: `1px solid ${theme.palette.common.secondary200}`,
  '& table > tbody > tr:last-of-type > td': {
    borderBottom: 'none',
  },
}));

export default {};
