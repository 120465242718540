import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export const ProductsCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.secondary200}`,
}));

export const ProductsCardHeader = styled(CardHeader)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.common.secondary200}`,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  '& > .MuiCardHeader-action': {
    marginRight: theme.spacing(-1),
  },
}));

export const ProductsCardContent = styled(CardContent)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: theme.spacing(10),
  rowGap: theme.spacing(2),
  justifyContent: 'center',
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  '&:last-child': {
    paddingBottom: theme.spacing(4),
  },
  '> button': {
    minWidth: 176,
  },
}));

export default ProductsCard;
