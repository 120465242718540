import { styled } from '@mui/material/styles';
import {
  Chip,
  Container as MuiContainer,
  Table,
  TextField,
} from '@mui/material';

export const Container = styled(MuiContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  paddingLeft: 36,
  paddingRight: 36,
  display: 'flex',
  alignSelf: 'flex-start',
  gap: theme.spacing(3),
  flexDirection: 'column',
}));

export const SearchInput = styled(TextField)(({ theme }) => ({
  minWidth: 350,
  '& .MuiOutlinedInput-root': {
    paddingLeft: theme.spacing(0.5),
  },
}));

export const ApplicationTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'separate',
  borderSpacing: 0,
  '& > thead > tr > th': {
    backgroundColor: theme.palette.common.background4,
    borderTop: `1px solid ${theme.palette.common.surface7}`,
    borderBottom: `1px solid ${theme.palette.common.surface7}`,
    paddingTop: theme.spacing(2),
  },
  '& > thead > tr > th:first-of-type': {
    borderLeft: `1px solid ${theme.palette.common.surface7}`,
    borderTopLeftRadius: 4,
  },
  '& > thead > tr > th:last-of-type': {
    borderRight: `1px solid ${theme.palette.common.surface7}`,
    borderTopRightRadius: 4,
  },
  '& > tbody > tr > td:first-of-type': {
    borderLeft: `1px solid ${theme.palette.common.surface7}`,
  },
  '& > tbody > tr > td:last-of-type': {
    borderRight: `1px solid ${theme.palette.common.surface7}`,
  },
}));

export const StatusChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '& > .MuiChip-label': {
    paddingTop: 5.5,
    paddingBottom: theme.spacing(0.5),
    minHeight: 27,
  },
}));

export default Container;
