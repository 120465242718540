import http, { query } from './http';
import { AddressDto } from './models/address-dto';
import { AddressWithoutIdDto } from './models/address-without-id-dto';
import { ApplicantDto } from './models/applicant-dto';
import { ApplicationDto } from './models/application-dto';
import { ApplicationDto as ApplicationSearchDto } from './bff/models/application-dto';
import { EvidenceDto } from './bff/models/evidence-dto';
import { ProductDto } from './models/product-dto';
import { ProductWithoutIdDto } from './models/product-without-id-dto';
import { QuoteDecision } from './models/quote-decision';
import { StatusDto } from './models/status-dto';
import { UnderwriteMeEnquiryType } from './models/underwrite-me-enquiry-type';
// eslint-disable-next-line import/no-cycle
import { ApplicationQueryParams } from './application-helpers';
import { AddressResultDtoLoqateResultDto } from './bff/models/address-result-dto-loqate-result-dto';
import { Operation } from './models/operation';
import { BankDetailsDto } from './models/bank-details-dto';
import { BankAccountResultDtoLoqateResultDto } from './bff/models/bank-account-result-dto-loqate-result-dto';
import { AddressLoqateDto } from './bff/models/address-loqate-dto';
import { OptionListDto } from './bff/models/option-list-dto';
import { ThirdPartyPayerDto } from './models/third-party-payer-dto';
import { UpdateApplicationAgencyCommissionTermsDto } from './models/update-application-agency-commission-terms-dto';
import { ActivatedPolicyDto } from './models/activated-policy-dto';

/**
 * Add an address to the application.
 */
async function addAddress(applicationId: string, address: AddressWithoutIdDto): Promise<AddressDto> {
  return http.post(`/api/v1/bff/Application/${applicationId}/address`, address);
}

/**
 * Add an applicant to the application.
 */
async function addApplicant(applicationId: string): Promise<ApplicantDto> {
  return http.post(`/api/v1/bff/Application/${applicationId}/applicant`, {});
}

/**
 * Add a product to the application.
 */
async function addProduct(applicationId: string, product: ProductWithoutIdDto): Promise<ProductDto> {
  return http.post(`/api/v1/bff/Application/${applicationId}/product`, product);
}

/**
 * Create an empty application.
 */
async function create(enquiryType: UnderwriteMeEnquiryType, agentId?: string): Promise<ApplicationDto> {
  return http.post('/api/v1/Application', {}, query({ enquiryType, agentId }));
}

/**
 * Converts a pre-sales application to full.
 */
async function convertToFull(applicationId: string): Promise<ApplicationDto> {
  return http.put(`/api/v1/bff/Application/${applicationId}/convert`, undefined);
}

/**
 * Delete an address.
 */
async function deleteAddress(applicationId: string, addressId: string): Promise<void> {
  return http.delete(`/api/v1/bff/Application/${applicationId}/address/${addressId}`);
}

/**
 * Delete an applicant.
 */
async function deleteApplicant(applicationId: string, applicantId: string): Promise<void> {
  return http.delete(`/api/v1/bff/Application/${applicationId}/applicant/${applicantId}`);
}

/**
 * Delete a product.
 */
async function deleteProduct(applicationId: string, productId: string): Promise<void> {
  return http.delete(`/api/v1/bff/Application/${applicationId}/product/${productId}`);
}

/**
 * Retrieve an applicant by id.
 */
async function getApplicant(applicationId: string, applicantId: string): Promise<ApplicantDto> {
  return http.get(`/api/v1/bff/Application/${applicationId}/applicant/${applicantId}`);
}

/**
 * Retrieve an application by id.
 */
async function getById(id: string): Promise<ApplicationDto> {
  return http.get(`/api/v1/bff/Application/${id}`);
}

/**
 * Validate products
 */
async function getValidateProducts(applicationId: string): Promise<ProductDto[]> {
  return http.get(`/api/v1/bff/Application/${applicationId}/validate-products`);
}

/**
 * Patch an address.
 */
async function patchAddress(applicationId: string, addressId: string, data: Operation[]): Promise<AddressDto> {
  return http.patch(`/api/v1/bff/Application/${applicationId}/address/${addressId}`, data);
}

/**
 * Patch an applicant.
 */
async function patchApplicant(applicationId: string, applicantId: string, data: Operation[]): Promise<ApplicantDto> {
  return http.patch(`/api/v1/bff/Application/${applicationId}/applicant/${applicantId}`, data);
}

/**
 * Patch an application.
 */
async function patchApplication(applicationId: string, data: Operation[]): Promise<ApplicationDto> {
  return http.patch(`/api/v1/bff/Application/${applicationId}`, data);
}

/**
 * Patch bank details.
 */
async function patchBankDetails(applicationId: string, data: Operation[]): Promise<BankDetailsDto> {
  return http.patch(`/api/v1/bff/Application/${applicationId}/bankdetails`, data);
}

/**
 * Patch a product.
 */
async function patchProduct(applicationId: string, productId: string, data: Operation[]): Promise<ProductDto> {
  return http.patch(`/api/v1/bff/Application/${applicationId}/product/${productId}`, data);
}

/**
 * Retrieve application status.
 */
async function getApplicationStatus(applicationId: string, includePolicyStatus?: boolean): Promise<StatusDto> {
  return http.get(`/api/v1/bff/Application/${applicationId}/status`, { includePolicyStatus });
}

/**
 * Get appication evidence.
 */
async function getApplicationEvidence(applicationId: string): Promise<EvidenceDto[]> {
  return http.get(`/api/v1/bff/application/${applicationId}/evidence`);
}

/**
 * Request quote decision.
 */
async function requestQuoteDecision(applicationId: string): Promise<QuoteDecision> {
  return http.put(`/api/v1/bff/Application/${applicationId}/quote-decision`, undefined);
}

/**
 * Lookup addresses by postcode.
 */
async function lookupAddress(postcode: string): Promise<AddressResultDtoLoqateResultDto> {
  return http.post('/api/v1/bff/address/lookup', { postcode });
}

/**
 * Set application third party ref
 */
async function putThirdPartyRef(applicationId: string, thirdPartyRef: string) {
  return http.put(`/api/v1/bff/Application/${applicationId}/thirdpartyref`, { value: thirdPartyRef });
}

/**
 * Request final decision.
 */
async function requestFinalDecision(applicationId: string): Promise<QuoteDecision> {
  return http.put(`/api/v1/bff/Application/${applicationId}/final-decision`, undefined);
}

/**
 * Validates bank account details.
 */
async function validateBankAccount(accountNumber: string, sortCode: string): Promise<BankAccountResultDtoLoqateResultDto> {
  return http.post('/api/v1/bff/bankAccount/details', { accountNumber, sortCode });
}

/**
 * Get applications.
 */
async function getApplications({ ...params }: ApplicationQueryParams): Promise<ApplicationSearchDto[]> {
  return http.get('/api/v1/bff/application', params);
}

/**
 * Search addresses
 */
async function searchAddress(stem: string): Promise<AddressResultDtoLoqateResultDto> {
  return http.post('/api/v1/bff/address/stem-lookup', { stem });
}

/**
 * Get address
 */
async function searchAddressById(id: string): Promise<AddressLoqateDto> {
  return http.get(`/api/v1/bff/address/retrieve/${id}`);
}

/**
 * Activate application
 */
async function activateApplication(id: string): Promise<void> {
  return http.post(`/api/v1/bff/application/${id}/activate`, {});
}

/**
 * Get option list
 */
async function getOptionList(branch: string, tag: string, optionList: string): Promise<OptionListDto> {
  return http.get(`/api/v1/bff/underwriting/optionlist/${branch}/${tag}/${optionList}`);
}

/**
 * Update commission terms
 */
async function updateCommissionTerms(id: string, data: UpdateApplicationAgencyCommissionTermsDto): Promise<void> {
  return http.put(`/api/v1/bff/application/${id}/commissionterms`, data);
}

/**
 * Update application owner
 */
async function updateOwner(id: string, adviserId: string): Promise<ApplicationDto> {
  return http.put(`/api/v1/bff/application/${id}/owner/${adviserId}`, {});
}

/**
 * Update quote effective date
 */
async function updateQuoteEffectiveDate(id: string, quoteEffectiveDate: string): Promise<void> {
  return http.post(`/api/v1/bff/onboarding/application/${id}/quote-effective`, { quoteEffectiveDate });
}

/**
 * Update third party payer.
 */
async function updateThirdPartyPayer(id: string, data: ThirdPartyPayerDto): Promise<void> {
  return http.put(`/api/v1/bff/application/${id}/thirdpartypayer`, data);
}

/**
 * Delete third party payer.
 */
async function deleteThirdPartyPayer(id: string): Promise<void> {
  return http.delete(`/api/v1/bff/application/${id}/thirdpartypayer`);
}

/**
 * Get policy details
 */
async function getPolicyDetails(id: string): Promise<ActivatedPolicyDto> {
  return http.get(`/api/v1/bff/application/${id}/policydetails`, {});
}

const applicationApi = {
  activateApplication,
  addAddress,
  addApplicant,
  addProduct,
  create,
  convertToFull,
  deleteApplicant,
  deleteProduct,
  deleteAddress,
  deleteThirdPartyPayer,
  getApplicant,
  getApplications,
  getApplicationEvidence,
  getApplicationStatus,
  getById,
  getPolicyDetails,
  getOptionList,
  requestQuoteDecision,
  getValidateProducts,
  lookupAddress,
  patchAddress,
  patchApplicant,
  patchApplication,
  patchBankDetails,
  patchProduct,
  putThirdPartyRef,
  requestFinalDecision,
  validateBankAccount,
  searchAddress,
  searchAddressById,
  updateCommissionTerms,
  updateQuoteEffectiveDate,
  updateOwner,
  updateThirdPartyPayer,
};

export default applicationApi;
