import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { shouldForwardProp } from '../../../../components/types';
import Duration from '../../../../components/duration';

type PriceTextProps = {
  size?: 'small' | 'medium' | 'large';
};

function getFontSizeFor(size: PriceTextProps['size']) {
  switch (size) {
    case 'small': return '0.875rem';
    case 'medium': return '1.5rem';
    case 'large': return '1.75rem';
    default: return undefined;
  }
}

export const PriceText = styled(Typography, {
  shouldForwardProp: (prop) => shouldForwardProp<PriceTextProps>(['size'], prop),
})<PriceTextProps>(({ size }) => ({
  fontSize: getFontSizeFor(size),
  fontWeight: size === 'small' ? 'bold' : 'normal',
}));

export const Conjunction = styled('span', {
  shouldForwardProp: (prop) => shouldForwardProp<PriceTextProps>(['size'], prop),
})<PriceTextProps>(({ size }) => ({
  fontSize: size === 'small' ? getFontSizeFor('small') : '1.125rem',
  fontWeight: 'normal',
}));

export const StyledDuration = styled(Duration, {
  shouldForwardProp: (prop) => shouldForwardProp<PriceTextProps>(['size'], prop),
})<PriceTextProps>(({ size }) => ({
  display: 'inline',
  fontSize: size === 'small' ? getFontSizeFor('small') : '1.125rem',
  fontWeight: 'normal',
}));

export const PostText = styled('span', {
  shouldForwardProp: (prop) => shouldForwardProp<PriceTextProps>(['size'], prop),
})<PriceTextProps>(({ size }) => ({
  display: 'inline',
  fontSize: size === 'small' ? getFontSizeFor('small') : '1.125rem',
  fontWeight: 'normal',
}));

export default {};
