import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setForgetPassword } from '../../services/profile-helpers';

function ForgotPassword() {
  const navigate = useNavigate();
  useEffect(() => {
    setForgetPassword();
    navigate('/');
  }, []);

  return null;
}

export default ForgotPassword;
