import {
  Popper,
  styled,
} from '@mui/material';

export const QuoteDatePopper = styled(Popper)(({ theme }) => ({
  zIndex: 2000,
  '& > div': {
    border: `1px solid ${theme.palette.common.surface5}`,
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  '& > div:before': {
    content: '""',
    position: 'absolute',
    top: 'calc(50% - 2.5px)',
    left: 5,
    width: 5,
    height: 5,
    backgroundColor: theme.palette.common.surface5,
    transform: 'rotate(45deg)',
  },
  '& .MuiInputBase-root': {
    '&:has(input:focus)': {
      border: 'none',
    },
    border: 'none',
    background: theme.palette.common.white,
    '&:hover, :focus, :active': {
      border: 'none',
    },
  },
}));

export default {};
