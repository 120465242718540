import {
  Box,
  Card,
  CardContent,
  Typography,
  styled,
} from '@mui/material';
import { shouldForwardProp } from '../../../../components/types';

export const DecisionTotalCard = styled(Card)(() => ({
  border: 'none',
}));

export const DecisionTotalCardContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.yellow200,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

export const EmRatingValue = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 'bold',
}));

type InlineWrapperProps = {
  inline?: boolean
};

export const InlineWrapper = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<InlineWrapperProps>(['inline'], prop),
})<InlineWrapperProps>(({ inline = false }) => {
  if (inline) {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: 4,
      '& p': {
        display: 'inline',
        '& span': {
          display: 'inline',
        },
      },
      '& p + p': {
        '& span': {
          marginLeft: 4,
        },
      },
    };
  }
  return {};
});

export default {};
