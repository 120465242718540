import React, { Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Panel } from './select-product-panel.styles';
import SectionHeader from '../../../../components/section-header/section-header';
import AddProductButtons from './add-product-buttons';
import { AvailableProductDefinition } from '../../../../services/models/available-product-definition';

interface SelectProductPanelProps {
  products: AvailableProductDefinition[];
  onChange?: () => unknown;
}

function SelectProductPanel({
  products,
  onChange = undefined,
}: SelectProductPanelProps, ref: Ref<unknown>) {
  const { t } = useTranslation();

  return (
    <Box ref={ref}>
      <SectionHeader>
        {t('components.selectProductPanel.title')}
      </SectionHeader>
      <Panel>
        <AddProductButtons products={products} onChange={onChange} />
      </Panel>
    </Box>
  );
}

export default forwardRef(SelectProductPanel);
