export const DashboardPage = { path: '/', id: 'Dashboard' } as const;
export const SearchPage = { path: '/search', id: 'Search' } as const;
export const ApplicationPage = { path: '/application/:applicationId', id: 'Application' } as const;
export const PolicySetupPage = { path: '/application/:applicationId/policy-setup', id: 'PolicySetup' } as const;
export const ReviewPage = { path: '/application/:applicationId/review', id: 'Review' } as const;
export const CompletePage = { path: '/application/:applicationId/complete', id: 'Complete' } as const;
export const ExpiredPage = { path: '/application/:applicationId/expired', id: 'Expired' } as const;
export const ActivePage = { path: '/application/:applicationId/active', id: 'Active' } as const;
export const CancelledPage = { path: '/application/:applicationId/cancelled', id: 'Cancelled' } as const;
export const InternalLoginPage = { path: '/internal', id: 'InternalLogin' } as const;
export const RegistrationPage = { path: '/registration', id: 'Registration' } as const;
export const FirmRegistrationPage = { path: '/registration/firm', id: 'Registration' } as const;
export const ForgotPasswordPage = { path: '/forgot', id: 'ForgotPassword' } as const;

export type PageWithParams =
  typeof DashboardPage |
  typeof SearchPage |
  typeof ApplicationPage |
  typeof PolicySetupPage |
  typeof ReviewPage |
  typeof CompletePage |
  typeof ExpiredPage |
  typeof ActivePage |
  typeof CancelledPage |
  typeof InternalLoginPage |
  typeof RegistrationPage |
  typeof FirmRegistrationPage |
  typeof ForgotPasswordPage;

export type Page = Pick<PageWithParams, 'id' | 'path'>;
