import React, { ReactNode } from 'react';
import {
  Box,
  Divider,
  Stack,
  StackProps,
  styled,
} from '@mui/material';
import FormHelpText from './form-help-text';

export const MainRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
  '& > .MuiFormControl-root': {
    flex: '1 1 auto',
  },
  '& .form-help-text-container': {
    maxWidth: 400,
    minWidth: 200,
    flex: '0 1 auto',
  },
}));

interface HelpTextProps {
  helpText?: string | { __html: string } | null;
}

function HelpText({ helpText = undefined }: HelpTextProps) {
  const helpTextElement = helpText && (
    (typeof helpText === 'string') ? <FormHelpText>{helpText}</FormHelpText> : <FormHelpText dangerouslySetInnerHTML={helpText} />
  );
  return (
    <div className="form-help-text-container">
      {helpTextElement}
    </div>
  );
}

interface FormFieldContainerProps extends StackProps {
  helpText?: string | { __html: string } | null;
  hideHelpText?: boolean;
  hideDivider?: boolean;
  rowEnd?: ReactNode;
  columnEnd?: ReactNode;
}

function FormFieldContainer({
  children,
  helpText = undefined,
  hideHelpText = undefined,
  hideDivider = undefined,
  columnEnd = undefined,
  rowEnd = undefined,
  ...props
}: FormFieldContainerProps) {
  return (
    <Stack direction="column" gap={1} {...props}>
      <MainRow>
        {children}
        <Stack direction="row" gap={1} sx={{ ml: 'auto' }}>
          {!hideHelpText && <HelpText helpText={helpText} />}
          {rowEnd}
        </Stack>
      </MainRow>
      {columnEnd}
      {!hideDivider && <Divider />}
    </Stack>
  );
}

export default FormFieldContainer;
