import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { asAge, asClientDate } from '../../../../utils/converters';
import { ClientDetailsContainer, TogglePanelButton } from './client-details-bar.styles';
import ClientDetailsPanel from './client-details-panel';
import { View, ProceedFunction } from '../../../view';
import { Enquiry } from '../../../../services/models/enquiry';
import enquiryApi from '../../../../services/enquiry-api';
import { useAppSelector } from '../../../../store/hooks';
import { selectActiveApplicant, selectApplication, selectPreSalesDecisionResultFor } from '../../../../features/application-slice';

interface ClientDetailsBarProps {
  proceedToNextView?: ProceedFunction;
}

function ClientDetailsBar({ proceedToNextView = undefined }: ClientDetailsBarProps) {
  const application = useAppSelector(selectApplication);
  const applicant = useAppSelector(selectActiveApplicant)!;
  const [open, setOpen] = useState(false);
  const [localeEnquiry, setLocalEnquiry] = useState<Enquiry>();
  const { t } = useTranslation();
  const applicantInfo = useAppSelector(selectPreSalesDecisionResultFor(applicant.id))?.applicant;

  const handleGetLocalEnquiry = async () => {
    const e = await enquiryApi.getForApplicantWithLocale(application.id, applicant.id, 'Summary');
    setLocalEnquiry(e);
  };

  useEffect(() => {
    handleGetLocalEnquiry();
  }, []);

  useEffect(() => {
    if (open) {
      handleGetLocalEnquiry();
    }
  }, [open]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (view: View) => {
    handleClose();
    if (proceedToNextView) {
      proceedToNextView({ target: view });
    }
  };

  return (
    <ClientDetailsContainer>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', marginTop: '2px' }}>
        <Stack direction="row" gap={16}>
          <Typography variant="h3">{t('components.clientDetailsBar.title')}</Typography>
          <Stack direction="row" gap={3}>
            <Typography variant="body1" sx={{ flex: '1 1 auto' }}>
              {applicant.gender && `${t(`common.gender.${applicant.gender}`)}: `}
              {applicant.age}
              {applicant.dateOfBirth && t('components.clientDetailsBar.dateOfBirthWithAge', {
                dateOfBirth: asClientDate(applicant.dateOfBirth),
                age: asAge(applicant.dateOfBirth),
              })}
            </Typography>
            <Typography variant="body1" data-testid="bmi">
              {applicantInfo?.bmi && t('components.clientDetailsBar.bmi', {
                bmi: applicantInfo.bmi,
              })}
            </Typography>
          </Stack>
        </Stack>
        <TogglePanelButton
          endIcon={open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
          onClick={handleClick}
        >
          {open ? t('components.clientDetailsBar.hideDetails') : t('components.clientDetailsBar.viewDetails')}
        </TogglePanelButton>
      </Stack>
      <Collapse in={open}>
        <ClientDetailsPanel
          applicant={applicant}
          enquiry={localeEnquiry}
          onEdit={handleEdit}
        />
      </Collapse>
    </ClientDetailsContainer>
  );
}

export default ClientDetailsBar;
