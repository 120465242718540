import variables from '../variables';
import typography from '../typography';

const MuiTab = {
  styleOverrides: {
    root: () => ({
      backgroundColor: variables.colors.surface6,
      minHeight: 54,
      minWidth: 184,
      textTransform: 'none' as const,
      ...typography.h4,
      fontFamily: typography.h5.fontFamily,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      '&.Mui-selected': {
        backgroundColor: variables.colors.white,
        color: variables.colors.greyDark,
        fontFamily: typography.h4.fontFamily,
      },
    }),
  },
};

export default MuiTab;
