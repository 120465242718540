import MuiContainer from '@mui/material/Container';
import { styled } from '@mui/material/styles';

export const Container = styled(MuiContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const OutletContainer = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.common.background4,
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
}));

export const MainContent = styled(Container)(({ theme }) => ({
  width: '100%',
  flex: '1 1 auto',
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(theme.mixins.mainHorizontalSpacing / 2),
  marginBottom: theme.mixins.footerBarMinHeight,
  '& > *': {
    flex: '1 1 auto',
  },
}));
