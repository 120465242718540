import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ApplicationDocumentContainer = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.secondary200}`,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

export default {};
