import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export const FaqCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[24],
}));

export const FaqCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface8,
  minHeight: 64,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  '& .MuiCardHeader-avatar': {
    color: theme.palette.common.secondary,
  },
})) as typeof CardHeader;

export const FaqCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:last-child': {
    paddingBottom: theme.spacing(3),
  },
}));
