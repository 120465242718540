import React, {
  Ref,
  forwardRef,
  useState,
} from 'react';
import {
  Card,
} from '@mui/material';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AnyObject, ObjectSchema } from 'yup';
import { getProductDefinitionByCode } from '../../../../features/settings-slice';
import {
  selectApplicationStatus,
} from '../../../../features/application-slice';
import { useAppSelector } from '../../../../store/hooks';
import { ProductDto } from '../../../../services/models/product-dto';
import {
  ProductError,
  ProductFormValues,
  getValidationSchema,
  mergeProductFormErrors,
  toFormValues,
  toApiValue,
  ProductEditability,
} from '../../../../services/product-helpers';
import { isPreSalesAppliction } from '../../../../services/application-helpers';
import ProductCardHeader from './product-card-header';
import {
  isFormEmpty,
} from '../../../../components/form/form.utils';
import PreSalesProductForm from './presales-product-form';
import OnboardingProductForm from './onboarding-product-form';
import { CoverType } from '../../../../services/models/cover-type';
import { ProductType } from '../../../../services/models/product-type';
import ProductCardTitle from '../../../../components/product-card-title';

const alwaysFilledFields = ['coverType', 'quoteBasis', 'commission'];

function isEmpty(values: ReturnType<typeof toFormValues>, validationSchema: ObjectSchema<AnyObject>, defaultCoverType: string | null) {
  const fields = Object.keys(validationSchema.fields);
  const fieldsThatCanBeEmpty = fields.filter((f) => !alwaysFilledFields.includes(f));
  const empty = isFormEmpty(fieldsThatCanBeEmpty, values)
    && (!fields.includes('coverType') || values.coverType === undefined || values.coverType === defaultCoverType);
  return empty;
}

export interface ProductPanelProps {
  applicantId: string;
  product: ProductDto;
  productErrors: ProductError[],
  allowDecision: boolean;
  onDelete?: (product: ProductDto) => unknown;
  onEnableDecision: () => unknown;
  onProductChange: (productId: string, changes: Partial<ProductDto>) => unknown;
  onConvertToFullApplication: () => unknown;
  editable?: ProductEditability;
}

function ProductPanel({
  applicantId,
  product,
  productErrors,
  allowDecision,
  onDelete = undefined,
  onEnableDecision,
  onProductChange,
  onConvertToFullApplication,
  editable = ProductEditability.Editable,
}: ProductPanelProps, ref: Ref<unknown>) {
  const productDefinition = useSelector((state) => getProductDefinitionByCode(state, product.code));
  const applicationStatus = useAppSelector(selectApplicationStatus);
  const isPreSales = isPreSalesAppliction(applicationStatus);
  const validationSchema = getValidationSchema(isPreSales, product.productType!);

  const formMethods = useForm({
    defaultValues: toFormValues(product),
    resolver: yupResolver(validationSchema as any),
  });

  const {
    formState: { errors },
    resetField,
    setValue,
    getValues,
    trigger,
  } = formMethods;
  const defaultCoverType = !isPreSales && product.productType === ProductType.Ip ? CoverType.Level : null;
  const empty = isEmpty(getValues(), validationSchema, defaultCoverType);
  const [showErrors, setShowErrors] = useState(!empty);

  if (!applicationStatus || !productDefinition) return null;

  const handleChange = async (changes: Partial<ProductFormValues>) => {
    const keys = Object.keys(changes) as (keyof ProductDto)[];
    const apiChanges = keys.reduce((res, key) => ({ ...res, [key.replaceAll('.', '/')]: toApiValue(key, changes[key]) }), {});
    await onProductChange(product.id, apiChanges);
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      resetField(key, { defaultValue: changes[key] });
      setValue(key, changes[key]);
    }
    await trigger();
    if (!showErrors && validationSchema.isValidSync(getValues())) {
      setShowErrors(true);
    }
  };

  const formProps = {
    product,
    errors: showErrors ? mergeProductFormErrors(productErrors.filter((e) => e.field !== 'Commission'), errors) : [],
    onProductChange: handleChange,
    onEnableDecision,
    allowDecision,
    editable,
  };

  return (
    <Box ref={ref}>
      <Card elevation={0} data-testid={product.code}>
        <ProductCardHeader
          title={<ProductCardTitle product={product} />}
          onDelete={onDelete ? () => onDelete(product) : undefined}
        />
        <FormProvider {...formMethods}>
          {isPreSales && (
            <PreSalesProductForm
              {...formProps}
              empty={empty}
              onConvertToFullApplication={onConvertToFullApplication}
              documents={productDefinition.documents}
              salesResources={productDefinition.salesResources}
            />
          )}
          {!isPreSales && <OnboardingProductForm applicantId={applicantId} {...formProps} displayHint={!showErrors} />}
        </FormProvider>
      </Card>
    </Box>
  );
}

export default forwardRef(ProductPanel);
