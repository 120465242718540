import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import SectionHeader from '../section-header';
import { Document } from '../../services/models/document';
import DocumentLink from './document-link';
import { DocumentCard, DocumentCardContent } from './documents-panel.styles';

export interface DocumentsPanelProps extends PropsWithChildren {
  documents: Document[]
}

function DocumentsPanel({ documents, children = null }: DocumentsPanelProps) {
  const { t } = useTranslation();

  if (documents.length <= 0) {
    return null;
  }

  return (
    <Box>
      <SectionHeader>
        {t('components.documentsPanel.title')}
      </SectionHeader>
      <DocumentCard>
        <DocumentCardContent>
          {children}
          {documents.map((document) => <DocumentLink key={document.location} document={document} />)}
        </DocumentCardContent>
      </DocumentCard>
    </Box>
  );
}

export default DocumentsPanel;
