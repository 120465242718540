import { Box, styled } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  color: theme.palette.common.secondary800,
}));

export const TooltipContent = styled(Box)(({ theme }) => ({
  '& ul': {
    margin: 0,
    marginLeft: theme.spacing(2),
    padding: 0,
    '& > li': {
      marginBottom: theme.spacing(1),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },
}));

export default InfoIcon;
