import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
} from '@mui/material';
import {
  TableContainerStyled,
  EvidenceRow,
  ExclusionsRow,
} from './decision-table.styles';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import { getProductDecisionExclusions } from '../../../../services/decision-helpers';
import { titleCase } from '../../../../utils/converters';

interface ProductQuoteKeyDecisonTableProps {
  applicantId: string;
  decision: ProductQuoteDecision | null;
  titlePrefix?: string;
}

function ProductQuoteKeyDecisonTable({
  applicantId,
  decision,
  titlePrefix = '',
}: ProductQuoteKeyDecisonTableProps) {
  const applicantDecision = decision?.applicantDecisions.find((ad) => ad.applicantId === applicantId);
  const hasEvidences = applicantDecision && applicantDecision.evidences.length > 0;
  const exclusions = applicantDecision && getProductDecisionExclusions(applicantDecision);
  const { t } = useTranslation();

  if (!applicantDecision) {
    return null;
  }

  return (
    <TableContainerStyled>
      <Table>
        <TableBody key={applicantDecision.applicantId}>
          {hasEvidences && (
            <EvidenceRow>
              <TableCell component="th" scope="row">{`${titleCase(`${titlePrefix}${t('components.productQuoteDecisonTable.evidences')}`)}`}</TableCell>
              <TableCell>
                {applicantDecision.evidences.map((e) => e.text).join(', ')}
              </TableCell>
            </EvidenceRow>
          )}
          {exclusions && exclusions.length > 0 && (
            <ExclusionsRow>
              <TableCell component="th" scope="row">{`${titleCase(`${titlePrefix}${t('components.productQuoteDecisonTable.exclusions')}`)}`}</TableCell>
              <TableCell>
                {exclusions.map((exclusion, index) => (
                  <Box key={exclusion} sx={{ mt: index > 0 ? 1.5 : 0 }}>
                    {exclusion}
                  </Box>
                ))}
              </TableCell>
            </ExclusionsRow>
          )}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
}

export default ProductQuoteKeyDecisonTable;
