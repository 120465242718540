import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { AdvisorJournalEntry } from '../../../../services/bff/models/advisor-journal-entry';
import { NotificationsTableContainer } from './notifications-table.styles';
import { asClientDateTime } from '../../../../utils/converters';
import { ApplicationPage } from '../../../pages';

interface NotificationsTableProps {
  journeyEntries: AdvisorJournalEntry[]
  loading?: boolean
}

function NotificationsTable({ journeyEntries, loading = false }: NotificationsTableProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGotoApplication = (applicationId: string) => {
    navigate(generatePath(ApplicationPage.path, { applicationId }));
  };

  return (
    <NotificationsTableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('components.notificationsTable.tableHeader.date')}</TableCell>
            <TableCell>{t('components.notificationsTable.tableHeader.reference')}</TableCell>
            <TableCell>{t('components.notificationsTable.tableHeader.activity')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={3}><CircularProgress /></TableCell>
            </TableRow>
          ) : (
            journeyEntries.map((journalEntry) => (
              <TableRow key={journalEntry.eventCreatedDateTime}>
                <TableCell>{asClientDateTime(journalEntry.eventCreatedDateTime)}</TableCell>
                <TableCell>
                  {journalEntry.applicationId && (
                    <Button
                      variant="text"
                      color="inherit"
                      size="small"
                      onClick={() => handleGotoApplication(journalEntry.applicationId!)}
                    >
                      {journalEntry.applicationId}
                    </Button>
                  )}
                </TableCell>
                <TableCell>{journalEntry.description}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </NotificationsTableContainer>
  );
}

export default NotificationsTable;
