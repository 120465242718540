import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import InfoTooltip from './info-tooltip';

interface TextWithInfoProps extends TypographyProps {
  info?: ReactNode;
}

function TextWithInfo({ children, info = undefined, ...props }: TextWithInfoProps) {
  return (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 0.5,
      }}
      {...props}
    >
      {children}
      {info && <InfoTooltip title={info} IconProps={{ fontSize: 'small' }} />}
    </Typography>
  );
}

export default TextWithInfo;
