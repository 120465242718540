import React from 'react';
import { PostcodeInput, PostcodeInputProps } from '../inputs';
import FieldBase, { FieldBaseProps } from './field-base';

export interface PostcodeFieldProps extends PostcodeInputProps, FieldBaseProps {
}

function PostcodeField({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: PostcodeFieldProps) {
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <PostcodeInput name={name} {...props} />
    </FieldBase>
  );
}

export default PostcodeField;
