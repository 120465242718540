import React from 'react';
import { FormHelperText, FormHelperTextProps, styled } from '@mui/material';

const StyledFormErrorText = styled(FormHelperText)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.error.main,
  lineHeight: 1,
  marginTop: theme.spacing(0.5),
  '&.Mui-error': {
    color: theme.palette.error.main,
  },
}));

function FormErrorText(props: FormHelperTextProps) {
  return (<StyledFormErrorText error {...props} />);
}

export default FormErrorText;
