import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ChipProps,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  InputBase,
  Paper,
  PopperProps,
  Stack,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { ReferenceChip, ReferencePopper } from './custom-reference-chip.styles';
import { putThirdPartyRef, selectApplicationState, selectThirdPartyRef } from '../../../../features/application-slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface ReferenceFormPopperProps extends PopperProps {
  reference?: string | null | undefined
  loading?: boolean
  onCancel: () => void
  onConfirm: (reference: string) => void
}

function ReferenceFormPopper({
  reference = undefined,
  loading = false,
  onCancel,
  onConfirm,
  ...props
}: ReferenceFormPopperProps) {
  const [value, setValue] = useState(reference ?? '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <ReferencePopper {...props} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <ClickAwayListener onClickAway={onCancel}>
              <Stack direction="row" alignItems="center">
                <InputBase
                  name="reference"
                  size="small"
                  defaultValue={reference}
                  placeholder="Enter your ref"
                  disabled={loading}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  aria-label="update"
                  color="secondary"
                  size="small"
                  onClick={() => onConfirm(value)}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={25} color="secondary" /> : <DoneIcon />}
                </IconButton>
              </Stack>
            </ClickAwayListener>
          </Paper>

        </Fade>
      )}
    </ReferencePopper>
  );
}

export interface CustomReferenceChipProps extends ChipProps {
  readOnly?: boolean
}

function CustomReferenceChip({
  readOnly = false,
  ...props
}: CustomReferenceChipProps) {
  const state = useAppSelector(selectApplicationState);
  const reference = useAppSelector(selectThirdPartyRef);
  const dispatch = useAppDispatch();
  const applicationId = state.status === 'available' ? state.application.id : null;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleConfirm = async (updatedReference: string) => {
    if (applicationId) {
      setBusy(true);
      try {
        await dispatch(putThirdPartyRef(applicationId, updatedReference));
        setAnchorEl(null);
        setOpen(false);
      } finally {
        setBusy(false);
      }
    }
  };

  const { t } = useTranslation();
  return (
    <Box>
      <ReferenceChip
        {...props}
        label={(
          <>
            {`${t('components.customReferenceChip.yourRef')} `}
            {reference && <strong>{reference}</strong>}
          </>
        )}
        deleteIcon={!readOnly ? (
          <Button onClick={handleClick} variant="text" size="small" startIcon={<EditIcon />}>
            <span>{reference ? t('common.edit') : t('common.add')}</span>
          </Button>
        ) : undefined}
        size="small"
        onDelete={!readOnly ? handleClick : undefined}
      />
      <ReferenceFormPopper
        reference={reference}
        placement="right"
        anchorEl={anchorEl}
        open={open}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        loading={busy}
      />
    </Box>
  );
}

export default CustomReferenceChip;
