import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentsPanel from '../../../../components/documents-panel';
import DownloadDocumentButton from '../../../../components/download/download-document-button';
import { DocumentsPanelProps } from '../../../../components/documents-panel/documents-panel';
import { QuoteDecision } from '../../../../services/models/quote-decision';
import documentApi from '../../../../services/document-api';
import { ApplicationStatus } from '../../../../services/models/application-status';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { applicantHasAcceptedProducts, getApplicantName } from '../../../../services/application-helpers';

interface ApplicationDocumentsPanelProps extends DocumentsPanelProps {
  applicationId: string;
  applicant: ApplicantDto;
  quoteDecision?: QuoteDecision | null;
  applicationStatus?: ApplicationStatus;
}

function ApplicationDocumentsPanel({
  applicationId,
  applicant,
  documents,
  quoteDecision = null,
  applicationStatus = undefined,
}: ApplicationDocumentsPanelProps) {
  const { t } = useTranslation();
  const generateQuoteDocument = () => documentApi.downloadQuoteIllustrationDocument(applicationId);
  const generateQAndADocument = (
    applicantId: string,
  ) => documentApi.downloadQuestionsAndAsnwersDocument(applicationId, applicantId);
  const generateAcceptanceDocument = () => documentApi.downloadAcceptanceDocument(applicationId);
  const hasAcceptedProducts = quoteDecision ? applicantHasAcceptedProducts(applicant.id, quoteDecision?.products) : false;

  return (
    <DocumentsPanel
      documents={documents}
    >
      {quoteDecision && (
        <DownloadDocumentButton
          label={t('components.applicationDocumentsPanel.downloadQuote.label')}
          fileName={`Quote illustration - ${applicationId}.pdf`}
          resourceName="Quote illustration"
          errorMessage={t('components.applicationDocumentsPanel.downloadQuote.error')}
          generateDocument={generateQuoteDocument}
          variant="text"
          color="secondary"
        />
      )}
      {applicationStatus && applicationStatus !== ApplicationStatus.Quote && (
        <DownloadDocumentButton
          label={`${t('components.applicationDocumentsPanel.downloadQAndA.label')} - ${getApplicantName(applicant)}`}
          fileName={`Application summary - ${getApplicantName(applicant)} - ${applicationId}.pdf`}
          resourceName="Application summary"
          errorMessage={t('components.applicationDocumentsPanel.downloadQAndA.error')}
          generateDocument={() => generateQAndADocument(applicant.id)}
          variant="text"
          color="secondary"
        />
      )}
      {applicationStatus && applicationStatus === ApplicationStatus.Decision && hasAcceptedProducts && (
        <DownloadDocumentButton
          label={t('components.applicationDocumentsPanel.downloadAcceptance.label')}
          fileName={`Acceptance offer - ${applicationId}.pdf`}
          resourceName="Acceptance offer"
          errorMessage={t('components.applicationDocumentsPanel.downloadAcceptance.error')}
          generateDocument={generateAcceptanceDocument}
          variant="text"
          color="secondary"
        />
      )}
    </DocumentsPanel>
  );
}

export default ApplicationDocumentsPanel;
