import { useCallback } from 'react';
import { proceed } from '../features/application-slice';
import { ProceedFunction, ProceedOptions } from '../routes/view';
import { useAppDispatch } from '../store/hooks';

function useProceed(): ProceedFunction {
  const dispatch = useAppDispatch();
  return useCallback((options?: ProceedOptions) => dispatch(proceed(options)), [dispatch]);
}

export default useProceed;
