import { styled } from '@mui/material/styles';
import {
  Chip,
  Table,
} from '@mui/material';

export const ApplicationTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'separate',
  borderSpacing: 0,
  '& > thead > tr > th': {
    backgroundColor: theme.palette.common.background4,
    borderTop: `1px solid ${theme.palette.common.surface7}`,
    borderBottom: `1px solid ${theme.palette.common.surface7}`,
    paddingTop: theme.spacing(2),
  },
  '& > thead > tr > th:first-of-type': {
    borderLeft: `1px solid ${theme.palette.common.surface7}`,
    borderTopLeftRadius: 4,
  },
  '& > thead > tr > th:last-of-type': {
    borderRight: `1px solid ${theme.palette.common.surface7}`,
    borderTopRightRadius: 4,
  },
  '& > tbody > tr > td:first-of-type': {
    borderLeft: `1px solid ${theme.palette.common.surface7}`,
  },
  '& > tbody > tr > td:last-of-type': {
    borderRight: `1px solid ${theme.palette.common.surface7}`,
  },
  '& > tbody > tr:hover > td': {
    cursor: 'pointer',
    backgroundColor: theme.palette.common.surface8,
  },
}));

export const StatusChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '& > .MuiChip-label': {
    paddingTop: 5.5,
    paddingBottom: theme.spacing(0.5),
    minHeight: 27,
  },
}));
