import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { EnquiryLine } from '../../../services/models/enquiry-line';

interface EnquiryLineBreadcrumbsProps {
  enquiryLine: EnquiryLine;
}

function EnquiryLineBreadcrumbs({ enquiryLine }: EnquiryLineBreadcrumbsProps) {
  return (
    <Breadcrumbs separator=">">
      <span>{enquiryLine.section}</span>
      <span>{enquiryLine.alias}</span>
    </Breadcrumbs>
  );
}

export default EnquiryLineBreadcrumbs;
