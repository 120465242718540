import React, { useCallback, useEffect, useState } from 'react';
import { FormControl, Stack, Typography } from '@mui/material';
import { NumberInput, NumberInputProps } from '../inputs';
import FieldBase, { FieldBaseProps } from './field-base';
import { Question } from '../../../services/models/question';
import BeerIcon from '../../../assets/icons/beer-icon.svg';
import WineIcon from '../../../assets/icons/wine-icon.svg';
import SpiritIcon from '../../../assets/icons/spirits-icon.svg';
import CocktailIcon from '../../../assets/icons/cocktail-icon.svg';
import { useEnquiryFormContext } from '../enquiry/enquiry-form-provider';
import { OptionDto } from '../../../services/bff/models/option-dto';

function getAlcoholType(name: string): string {
  return name.replace(/^.*_/, '').toLowerCase();
}

function getQuestionIcon(name: string): string {
  switch (getAlcoholType(name)) {
    case 'beer': return BeerIcon;
    case 'wine': return WineIcon;
    case 'spirits': return SpiritIcon;
    case 'other': return CocktailIcon;
    default: return BeerIcon;
  }
}

function getQuestionOptionText(name: string, options: OptionDto[]): string {
  return options.find((option) => option.name === name)?.text ?? '';
}

export interface AlcoholFieldProps extends NumberInputProps, FieldBaseProps {
  questions: Question[]
}

function AlcoholField({
  name,
  questions,
  onChangeCommitted,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: AlcoholFieldProps) {
  const [options, setOptions] = useState<OptionDto[]>([]);
  const { getOptionList } = useEnquiryFormContext();

  const getQuestionTextOptions = useCallback(async () => {
    const response = await getOptionList('GroupQuestionsText');
    setOptions(response.options);
  }, [setOptions]);

  const handleChange = (path: string | null | undefined, value: any) => {
    if (onChangeCommitted && path) {
      onChangeCommitted(path, value);
    }
  };

  useEffect(() => {
    if (options.length <= 0) {
      getQuestionTextOptions();
    }
  }, []);

  return (
    <FieldBase
      name={name}
      label={getQuestionOptionText(name, options)}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <Stack direction="row" gap={4} sx={{ mt: 1 }}>
        {questions.map((question, index) => (
          <FormControl key={question.path}>
            <Stack gap={1.5} sx={{ minWidth: 110 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <NumberInput
                  key={question.path}
                  name={question.name!}
                  {...props}
                  onChangeCommitted={(_, value) => handleChange(question.path, value)}
                  inputProps={{ maxLength: 3 }}
                  sx={{ width: 50 }}
                />
                <img src={getQuestionIcon(question.name!)} alt={question.definition.text || ''} height="28" />
              </Stack>
              <Typography variant="body2" sx={{ maxWidth: index === 0 ? 110 : 'auto' }}>{getQuestionOptionText(question.name!, options)}</Typography>
            </Stack>
          </FormControl>
        ))}
      </Stack>
    </FieldBase>
  );
}

export default AlcoholField;
