import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDecision = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  '& > svg': {
    marginRight: theme.spacing(1),
  },
}));

export default StyledDecision;
