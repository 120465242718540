import React from 'react';
import { useTranslation } from 'react-i18next';
import DownloadDocumentButton from '../../../../components/download/download-document-button';
import { activatedApplicantsHaveMemberIds, getApplicantName } from '../../../../services/application-helpers';
import documentApi from '../../../../services/document-api';
import { ApplicationDocumentContainer } from './application-documents.styles';
import { QuoteDecision } from '../../../../services/models/quote-decision';
import { ApplicationDto } from '../../../../services/models/application-dto';
import { ApplicationStatus } from '../../../../services/models/application-status';

function showAcceptanceOffer(status: ApplicationStatus | undefined): boolean {
  return status === ApplicationStatus.Decision || status === ApplicationStatus.Active;
}

function showPolicySchedule(status: ApplicationStatus | undefined): boolean {
  return status === ApplicationStatus.Active;
}

function showDirectDebit(status: ApplicationStatus | undefined): boolean {
  return status === ApplicationStatus.Active;
}

export interface ApplicationDocumentsProps {
  application: ApplicationDto;
  quoteDecision?: QuoteDecision | null;
}

function ApplicationDocuments({ application, quoteDecision = null }: ApplicationDocumentsProps) {
  const { t } = useTranslation();
  const { status } = application;
  const generateQuoteDocument = () => documentApi.downloadQuoteIllustrationDocument(application.id);
  const generateAcceptanceDocument = () => documentApi.downloadAcceptanceDocument(application.id);
  const generatePolicyScheduleDocument = () => documentApi.downloadPolicyScheduleDocument(application.id);
  const generateApplicationSummaryDocument = (applicantId: string) => documentApi.downloadQuestionsAndAsnwersDocument(application.id, applicantId);
  const generateDirectDebitDocument = () => documentApi.downloadDirectDebitDocument(application.id);
  const hasActiveMemberIds = activatedApplicantsHaveMemberIds(application);

  return (
    <ApplicationDocumentContainer gap={2} direction="row" alignItems="flex-start" flexWrap="wrap">
      {application.applicants.map((applicant) => (
        <DownloadDocumentButton
          key={applicant.id}
          label={`${t('components.applicationDocuments.applicationSummary.label')} - ${getApplicantName(applicant)}`}
          fileName={`Application summary - ${getApplicantName(applicant)} - ${application.id}.pdf`}
          resourceName="Application summary"
          errorMessage={t('errors.documentDownload')}
          generateDocument={() => generateApplicationSummaryDocument(applicant.id)}
          variant="text"
          color="secondary"
        />
      ))}
      {quoteDecision && (
        <DownloadDocumentButton
          label={t('components.applicationDocuments.quoteIllustration.label')}
          fileName={`Quote illustration - ${application.id}.pdf`}
          resourceName="Quote illustration"
          errorMessage={t('errors.documentDownload')}
          generateDocument={generateQuoteDocument}
          variant="text"
          color="secondary"
        />
      )}
      {quoteDecision && showAcceptanceOffer(status) && (
        <DownloadDocumentButton
          label={t('components.applicationDocuments.acceptanceOffer.label')}
          fileName={`Acceptance offer - ${application.id}.pdf`}
          resourceName="Acceptance offer"
          errorMessage={t('errors.documentDownload')}
          generateDocument={generateAcceptanceDocument}
          variant="text"
          color="secondary"
        />
      )}
      {hasActiveMemberIds && showPolicySchedule(status) && (
        <DownloadDocumentButton
          label={t('components.applicationDocuments.policySchedule.label')}
          fileName={`Policy schedule - ${application.id}.pdf`}
          resourceName="Policy schedule"
          errorMessage={t('errors.documentDownload')}
          generateDocument={generatePolicyScheduleDocument}
          variant="text"
          color="secondary"
        />
      )}
      {hasActiveMemberIds && showDirectDebit(status) && (
        <DownloadDocumentButton
          label={t('components.applicationDocuments.directDebit.label')}
          fileName={`Direct debit - ${application.id}.pdf`}
          resourceName="Direct debit"
          errorMessage={t('errors.documentDownload')}
          generateDocument={generateDirectDebitDocument}
          variant="text"
          color="secondary"
        />
      )}
    </ApplicationDocumentContainer>
  );
}

export default ApplicationDocuments;
