import http from './http';
import { ToolSettings } from './models/tool-settings';

/**
 * Retrieve all available tools.
 */
async function getTools(): Promise<ToolSettings[]> {
  return http.get('/api/v1/tools');
}

const toolsApi = {
  getTools,
};

export default toolsApi;
