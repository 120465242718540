const MuiFormControl = {
  styleOverrides: {
    root: () => ({
      margin: 0,
      flexShrink: 0,
    }),
  },
};

export default MuiFormControl;
