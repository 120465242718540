import { Components } from '@mui/material/styles';
import variables from '../variables';
import typography from '../typography';

const MuiButtonGroup: Components['MuiButtonGroup'] = {
  styleOverrides: {
    root: () => ({
      button: {
        ...typography.body1,
        border: `1px solid ${variables.colors.greyLight}`,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 9,
        paddingBottom: 9,
        '&.MuiButton-outlined.MuiButtonGroup-grouped': {
          color: variables.colors.greyLight,
          '&:hover': {
            borderWidth: 1,
            color: variables.colors.greyDark,
            backgroundColor: variables.colors.white,
          },
        },
        '&.MuiButton-contained.MuiButtonGroup-grouped, &.MuiButton-contained.MuiButtonGroup-grouped:hover': {
          color: variables.colors.greyDark,
          backgroundColor: variables.colors.background3,
          borderColor: variables.colors.green,
          borderWidth: 1,
          '& + button.MuiButton-outlined.MuiButtonGroup-grouped': {
            borderLeftColor: variables.colors.green,
          },
        },
      },
    }),
  },
};

export default MuiButtonGroup;
