import {
  Accordion,
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  alpha,
  styled,
} from '@mui/material';
import { ProgressStatus } from '../../../../services/models/progress-status';
import { InfoTooltip } from '../../../../components/info';
import { shouldForwardProp } from '../../../../components/types';

type EnquiryLinePanelProps = {
  status: ProgressStatus;
};

export const EnquiryLinePanel = styled(Box, {
  shouldForwardProp: (prop) => shouldForwardProp<EnquiryLinePanelProps>(['status'], prop),
})<EnquiryLinePanelProps>(({ theme, status }) => {
  const commonProps = {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    border: `1px solid ${theme.palette.common.secondary200}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    minHeight: 50,
    '& h4': {
      flex: '1 1 auto',
    },
    '& > div': {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
    },
    '& > .MuiButton-root': {
      ...theme.typography.body2,
      fontWeight: 'bold',
      minWidth: 70,
    },
    '& svg': {
      fontSize: 24,
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      paddingLeft: theme.spacing(1),
      gap: theme.spacing(0.5),
      '& > svg': {
        fontSize: 22,
      },
      '& > .MuiIconButton-root': {
        width: 17,
      },
    },
  };
  if (status === ProgressStatus.Complete) {
    return {
      ...commonProps,
      backgroundColor: theme.palette.common.background3,
    };
  }
  if (status === ProgressStatus.Incomplete) {
    return {
      ...commonProps,
      backgroundColor: theme.palette.common.surface10,
      '.MuiButton-outlinedSecondary, .MuiButton-outlinedSecondary:disabled': {
        backgroundColor: 'transparent',
      },
    };
  }
  return commonProps;
});

export const DisclosureCard = styled(Card)(({ theme }) => ({
  '& .MuiCardContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    paddingTop: theme.spacing(1),
    '& > div:not(:first-of-type) > .MuiStack-root ': {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3),
    },
  },
}));

export const DisclosureCardHeader = styled(CardHeader)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.surface8, 0.42),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  minHeight: 65,
  '& .MuiCardHeader-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const DisclosureDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
}));

export const StyledInfoTooltip = styled(InfoTooltip)(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.common.surface2,
}));

export const StyledCardActions = styled(CardActions)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

export const StyledDisclosuresPanel = styled(Box)(({ theme }) => ({
  maxWidth: 576,
  minWidth: 576,
  [theme.breakpoints.down('lg')]: {
    maxWidth: 232,
    minWidth: 232,
  },
}));

export const StyledDisclosureStatus = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.common.greyLight,
  ...theme.typography.body2,
  '& > svg': {
    marginTop: -2,
    fontSize: 16,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: 12,
    '& > svg': {
      fontSize: 13,
    },
  },
}));

export const StyledDisclosureFormCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& > div': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
}));

export const StyledDisclosureButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type DisclosureAccordionProps = {
  expandable: boolean;
};

export const StyledDisclosureAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => shouldForwardProp<DisclosureAccordionProps>(['expandable'], prop),
})<DisclosureAccordionProps>(({ theme, expandable }) => ({
  '&:not(.Mui-expanded)': {
    marginBottom: theme.spacing(-3),
  },
  '& > .MuiAccordionSummary-root': {
    padding: 0,
    cursor: expandable ? 'pointer' : 'default',
  },
  '& .MuiAccordionSummary-content': {
    [theme.breakpoints.down('lg')]: {
      marginTop: 0,
    },
    '& > div': {
      flex: 1,
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        gap: theme.spacing(1),
      },
    },
  },
  '& .MuiAccordionDetails-root': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: 0,
  },
}));

export default {};
