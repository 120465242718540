import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import MuiChip from '@mui/material/Chip';
import MuiFormControl from '@mui/material/FormControl';
import { shouldForwardProp } from '../types';

type FormContainerProps = {
  condensed?: boolean;
};

export const FormContainer = styled('form', {
  shouldForwardProp: (prop) => shouldForwardProp<FormContainerProps>(['condensed'], prop),
})<FormContainerProps>(({ theme, condensed }) => {
  if (condensed) {
    return {
      '& label': {
        marginBottom: theme.spacing(0.5),
      },
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(1),
      },
      width: '100%',
    };
  }
  return {
    width: '100%',
    '& hr': {
      margin: `${theme.spacing(3)} 0`,
    },
  };
});

export const FormControl = styled(MuiFormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '& > label': {
    width: '100%',
    marginBottom: theme.spacing(0.5),
    '& > .MuiSvgIcon-root': {
      marginLeft: theme.spacing(0.5),
      marginTop: -6,
      top: 6,
      bottom: 0,
      position: 'relative',
    },
  },
  '& > div:first-of-type': {
    flex: 1,
  },
  '& .MuiOutlinedInput-root fieldset > legend': {
    width: 0,
  },
}));

export const FormStateIconContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '& > div:first-of-type': {
    marginTop: 5,
  },
  '& > button': {
    marginLeft: theme.spacing(0.5),
  },
}));

export const FormInputContainer = styled(MuiBox)(() => ({
  maxWidth: '100%',
}));

export const InputStatus = styled(MuiBox)(() => ({
  width: 150,
  flex: '0 0 auto',
  alignSelf: 'flex-start',
}));

export const InputStatisfied = styled(MuiChip)(({ theme }) => ({
  backgroundColor: theme.palette.common.background3,
  float: 'right',
}));
