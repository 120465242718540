import React, { FormEvent, useState } from 'react';
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../components/form/fields';
import AuthLayout from './auth-layout';
import { FormContainer } from '../../../components/form';
import { changePasswordSchema } from '../../../services/auth-helpers';

interface ChangePasswordProps {
  loading?: boolean;
  error?: string | null;
  success?: boolean | null;
  onChangePassword: (verificationCode: string, password: string) => void;
  onCancel: () => void;
}

function ChangePassword({
  onChangePassword,
  onCancel,
  loading = false,
  error = null,
  success = null,
}: ChangePasswordProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
  });

  const { formState: { isValid }, getValues } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { verficationCode, password } = getValues();
    onChangePassword(verficationCode, password);
  };

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom>{t('components.auth.changePassword.title')}</Typography>
          <Stack>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}
            {success === true ? (
              <>
                <Typography variant="h5">{t('components.auth.changePassword.confirmationTitle')}</Typography>
                <Typography variant="body2">{t('components.auth.changePassword.confirmationMessage')}</Typography>
              </>
            ) : (
              <>
                <TextField
                  name="verficationCode"
                  type="verficationCode"
                  sublabel={t('components.auth.changePassword.verificationCode')}
                />
                <TextField
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  sublabel={t('components.auth.changePassword.password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          sx={{ mr: 0 }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  sublabel={t('components.auth.changePassword.confirmPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                          sx={{ mr: 0 }}
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoadingButton
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  disabled={!isValid}
                >
                  {t('components.auth.changePassword.continue')}
                </LoadingButton>
              </>
            )}
          </Stack>
          <Button
            variant="text"
            color="info"
            size="small"
            sx={{ p: 0, mt: 2, mb: 2 }}
            onClick={onCancel}
          >
            {t('components.auth.changePassword.back')}
          </Button>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default ChangePassword;
