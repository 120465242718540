import { useCallback } from 'react';
import enquiryApi from '../services/enquiry-api';
import { useAppSelector } from '../store/hooks';
import { selectApplication } from '../features/application-slice';

function useOptionLookup(applicantId: string) {
  const application = useAppSelector(selectApplication);
  return useCallback((async (questionName: string, stem: string) => (
    enquiryApi.lookupOptions(application.id, applicantId, questionName, stem)
  )), [applicantId]);
}

export default useOptionLookup;
