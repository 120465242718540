import React from 'react';
import {
  Box,
  Stack,
} from '@mui/material';
import { Decision, ImmediateDecision } from '../../../../components/decision';
import { isImmediateDecision } from '../../../../services/decision-helpers';
import { TotalPrice } from '../price';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';

interface ProductDecisionAndPremiumProps {
  decision: ProductQuoteDecision | null;
}

function ProductDecisionAndPremium({ decision }: ProductDecisionAndPremiumProps) {
  return (
    <Stack direction="row" gap={4}>
      <Stack direction="row" gap={4} flexGrow={1} data-testid="product-decision">
        {decision?.decision && (
          <>
            <Decision decision={decision.decision} />
            {isImmediateDecision(decision.decision) && <ImmediateDecision />}
          </>
        )}
      </Stack>
      <Box sx={{ minWidth: 120, display: 'flex', justifyContent: 'flex-end' }}>
        {decision?.premiums && (
          <TotalPrice
            premium={decision.premiums}
            priceProps={{ size: 'medium' }}
            data-testid="product-premium"
          />
        )}
      </Box>
    </Stack>
  );
}

export default ProductDecisionAndPremium;
