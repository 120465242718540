import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

export const FirmRegistrationContainer = styled(Container)(({ theme }) => ({
  marginBottom: 80,
  '& h2': {
    marginTop: theme.spacing(4),
    fontFamily: theme.typography.h6.fontFamily,
  },
  '& .MuiFormLabel-root': {
    fontWeight: 800,
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiStack-root hr': {
    marginTop: theme.spacing(1),
  },
}));

export default {};
