import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { Enquiry } from '../../../../services/models/enquiry';
import PersonalInformationFull from './personal-information-full';
import ContactInformation from './contact-information';
import { View } from '../../../view';
import { DeleteClientButton, ClientDetailsStatusPanel as StyledClientDetailsStatusPanel } from './client-details-bar.styles';
import { ApplicantStatusDto } from '../../../../services/models/applicant-status-dto';
import { ApplicantStatusSectionName, getApplicantSectionStatus } from '../../../../services/application-helpers';
import useBusyState from '../../../../hooks/use-busy-state';
import { Delete } from '../../../../components/svg-icon';

interface ClientDetailsStatusPanelProps {
  applicant: ApplicantDto;
  applicantStatus: ApplicantStatusDto;
  enquiry?: Enquiry | null;
  hideEditPersonalDetails?: boolean;
  hideEditContactDetails?: boolean;
  onEdit: (view: View) => void;
  hideDelete?: boolean;
  onDelete: (applicantId: string) => unknown;
}

function ClientDetailsStatusPanel({
  applicant,
  applicantStatus,
  enquiry = null,
  hideEditPersonalDetails = false,
  hideEditContactDetails = false,
  onEdit,
  hideDelete = false,
  onDelete,
}: ClientDetailsStatusPanelProps) {
  const { t } = useTranslation();
  const [busy, withBusyState] = useBusyState();
  const personalInformationStatus = getApplicantSectionStatus(ApplicantStatusSectionName.PersonalInformation, applicantStatus);
  const contactInformationStatus = getApplicantSectionStatus(ApplicantStatusSectionName.ContactInformation, applicantStatus);
  const hadleDelete = withBusyState(() => onDelete(applicant.id));
  return (
    <StyledClientDetailsStatusPanel direction="row" gap={16}>
      <PersonalInformationFull
        applicant={applicant}
        enquiry={enquiry ?? undefined}
        status={personalInformationStatus}
        onEdit={hideEditPersonalDetails ? undefined : onEdit}
      />
      <ContactInformation
        applicant={applicant}
        status={contactInformationStatus}
        onEdit={hideEditContactDetails ? undefined : onEdit}
      />
      {!hideDelete && (
        <DeleteClientButton
          variant="text"
          onClick={hadleDelete}
          startIcon={!busy && <Delete />}
          loading={busy}
        >
          {t('components.clientDetailsStatusPanel.deleteClient')}
        </DeleteClientButton>
      )}
    </StyledClientDetailsStatusPanel>
  );
}

export default ClientDetailsStatusPanel;
