import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Container,
  SearchInput,
} from './search.styles';
import { useAppSelector } from '../../store/hooks';
import { selectOnboardingToolSettings, selectPresalesToolSettings } from '../../features/settings-slice';
import applicationApi from '../../services/application-api';
import { ApplicationDto as ApplicationSearchDto } from '../../services/bff/models/application-dto';
import { UnderwriteMeEnquiryType } from '../../services/models/underwrite-me-enquiry-type';
import { ApplicationPage } from '../pages';
import ApplicationTable from './components/application-table';
import { ApplicationQueryParams } from '../../services/application-helpers';
import InfiniteScroll from '../../components/infinite-scroll';
import { Select } from '../../components/select';
import { ApplicationStatus } from '../../services/models/application-status';
import analytics from '../../services/analytics';
import useStartApplication from '../../hooks/use-start-application';

const PAGE_ITEMS = 20;
const excludedFilters = [ApplicationStatus.PreSaleExpired, ApplicationStatus.QuoteExpired] as string[];

function Search() {
  const location = useLocation();
  const initialised = useRef(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [applications, setApplications] = useState<ApplicationSearchDto[]>();
  const [queryParams, setQueryParams] = useState<ApplicationQueryParams>({
    applicationId: null,
    from: 0,
    size: PAGE_ITEMS,
  });
  const navigate = useNavigate();
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled;
  const onpreSalesEnabled = useAppSelector(selectPresalesToolSettings)?.enabled;
  const { t } = useTranslation();
  const [selectOwnerDialog, handleStartApplication] = useStartApplication();

  const handleGetApplications = async (params: ApplicationQueryParams) => {
    try {
      setBusy(true);
      const response = await applicationApi.getApplications(params);
      setHasMore(response.length > 0 && response.length === PAGE_ITEMS);
      setApplications((state) => {
        if (params.from === 0) {
          return response;
        }
        return [...state ?? [], ...response];
      });
    } finally {
      setBusy(false);
    }
  };

  const handleContinueApplication = async (applicationId: string) => {
    navigate(generatePath(ApplicationPage.path, { applicationId }));
  };

  const performSearch = _.debounce((searchTerm) => {
    const newParams = {
      ...queryParams,
      searchTerm,
      from: 0,
      size: PAGE_ITEMS,
    };
    setQueryParams(newParams);
    handleGetApplications(newParams);
    analytics.trackSearchRequest(searchTerm);
  }, 1000);

  const handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    performSearch(value);
  };

  const handleLoadMore = async () => {
    if (!busy) {
      const newParams = {
        ...queryParams,
        from: applications?.length,
      };
      handleGetApplications(newParams);
    }
  };

  const handleColumnSort = (orderBy: string | null, descending: boolean | null) => {
    const newParams = {
      ...queryParams,
      from: 0,
      orderBy,
      descending,
    };
    setQueryParams(newParams);
    handleGetApplications(newParams);
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    const newParams = {
      ...queryParams,
      from: 0,
      status: value as ApplicationStatus,
    };
    setQueryParams(newParams);
    handleGetApplications(newParams);
  };

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      const newParams = {
        from: 0,
        size: PAGE_ITEMS,
        orderBy: 'id',
        descending: true,
        status: location.state?.status ?? undefined,
      };
      setQueryParams(newParams);
      handleGetApplications(newParams);
    }
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="h3" component="h1">
            {t('components.search.title')}
          </Typography>
          <Stack direction="row" gap={2}>
            {onpreSalesEnabled && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleStartApplication(UnderwriteMeEnquiryType.PreSales)}
              >
                {t('components.search.newPresalesEnquiry')}
              </Button>
            )}
            {onboardingEnabled && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleStartApplication(UnderwriteMeEnquiryType.Full)}
              >
                {t('components.search.newQuote')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Card>
          <CardHeader
            sx={{ p: 2.5 }}
            title={(
              <Stack direction="row" justifyContent="space-between">
                <SearchInput
                  InputProps={{
                    placeholder: 'Search reference or applicant',
                    endAdornment: <InputAdornment position="end" disablePointerEvents><SearchIcon /></InputAdornment>,
                  }}
                  onChange={handleSearchChange}
                />
                <Select
                  id="status"
                  name="status"
                  displayEmpty
                  options={Object.values(ApplicationStatus).filter((status) => !excludedFilters.includes(status)).map((value) => value)}
                  labelTranslationBasePath="common.applicationStatus"
                  value={queryParams.status ?? ''}
                  onChange={handleStatusFilterChange}
                  placeholder="Filter"
                />
              </Stack>
            )}
          />
          <CardContent sx={{ p: 2.5 }}>
            {!applications ? Array.from(Array(3).keys()).map((index) => <Skeleton key={index} variant="rectangular" width="100%" height={47} sx={{ mb: 1 }} />) : (
              <InfiniteScroll
                active
                loading={busy}
                hasMore={hasMore}
                onLoadMore={handleLoadMore}
              >
                <ApplicationTable
                  applications={applications ?? []}
                  onSelectApplication={handleContinueApplication}
                  sortProps={{
                    onSort: handleColumnSort,
                    orderBy: queryParams.orderBy,
                    descending: queryParams.descending,
                  }}
                />
              </InfiniteScroll>
            )}
          </CardContent>
        </Card>
      </Container>
      {selectOwnerDialog}
    </>
  );
}

export default Search;
