import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  '&:before': {
    content: 'none',
  },
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.palette.common.secondary200}`,
  },
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  gap: theme.spacing(4),
  '.MuiAccordionSummary-content': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    minHeight: 40,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

export const QuestionAndAswers = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(8),
  justifyContent: 'flex-start',
  '& > p:first-of-type': {
    flex: '0 1 75%',
  },
}));

export default {};
