import React from 'react';
import { InputAdornment } from '@mui/material';
import NumberInput, { NumberInputProps } from './number-input';

export const ZeroDecimalsMoneyFormatter = new Intl.NumberFormat('en-UK', {
  maximumFractionDigits: 0,
});

export const TwoDecimalsMoneyFormatter = new Intl.NumberFormat('en-UK', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export interface MoneyInputProps extends NumberInputProps { }

function MoneyInput({
  formatter = ZeroDecimalsMoneyFormatter,
  ...otherProps
}: MoneyInputProps) {
  return (
    <NumberInput
      formatter={formatter}
      startAdornment={<InputAdornment position="start" disablePointerEvents>£</InputAdornment>}
      {...otherProps}
    />
  );
}

export default MoneyInput;
