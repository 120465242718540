import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignSelf: 'flex-start',
  gap: theme.spacing(theme.mixins.mainHorizontalSpacing),
  width: '100%',
  '& > div': {
    '&.panel-enter': {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    '&.panel-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'opacity 300ms, transform 300ms',
    },
    '&.panel-exit': {
      opacity: 1,
    },
    '&.panel-exit-active': {
      opacity: 0,
      transform: 'scale(0.9)',
      transition: 'opacity 300ms, transform 300ms',
    },
  },
}));

export const ProductContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    '& > div': {
      flexDirection: 'column',
      alignItems: 'center',
      '& > div': {
        '&:not(:first-of-type)': {
          paddingTop: theme.spacing(4),
        },
        paddingBottom: theme.spacing(4),
      },
    },
  },
}));

export const FeaturePanelContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.background4,
  paddingLeft: 40,
  paddingRight: 40,
  paddingTop: 70,
  paddingBottom: 70,
  width: '100%',
  '& > div': {
    maxWidth: 340,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    '& > div': {
      marginBottom: theme.spacing(4),
    },
  },
}));

export default Container;
