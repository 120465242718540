import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analyticsService from '../../services/analytics';

function withTracker <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> {
  function Tracker(props: any) {
    const location = useLocation();

    useEffect(() => {
      analyticsService.trackPage(location.pathname);
    }, [location]);

    return <WrappedComponent {...props} />;
  }

  return Tracker;
}

export default withTracker;
