import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, StackProps } from '@mui/material';
import { Premium } from '../../../../services/models/premium';
import { TotalPremium } from '../../../../services/models/total-premium';
import { PermanentPremium } from '../../../../services/models/permanent-premium';
import { TemporaryPremium } from '../../../../services/models/temporary-premium';
import Price, { PriceProps } from './price';
import { isNonZeroPremium } from '../../../../services/decision-helpers';
import { Conjunction } from './price.styles';
import { ApplicantTotalPremium } from '../../../../services/models/applicant-total-premium';

function getPrices(premium: Premium | TotalPremium | ApplicantTotalPremium): (PermanentPremium | TemporaryPremium)[] {
  return [
    premium.permanentPremium,
    (premium as Premium).temporaryPremium,
    ...(premium as TotalPremium).temporaryPremiums ?? [],
  ].filter((p) => isNonZeroPremium(p)) as (PermanentPremium | TemporaryPremium)[];
}

export interface TotalPriceProps extends StackProps {
  premium: Premium | TotalPremium | ApplicantTotalPremium;
  frequency?: ReactNode;
  priceProps?: Omit<PriceProps, 'premium' | 'frequency'>;
}

function TotalPrice({
  premium,
  frequency = null,
  priceProps = undefined,
  ...props
}: TotalPriceProps) {
  const { t } = useTranslation();
  const prices = getPrices(premium);
  return (
    <Stack direction="row" alignItems="baseline" gap={1} {...props}>
      {prices.map((p, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {index > 0 && <Conjunction size={priceProps?.size}>{t('common.and')}</Conjunction>}
          <Price premium={p} frequency={frequency} {...priceProps} />
        </React.Fragment>
      ))}
    </Stack>
  );
}

export default TotalPrice;
