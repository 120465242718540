import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormContainer, FormHelpText } from '../../../../components/form';

export const DividerStyled = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.common.secondary200,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
}));

export const FormContainerStyled = styled(FormContainer)(({ theme }) => ({
  '& label': {
    marginBottom: theme.spacing(1),
  },
  'label.MuiInputLabel-formControl': {
    lineHeight: 1.5,
  },
  '& .MuiFormGroup-root': {
    paddingTop: theme.spacing(1),
  },
  '& .MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label': {
    fontFamily: theme.typography.h5.fontFamily,
  },
}));

export const ConfirmStatements = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  '& > ul': {
    margin: 0,
    '& > li': {
      ...theme.typography.body2,
      marginBottom: theme.spacing(1),
    },
  },
}));

export const PaymentHelpText = styled(FormHelpText)(({ theme }) => ({
  maxWidth: 325,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export default {};
