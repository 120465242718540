enum QuestionType {
  OPTION_BACKED = 'OPTION_BACKED',
  OPTION_LIST = 'OPTION_LIST',
  OPTION_GROUP = 'OPTION_GROUP',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  DATE = 'DATE',
  FUTURE_DATE = 'FUTURE_DATE',
  PAST_DATE = 'PAST_DATE',
}

export default QuestionType;
