import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import DirectDebigLogo from '../../../../assets/images/direct-debit.svg';

export const GuaranteeList = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: 20,
  '& > li:first-of-type': {
    marginBottom: theme.spacing(2),
  },
}));

function DirectDebitPanel() {
  const { t } = useTranslation();
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing(4),
        backgroundColor: theme.palette.common.background4,
      })}
    >
      <Stack direction="column">
        <img src={DirectDebigLogo} width={200} alt={t('components.directDebitPanel.logoAlt')!} />
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>{t('components.directDebitPanel.title')}</Typography>
        <Stack direction="row" gap={4}>
          <GuaranteeList>
            <li><Typography variant="body2">{t('components.directDebitPanel.items.0')}</Typography></li>
            <li><Typography variant="body2">{t('components.directDebitPanel.items.1')}</Typography></li>
          </GuaranteeList>
          <GuaranteeList>
            <li><Typography variant="body2">{t('components.directDebitPanel.items.2')}</Typography></li>
            <li><Typography variant="body2">{t('components.directDebitPanel.items.3')}</Typography></li>
          </GuaranteeList>
        </Stack>
      </Stack>
    </Box>
  );
}

export default DirectDebitPanel;
