import React from 'react';
import {
  Button,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import useBusyState from '../../../../hooks/use-busy-state';
import SequentialTaskQueue from '../../../../services/sequential-task-queue';
import applicationApi from '../../../../services/application-api';
import { patchBankDetails } from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';
import { handleError } from '../../../../components/form/form.utils';

const validStatusInformation = 'OK';

function isValidStatusInformation(statusInformation: string | null | undefined): boolean {
  return statusInformation === validStatusInformation;
}

export interface BankDetailsValidationProps {
  applicationId: string;
  accountNumber: string | null | undefined;
  sortCode: string | null | undefined;
  validated: boolean;
  disabled?: boolean;
  queue: SequentialTaskQueue;
  setErrors: (errors: string[]) => void;
}

function BankDetailsValidation({
  applicationId,
  accountNumber,
  sortCode,
  queue,
  validated,
  disabled = undefined,
  setErrors,
}: BankDetailsValidationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [busy, withBusyState] = useBusyState();
  const [validating, withValidatingState] = useBusyState();

  const validateBankDetails = withValidatingState(queue.sequentialize(async () => {
    if (!accountNumber || !sortCode) {
      return;
    }
    try {
      setErrors([]);
      const result = (await applicationApi.validateBankAccount(accountNumber, sortCode)).items?.[0];
      const status = result?.statusInformation;
      if (result?.isCorrect) {
        await dispatch(patchBankDetails(applicationId, { accountValidation: isValidStatusInformation(status) }));
      } else {
        if (validated) {
          await dispatch(patchBankDetails(applicationId, { accountValidation: null }));
        }
        const translatedError = t(`errors.bankAccountValidation.${status}`, result ?? {});
        const message = !translatedError || translatedError.startsWith('errors.')
          ? t('errors.bankAccountValidation.UknownError', { status })
          : translatedError;
        setErrors([message]);
      }
    } catch (e) {
      handleError(e, (message) => {
        setErrors([message]);
      });
    }
  }));

  const modifyValidatedAccount = withBusyState(queue.sequentialize(async () => {
    try {
      await dispatch(patchBankDetails(applicationId, { accountValidation: null }));
    } catch (e) {
      handleError(e, (message) => {
        setErrors([message]);
      });
    }
  }));

  return (
    <Stack direction="row" gap={4} alignItems="center">
      {validated && (
        <>
          <Stack direction="row" gap={1} alignItems="center">
            <CheckCircleOutlineIcon color="success" />
            <Typography
              variant="body1"
              fontWeight="bold"
            >
              {t('components.bankDetailsValidation.validated')}
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            color="secondary"
            onClick={modifyValidatedAccount}
            disabled={busy || disabled}
          >
            {t('components.bankDetailsValidation.change')}
          </Button>
        </>
      )}
      {!validated && (
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={validateBankDetails}
          loading={validating}
          disabled={disabled || busy}
        >
          {t('common.validate')}
        </LoadingButton>
      )}
    </Stack>
  );
}

export default BankDetailsValidation;
