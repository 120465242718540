import http, { query } from './http';
import { ApplicantDto } from './models/applicant-dto';
import { Enquiry } from './models/enquiry';
import { EnquiryCollation } from './models/enquiry-collation';
import { QuestionAnswerDto } from './models/question-answer-dto';

/**
 * Closes an enquiry.
 */
async function close(applicationId: string, applicantId: string, collation?: EnquiryCollation): Promise<Enquiry> {
  return http.post(`/api/v1/bff/Enquiry/${applicationId}/applicant/${applicantId}/close`, undefined, query({ collation }));
}

/**
 * Retrieve an enquiry by id.
 */
async function getForApplicant(applicationId: string, applicantId: string, collation?: EnquiryCollation): Promise<Enquiry> {
  return http.get(`/api/v1/bff/Enquiry/${applicationId}/applicant/${applicantId}`, { collation });
}

async function getForApplicantWithLocale(applicationId: string, applicantId: string, locale: string, collation?: EnquiryCollation): Promise<Enquiry> {
  return http.get(`/api/v1/bff/Enquiry/${applicationId}/applicant/${applicantId}`, { locale, collation });
}

async function getAllFor(applicationId: string, applicants: ApplicantDto[], collation?: EnquiryCollation): Promise<Record<string, Enquiry>> {
  const enquiriesArray = await Promise.all(applicants.map((applicant) => getForApplicant(applicationId, applicant.id, collation)));
  return enquiriesArray.reduce((result, enquiry) => ({ ...result, [enquiry.enquiryId]: enquiry }), {});
}

async function lookupOptions(applicationId: string, applicantId: string, questionName: string, stem: string): Promise<string[]> {
  return http.get(`/api/v1/bff/Enquiry/${applicationId}/applicant/${applicantId}/lookup-question-option/${questionName}/${stem}`);
}

/**
 * Post answers for an enquiry.
 */
async function postAnswers(applicationId: string, applicantId: string, answers: QuestionAnswerDto[], collation?: EnquiryCollation): Promise<Enquiry> {
  return http.post(`/api/v1/bff/Enquiry/${applicationId}/applicant/${applicantId}/answers`, answers, query({ collation }));
}

const enquiryApi = {
  close,
  getAllFor,
  getForApplicant,
  getForApplicantWithLocale,
  lookupOptions,
  postAnswers,
};

export default enquiryApi;
