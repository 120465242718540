import { PreSalesDecision } from './models/pre-sales-decision';
import { ReasonDecision } from './models/reason-decision';
import { PreSalesDecisionResult } from './models/pre-sales-decision-result';
import { DecisionType } from './product-helpers';
import { RangedPermanentLoading } from './models/ranged-permanent-loading';
import { RangedTemporaryLoading } from './models/ranged-temporary-loading';
import { PermanentPremium } from './models/permanent-premium';
import { TemporaryPremium } from './models/temporary-premium';
import { Premium } from './models/premium';
import { RangedLoading } from './models/ranged-loading';
import { QuoteDecision } from './models/quote-decision';
import { ProductQuoteDecision } from './models/product-quote-decision';
import { ApplicantQuoteDecision } from './models/applicant-quote-decision';

export function isImmediateDecision(decision: DecisionType | string | undefined | null): boolean {
  return decision === DecisionType.STANDARD || decision === DecisionType.NON_STANDARD;
}

export function isManualUnderwritingDecision(decision: DecisionType | string | undefined | null): boolean {
  return decision === DecisionType.EVIDENCE_REQUIRED || decision === DecisionType.REFER;
}

export function isUnfavorableDecision(decision: DecisionType | string | undefined | null): boolean {
  return decision === DecisionType.POSTPONE || decision === DecisionType.DECLINE;
}

export const preSalesDecisionsHasEvidence = (
  decisions: PreSalesDecision[],
): boolean => decisions.some(
  (decision) => decision.decision !== DecisionType.DECLINE && decision.consolidatedEvidences && decision.consolidatedEvidences.length > 0,
);

export function hasIndicativeLoading(loading: RangedPermanentLoading | RangedTemporaryLoading | null | undefined): boolean {
  return !!loading?.min || !!loading?.max;
}

export function hasAnyRangedLoadings(loading?: { em?: RangedLoading, pm?: RangedLoading }) {
  return (
    hasIndicativeLoading(loading?.em?.permanent)
    || hasIndicativeLoading(loading?.em?.temporary)
    || hasIndicativeLoading(loading?.pm?.permanent)
    || hasIndicativeLoading(loading?.pm?.temporary)
  );
}

export function hasAnyLoadings(reasonDecision: ReasonDecision) {
  return (
    reasonDecision.em?.permanent?.total
    || reasonDecision.em?.temporary?.total
    || reasonDecision.pm?.permanent?.total
    || reasonDecision.pm?.temporary?.total
  );
}

export function hasAnyIndicativeLoadings({ indicative }: ReasonDecision) {
  return hasAnyRangedLoadings(indicative);
}

export function hasAnyIndicativeExclusions({ indicative }: ReasonDecision) {
  return indicative?.exclusions && indicative.exclusions.length > 0;
}

export function hasAnyIndicativeDecision({ indicative }: ReasonDecision) {
  return indicative?.decision;
}

export function hasAnyTotalRatingLoadings({ totalRating }: PreSalesDecision) {
  return hasAnyRangedLoadings(totalRating);
}

export function hasEvidence(reasonDecision: ReasonDecision): boolean {
  if (reasonDecision.evidences && reasonDecision.evidences.length > 0) {
    return true;
  }
  return false;
}

export function hasExclusions(reasonDecision: ReasonDecision): boolean {
  if (reasonDecision.exclusions && reasonDecision.exclusions.length > 0) {
    return true;
  }
  return false;
}

export const preSalesDecisionHasExclusions = (
  decision: PreSalesDecision,
): boolean => {
  if (!isUnfavorableDecision(decision.decision) && decision.reasonDecisions) {
    return decision.reasonDecisions!.some((reasonDecision) => hasExclusions(reasonDecision));
  }
  return false;
};

export const preSalesDecisionsHasExclusions = (
  decisions: PreSalesDecision[],
): boolean => decisions.some(preSalesDecisionHasExclusions);

export const preSalesDecisionsHasTotalRating = (
  decisions: PreSalesDecision[],
): boolean => decisions.some((decision) => decision?.totalRating && hasAnyTotalRatingLoadings(decision));

export const preSalesDecisionsHasPremium = (decisions: PreSalesDecision[]): boolean => (
  decisions.some((decision) => decision?.premium));

export function formatEmLoading(value: number | null | undefined): string {
  const numericValue = value || 0;
  return numericValue >= 0 ? `+${numericValue}%` : `${numericValue}%`;
}

export function formatPmLoading(value: number): string {
  return `${value} per mille`;
}

export function formatIndicativePmLoading(loading: RangedPermanentLoading | RangedTemporaryLoading): string {
  if (loading.min != null && loading.min === loading.max) {
    return formatPmLoading(loading.min);
  }
  return `${loading.min} to ${loading.max} per mille`;
}

export function groupReasonDecisionsByReason(decisions: PreSalesDecision[]): Record<string, ReasonDecision[]> {
  const reasons = Array.from(new Set(decisions.flatMap((decision) => decision.reasonDecisions?.map((it) => it.reason!) || []))).sort();

  return reasons.reduce((values, reason) => ({
    ...values,
    [reason]: decisions.map((decision) => (
      decision.reasonDecisions?.find((reasonDecision) => reasonDecision.reason === reason)
    )),
  }), {});
}

export function hasApplicantPreSalesDecision(applicantId: string | null | undefined, decisions: Record<string, PreSalesDecisionResult>): boolean {
  if (applicantId
    && decisions[applicantId]
    && decisions[applicantId].preSalesDecisions
    && decisions[applicantId].preSalesDecisions!.length > 0
  ) {
    return true;
  }
  return false;
}

export function reasonDecisionIsNotStandard(reasonDecision: ReasonDecision): boolean {
  return reasonDecision.decision !== DecisionType.STANDARD;
}

export function hasApplicantReasonDecisions(applicantId: string | null | undefined, productQuoteDecision: ProductQuoteDecision): boolean {
  const applicantDecision = productQuoteDecision.applicantDecisions.find((ad) => ad.applicantId === applicantId);
  if (applicantDecision) {
    return applicantDecision.reasonDecisions.length > 0 && applicantDecision.reasonDecisions.some(reasonDecisionIsNotStandard);
  }
  return false;
}

export function reasonDecisionHasNoInd(reasonDecision: ReasonDecision): boolean {
  return reasonDecision.indicative?.noInd === true;
}

export function decisionHasNoInd(decision: PreSalesDecision): boolean {
  return !!decision.reasonDecisions?.some(reasonDecisionHasNoInd);
}

export function decisionsHasNoInd(decisions: PreSalesDecision[]): boolean {
  return decisions.some(decisionHasNoInd);
}

export function isNonZeroPremium(premium: PermanentPremium | TemporaryPremium | null | undefined) {
  return premium && (premium.min || premium.max);
}

export function hasNonZeroPremium(premium: Premium) {
  return isNonZeroPremium(premium.permanentPremium) || isNonZeroPremium(premium.temporaryPremium);
}

export function getProductDecision(decision: QuoteDecision | null, productId: string): ProductQuoteDecision | null {
  return decision?.products.find((p) => p.id === productId) ?? null;
}

export function getProductDecisionExclusions(decision: ApplicantQuoteDecision): string[] {
  const exclusions = decision.reasonDecisions.reduce((acc, curr) => {
    if (curr?.exclusions) {
      return acc.concat(curr.exclusions.map((exclusion) => `${exclusion.shortText} - ${exclusion.text}`));
    }
    return acc;
  }, [] as string[]);

  return Array.from(new Set(exclusions));
}
