import {
  Stepper,
  Step,
  styled,
  Theme,
  StepLabel,
} from '@mui/material';
import { shouldForwardProp } from '../../../../components/types';
import { ProgressStatus } from '../../../../services/models/progress-status';

export const StatusStepper = styled(Stepper)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.secondary200}`,
  borderRadius: 24,
  display: 'inline-flex',
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  '& > div:first-of-type:has(.Mui-completed)': {
    marginLeft: theme.spacing(1),
  },
  '& > div:last-of-type > .Mui-disabled': {
    marginRight: theme.spacing(1),
  },
}));

export const StatusStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    ...theme.typography.body2,
    display: 'block',
    marginTop: 2,
  },
  '& .MuiStepLabel-iconContainer': {
    paddingRight: 0,
  },
}));

function getApplicationStatusColor(status: ProgressStatus, theme: Theme): string {
  switch (status) {
    case ProgressStatus.Complete: return theme.palette.common.green;
    case ProgressStatus.NotStarted: return 'transparent';
    case ProgressStatus.Incomplete: return theme.palette.common.orange;
    default: return 'transparent';
  }
}

type StatusStepProps = {
  status: ProgressStatus;
  active: boolean;
};

export const StatusStep = styled(Step, {
  shouldForwardProp: (prop) => shouldForwardProp<StatusStepProps>(['status', 'active'], prop),
})<StatusStepProps>(({ theme, active }) => ({
  border: active ? `1px solid ${theme.palette.common.secondary200}` : 'none',
  borderRadius: 24,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  margin: -1,
  minHeight: 48,
  paddingLeft: active ? theme.spacing(1) : 0,
  paddingRight: active ? theme.spacing(2) : 0,
  '& .MuiStepLabel-label.Mui-active': {
    marginLeft: theme.spacing(0),
  },
}));

type StatusStepIconProps = {
  status: ProgressStatus;
  active: boolean;
};

export const StatusStepIcon = styled('span', {
  shouldForwardProp: (prop) => shouldForwardProp<StatusStepIconProps>(['status', 'active'], prop),
})<StatusStepIconProps>(({ theme, status, active }) => {
  let border = status === ProgressStatus.NotStarted ? `2px solid ${theme.palette.common.secondary600}` : 'none';
  if (active && status === ProgressStatus.NotStarted) {
    border = `2px solid ${theme.palette.common.green}`;
  }
  return {
    width: 18,
    height: 18,
    borderRadius: '50%',
    border,
    backgroundColor: getApplicationStatusColor(status, theme),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  };
});

export default {};
