import React from 'react';
import FieldBase, { FieldBaseProps } from './field-base';
import TypeaheadInput, { TypeaheadInputProps } from '../inputs/typeahead-input';

interface TypeaheadFieldProps extends TypeaheadInputProps, FieldBaseProps {
}

function TypeaheadField({
  name,
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: TypeaheadFieldProps) {
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={{ ...LabelProps, htmlFor: name }}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <TypeaheadInput name={name} {...props} />
    </FieldBase>
  );
}

export default TypeaheadField;
