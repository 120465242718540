import React, { PropsWithChildren } from 'react';
import {
  Accordion,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { CommissionAccordionDetails, CommissionAccordionSummary } from './commission-details-accordion.styles';
import { QuoteCommission } from '../../services/models/quote-commission';
import { Price } from '../../routes/application/components/price';
import { CommissionStyle } from '../../services/models/commission-style';
import { numberToMoney } from '../../utils/converters';

export interface CommissionDetailsProps extends PropsWithChildren {
  productId: string;
  commission: QuoteCommission | null | undefined;
}

function CommissionDetailsAccordion({ productId, commission, children }: CommissionDetailsProps) {
  const { t } = useTranslation();
  const initalCommissionLabelId = `${productId}-initial-commission`;
  const renewalCommissionLabelId = `${productId}-renewal-commission`;
  const commissionPremium = commission?.style === CommissionStyle.NonIndemnity ? commission?.initialCommission : commission?.total;
  return (
    <Accordion disableGutters>
      <CommissionAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${productId}-commission-content`}
        id={`${productId}-commission-header`}
      >
        <Typography variant="h4">{t('components.commissionDetails.title')}</Typography>
      </CommissionAccordionSummary>
      <CommissionAccordionDetails>
        <Stack direction="row" gap={2} flexWrap="wrap">
          <Box sx={{ minHeight: 40 }}>
            <Typography variant="body2" id={initalCommissionLabelId}>
              {t('components.commissionDetails.initialCommission')}
            </Typography>
            {commission && commissionPremium && (
              <Price
                premium={commissionPremium}
                size="small"
                aria-labelledby={initalCommissionLabelId}
                postText={commission.style === CommissionStyle.NonIndemnity ? t('components.commissionDetails.paidInstallments') : t('components.commissionDetails.paidUpfront')}
              />
            )}
          </Box>
          <Box sx={{ minHeight: 40 }}>
            <Typography variant="body2" id={renewalCommissionLabelId}>
              {t('components.commissionDetails.renewalCommission')}
            </Typography>
            {commission?.renewalAmount && (
              <Typography variant="body2" fontWeight="bold" aria-labelledby={renewalCommissionLabelId}>
                {t('components.commissionDetails.renewalValue', {
                  renewalAmount: numberToMoney(commission.renewalAmount, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                  renewalMonth: commission.renewalMonth,
                })}
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack direction="row" gap={2} flexWrap="wrap">
          {children}
        </Stack>
      </CommissionAccordionDetails>
    </Accordion>
  );
}

export default CommissionDetailsAccordion;
