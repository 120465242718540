import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelAndValue from '../label-and-value/label-and-value';
import { ProductDetailsProps } from './product-details-types';

function HealthCareDetails({ product, labelAndValueProps: attributeProps, ...props }: ProductDetailsProps) {
  const { t } = useTranslation();
  return (
    <Stack direction="row" gap={4} flexWrap="wrap" {...props}>
      <LabelAndValue
        label={t('components.healthCareDetails.mentalHealthCover')}
        value={product.mentalHealthCover ? t('common.yes') : t('common.no')}
        {...attributeProps}
      />
    </Stack>
  );
}

export default HealthCareDetails;
