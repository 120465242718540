import { useEffect } from 'react';

const useFocusScroll = (containerRef: React.RefObject<HTMLDivElement>, offsetHeight: number = 0) => {
  useEffect(() => {
    const scrollIntoViewIfNeeded = (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      const container = containerRef.current?.getBoundingClientRect();
      const isBelowView = rect.top < 0 || rect.bottom > (container?.height ?? window.innerHeight);
      if (isBelowView && containerRef.current) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        containerRef.current.scrollBy(0, offsetHeight);
      }
    };

    const handleInputFocus = () => {
      if (containerRef.current) {
        const focusedInput = containerRef.current.querySelector(':focus') as HTMLElement | null;
        if (focusedInput) {
          scrollIntoViewIfNeeded(focusedInput);
        }
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('focusin', handleInputFocus);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('focusin', handleInputFocus);
      }
    };
  }, [containerRef, offsetHeight]);
};

export default useFocusScroll;
