import React from 'react';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { RatingsTooltip } from './reason-decision-ratings-tooltip.styles';
import ReasonDecisionRatings from './reason-decision-ratings';
import ReasonDecisionIndicativeRatings from './reason-decision-indicative-ratings';

interface ReasonDecisionRatingsTooltipProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionRatingsTooltip(props: ReasonDecisionRatingsTooltipProps) {
  return (
    <RatingsTooltip>
      <ReasonDecisionRatings {...props} />
      <ReasonDecisionIndicativeRatings {...props} />
    </RatingsTooltip>
  );
}

export default ReasonDecisionRatingsTooltip;
