import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import { preSalesDecisionHasExclusions } from '../../../../services/decision-helpers';
import { TextWithInfo } from '../../../../components/info';
import { Exclusion } from '../../../../services/models/exclusion';

export interface ExclusionsProps {
  decision: PreSalesDecision
}

function Exclusions({
  decision,
}: ExclusionsProps) {
  const { t } = useTranslation();

  if (decision && !preSalesDecisionHasExclusions(decision)) {
    return (
      <Typography variant="body2">
        {t('components.exclusions.none')}
      </Typography>
    );
  }

  return decision.reasonDecisions!
    .flatMap((reasonDecision) => reasonDecision.exclusions)
    .reduce((acc, curr) => {
      if (curr && !acc.some((exclusion: Exclusion) => exclusion.code === curr.code)) {
        acc.push(curr);
      }
      return acc;
    }, [] as Exclusion[])
    .map((exclusion) => (
      <TextWithInfo
        variant="body2"
        fontWeight="bold"
        info={exclusion?.text}
        key={exclusion?.code}
      >
        {exclusion?.shortText}
      </TextWithInfo>
    ));
}

export default Exclusions;
