import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.background3,
  display: 'flex',
  height: 325,
  marginTop: theme.spacing(-2),
  marginLeft: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  paddingLeft: 85,
  paddingRight: 85,
  position: 'relative',
  '& > h1': {
    width: 600,
    [theme.breakpoints.down('lg')]: {
      width: 500,
    },
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
  '& > img': {
    position: 'absolute',
    right: 85,
    top: 32,
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.75)',
      right: 16,
      top: 64,
    },
  },
}));

export default {};
