import { styled } from '@mui/material/styles';
import { TableContainer } from '@mui/material';

export const NotificationsTableContainer = styled(TableContainer)(({ theme }) => ({
  '& table > thead > tr > th': {
    backgroundColor: theme.palette.common.background4,
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    ...theme.typography.h5,
    fontSize: theme.typography.body2.fontSize,
  },
  '& table > tbody > tr > td > button': {
    textDecoration: 'underline',
    color: theme.palette.common.blue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})) as typeof TableContainer;

export default {};
