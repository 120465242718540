import React, {
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { UnderwriteMeEnquiryType } from '../services/models/underwrite-me-enquiry-type';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { startApplication } from '../features/application-slice';
import { ApplicationPage } from '../routes/pages';
import useNavigateWithEvent from '../components/analytics/navigate-event';
import analytics from '../services/analytics';
import OwnerDialog from '../components/owner-dialog';
import { selectCurrentUserAdvisorDetails, selectUser } from '../features/settings-slice';
import { Role, userHasAnyRoleOf } from '../services/profile-helpers';

type SelectOwnerState = null | { enquiryType: UnderwriteMeEnquiryType };

function useStartApplication(): [ReactNode, (enquiryType: UnderwriteMeEnquiryType) => Promise<void>] {
  const { t } = useTranslation();
  const navigate = useNavigateWithEvent();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userIsParaplanner = user && userHasAnyRoleOf(user, [Role.PARAPLANNER]);
  const advisor = useAppSelector(selectCurrentUserAdvisorDetails);
  const firm = advisor?.firmName && advisor.firmFRN
    ? { name: advisor.firmName, frn: advisor.firmFRN }
    : undefined;
  const [selectOwner, setSelectOwner] = useState<SelectOwnerState>(null);

  const startNewApplication = useCallback(async (enquiryType: UnderwriteMeEnquiryType, ownerId?: string) => {
    const application = await dispatch(startApplication(enquiryType, ownerId));
    analytics.trackNewApplication(application.id, enquiryType);
    navigate(generatePath(ApplicationPage.path, { applicationId: application.id || null }), {}, { action: 'New application', category: enquiryType });
  }, []);

  const handleStartApplication = useCallback(async (enquiryType: UnderwriteMeEnquiryType) => {
    if (userIsParaplanner) {
      setSelectOwner({ enquiryType });
    } else {
      await startNewApplication(enquiryType);
    }
  }, [userIsParaplanner]);

  const hadleApplicationOwnerSelected = useCallback(async (ownerId: string) => {
    if (selectOwner) {
      setSelectOwner(null);
      await startNewApplication(selectOwner.enquiryType, ownerId);
    }
  }, [selectOwner]);

  const dialog = selectOwner
    ? (
      <OwnerDialog
        open
        title={t('components.dashboard.selectOwner.title')}
        confirmLabel={t('components.dashboard.selectOwner.confirm')}
        onClose={() => setSelectOwner(null)}
        onConfirm={hadleApplicationOwnerSelected}
        firm={firm}
      />
    )
    : null;
  return [dialog, handleStartApplication];
}

export default useStartApplication;
