import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';

interface FeaturePanelProps {
  title: string
  description: string
  icon: string
}

function FeaturePanel({ title, description, icon }: FeaturePanelProps) {
  return (
    <Stack>
      <Box sx={{ mb: 2, textAlign: 'center' }}><img src={icon} height={64} alt={title} /></Box>
      <Typography variant="h3" align="center" color="secondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {description}
      </Typography>
    </Stack>
  );
}

export default FeaturePanel;
