import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Footer as StyledFooter } from './footer.styles';
import FaqIcon from '../../../assets/icons/faq-icon.svg';
import { Drawer } from '../../../routes/application/components/application-layout/application-layout.styles';
import FaqDrawer from './faq-drawer';

function Footer() {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <StyledFooter>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Button variant="text" color="secondary" sx={{ gap: '8px' }} onClick={() => setDrawerOpen(true)}>
          <img src={FaqIcon} alt={t('components.footer.instructions')!} />
          {t('components.footer.instructions')}
        </Button>
      </Stack>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
      >
        <FaqDrawer onCancel={() => setDrawerOpen(false)} />
      </Drawer>
    </StyledFooter>
  );
}

export default Footer;
