import React, { PropsWithChildren, ReactNode } from 'react';
import { Layout, MainContent } from '../../../../components/layout';
import ApplicationHeader from '../../../application/components/application-layout/application-header';

interface PolicySetupLayoutLayoutProps extends PropsWithChildren {
  info?: ReactNode;
  footer?: ReactNode;
}

function PolicySetupLayoutLayout({
  children,
  info = undefined,
  footer = undefined,
}: PolicySetupLayoutLayoutProps) {
  return (
    <Layout header={<ApplicationHeader />} info={info} footer={footer}>
      <MainContent maxWidth={false}>
        {children}
      </MainContent>
    </Layout>
  );
}

export default PolicySetupLayoutLayout;
