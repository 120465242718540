import { alpha, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { shouldForwardProp } from '../../../../components/types';

type ContainerProps = {
  enabled: boolean;
};

export const Container = styled(Stack, {
  shouldForwardProp: (prop) => shouldForwardProp<ContainerProps>(['enabled'], prop),
})<ContainerProps>(({ theme, enabled }) => ({
  paddingTop: 85,
  paddingBottom: 55,
  width: 200,
  '& h2': {
    borderBottom: '6px solid',
    borderColor: enabled ? theme.palette.primary.main : alpha(theme.palette.common.greyLight, 0.3),
    color: enabled ? theme.palette.common.greyDark : theme.palette.common.greyLight,
  },
  '& h3': {
    color: enabled ? theme.palette.common.greyDark : theme.palette.common.greyLight,
  },
}));

export default {};
