import { RegistrationStep } from '../services/registration-helpers';

export interface View {
  infoBar: null | 'clientDetails';
  mainContent: null | 'products' | RegistrationStep;
  drawer: null
  | { type: 'clientDetailsBasic' }
  | { type: 'contactDetails', next?: View }
  | { type: 'lifestyle' }
  | { type: 'condition', conditionPath: string }
  | { type: 'preUnderwritingDeclaration', next: View }
  | { type: 'postUnderwritingDeclaration' }
  | { type: 'amraDeclaration' }
  | { type: 'amraDoctorDetails' }
}

export type ProceedOptions = {
  force?: boolean;
  target?: View;
};

export type ProceedFunction = (options?: ProceedOptions) => void;

export const emptyView: View = { infoBar: null, mainContent: null, drawer: null } as const;
export const newApplicantView: View = { infoBar: null, mainContent: null, drawer: { type: 'clientDetailsBasic' } } as const;
export const editApplicantView: View = { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'clientDetailsBasic' } } as const;
export const contactDetailsView: (next?: View) => View = (next) => (
  { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'contactDetails', next } } as const
);
export const productView: View = { infoBar: 'clientDetails', mainContent: 'products', drawer: null } as const;
export const lifestyleView: View = { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'lifestyle' } } as const;
export const conditionView: (conditionPath: string) => View = (conditionPath) => (
  { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'condition', conditionPath } } as const
);
export const preUnderwritingDeclarationView: (next: View) => View = (next) => (
  { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'preUnderwritingDeclaration', next } } as const
);
export const postUnderwritingDeclarationView: View = { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'postUnderwritingDeclaration' } } as const;
export const amraDeclarationView: View = { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'amraDeclaration' } } as const;
export const amraDoctorDetailsView: View = { infoBar: 'clientDetails', mainContent: 'products', drawer: { type: 'amraDoctorDetails' } } as const;

export const firmReferenceView: View = { infoBar: null, mainContent: RegistrationStep.FCAREFERENCE, drawer: null } as const;
export const firmDetailsView: View = { infoBar: null, mainContent: RegistrationStep.FIRMDETAILS, drawer: null } as const;
