import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDecisionChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.surface5}`,
  flexDirection: 'row-reverse',
  '& > .MuiChip-icon': {
    marginRight: 5,
    marginLeft: -6,
  },
  '& .MuiChip-label': {
    ...theme.typography.body1,
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

export default {};
