import React from 'react';
import {
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  hasNonZeroPremium,
  isManualUnderwritingDecision,
  reasonDecisionHasNoInd,
} from '../../../../services/decision-helpers';
import { TextWithInfo } from '../../../../components/info';
import { TotalPrice } from '../price';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import ReasonDecisionRatingsTooltip from './reason-decision-ratings-tooltip';

export interface ReasonDecisionRatingsProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionPremium({ id, reasonDecision }: ReasonDecisionRatingsProps) {
  const { t } = useTranslation();
  if (!hasNonZeroPremium(reasonDecision)) {
    return null;
  }
  const labelId = `${id}_premium`;
  const hasNoInd = reasonDecisionHasNoInd(reasonDecision);

  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {t(isManualUnderwritingDecision(reasonDecision.decision) ? 'components.reasonDecisionCard.indicativeRating' : 'components.reasonDecisionCard.rating')}
      </Typography>
      {hasNoInd ? (
        <TextWithInfo
          variant="body2"
          fontWeight="bold"
          info={t('components.deferredPeriods.unavailableInfo')}
          aria-labelledby={labelId}
        >
          {t('components.deferredPeriods.unavailable')}
        </TextWithInfo>
      ) : (
        <TextWithInfo
          component="div"
          info={<ReasonDecisionRatingsTooltip reasonDecision={reasonDecision} id={id} />}
        >
          <TotalPrice premium={reasonDecision} priceProps={{ size: 'small', showPlusSign: true }} aria-labelledby={labelId} />
        </TextWithInfo>
      )}
    </section>
  );
}

export default ReasonDecisionPremium;
