import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CancelDrawerButton,
  DrawerCard,
  DrawerCardContent,
  DrawerCardHeader,
} from '../../drawer-card';
import FaqCard from '../../faq-card';

type FaqItem = {
  question: string,
  answer: string,
};

interface FaqDrawerProps {
  onCancel: () => unknown;
}

function FaqDrawer({ onCancel }: FaqDrawerProps) {
  const { t } = useTranslation();

  return (
    <DrawerCard>
      <DrawerCardHeader
        title={t('components.faqDrawer.title')}
        action={<CancelDrawerButton onClick={onCancel} />}
      />
      <DrawerCardContent sx={{ paddingBottom: 0, overflowY: 'scroll', height: 'calc(100% - 96px)' }}>
        {t<string, FaqItem[]>('components.faqDrawer.faqs', { returnObjects: true }).map((faq: FaqItem) => (
          <FaqCard title={faq.question} sx={{ mb: 2 }} key={faq.question}>
            {faq.answer}
          </FaqCard>
        ))}
      </DrawerCardContent>
    </DrawerCard>
  );
}

export default FaqDrawer;
