import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

function CancelDrawerButton(props: IconButtonProps) {
  const { t } = useTranslation();
  return (
    <IconButton aria-label={t('common.cancel')!} {...props}>
      <CloseIcon />
    </IconButton>
  );
}

export default CancelDrawerButton;
