import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Evidence } from '../../../../services/models/evidence';

interface ReasonDecisionEvidencesProps {
  id: string;
  evidences: Evidence[] | null | undefined;
}

function ReasonDecisionEvidences({ id, evidences }: ReasonDecisionEvidencesProps) {
  const { t } = useTranslation();
  if (!evidences || evidences.length === 0) {
    return null;
  }
  const labelId = `${id}_evidence`;
  return (
    <section>
      <Typography variant="body2" component="label" id={labelId}>
        {t('components.reasonDecisionCard.evidence')}
      </Typography>
      <Stack aria-labelledby={labelId}>
        {evidences.map((evidence) => (
          <Typography variant="body2" fontWeight="bold" key={evidence.code}>{evidence.text}</Typography>
        ))}
      </Stack>
    </section>
  );
}

export default ReasonDecisionEvidences;
