import React, {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import AuthLayout from './auth-layout';
import { FormContainer } from '../../../components/form';
import { verificationCodeSchema } from '../../../services/auth-helpers';
import { Divider, VerficationCodeInputContainer } from './auth.styles';

const CODE_LENGTH = 6;

function isValidValidationCode(code: string[]): boolean {
  return code.join('').length === CODE_LENGTH;
}

interface VerificationCodeProps {
  phoneNumber: string;
  loading?: boolean;
  error?: string | null;
  success?: boolean | null;
  onVerificationCode: (verificationCode: string, rememberMe: boolean) => void;
  onCancel: () => void;
  onResendCode: () => void;
}

function VerificationCode({
  phoneNumber,
  onVerificationCode,
  onCancel,
  onResendCode,
  loading = false,
  error = null,
}: VerificationCodeProps) {
  const { t } = useTranslation();
  const [code, setCode] = useState<string[]>(Array.from(Array(6).keys()).map(() => ''));
  const ref = useRef<HTMLDivElement>(null);

  const formMethods = useForm({
    defaultValues: {
      rememberMe: false,
    },
    resolver: yupResolver(verificationCodeSchema),
    mode: 'onChange',
  });

  const { getValues, setValue } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { verficationCode, rememberMe } = getValues();
    onVerificationCode(verficationCode, rememberMe ?? false);
  };

  const handleFocus = (event: FocusEvent) => {
    const target = event.target as HTMLInputElement;
    if (target) {
      target.select();
    }
  };

  const handleResendCode = (event: MouseEvent) => {
    event.preventDefault();
    onResendCode();
  };

  const setCodeValue = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: number) => {
    const { value } = event.target as HTMLInputElement;
    setCode((currentCode) => currentCode.map((item, k) => (key === k ? value : item)));
    if (ref.current && ref.current.children.length - 1 > key) {
      ref.current.children[key + 1].querySelector('input')?.focus();
    }
  };

  useEffect(() => {
    setValue('verficationCode', code.join(''));
  }, [code]);

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom>{t('components.auth.verificationCode.title')}</Typography>
          <Typography variant="body2" gutterBottom>
            {`${t('components.auth.verificationCode.sentMessage')} ${phoneNumber}`}
          </Typography>
          <Stack sx={{ mb: 2 }}>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}

            <FormLabel>{t('components.auth.verificationCode.codeLabel')}</FormLabel>
            <VerficationCodeInputContainer direction="row" gap={1} ref={ref}>
              {Array.from(Array(CODE_LENGTH).keys()).map((key) => (
                <TextField
                  key={key}
                  name={`code${key}`}
                  type="tel"
                  inputProps={{
                    min: 1,
                    max: 1,
                    maxLength: 1,
                    pattern: '[0-9]*',
                  }}
                  onFocus={(event) => handleFocus(event)}
                  onChange={(event) => setCodeValue(event, key)}
                  value={code[key]}
                />
              ))}
            </VerficationCodeInputContainer>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={(e, checked) => setValue('rememberMe', checked)}
                  value={getValues().rememberMe}
                />
              )}
              label={t('components.auth.verificationCode.rememberLabel')}
            />
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!isValidValidationCode(code)}
            >
              {t('components.auth.verificationCode.continue')}
            </LoadingButton>
          </Stack>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: -2,
            }}
          >
            {t('components.auth.verificationCode.noCodeMessage')}
            <Link href="../" color="common.blue" onClick={handleResendCode}>{t('components.auth.verificationCode.resend')}</Link>
          </Typography>
          <Divider />
          <Button
            variant="text"
            color="info"
            size="small"
            sx={{ p: 0, mt: 0, mb: 2 }}
            onClick={onCancel}
          >
            {t('components.auth.verificationCode.back')}
          </Button>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default VerificationCode;
