import React from 'react';
import {
  Breadcrumbs,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { useAppSelector } from '../../../../store/hooks';
import { selectEnquiryFor } from '../../../../features/application-slice';
import { getClientDetailsEnquiryLine } from '../../../../services/enquiry-helpers';
import { EnquiryLineForm } from '../../../../components/form/enquiry';

interface LifestyleFormProps {
  applicant: ApplicantDto;
  onSubmit: () => unknown;
  onCancel: () => unknown;
}

function LifestyleForm({
  applicant,
  onSubmit,
  onCancel,
}: LifestyleFormProps) {
  const { t } = useTranslation();
  const enquiry = useAppSelector(selectEnquiryFor(applicant.id));
  const enquiryLine = getClientDetailsEnquiryLine(enquiry);
  if (!enquiryLine) {
    return null;
  }

  return (
    <EnquiryLineForm
      applicantId={applicant.id}
      enquiry={enquiry}
      enquiryLine={enquiryLine}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={(
        <Breadcrumbs separator=">">
          <span>{t('components.lifestyleForm.title')}</span>
          <span>{enquiryLine?.section}</span>
        </Breadcrumbs>
      )}
      submitLabel={t('components.lifestyleForm.confirmButtonLabel')}
      hideNavigation
    />

  );
}

export default LifestyleForm;
