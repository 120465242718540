import React, { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Alert, Button, Typography } from '@mui/material';
import useBusyState from '../../../../hooks/use-busy-state';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  VALID,
  applicantSelected,
  requestFinalDecision,
  selectActiveApplicant,
  selectApplicationStatus,
  selectAvailableApplication,
  selectInactiveApplicant,
  selectPostUnderwritingDeclarationEnabledFor,
  updateDecision,
} from '../../../../features/application-slice';
import { ApplicationStatus } from '../../../../services/models/application-status';
import {
  showIncompleteApplicantWarning,
  hasInvalidProduct,
  isPreSalesAppliction,
  getApplicantName,
} from '../../../../services/application-helpers';
import { isImmediateDecision } from '../../../../services/decision-helpers';
import { PolicySetupPage } from '../../../pages';
import { FooterBar } from '../../../../components/layout/footer';
import useProceed from '../../../../hooks/use-proceed';
import { postUnderwritingDeclarationView } from '../../../view';
import analytics from '../../../../services/analytics';
import { areAllEnquiriesClosed } from '../../../../services/enquiry-helpers';

function ActionFooter() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const proceedToNextView = useProceed();
  const [busy, withBusyState] = useBusyState();
  const {
    enquiries,
    application,
    applicationStatus,
    productsStatus,
    quoteDecision,
  } = useAppSelector(selectAvailableApplication);
  const status = useAppSelector(selectApplicationStatus);
  const activeApplicant = useAppSelector(selectActiveApplicant);
  const inactiveApplicant = useAppSelector(selectInactiveApplicant);
  const underwritingDeclarationEnabled = useAppSelector(selectPostUnderwritingDeclarationEnabledFor(activeApplicant?.id ?? ''));
  const allEnquiriesClosed = areAllEnquiriesClosed(enquiries);
  const navigate = useNavigate();

  const showGetQuote = !quoteDecision;
  const getQuoteDisabled = productsStatus.status !== VALID.status || hasInvalidProduct(isPreSalesAppliction(status), application);

  const handleEnableDecision = useCallback(withBusyState(async () => {
    analytics.trackGetQuote(application.id);
    if (allEnquiriesClosed) {
      await dispatch(requestFinalDecision());
    } else {
      await dispatch(updateDecision());
    }
  }), [allEnquiriesClosed]);

  const handleSwitchApplicant = (applicantId: string) => {
    dispatch(applicantSelected(applicantId));
  };

  const showBuyCover = (status === ApplicationStatus.Decision || status === ApplicationStatus.Underwriting) && quoteDecision;
  const buyCoverDisabled = status === ApplicationStatus.Underwriting
    || !quoteDecision || !quoteDecision.products.some((p) => isImmediateDecision(p.decision));

  const handleBuyCover = () => {
    analytics.trackBuyCover(application.id);
    navigate(generatePath(PolicySetupPage.path, { applicationId: application.id || null }));
  };

  const showUnderwritingDeclaration = activeApplicant && quoteDecision && status === ApplicationStatus.Apply && underwritingDeclarationEnabled;
  const handleUnderwritingDeclaration = () => proceedToNextView({ target: postUnderwritingDeclarationView });

  const showApplicantIncompleteWarning = showIncompleteApplicantWarning(activeApplicant, applicationStatus, enquiries);

  return (showGetQuote || showBuyCover || showUnderwritingDeclaration || showApplicantIncompleteWarning) && (
    <FooterBar>
      {showApplicantIncompleteWarning && inactiveApplicant && (
        <Alert
          sx={{ alignItems: 'center', marginRight: 'auto' }}
          severity="warning"
          action={(
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={() => handleSwitchApplicant(inactiveApplicant.id)}
            >
              {`${t('components.actionFooter.complete')} ${getApplicantName(inactiveApplicant)}`}
            </Button>
          )}
        >
          {t('components.actionFooter.incompleteApplicantWarning')}
        </Alert>
      )}
      {showGetQuote && (
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleEnableDecision}
          disabled={getQuoteDisabled}
          loading={busy}
        >
          {t('components.actionFooter.getQuote')}
        </LoadingButton>
      )}
      {showBuyCover && (
        <>
          {status === ApplicationStatus.Underwriting && (
            <Typography variant="body1" fontWeight="bold">
              {t('components.actionFooter.manualUnderwritingMessage')}
            </Typography>
          )}
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleBuyCover}
            disabled={buyCoverDisabled}
            loading={busy}
            endIcon={<ArrowForwardIcon />}
          >
            {t('components.actionFooter.buyCover')}
          </LoadingButton>
        </>
      )}
      {showUnderwritingDeclaration && (
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleUnderwritingDeclaration}
          loading={busy}
          endIcon={<ArrowForwardIcon />}
        >
          {t('components.actionFooter.underwritingDeclaration')}
        </LoadingButton>
      )}
    </FooterBar>
  );
}

export default ActionFooter;
