import {
  PreloadedState,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { UnknownAsyncThunkAction } from '@reduxjs/toolkit/dist/matchers';
import logger from 'redux-logger';
// eslint-disable-next-line import/no-cycle
import { applicationSlice } from '../features/application-slice';
// eslint-disable-next-line import/no-cycle
import { settingsSlice } from '../features/settings-slice';
// eslint-disable-next-line import/no-cycle
import { registrationSlice } from '../features/registration-slice';

const reducer = combineReducers({
  application: applicationSlice.reducer,
  settings: settingsSlice.reducer,
  registration: registrationSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV !== 'development') {
      return getDefaultMiddleware();
    }
    return getDefaultMiddleware().concat(logger);
  },
  preloadedState,
});

export const store = setupStore();

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, UnknownAsyncThunkAction>;
