import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UnderwritingStatusTable from './underwriting-status-table';
import applicationApi from '../../../../services/application-api';
import { EvidenceDto } from '../../../../services/bff/models/evidence-dto';
import { EvidenceStatus } from '../../../../services/bff/models/evidence-status';
import SectionHeader from '../../../../components/section-header';
import { toastError } from '../../../../components/toast';

interface UnderwritingStatusProps extends BoxProps {
  applicationId: string;
}

function UnderwritingStatus({ applicationId, ...props }: UnderwritingStatusProps) {
  const { t } = useTranslation();
  const [evidences, setEvidences] = useState<EvidenceDto[]>();

  const getUnderwritingStatus = async () => {
    try {
      const response = await applicationApi.getApplicationEvidence(applicationId);
      setEvidences(response.filter((evidence) => evidence.status !== EvidenceStatus.New));
    } catch {
      toastError('Error retreiving evidences');
    }
  };

  useEffect(() => {
    getUnderwritingStatus();
  }, [applicationId]);

  if (!evidences || evidences.length <= 0) return null;

  return (
    <Box {...props}>
      <SectionHeader gutterBottom>{t('components.underwritingStatus.title')}</SectionHeader>
      <UnderwritingStatusTable evidences={evidences} />
    </Box>
  );
}

export default UnderwritingStatus;
