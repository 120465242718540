import { Toolbar } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { CSSProperties } from 'react';
import { shouldForwardProp } from '../../types';

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer - 1,
  borderBottom: `2px solid ${theme.palette.common.primary}`,
  '&:has(.info-bar)': {
    borderBottom: 'none',
  },
}));

export const MainToolBar = styled(Toolbar)(({ theme }) => ({
  minHeight: theme.mixins.toolbar.minHeight,
  '@media (min-width:600px)': {
    minHeight: theme.mixins.toolbar.minHeight,
  },
}));

export const InfoBar = styled(Toolbar)(({ theme }) => {
  const allWidths: CSSProperties = {
    minHeight: theme.mixins.infoBar.minHeight,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    gap: theme.spacing(theme.mixins.mainHorizontalSpacing),
    flexWrap: 'wrap',
  };
  return {
    ...allWidths,
    [theme.breakpoints.up('sm')]: {
      ...allWidths,
    },
    backgroundColor: theme.palette.common.surface9,
    color: theme.palette.common.white,
  };
});

type LogoContainerProps = {
  size?: 'small' | 'large';
};

export const LogoContainer = styled('div', {
  shouldForwardProp: (prop) => shouldForwardProp<LogoContainerProps>(['size'], prop),
})<LogoContainerProps>(({ theme, size }) => ({
  alignItems: 'center',
  alignSelf: 'stretch',
  minWidth: size === 'small' ? 85 : 212,
  borderRight: size === 'small' ? `1px solid ${theme.palette.common.secondary200}` : 'none',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  '& > button:hover': {
    backgroundColor: 'transparent',
  },
  '& img': {
    height: size === 'small' ? 35 : 46,
  },
}));
