import React from 'react';
import { useFormContext } from 'react-hook-form';
import { QuoteBasis } from '../../../../services/models/quote-basis';
import FieldBase, { FieldBaseProps } from '../../../../components/form/fields/field-base';
import MoneyInput, { MoneyInputProps } from '../../../../components/form/inputs/money-input';
import { numberToMoney } from '../../../../utils/converters';
import { ReadonlyCoverAmount } from './cover-amount-field.styles';

interface CoverAmountFieldProp extends Omit<MoneyInputProps, 'name'>, Omit<FieldBaseProps, 'name'> {
  decisionCoverAmount?: number;
}

function CoverAmountField({
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  hideError = true,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  decisionCoverAmount = undefined,
  ...props
}: CoverAmountFieldProp) {
  const { watch } = useFormContext();
  const quoteBasis = watch('quoteBasis');
  return (
    <FieldBase
      name="coverAmount"
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      {quoteBasis === QuoteBasis.CoverAmount && (
        <MoneyInput
          name="coverAmount"
          sx={{ maxWidth: 180 }}
          min={0}
          {...props}
        />
      )}
      {quoteBasis !== QuoteBasis.CoverAmount && decisionCoverAmount && (
        <ReadonlyCoverAmount aria-labelledby="coverAmount-label">
          {numberToMoney(decisionCoverAmount)}
        </ReadonlyCoverAmount>
      )}
    </FieldBase>
  );
}

export default CoverAmountField;
