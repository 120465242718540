import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Container } from './product-availability-panel.styles';

interface ProductAvailabilityPanelProps {
  title: string
  unavailableText: string
  enabled: boolean
}

function ProductAvailabilityPanel({ title, unavailableText, enabled }: ProductAvailabilityPanelProps) {
  const { t } = useTranslation();
  return (
    <Container enabled={enabled}>
      <Typography variant="h2" align="center" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h3" align="center">
        {enabled ? t('components.productAvailabilityPanel.available') : t(unavailableText)}
      </Typography>
    </Container>
  );
}

export default ProductAvailabilityPanel;
