import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { AuthContainer, AuthPanel } from './auth.styles';
import ExeterLogo from '../../../assets/images/global/exeter-logo.svg';

function AuthLayout({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  return (
    <AuthContainer maxWidth={false} disableGutters>
      <Stack direction="column" gap={4} alignItems="center">
        <img src={ExeterLogo} width="115" alt={t('components.meta.title')!} />
        <AuthPanel elevation={0}>
          {children}
        </AuthPanel>
      </Stack>
    </AuthContainer>
  );
}

export default AuthLayout;
