import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
} from '@mui/material';
import {
  EmRatingValue,
  InlineWrapper,
} from './decision-total.styles';
import { TotalRating } from '../../../../services/models/total-rating';
import {
  hasIndicativeLoading,
  formatIndicativePmLoading,
  formatEmLoading,
  hasAnyRangedLoadings,
} from '../../../../services/decision-helpers';
import Duration from '../../../../components/duration';
import { RangedPermanentLoading } from '../../../../services/models/ranged-permanent-loading';
import { RangedTemporaryLoading } from '../../../../services/models/ranged-temporary-loading';

interface IndicativeEmLoadingProps {
  loading: RangedPermanentLoading | RangedTemporaryLoading | undefined;
}

function IndicativeEmLoading({ loading }: IndicativeEmLoadingProps) {
  const { t } = useTranslation();
  return (
    <EmRatingValue>
      {formatEmLoading(loading?.min)}
      {loading?.min !== loading?.max && (
        <>
          <Typography variant="body2" component="span">
            {' '}
            {t('common.to')}
            {' '}
          </Typography>
          {formatEmLoading(loading?.max)}
        </>
      )}
    </EmRatingValue>
  );
}

export interface TotalRatingContentProps {
  totalRating: TotalRating;
  inline?: boolean;
}
function TotalRatingContent({ totalRating, inline = false }: TotalRatingContentProps) {
  const { t } = useTranslation();
  const { em, pm } = totalRating;
  return (
    <>
      {(hasIndicativeLoading(em?.permanent) || !hasAnyRangedLoadings(totalRating)) && (
        <IndicativeEmLoading loading={em?.permanent} />
      )}
      {hasIndicativeLoading(pm?.permanent) && (
        <Typography variant="body2" fontWeight="bold">
          {formatIndicativePmLoading(pm?.permanent!)}
        </Typography>
      )}
      <InlineWrapper inline={inline}>
        {(hasIndicativeLoading(em?.permanent) || hasIndicativeLoading(pm?.permanent))
          && (hasIndicativeLoading(em?.temporary) || hasIndicativeLoading(pm?.temporary))
          && (
            <Typography variant="body2" fontWeight="bold">{t('common.and')}</Typography>
          )}
        {hasIndicativeLoading(em?.temporary) && (
          <>
            <IndicativeEmLoading loading={em?.temporary} />
            <Duration value={em?.temporary?.duration} noSpace />
          </>
        )}
        {hasIndicativeLoading(pm?.temporary) && (
          <Typography variant="body2" fontWeight="bold">
            {formatIndicativePmLoading(pm?.temporary!)}
            <Duration value={pm?.temporary?.duration} noSpace={hasIndicativeLoading(pm?.permanent)} />
          </Typography>
        )}
      </InlineWrapper>
    </>
  );
}

export default TotalRatingContent;
