import React from 'react';
import {
  Card,
  CardContent,
  CardProps,
} from '@mui/material';
import { CoverCardHeader } from './cover-panel.styles';
import { TotalPrice } from '../../../application/components/price';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { ProductCode } from '../../../../services/models/product-code';
import { ProductDto } from '../../../../services/models/product-dto';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import { HealthCareDetails, IncomeProtectionDetails, RealLifeDetails } from '../../../../components/product-details';
import ProductCardTitle from '../../../../components/product-card-title';

function getCardContent(product: ProductDto, decisionCoverAmount: number | undefined) {
  switch (product.code) {
    case ProductCode.INCOME_PROTECTION: return <IncomeProtectionDetails product={product} decisionCoverAmount={decisionCoverAmount} />;
    case ProductCode.REAL_LIFE: return (<RealLifeDetails product={product} decisionCoverAmount={decisionCoverAmount} />);
    case ProductCode.HEALTH_CARE: return <HealthCareDetails product={product} />;
    default: return null;
  }
}

interface CoverPanelProps extends CardProps {
  applicationId: string;
  applicants: ApplicantDto[]
  product: ProductDto
  quoteProduct?: ProductQuoteDecision | null
}

function CoverPanel({
  applicationId,
  applicants,
  product,
  quoteProduct = null,
  ...props
}: CoverPanelProps) {
  return (
    <Card elevation={0} {...props}>
      <CoverCardHeader
        title={<ProductCardTitle product={product} applicants={applicants} />}
        subheader={quoteProduct?.premiums?.permanentPremium ? <TotalPrice premium={quoteProduct.premiums} frequency="p/m" priceProps={{ size: 'large' }} /> : null}
      />
      <CardContent sx={{ pt: 2 }}>
        {getCardContent(product, quoteProduct?.coverAmount)}
      </CardContent>
    </Card>
  );
}

export default CoverPanel;
