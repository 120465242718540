import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    maxWidth: 485,
  },
  '& h2': {
    ...theme.typography.h2,
    fontFamily: theme.typography.h6.fontFamily,
  },
  '& p:first-of-type': {
    marginBottom: theme.spacing(2),
  },
}));

export default {};
