import React from 'react';
import { ProceedFunction } from '../../../view';
import ClientDetailsBar from '../client-details-bar/client-details-bar';
import ClientDetailsStatusBar from '../client-details-bar/client-details-status-bar';
import { useAppSelector } from '../../../../store/hooks';
import { selectView } from '../../../../features/application-slice';

interface InfoBarContentProps {
  isPreSales?: boolean;
  proceedToNextView?: ProceedFunction;
  readonly?: boolean;
}

function InfoBarContent({
  isPreSales = false,
  proceedToNextView = undefined,
  readonly = false,
}: InfoBarContentProps) {
  const view = useAppSelector(selectView);
  if (view.infoBar === 'clientDetails' && isPreSales) {
    return <ClientDetailsBar proceedToNextView={proceedToNextView} />;
  }
  if (view.infoBar === 'clientDetails' && !isPreSales) {
    return <ClientDetailsStatusBar proceedToNextView={proceedToNextView} readonly={readonly} />;
  }
  return null;
}

export default InfoBarContent;
