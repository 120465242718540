import React from 'react';
import {
  CheckboxOptionsField,
  MoneyField,
  MultiTypeaheadField,
  RadioOptionsField,
  SelectField,
  TextField,
  TypeaheadField,
  FieldBaseProps,
  HeightField,
  WeightField,
  NumberField,
} from '../fields';
import QuestionType from '../../../services/models/question-type';
import { formPath } from '../form.utils';
import { WithOnChangeCommitted } from '../../types';
import {
  GroupTags,
  GroupedQuestion,
  additionalSingleAnswerPaths,
  getExclusiveOptions,
  getGroupQuestionName,
  heightUnits,
  preSalesDisclosureQuestionName,
  weightUnits,
} from '../../../services/enquiry-helpers';
import FuzzyDateField from '../fields/fuzzy-date-field';
import { ZeroDecimalsFormatter } from '../inputs';
import AlcoholField from '../fields/alcohol-field';
import PreDisclosedPanel from './pre-disclosed-panel';

const DecimalNumberFormatter = new Intl.NumberFormat('en-UK', {
  useGrouping: false,
  maximumFractionDigits: 2,
});

interface QuestionInputProps extends Omit<FieldBaseProps, 'name'>, WithOnChangeCommitted<unknown> {
  question: GroupedQuestion;
  disabled?: boolean;
  search?: (name: string, searchValue: string) => string[] | Promise<string[]>;
}

function QuestionField({
  question,
  search = undefined,
  onChangeCommitted,
  ...props
}: QuestionInputProps) {
  const name = formPath(question);

  const handleChange = onChangeCommitted
    ? (path: string, value: unknown) => {
      if (additionalSingleAnswerPaths.includes(path) || question.groupName) {
        onChangeCommitted(path, value);
      } else {
        onChangeCommitted(question.path!, value);
      }
    }
    : undefined;

  if (!name || !question.definition) {
    return null;
  }

  if (question.name === preSalesDisclosureQuestionName && question.answers) {
    return (
      <PreDisclosedPanel conditions={question.answers ?? []} sx={{ mb: 3 }} />
    );
  }

  if (question.groupName === GroupTags.ALCOHOL && question.groupQuestions) {
    return (
      <AlcoholField
        name={getGroupQuestionName(question, GroupTags.ALCOHOL)}
        onChangeCommitted={handleChange}
        questions={question.groupQuestions}
        {...props}
      />
    );
  }

  switch (question.definition.type) {
    case QuestionType.OPTION_LIST: {
      const options = question?.definition?.options as string[];
      if (Array.isArray(options) && options.length > 0) {
        if (question.definition?.isMultiValued) {
          return (
            <CheckboxOptionsField
              name={name}
              onChangeCommitted={handleChange}
              options={options}
              exclusiveOptions={getExclusiveOptions(question?.definition?.optionTags)}
              {...props}
            />
          );
        }
        return (
          <SelectField
            name={name}
            onChangeCommitted={handleChange}
            options={options}
            {...props}
          />
        );
      }
      break;
    }
    case QuestionType.OPTION_GROUP: {
      const options = question?.definition?.options as string[];
      if (Array.isArray(options) && options.length > 0) {
        if (question.definition?.isMultiValued) {
          return (
            <CheckboxOptionsField
              name={name}
              onChangeCommitted={handleChange}
              options={options}
              exclusiveOptions={getExclusiveOptions(question?.definition?.optionTags)}
              {...props}
            />
          );
        }
        return (
          <RadioOptionsField
            name={name}
            onChangeCommitted={handleChange}
            options={options}
            {...props}
          />
        );
      }
      break;
    }
    case QuestionType.STRING: {
      return (
        <TextField
          name={name}
          onChangeCommitted={handleChange}
          // inputProps={{ maxLength: question?.definition?.maxLength ?? null }}
          {...props}
        />
      );
    }
    case QuestionType.NUMBER:
    case QuestionType.INTEGER: {
      if (question.definition.tags?.includes('CURRENCY')) {
        return (
          <MoneyField
            name={name}
            onChangeCommitted={handleChange}
            min={question?.definition?.lowerBound ?? undefined}
            max={question?.definition?.upperBound ?? undefined}
            {...props}
          />
        );
      }
      if (question.definition.tags?.includes('HEIGHT')) {
        return (
          <HeightField
            name={name}
            systemName={heightUnits}
            onChangeCommitted={handleChange}
            min={question?.definition?.lowerBound ?? undefined}
            max={question?.definition?.upperBound ?? undefined}
            {...props}
          />
        );
      }
      if (question.definition.tags?.includes('WEIGHT')) {
        return (
          <WeightField
            name={name}
            systemName={weightUnits}
            onChangeCommitted={handleChange}
            min={question?.definition?.lowerBound ?? undefined}
            max={question?.definition?.upperBound ?? undefined}
            {...props}
          />
        );
      }
      const formatter = question.definition.type === QuestionType.INTEGER ? ZeroDecimalsFormatter : DecimalNumberFormatter;
      return (
        <NumberField
          name={name}
          onChangeCommitted={handleChange}
          formatter={formatter}
          min={question?.definition?.lowerBound ?? undefined}
          max={question?.definition?.upperBound ?? undefined}
          {...props}
        />
      );
    }
    case QuestionType.DATE:
    case QuestionType.FUTURE_DATE:
    case QuestionType.PAST_DATE: {
      return (
        <FuzzyDateField name={name} onChangeCommitted={handleChange} {...props} />
      );
    }
    case QuestionType.OPTION_BACKED: {
      const handleSearch = search ? (searchValue: string) => search(question.name!, searchValue) : () => [];
      if (question.definition?.isMultiValued) {
        return (
          <MultiTypeaheadField
            key={name}
            name={name}
            onChangeCommitted={handleChange}
            minChars={question.definition.minSearchChars || undefined}
            search={handleSearch}
            freeSolo={question.definition.isStrict === false}
            {...props}
          />
        );
      }
      return (
        <TypeaheadField
          key={name}
          name={name}
          onChangeCommitted={handleChange}
          minChars={question.definition.minSearchChars || undefined}
          search={handleSearch}
          freeSolo={question.definition.isStrict === false}
          {...props}
        />
      );
    }
    default: return null;
  }
  return null;
}

export default QuestionField;
