/* eslint-disable react/require-default-props */
import React, { ElementType } from 'react';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import {
  useThemeProps,
  getTextFieldUtilityClass,
  unstable_composeClasses as composeClasses,
  TextFieldClasses,
  InputBaseProps,
  TextFieldProps as MuiTextFieldProps,
  TextFieldVariants,
} from '@mui/material';
import { TextInput } from '../inputs';
import { WithOnChangeCommitted } from '../../types';
import FieldBase, { FieldBaseProps } from './field-base';

const useUtilityClasses = (classes: Partial<TextFieldClasses> | undefined) => {
  const slots = {
    root: ['root'],
  };

  return composeClasses(slots, getTextFieldUtilityClass, classes);
};

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'InputLabelProps' | 'FormHelperTextProps' | 'name'>, FieldBaseProps, WithOnChangeCommitted<string> {
  name: string;
  InputComponent?: ElementType<InputBaseProps>;
  variant?: TextFieldVariants;
  validateChange?: (value: string) => boolean;
  transform?: (value: string) => string;
}

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>((inProps, ref) => {
  const props = useThemeProps({ props: inProps, name: 'MuiTextField' });
  const {
    fieldState: { invalid },
  } = useController({ name: props.name || '' });
  const {
    autoComplete,
    autoFocus = false,
    children,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = invalid,
    FormControlProps,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    hideError = false,
    id: idOverride,
    InputComponent = TextInput,
    inputProps,
    InputProps,
    inputRef,
    label,
    LabelProps,
    maxRows,
    minRows,
    multiline = false,
    name,
    onBlur,
    onChange,
    onChangeCommitted,
    onClick,
    onFocus,
    placeholder,
    required = false,
    rows,
    select = false,
    SelectProps,
    sublabel,
    SublabelProps,
    sx,
    type,
    value,
    variant = 'outlined',
    validateChange,
    transform,
    ...other
  } = props;

  const ownerState = {
    ...props,
    autoFocus,
    color,
    disabled,
    error,
    fullWidth,
    multiline,
    required,
    select,
    variant,
  };
  const classes = useUtilityClasses(ownerState.classes);

  const helperTextId = helperText ? `${name}-helper-text` : undefined;
  const textInputSpecificProps = InputComponent === TextInput ? { onChangeCommitted, validateChange, transform } : undefined;
  const inputElement = (
    <InputComponent
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      type={type}
      value={value}
      id={name}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onClick={onClick}
      placeholder={placeholder}
      inputProps={inputProps}
      sx={sx}
      {...textInputSpecificProps}
      {...InputProps}
    />
  );
  return (
    <FieldBase
      name={name}
      helperText={helperText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      label={label}
      LabelProps={LabelProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
      FormControlProps={{
        className: clsx(classes.root, className),
        disabled,
        fullWidth,
        ref,
        required,
        color,
        ...other,
        ...FormControlProps,
      }}
    >
      {inputElement}
    </FieldBase>
  );
});

export default TextField;
