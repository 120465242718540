import React, { Fragment, ReactNode } from 'react';
import { AddressDto } from '../../services/models/address-dto';

function addressLine(key: string, value: string | null | undefined): ReactNode {
  return value ? (
    <Fragment key={key}>
      {value}
      <br />
    </Fragment>
  ) : null;
}

function getAddressLines(address: AddressDto | null): ReactNode {
  if (!address) {
    return null;
  }
  const cityAndCounty = [address.cityName, address.county].filter((p) => p).join(', ');
  return [
    addressLine('address1', address.address1),
    addressLine('address2', address.address2),
    addressLine('address3', address.address3),
    addressLine('cityAndCounty', cityAndCounty),
  ].filter((p) => p);
}

interface AddressProps {
  address: AddressDto | null;
}

function Address({ address }: AddressProps) {
  return (
    <>{getAddressLines(address)}</>
  );
}

export default Address;
