import React from 'react';
import {
  CardContent,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../../../components/section-header/section-header';
import PlaceholderCard from '../../../../components/placeholder-card';
import { Enquiry } from '../../../../services/models/enquiry';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectAmraStatus,
  selectApplicationOrigin,
  selectPostUnderwritingDeclarationEnabledFor,
} from '../../../../features/application-slice';
import {
  getConditionEnquiryLines,
  getConditionSections,
  getSectionStatus,
  getUMConditionSections,
} from '../../../../services/enquiry-helpers';
import { EnquiryLine } from '../../../../services/models/enquiry-line';
import {
  DisclosureCard,
  StyledDisclosuresPanel,
} from './disclosure-panel.styles';
import { HintCardHeader } from '../../../../components/card-hint';
import DisclosureAccordion from './disclosure-accordion';
import { ApplicationStatus } from '../../../../services/models/application-status';
import { getUndwerwritingDeclarationStatus, isUMEApplication } from '../../../../services/application-helpers';
import EnquiryLinePanel from './enquiry-line-panel';
import { ProgressStatus } from '../../../../services/models/progress-status';
import DeclarationPanel from './declaration-panel';

function aggregate(...statuses: ProgressStatus[]): ProgressStatus {
  if (statuses.every((s) => s === ProgressStatus.Complete)) {
    return ProgressStatus.Complete;
  }
  if (statuses.every((s) => s === ProgressStatus.NotStarted)) {
    return ProgressStatus.NotStarted;
  }
  return ProgressStatus.Incomplete;
}

export interface OnboardingDisclosuresPanelProps {
  applicantId: string;
  enquiry: Enquiry;
  applicationStatus?: ApplicationStatus | null;
  disabled?: boolean;
  showDisclosures?: boolean;
  showApplyHeader?: boolean;
  onEdit?: (enquiryLine: EnquiryLine) => unknown;
  onEditAmra?: () => unknown;
  onEditUnderwritingDeclaration?: () => unknown;
}

function OnboardingDisclosuresPanel({
  applicantId,
  enquiry,
  applicationStatus = undefined,
  disabled = false,
  showDisclosures = false,
  showApplyHeader = false,
  onEdit = undefined,
  onEditAmra = undefined,
  onEditUnderwritingDeclaration = undefined,
}: OnboardingDisclosuresPanelProps) {
  const { t } = useTranslation();
  const isUME = isUMEApplication(useAppSelector(selectApplicationOrigin));
  const disclosureSections = isUME ? getUMConditionSections(enquiry) : getConditionSections(enquiry, true);
  const enquiryLines = disclosureSections.flatMap((s) => getConditionEnquiryLines(s));
  const amraStatus = useAppSelector(selectAmraStatus(applicantId));
  const uwDeclarationStatus = getUndwerwritingDeclarationStatus(enquiry);
  const uwDeclarationDisabled = !useAppSelector(selectPostUnderwritingDeclarationEnabledFor(applicantId));
  const status = aggregate(...disclosureSections.map((s) => getSectionStatus(s)), amraStatus, uwDeclarationStatus);

  const declarations = (
    <>
      <DeclarationPanel
        title={t('components.disclosuresPanel.amraTitle')}
        status={amraStatus}
        onEdit={onEditAmra}
        disabled={isUME || disabled}
      />
      <DeclarationPanel
        title={t('components.disclosuresPanel.underwritingDeclarationTitle')}
        status={uwDeclarationStatus}
        disabled={isUME || disabled || uwDeclarationDisabled}
        onEdit={onEditUnderwritingDeclaration}
      />
    </>
  );

  return (
    <StyledDisclosuresPanel>
      <SectionHeader>
        {t('components.disclosuresPanel.title')}
      </SectionHeader>
      {!showDisclosures && (
        <PlaceholderCard>
          <CardContent>{t('components.disclosuresPanel.noProductMessage')}</CardContent>
        </PlaceholderCard>
      )}
      {showDisclosures && (
        <Stack gap={3} data-testid="disclosures-section">
          {disclosureSections.length > 0 && (
            <DisclosureCard>
              {showApplyHeader && (
                <HintCardHeader
                  title={t('components.disclosuresPanel.startUnderwritingTitle')}
                />
              )}
              <CardContent>
                <Stack gap={1}>
                  <DisclosureAccordion
                    title={disclosureSections[0]?.name ?? ''}
                    status={status}
                    hideDetails={isUME}
                    expandable={!isUME}
                    expanded={applicationStatus !== ApplicationStatus.Decision}
                  >
                    {enquiryLines.map((enquiryLine) => (
                      <EnquiryLinePanel
                        key={enquiryLine.path}
                        enquiryLine={enquiryLine}
                        onEdit={onEdit}
                        disabled={disabled}
                        hideInformation
                      />
                    ))}
                    {!isUME && declarations}
                  </DisclosureAccordion>
                  {isUME && declarations}
                </Stack>
              </CardContent>
            </DisclosureCard>
          )}
        </Stack>
      )}
    </StyledDisclosuresPanel>
  );
}

export default OnboardingDisclosuresPanel;
