import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import { UnderwritingNotesStyled } from './underwriting-notes.styles';

interface UnderwritingNotesProps {
  productDecision: ProductQuoteDecision;
}

function UnderwritingNotes({ productDecision }: UnderwritingNotesProps) {
  const { t } = useTranslation();
  const notes = productDecision.uwNotes
    ? [...productDecision.restrictionNotes, productDecision.uwNotes]
    : productDecision.restrictionNotes;
  return notes.length > 0 && (
    <UnderwritingNotesStyled>
      <Typography variant="h4">{t('components.underwritingNotes.title')}</Typography>
      <ul>
        {notes.map((note, index) => (
          // eslint-disable-next-line
          <li key={index} dangerouslySetInnerHTML={{ __html: note.replace(/\n/g, '<br />') }}/>
        ))}
      </ul>
    </UnderwritingNotesStyled>
  );
}

export default UnderwritingNotes;
