import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { AvailableProductDefinition } from '../../../../services/models/available-product-definition';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { addProduct, selectApplication } from '../../../../features/application-slice';
import { ProductWithoutIdDto } from '../../../../services/models/product-without-id-dto';
import useBusyState from '../../../../hooks/use-busy-state';
import { IpPreSalesDefaults, validateApplicantsForProduct } from '../../../../services/product-helpers';
import { isPreSalesAppliction } from '../../../../services/application-helpers';
import { ProductType } from '../../../../services/models/product-type';
import { CoverType } from '../../../../services/models/cover-type';
import { toastError } from '../../../../components/toast';

interface ProductButtonsProps {
  products: AvailableProductDefinition[];
  onChange?: () => unknown;
}

function AddProductButtons({ products, onChange = undefined }: ProductButtonsProps) {
  const [busy, withBusySate] = useBusyState();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectApplication);
  const isPreSales = isPreSalesAppliction(application.status);

  const handleProductSelect = withBusySate(async (selectedProduct: AvailableProductDefinition) => {
    const data: ProductWithoutIdDto = {
      applicantIds: selectedProduct.applicants.map((applicant) => applicant.id!),
      code: selectedProduct.productCode,
      coverType: selectedProduct.productType === ProductType.Ip ? CoverType.Level : undefined,
      ...(isPreSales && selectedProduct.productType === ProductType.Ip ? IpPreSalesDefaults : undefined),
    };
    try {
      await dispatch(addProduct(application.id, data));
      if (onChange) {
        await onChange();
      }
    } catch (e) {
      toastError(t('components.addProductButtons.error'));
    }
  });
  return (
    <>
      {products.map((product) => {
        const reasonInvalid = validateApplicantsForProduct(product.applicants, product);
        const key = `${product.productCode}-${product.applicants.length}`;
        const button = (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleProductSelect(product)}
            id={product.productCode}
            key={key}
            disabled={busy || !product.available || !!reasonInvalid}
          >
            {product.name}
          </Button>
        );
        return reasonInvalid
          ? (
            <Tooltip key={key} title={reasonInvalid}>
              <span>{button}</span>
            </Tooltip>
          )
          : button;
      })}
    </>
  );
}

export default AddProductButtons;
