import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReasonDecision } from '../../../../services/models/reason-decision';
import { DecisionCard, DecisionCardContent } from './reason-decision.styles';
import ReasonDecisionDecision from './reason-decision-decision';
import ReasonDecisionRatings from './reason-decision-ratings';
import ReasonDecisionEvidences from './reason-decision-evidences';
import ReasonDecisionExclusions from './reason-decision-exclusions';
import ReasonDecisionIndicativeRatings from './reason-decision-indicative-ratings';
import ReasonDecisionIndicativeDecision from './reason-decision-indicative-decision';
import { useAppSelector } from '../../../../store/hooks';
import { selectOnboardingToolSettings } from '../../../../features/settings-slice';
import ReasonDecisionPremium from './reason-decision-premium';

export interface ReasonDecisionCardProps {
  id: string;
  reasonDecision: ReasonDecision;
}

function ReasonDecisionCard({ reasonDecision, ...props }: ReasonDecisionCardProps) {
  const { t } = useTranslation();
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled;
  return (
    <DecisionCard>
      <DecisionCardContent>
        <ReasonDecisionDecision reasonDecision={reasonDecision} {...props} />
        {onboardingEnabled && <ReasonDecisionPremium reasonDecision={reasonDecision} {...props} />}
        {!onboardingEnabled && <ReasonDecisionRatings reasonDecision={reasonDecision} {...props} />}
        <ReasonDecisionEvidences evidences={reasonDecision.evidences} {...props} />
        <ReasonDecisionExclusions exclusions={reasonDecision.exclusions} {...props} />
        <ReasonDecisionExclusions
          label={t('components.reasonDecisionCard.indicativeExclusions')}
          exclusions={reasonDecision.indicative?.exclusions}
          id={`${props.id}_indicative`}
        />
        {!onboardingEnabled && <ReasonDecisionIndicativeRatings reasonDecision={reasonDecision} {...props} />}
        <ReasonDecisionIndicativeDecision decision={reasonDecision.indicative?.decision} {...props} />
      </DecisionCardContent>
    </DecisionCard>
  );
}

export default ReasonDecisionCard;
