import React, { PropsWithChildren, ReactNode } from 'react';
import { Drawer } from './application-layout.styles';
import { Layout, MainContent } from '../../../../components/layout';
import ApplicationHeader from './application-header';

interface ApplicationLayoutProps extends PropsWithChildren {
  info?: ReactNode;
  drawer?: ReactNode;
  drawerOpen?: boolean;
  footer?: ReactNode;
}

function ApplicationLayout({
  children,
  info = undefined,
  drawer = undefined,
  drawerOpen = false,
  footer = undefined,
}: ApplicationLayoutProps) {
  return (
    <Layout header={<ApplicationHeader />} info={info} footer={footer}>
      <MainContent maxWidth={false}>
        {children}
      </MainContent>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
      >
        {drawer}
      </Drawer>
    </Layout>
  );
}

export default ApplicationLayout;
