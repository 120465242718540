import {
  Chip,
  Popper,
  styled,
} from '@mui/material';
import { shouldForwardProp } from '../../../../components/types';

export type ReferenceChipProps = {
  readOnly?: boolean;
};

export const ReferenceChip = styled(Chip, {
  shouldForwardProp: (prop) => shouldForwardProp<ReferenceChipProps>(['readOnly'], prop),
})<ReferenceChipProps>(({ theme, readOnly }) => ({
  ...theme.typography.body2,
  '& > .MuiChip-label': {
    paddingRight: theme.spacing(2),
  },
  '& .MuiChip-deleteIcon': {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    marginLeft: !readOnly ? theme.spacing(-2) : -4,
    '& > span:first-of-type': {
      marginRight: theme.spacing(0.5),
    },
    '& > span:nth-of-type(2)': {
      textDecoration: 'underline',
    },
  },
}));

export const ReferencePopper = styled(Popper)(({ theme }) => ({
  zIndex: 2000,
  '& > div': {
    border: `1px solid ${theme.palette.common.surface5}`,
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  '& > div:before': {
    content: '""',
    position: 'absolute',
    top: 'calc(50% - 2.5px)',
    left: 5,
    width: 5,
    height: 5,
    backgroundColor: theme.palette.common.surface5,
    transform: 'rotate(45deg)',
  },
  '& .MuiInputBase-root': {
    '&:has(input:focus)': {
      border: 'none',
    },
    border: 'none',
    background: theme.palette.common.white,
    '&:hover, :focus, :active': {
      border: 'none',
    },
  },
}));

export default {};
