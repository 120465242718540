import React, { ReactNode, Ref, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import {
  AppBar as AppBarStyled,
  InfoBar,
  LogoContainer,
  MainToolBar,
} from './app-bar.styles';
import LogoSmall from '../../../assets/images/global/exeter-logo-sm.svg';
import Logo from '../../../assets/images/global/exeter-logo.svg';
import { DashboardPage } from '../../../routes/pages';
import UserMenu from '../../user-menu';
import { useAppDispatch } from '../../../store/hooks';
import { clearApplication } from '../../../features/application-slice';

interface AppBarProps {
  header: ReactNode;
  info?: ReactNode;
  logoSize?: 'small' | 'large';
}

function AppBar({ header, info = undefined, logoSize = 'small' }: AppBarProps, ref: Ref<HTMLElement>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigate = () => {
    dispatch(clearApplication());
    navigate(DashboardPage.path);
  };

  return (
    <AppBarStyled position="fixed" ref={ref}>
      <MainToolBar disableGutters>
        <LogoContainer size={logoSize}>
          <IconButton disableFocusRipple disableTouchRipple onClick={handleNavigate} id="logo">
            <img src={logoSize === 'large' ? Logo : LogoSmall} alt={t('components.appBar.logoAlt') ?? ''} />
          </IconButton>
        </LogoContainer>
        {header}
        <UserMenu sx={{ float: 'right', mr: 2 }} />
      </MainToolBar>
      {info && (
        <InfoBar className="info-bar">
          {info}
        </InfoBar>
      )}
    </AppBarStyled>
  );
}

export default forwardRef(AppBar);
