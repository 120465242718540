import { ReactNode, useEffect, useState } from 'react';
import currentVersion from '../../version.json';

interface CacheBusterProps {
  children: (busy: boolean, version: string | null) => ReactNode;
}

function CacheBuster({ children }: CacheBusterProps) {
  const [busy, setBusy] = useState<boolean>(false);
  const [version, setVersion] = useState<string | null>(null);

  const init = async () => {
    try {
      setBusy(true);
      fetch('/version.json', { cache: 'no-cache' })
        .then((response) => response.json())
        .then((data) => {
          setVersion(data.commit);
          if (data.commit !== currentVersion.commit) {
            // Fetch a non-cached version of the index file
            fetch('/', { cache: 'no-cache' }).then(() => {
              window.location.reload();
            });
          }
        });
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return children(busy, version);
}

export default CacheBuster;
