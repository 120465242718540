import React from 'react';
import {
  Accordion,
  AccordionProps,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { ProductQuoteDecisionAccordionDetails, ProductQuoteDecisionAccordionSummary } from './product-quote-decision-accordion.styles';

export interface ProductQuoteDecisionDetailsProps extends AccordionProps {
  productId: string;
  title?: string;
}

function ProductQuoteDecisionAccordion({
  productId,
  title = undefined,
  children,
  ...props
}: ProductQuoteDecisionDetailsProps) {
  const { t } = useTranslation();
  return (
    <Accordion disableGutters {...props}>
      <ProductQuoteDecisionAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${productId}-quoteDecision-content`}
        id={`${productId}-quoteDecision-header`}
        data-testid="quote-decision-accordion"
      >
        <Typography variant="h4">{title || t('components.productQuoteDecisionAccordion.title')}</Typography>
      </ProductQuoteDecisionAccordionSummary>
      <ProductQuoteDecisionAccordionDetails>
        {children}
      </ProductQuoteDecisionAccordionDetails>
    </Accordion>
  );
}

export default ProductQuoteDecisionAccordion;
