import React, { MouseEvent, useEffect, useState } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  Box,
  BoxProps,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { AuthSession } from '../../services/auth-api';

interface UserMenuProps extends BoxProps {}

function UserMenu(props: UserMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [username, setUsername] = useState<string | null>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    await AuthSession.signOut();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUsername = async () => {
    setUsername(await AuthSession.getCurrentUserName());
  };

  const checkAuthenticated = async () => {
    setIsAuthenticated(await AuthSession.isCurrentUserAuthenticated());
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUsername();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    checkAuthenticated();
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box {...props}>
      <IconButton
        size="large"
        aria-label="user options"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="secondary"
      >
        <AccountCircleOutlinedIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {username && (
          <ListItem>
            <ListItemText
              secondary="Logged in as:"
              primary={username}
              sx={{ display: 'flex', flexDirection: 'column-reverse' }}
            />
          </ListItem>
        )}
        <Divider />
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}

export default UserMenu;
