import React, { ReactNode } from 'react';
import {
  CardContent,
  CardHeader,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import WalletIcon from '../../../../assets/icons/wallet-icon.svg';
import SettingsIcon from '../../../../assets/icons/settings-icon.svg';
import NotesIcon from '../../../../assets/icons/notes-icon.svg';
import { LinkButton, ToolCard } from './tools.styles';

const tools = [
  {
    name: 'incomeFirstToolkit',
    url: '//www.the-exeter.com/adviser/income-protection/income-first-toolkit/',
    icon: WalletIcon,
  },
  {
    name: 'incomeRiskCalculator',
    url: '//www.the-exeter.com/adviser/income-protection/income-risk-calculator/',
    icon: SettingsIcon,
  },
  {
    name: 'lettersEditor',
    url: '//www.the-exeter.com/adviser/income-protection/suitability-letters-editor/',
    icon: NotesIcon,
  },
];

interface ToolListItemProps extends ListItemProps {
  title: string
  description: string
  icon: ReactNode
  action: ReactNode
}

function ToolListItem({
  title,
  description,
  icon,
  action,
  ...props
}: ToolListItemProps) {
  return (
    <ListItem {...props}>
      <ListItemAvatar>
        {icon}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={description}
      />
      <ListItemSecondaryAction>
        {action}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function Tools() {
  const { t } = useTranslation();
  return (
    <ToolCard>
      <CardHeader title={t('components.dashboard.tools.title')} titleTypographyProps={{ align: 'left' }} sx={{ pl: 3, pr: 3 }} />
      <CardContent sx={{ p: 1 }}>
        <List dense sx={{ p: 0, my: 1 }}>
          {tools.map(({ name, icon, url }) => (
            <ToolListItem
              key={name}
              title={t(`components.dashboard.tools.${name}.title`)}
              description={t(`components.dashboard.tools.${name}.description`)}
              icon={<img src={icon} width={32} alt={t(`components.dashboard.tools.${name}.title`)!} />}
              action={(
                <Link
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkButton
                    variant="text"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                  >
                    {t(`components.dashboard.tools.${name}.link`)}
                  </LinkButton>
                </Link>
              )}
            />
          ))}
        </List>
      </CardContent>
    </ToolCard>
  );
}

export default Tools;
