import React from 'react';
import FieldBase, { FieldBaseProps } from './field-base';
import { CheckboxInput, CheckboxInputProps } from '../inputs';

interface CheckboxFieldProps<T> extends Omit<CheckboxInputProps<T>, 'label'>, FieldBaseProps {
  valueLabel?: string | null;
}

function CheckboxField<T>({
  name,
  valueLabel = '',
  label = undefined,
  LabelProps = undefined,
  helperText = undefined,
  infoText = undefined,
  hideError = undefined,
  FormHelperTextProps = undefined,
  FormControlProps = undefined,
  sublabel = undefined,
  SublabelProps = undefined,
  ...props
}: CheckboxFieldProps<T>) {
  const labelId = label ? `${name}-label` : undefined;
  return (
    <FieldBase
      name={name}
      label={label}
      LabelProps={LabelProps}
      helperText={helperText}
      infoText={infoText}
      hideError={hideError}
      FormHelperTextProps={FormHelperTextProps}
      FormControlProps={FormControlProps}
      sublabel={sublabel}
      SublabelProps={SublabelProps}
    >
      <CheckboxInput
        name={name}
        label={valueLabel}
        {...props}
        aria-labelledby={labelId}
      />
    </FieldBase>
  );
}

export default CheckboxField;
